import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_MENU = gql`
  subscription onCreateMenu {
    onCreateMenu {
      menu {
        id
        name
        desc {
          title
          short
          body
          website
          style
          rows
        }
        type
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
          type
          desc
        }
        action
        params
        rank
        style
        domain
        locationId
        component
        childNodeIds
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export const ON_UPDATE_MENU = gql`
  subscription onUpdateMenu {
    onUpdateMenu {
      menu {
        id
        name
        desc {
          title
          short
          body
          website
          style
          rows
        }
        type
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
          type
          desc
        }
        action
        params
        rank
        style
        domain
        locationId
        component
        childNodeIds
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export const ON_DELETE_MENU = gql`
  subscription onDeleteMenu {
    onDeleteMenu
  }
`;

const useOnCreateMenu = () => useSubscription(ON_CREATE_MENU);
const useOnUpdateMenu = () => useSubscription(ON_UPDATE_MENU);
const useOnDeleteMenu = () => useSubscription(ON_DELETE_MENU);

export { useOnCreateMenu, useOnUpdateMenu, useOnDeleteMenu };

import { gql } from "@apollo/client";

const CREATE_CHANNEL = gql`
  mutation createChannel($input: CreateChannelInput!) {
    createChannel(input: $input) {
      channel {
        id
        name
        desc {
          title
          short
          body
          website
          style
          rows
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        availability {
          from
          to
        }
        style
        type
        category
        domain
        locationId
        status
        menuId
        channelURI {
          type
          protocol
          address
          locator
          port
        }
        altURI
        serviceId
        globalServiceId
        channelNum {
          major
          minor
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const COPY_CHANNEL_TO_OTHER_LOCATION = gql`
  mutation copyChannelToOtherLocation($input: CopyChannelToOtherLocationInput!) {
    copyChannelToOtherLocation(input: $input) {
      channel {
        id
        name
        desc {
          title
          short
          body
          website
          style
          rows
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        availability {
          from
          to
        }
        style
        type
        category
        domain
        locationId
        status
        menuId
        channelURI {
          type
          protocol
          address
          locator
          port
        }
        altURI
        serviceId
        globalServiceId
        channelNum {
          major
          minor
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const COPY_CHANNEL_LINE_UP_TO_OTHER_LOCATION = gql`
  mutation copyChannelLineUpToOtherLocation($input: CopyChannelLineUpToOtherLocationInput!) {
    copyChannelLineUpToOtherLocation(input: $input) {
      total
      channels {
        id
        name
        desc {
          title
          short
          body
          website
          style
          rows
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        availability {
          from
          to
        }
        style
        type
        category
        domain
        locationId
        status
        menuId
        channelURI {
          type
          protocol
          address
          locator
          port
        }
        altURI
        serviceId
        globalServiceId
        channelNum {
          major
          minor
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const UPDATE_CHANNEL = gql`
  mutation updateChannel($input: UpdateChannelInput!) {
    updateChannel(input: $input) {
      channel {
        id
        name
        desc {
          title
          short
          body
          website
          style
          rows
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        availability {
          from
          to
        }
        style
        type
        category
        domain
        locationId
        status
        menuId
        channelURI {
          type
          protocol
          address
          locator
          port
        }
        altURI
        serviceId
        globalServiceId
        channelNum {
          major
          minor
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const DELETE_CHANNEL = gql`
  mutation deleteChannel($id: ID!) {
    deleteChannel(id: $id) {
      id
    }
  }
`;

const DELETE_ALL_CHANNELS = gql`
  mutation deleteAllChannels($input: DeleteAllInput!) {
    deleteAllChannels(input: $input) {
      total
    }
  }
`;

export {
  CREATE_CHANNEL,
  UPDATE_CHANNEL,
  DELETE_CHANNEL,
  DELETE_ALL_CHANNELS,
  COPY_CHANNEL_TO_OTHER_LOCATION,
  COPY_CHANNEL_LINE_UP_TO_OTHER_LOCATION,
};

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Slide } from "@mui/material";
import { actionDeleteAlertFolder, actionUpdateAlertFolder, getAllAlertFolders } from "../store/AlertFolder.slice";
import { getAllAlertAssets } from "../store/AlertAsset.slice";
import {
  useOnCreateAlertFolder,
  useOnUpdateAlertFolder,
  useOnDeleteAlertFolder,
} from "../graphQL/alertFolder/subscription";
import AlertFolderCard from "../components/CustomCards/AlertFolderCard/AlertFolderCard";

import { useAppContext } from "../contexts/AppContext";
import { useOnCreateAsset, useOnDeleteAsset, useOnUpdateAsset } from "../graphQL/asset/subscription";
import { useOnCreateAssetFolder, useOnDeleteAssetFolder, useOnUpdateAssetFolder } from "../graphQL/assetFolder/subscription";
import { useOnCreateChannel, useOnDeleteChannel, useOnUpdateChannel } from "../graphQL/channels/subscription";
import { useOnCreateLocation, useOnDeleteLocation, useOnUpdateLocation } from "../graphQL/locations/subscription";
import { useOnCreateUserFolder, useOnDeleteUserFolder, useOnUpdateUserFolder } from "../graphQL/userFolder/subscription";
import { useOnCreateUserGroup, useOnDeleteUserGroup, useOnUpdateUserGroup } from "../graphQL/userGroup/subscription";
import { actionDeleteAsset, actionDeleteMyAsset, actionUpdateAsset, actionUpdateMyAsset } from "../store/Asset.slice";
import { useOnCreateMenu, useOnDeleteMenu, useOnUpdateMenu } from "../graphQL/menu/subscription";
import { actionDeleteMenu, actionUpdateMenu } from "../store/Menu.slice";
import { useOnCreateAppUser, useOnDeleteAppUser, useOnUpdateAppUser } from "../graphQL/user/subscription";
import { actionDeleteUser, actionUpdateUser } from "../store/User.slice";
// import { toast } from "../libs";

function SubscriptionWrapper() {
  const dispatch = useDispatch();

  const { data: createdAlertFolder, loading: loadingCreateAlertFolder } = useOnCreateAlertFolder();
  const { data: updatedAlertFolder, loading: loadingUpdateAlertFolder } = useOnUpdateAlertFolder();
  const { data: deletedAlertFolder, loading: loadingDeleteAlertFolder } = useOnDeleteAlertFolder();
  
  const { data: createAsset, loading: loadingCreateAsset, error: errorCreateAsset } = useOnCreateAsset()
  const { data: updateAsset, loading: loadingUpdateAsset, error: errorUpdateAsset } = useOnUpdateAsset()
  const { data: deleteAsset, loading: loadingDeleteAsset, error: errorDeleteAsset } = useOnDeleteAsset()

  const { data: createAssetFolder, loading: loadingCreateAssetFolder, error: errorCreateAssetFolder } = useOnCreateAssetFolder()
  const { data: updateAssetFolder, loading: loadingUpdateAssetFolder, error: errorUpdateAssetFolder } = useOnUpdateAssetFolder()
  const { data: deleteAssetFolder, loading: loadingDeleteAssetFolder, error: errorDeleteAssetFolder } = useOnDeleteAssetFolder()

  const { data: createChannel, loading: loadingCreateChannel, error: errorCreateChannel } = useOnCreateChannel()
  const { data: updateChannel, loading: loadingUpdateChannel, error: errorUpdateChannel } = useOnUpdateChannel()
  const { data: deleteChannel, loading: loadingDeleteChannel, error: errorDeleteChannel } = useOnDeleteChannel()

  const { data: createLocation, loading: loadingCreateLocation, error: errorCreateLocation } = useOnCreateLocation()
  const { data: updateLocation, loading: loadingUpdateLocation, error: errorUpdateLocation } = useOnUpdateLocation()
  const { data: deleteLocation, loading: loadingDeleteLocation, error: errorDeleteLocation } = useOnDeleteLocation()

  const { data: createUserFolder, loading: loadingCreateUserFolder, error: errorCreateUserFolder } = useOnCreateUserFolder()
  const { data: updateUserFolder, loading: loadingUpdateUserFolder, error: errorUpdateUserFolder } = useOnUpdateUserFolder()
  const { data: deleteUserFolder, loading: loadingDeleteUserFolder, error: errorDeleteUserFolder } = useOnDeleteUserFolder()

  const { data: createUserGroup, loading: loadingCreateUserGroup, error: errorCreateUserGroup } = useOnCreateUserGroup()
  const { data: updateUserGroup, loading: loadingUpdateUserGroup, error: errorUpdateUserGroup } = useOnUpdateUserGroup()
  const { data: deleteUserGroup, loading: loadingDeleteUserGroup, error: errorDeleteUserGroup } = useOnDeleteUserGroup()

  const { data: createMenu, loading: loadingCreateMenu, error: errorCreateMenu } = useOnCreateMenu()
  const { data: updateMenu, loading: loadingUpdateMenu, error: errorUpdateMenu } = useOnUpdateMenu()
  const { data: deleteMenu, loading: loadingDeleteMenu, error: errorDeleteMenu } = useOnDeleteMenu()

  const { data: createUser, loading: loadingCreateUser, error: errorCreateUser } = useOnCreateAppUser()
  const { data: updateUser, loading: loadingUpdateUser, error: errorUpdateUser } = useOnUpdateAppUser()
  const { data: deleteUser, loading: loadingDeleteUser, error: errorDeleteUser } = useOnDeleteAppUser()

  const [{ newAlertFolder }, { setNewAlertFolder }] = useAppContext();

  useEffect(() => {
    dispatch(getAllAlertAssets());
    dispatch(getAllAlertFolders());
  }, [dispatch]);

  useEffect(() => {
    if (!errorCreateAsset && !loadingCreateAsset && createAsset) {
      console.log(createAsset)
      dispatch(actionUpdateAsset(createAsset.onCreateMediaAsset.mediaAsset));
      dispatch(actionUpdateMyAsset(createAsset.onCreateMediaAsset.mediaAsset));
    } 
    if (errorCreateAsset) {
      console.log(errorCreateAsset)
    }
  }, [
    createAsset, loadingCreateAsset, errorCreateAsset
  ])

  useEffect(() => {
    if (!errorUpdateAsset && !loadingUpdateAsset && updateAsset) {
      console.log(updateAsset)
      dispatch(actionUpdateAsset(updateAsset.onUpdateMediaAsset.mediaAsset));
      dispatch(actionUpdateMyAsset(updateAsset.onUpdateMediaAsset.mediaAsset));
    }
    if (errorUpdateAsset) {
      console.log(errorUpdateAsset)
    }
  }, [
    updateAsset, loadingUpdateAsset, errorUpdateAsset
  ])

  useEffect(() => {
    if (deleteAsset) {
      console.log(deleteAsset.onDeleteMediaAsset)
      dispatch(actionDeleteAsset(deleteAsset.onDeleteMediaAsset.id));
      dispatch(actionDeleteMyAsset(deleteAsset.onDeleteMediaAsset.id));
    }
    if (errorDeleteAsset) {
      console.log(errorDeleteAsset)
    }

  }, [
    deleteAsset, loadingDeleteAsset, errorDeleteAsset
  ])

  useEffect(() => {
    if (!errorCreateAssetFolder && !loadingCreateAssetFolder && createAssetFolder) {
      console.log(createAssetFolder)
    } 
    if (errorCreateAssetFolder) {
      console.log(errorCreateAssetFolder)
    }
  }, [
    createAssetFolder, loadingCreateAssetFolder, errorCreateAssetFolder
  ])

  useEffect(() => {
    if (!errorUpdateAssetFolder && !loadingUpdateAssetFolder && updateAssetFolder) {
      console.log(updateAssetFolder)
    }
    if (errorUpdateAssetFolder) {
      console.log(errorUpdateAssetFolder)
    }
  }, [
    updateAssetFolder, loadingUpdateAssetFolder, errorUpdateAssetFolder
  ])

  useEffect(() => {
    if (deleteAssetFolder) {
      console.log(deleteAssetFolder)
    }
    if (errorDeleteAssetFolder) {
      console.log(errorDeleteAssetFolder)
    }

  }, [
    deleteAssetFolder, loadingDeleteAssetFolder, errorDeleteAssetFolder
  ])

  useEffect(() => {
    if (!errorCreateChannel && !loadingCreateChannel && createChannel) {
      console.log(createChannel)
    } 
    
    if (errorCreateChannel) {
      console.log(errorCreateChannel)
    }
  }, [
    createChannel, loadingCreateChannel, errorCreateChannel
  ])

  useEffect(() => {
    if (!errorUpdateChannel && !loadingUpdateChannel && updateChannel) {
      console.log(updateChannel)
    }
    if (errorUpdateChannel) {
      console.log(errorUpdateChannel)
    }
  }, [
    updateChannel, loadingUpdateChannel, errorUpdateChannel
  ])

  useEffect(() => {
    if (deleteChannel) {
      console.log(deleteChannel)
    }
    if (errorDeleteChannel) {
      console.log(errorDeleteChannel)
    }
  }, [
    deleteChannel, loadingDeleteChannel, errorDeleteChannel
  ])

  useEffect(() => {
    if (!errorCreateLocation && !loadingCreateLocation && createLocation) {
      console.log(createLocation)
    } 
    if (errorCreateLocation) {
      console.log(errorCreateLocation)
    }
  }, [
    createLocation, loadingCreateLocation, errorCreateLocation
  ])

  useEffect(() => {
    if (!errorUpdateLocation && !loadingUpdateLocation && updateLocation) {
      console.log(updateLocation)
    }
    if (errorUpdateLocation) {
      console.log(errorUpdateLocation)
    }
  }, [
    updateLocation, loadingUpdateLocation, errorUpdateLocation
  ])

  useEffect(() => {
    if (deleteLocation) {
      console.log(deleteLocation)
    }
    if (errorDeleteLocation) {
      console.log(errorDeleteLocation)
    }

  }, [
    deleteLocation, loadingDeleteLocation, errorDeleteLocation
  ])

  useEffect(() => {
    if (!errorCreateUserFolder && !loadingCreateUserFolder && createUserFolder) {
      console.log(createUserFolder)
    } 
    if (errorCreateUserFolder) {
      console.log(errorCreateUserFolder)
    }
  }, [
    createUserFolder, loadingCreateUserFolder, errorCreateUserFolder
  ])

  useEffect(() => {
    if (!errorUpdateUserFolder && !loadingUpdateUserFolder && updateUserFolder) {
      console.log(updateUserFolder)
    }
    if (errorUpdateUserFolder) {
      console.log(errorUpdateUserFolder)
    }
  }, [
    updateUserFolder, loadingUpdateUserFolder, errorUpdateUserFolder
  ])

  useEffect(() => {
    if (deleteUserFolder) {
      console.log(deleteUserFolder)
    }
    if (errorDeleteUserFolder) {
      console.log(errorDeleteUserFolder)
    }

  }, [
    deleteUserFolder, loadingDeleteUserFolder, errorDeleteUserFolder
  ])

  useEffect(() => {
    if (!errorCreateUserGroup && !loadingCreateUserGroup && createUserGroup) {
      console.log(createUserGroup)
    } 
    if (errorCreateUserGroup) {
      console.log(errorCreateUserGroup)
    }
  }, [
    createUserGroup, loadingCreateUserGroup, errorCreateUserGroup
  ])

  useEffect(() => {
    if (!errorUpdateUserGroup && !loadingUpdateUserGroup && updateUserGroup) {

    }
    if (errorUpdateUserGroup) {
      console.log(errorUpdateUserGroup)
    }
  }, [
    updateUserGroup, loadingUpdateUserGroup, errorUpdateUserGroup
  ])

  useEffect(() => {
    if (deleteUserGroup) {
      console.log(deleteUserGroup)
    }
    if (errorDeleteUserGroup) {
      console.log(errorDeleteUserGroup)
    }

  }, [
    deleteUserGroup, loadingDeleteUserGroup, errorDeleteUserGroup
  ])

  useEffect(() => {
    if (!errorCreateMenu && !loadingCreateMenu && createMenu) {
      console.log(createMenu);
      dispatch(actionUpdateMenu(createMenu?.onCreateMenu?.menu));
    } 
    if (errorCreateMenu) {
      console.log(errorCreateMenu)
    }
  }, [createMenu, loadingCreateMenu, errorCreateMenu, dispatch]);

  useEffect(() => {
    if (!errorUpdateMenu && !loadingUpdateMenu && updateMenu) {
      console.log(updateMenu);
      dispatch(actionUpdateMenu(updateMenu?.onUpdateMenu?.menu));
    }
    if (errorUpdateMenu) {
      console.log(errorUpdateMenu)
    }
  }, [updateMenu, loadingUpdateMenu, errorUpdateMenu, dispatch]);

  useEffect(() => {
    if (deleteMenu) {
      console.log(deleteMenu)
      dispatch(actionDeleteMenu(deleteMenu?.onDeleteMenu?.id));
    }
    if (errorDeleteMenu) {
      console.log(errorDeleteMenu)
    }
  }, [deleteMenu, loadingDeleteMenu, errorDeleteMenu, dispatch]);

  useEffect(() => {
    if (!loadingCreateAlertFolder && createdAlertFolder?.onCreateAlertFolder) {
      //   console.log(createdAlertFolder?.onCreateAlertFolder?.alertFolder);
      const folder = createdAlertFolder?.onCreateAlertFolder?.alertFolder;
      setNewAlertFolder(folder);
      dispatch(actionUpdateAlertFolder(createdAlertFolder?.onCreateAlertFolder?.alertFolder));
    }
  }, [createdAlertFolder, dispatch, loadingCreateAlertFolder]); //eslint-disable-line

  useEffect(() => {
    if (!loadingUpdateAlertFolder && updatedAlertFolder?.onUpdateAlertFolder) {
      //   console.log(updatedAlertFolder?.onUpdateAlertFolder?.alertFolder);
      const folder = updatedAlertFolder?.onUpdateAlertFolder?.alertFolder;
      setNewAlertFolder(folder);
      dispatch(actionUpdateAlertFolder(updatedAlertFolder?.onUpdateAlertFolder?.alertFolder));
    }
  }, [updatedAlertFolder, loadingUpdateAlertFolder, dispatch]); //eslint-disable-line

  useEffect(() => {
    if (!loadingDeleteAlertFolder && deletedAlertFolder?.onDeleteAlertFolder) {
      dispatch(actionDeleteAlertFolder(deletedAlertFolder?.onDeleteAlertFolder?.id));
    }
  }, [deletedAlertFolder, dispatch, loadingDeleteAlertFolder]);

  useEffect(() => {
    if (!errorCreateUser && !loadingCreateUser && createUser) {
      console.log(createUser);
      dispatch(actionUpdateUser(createUser?.onCreateAppUser?.user));
    } 
    if (errorCreateUser) {
      console.log(errorCreateUser)
    }
  }, [createUser, loadingCreateUser, errorCreateUser, dispatch]);

  useEffect(() => {
    if (!errorUpdateUser && !loadingUpdateUser && updateUser) {
      console.log(updateUser);
      dispatch(actionUpdateUser(updateUser?.onUpdateAppUser?.user));
    }
    if (errorUpdateUser) {
      console.log(errorUpdateUser)
    }
  }, [updateUser, loadingUpdateUser, errorUpdateUser, dispatch]);

  useEffect(() => {
    if (deleteUser) {
      console.log(deleteUser)
      dispatch(actionDeleteUser(deleteUser?.onDeleteAppUser?.id));
    }
    if (errorDeleteUser) {
      console.log(errorDeleteUser)
    }
  }, [deleteUser, loadingDeleteUser, errorDeleteUser, dispatch]);

  return (
    <>
      <Box
        sx={{
          display: "none",
        }}
      ></Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 999,
        }}
      >
        <Slide direction="up" in={!!newAlertFolder} mountOnEnter unmountOnExit>
          <Box>
            <AlertFolderCard alertFolder={newAlertFolder}></AlertFolderCard>
          </Box>
        </Slide>
      </Box>
    </>
  );
}

export default SubscriptionWrapper;

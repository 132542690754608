import moment from "moment";
import config from "../config";
import { useSyncExternalStore } from "react";
export * from "./helpers";

export const downloadUsingFetch = async (file) => {
  // console.log(file);
  const fileContent = await fetch(`${config.ENV.REACT_APP_HLS_BASE_URL}/${file?.directory}/${file?.name}`);
  const fileBlob = await fileContent.blob();
  const fileURL = URL.createObjectURL(fileBlob);

  const anchor = document.createElement("a");
  anchor.href = fileURL;
  anchor.download = file.name;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(fileBlob);
};

export const downloadUsingFetchFromUrl = async (url) => {
  const fileContent = await fetch(url);
  const fileBlob = await fileContent.blob();
  const fileURL = URL.createObjectURL(fileBlob);

  const anchor = document.createElement("a");
  anchor.href = fileURL;
  anchor.download = url.replace(/^.*(\\|\/|:)/, "");

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(fileBlob);
};

export const getAbsoluteUrl = (url) => {
  return `${config.ENV.REACT_APP_HLS_BASE_URL}/${url}`.replace("//", "/");
};

export const getPlayerTime = (time) => {
  if (!time) time = 0;
  time = Math.floor(time);
  let h = Math.floor(time / 3600);
  let m = Math.floor((time % 3600) / 60);
  let s = time % 60;
  if (h > 0) return `${h}:${m > 10 ? m : `0${m}`}:${s > 10 ? s : `0${s}`}`;
  return `${m}:${s < 10 ? `0${s}` : s}`;
};

export const isImageType = (type) => {
  return type?.includes("image");
};

export const isVideoType = (type) => {
  return type?.includes("video");
};

export const isAudioType = (type) => {
  return type?.includes("audio");
};

export const isVideoAsset = (asset) => {
  if (asset?.file?.contentType?.includes("video")) return true;
  return false;
};

export const isImageAsset = (asset) => {
  if (asset?.file?.contentType?.includes("image")) return true;
  return false;
};

export const isAudioAsset = (asset) => {
  if (asset?.file?.contentType?.includes("audio")) return true;
  return false;
};

export const isPossibleTypeForAsset = (type) => {
  if (type?.includes("video")) return true;
  if (type?.includes("audio")) return true;
  if (type?.includes("image")) return true;
  return false;
};

export const getHLSUrl = (hlsPath) => {
  const baseUrl = config.ENV.REACT_APP_HLS_BASE_URL;
  return baseUrl + hlsPath;
};

export const getUserAvatarPath = (avatar) => {
  if (!avatar?.dir || !avatar?.name) return null;
  return `${avatar?.base || config.ENV.REACT_APP_HLS_BASE_URL}/${avatar?.dir}/${avatar?.name}`;
};

export const getUserThumbnailPath = (avatar) => {
  if (!avatar?.dir || !avatar?.thumbnail) return null;
  return `${avatar?.base || config.ENV.REACT_APP_HLS_BASE_URL}/${avatar?.dir}/${avatar?.thumbnail}`;
};

export const getAvatarPath = (avatar) => {
  if (avatar?.name) {
    if (avatar?.base) {
      return `${config.ENV.REACT_APP_HLS_BASE_URL}/${avatar?.base}/${avatar?.dir}/${avatar?.name}`;
    }
    return `${config.ENV.REACT_APP_HLS_BASE_URL}/${avatar?.dir}/${avatar?.name}`;
  }
  return null;
};

export const getAvatarPathWithDomain = (avatar, domain = "") => {
  if (avatar?.name) {
    if (domain) {
      return `${config.ENV.REACT_APP_HLS_BASE_URL}/${domain}/${avatar?.dir}/${avatar?.name}`;
    }
    if (avatar?.base) {
      return `${config.ENV.REACT_APP_HLS_BASE_URL}/${avatar?.base}/${avatar?.dir}/${avatar?.name}`;
    }
    return `${config.ENV.REACT_APP_HLS_BASE_URL}/${avatar?.dir}/${avatar?.name}`;
  }
  return null;
};

export const getAvatarThumbnailPath = (avatar) => {
  if (avatar?.status) {
    if (avatar?.thumbnail) {
      return `${config.ENV.REACT_APP_HLS_BASE_URL}/${avatar?.dir}/${avatar?.thumbnail}`;
    }
  } else {
    return null;
  }
  return null;
};

export const getAssetAvatarPath = (asset) => {
  if (isImageAsset(asset)) {
    return getMediaUrl(asset);
  }
  if (asset?.avatar)
    if (asset?.avatar?.status) {
      if (asset?.avatar?.name) {
        return `${config.ENV.REACT_APP_HLS_BASE_URL}/${asset?.avatar?.dir}/${asset?.avatar?.name}`;
      }
    } else {
      return null;
    }
  return null;
};

export const getMediaUrl = (asset) => {
  //if (asset?.extVideoURL) return asset.extVideoURL;
  if (asset?.file?.outputHls) {
    if (
      asset?.file?.outputHls.includes("https:/") ||
      asset?.file?.outputHls.includes("http:/") ||
      asset?.file?.outputHls.includes("file:/")
    )
      return asset.file.outputHls;
    return config.ENV.REACT_APP_HLS_BASE_URL + asset.file.outputHls;
  }
  if (isImageAsset(asset)) {
    const key = `${asset?.file?.directory}/${asset?.file?.name}`;
    const imagePath = `${config.ENV.REACT_APP_HLS_BASE_URL}/${key}`;
    return imagePath;
  }
  return null;
};

export const getStockAudio = (stockAudio) => {
  if (stockAudio?.file) {
    const key = `${stockAudio?.file?.directory}/${stockAudio?.file?.name}`;
    const audioPath = `${config.ENV.REACT_APP_HLS_BASE_URL}/${key}`;
    return audioPath;
  }
  return null;
}

export const getNewClassName = () => {
  let lastNumber = 0;
  if (localStorage.getItem("AUTO_CLASS_NO")) {
    lastNumber = parseInt(localStorage.getItem("AUTO_CLASS_NO")) || 0;
  }
  lastNumber++;
  localStorage.setItem("AUTO_CLASS_NO", lastNumber);

  return `Class ${lastNumber}`;
};

export const getNewEduLessonFolderName = () => {
  let lastNumber = 0;
  if (localStorage.getItem("AUTO_EDU_LESSON_FOLDER_NO")) {
    lastNumber = parseInt(localStorage.getItem("AUTO_EDU_LESSON_FOLDER_NO")) || 0;
  }
  lastNumber++;
  localStorage.setItem("AUTO_EDU_LESSON_FOLDER_NO", lastNumber);

  return `Topic ${lastNumber}`;
};

export const getAssetDisplayName = (type) => {
  const name = moment().format("MMM Do hh:mm:ss");
  if (isImageType(type)) return `${name}-Image`;
  if (isVideoType(type)) return `${name}-Video`;
  if (isAudioType(type)) return `${name}-Audio`;
  return name;
};

export const getAssetFilePath = (asset) => {
  return `${config.ENV.REACT_APP_HLS_BASE_URL}/${asset?.file?.directory}/${asset?.file?.name}`;
};

export const getChannelUrlFromURI = (uri) => {
  return `${uri?.protocol || ""}${uri?.address || ""}${uri?.port || ""}${uri?.locator || ""}`;
};

export function useWindowDimensions() {
    // the 3rd parameter is optional and only needed for server side rendering
    return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}

function subscribe(callback) {
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
}

function getSnapshot() {
    return { width: window.innerWidth, height: window.innerHeight };
}

function getServerSnapshot() {
    return {
        width: 0,
        height: 0,
    };
}

export function getTime(time) {
  if (Array.isArray(time)) {
    time = time[0];
  } else if (!time) {
    time = 0;
  }
  time = Math.floor(time);
  let m = Math.floor(time / 60);
  let s = time % 60;
  return `${m}:${s < 10 ? `0${s}` : s}`;
}

export function getDateTime(timestamp) {
  const num = parseFloat(timestamp);
  const date = new Date(num);
  return `${date.toLocaleDateString("en-US")} ${date.toLocaleTimeString("en-US")}`
}

export function getTimeDifference(timestamp) {
  if (!timestamp) return ''
  const diff = new Date().getTime() - new Date(Number(timestamp)).getTime();
  const diffSeconds = Math.floor(diff / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  if (diffSeconds < 60) {
      return `${diffSeconds} seconds ago`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  } else if (diffHours < 24) {
    return `${diffHours} hours ago`;
  } else if (diffDays < 7) {
    return `${diffDays} days ago`;
  } else {
    return `${diffDays} days ago`;
  }
}

export function getCaptionUrl(url) {
  if (url) {
    return url.replace(`https://s3.us-east-2.amazonaws.com/${config.ENV.REACT_APP_STORAGE_BUCKET_NAME}`, config.ENV.REACT_APP_HLS_BASE_URL);
  }
  return ''
}

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

export const generateAuthCode = () => Math.floor(100000 + Math.random() * 900000);

export const getUniqueAuthCodeList = (codes, len = 5) => {
  let list = [];
  let code = `${generateAuthCode()}`;
  
  for (let i = 0; i < len; i++) {
    while (codes.includes(code) || list.includes(code)) {
      code = `${generateAuthCode()}`;
    }
    list = [...list, code];
    code = `${generateAuthCode()}`;
  }
  return list;
}
import React, { useEffect, useState } from 'react';
import { Box, Drawer, IconButton, Slide, TextField, ThemeProvider, Typography, InputAdornment } from '@mui/material';
import { getPlayerTime } from '../../utils';
import { lightTheme } from '../../libs/theme';
import { v4 as uuid_v4 } from 'uuid';
import useAssetNote from '../../hooks/useAssetNote';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { isMobile } from 'react-device-detect';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const WatchComponent = ({ playerStatus, onClick, watchRef }) => {
  return (
    <Box
      ref={watchRef}
      sx={{
        position: 'relative',
        width: { xs: '2.75rem', md: '3rem' },
        height: '2.5rem',
      }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '0.65rem',
          top: { xs: '0.1rem', md: '0.08rem' },
          width: { xs: '1.25rem', md: '1.5rem' },
          height: { xs: '2.3rem', md: '2.4rem' },
          background: '#D3D3D3',
          zIndex: 10,
          borderRadius: '20%',
        }}
      ></Box>
      <Box
        sx={{
          border: '3px solid #ffffff',
          borderRadius: '50%',
          position: 'absolute',
          left: '0.25rem',
          top: '0.25rem',
          width: { xs: '2rem', md: '2.1rem' },
          height: { xs: '2rem', md: '2.1rem' },
          zIndex: 12,
          background: '#232323',
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body2" sx={{ color: '#D3D3D3', fontSize: '0.75rem' }} textAlign="center">
          {getPlayerTime(playerStatus?.playedSeconds)}
        </Typography>
      </Box>
    </Box>
  );
};

function NoteEditor({ open, onClose, content, setContent, playerStatus, setVideoPlaying }) {
  const saveNote = () => {
    if (content?.note?.text) {
      onClose(true);
    }
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Drawer
        open={open}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            bgcolor: '#000',
            p: 0.5,
            width: '100%',
            maxWidth: '1200px',
            left: '50%',
            right: 'unset',
            transform: 'translateX(-50%) !important',
          },
        }}
        anchor="bottom"
      >
        <Box display="flex" alignItems="center" sx={{ zIndex: 999 }}>
          <WatchComponent playerStatus={playerStatus}></WatchComponent>
          <Box flexGrow={1} sx={{ mx: 0.5 }}>
            <TextField
              size="small"
              value={content?.note?.text}
              onChange={(e) => {
                e.preventDefault();
                setContent({
                  ...content,
                  note: {
                    text: e.target.value,
                  },
                });
              }}
              onBlur={() => {
                if (isMobile) {
                  saveNote()
                }
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  saveNote()
                }
              }}
              variant="outlined"
              fullWidth
              placeholder="Edit Note"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => saveNote()}>
                      <SendOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
}

function PlayerWatch({ playerStatus, asset, openNoteEditor, setOpenNoteEditor, setVideoPlaying }) {
  // const [openNoteEditor, setOpenNoteEditor] = useState(false);
  const [noteContent, setNoteContent] = useState();

  const [{ assetNote }, { updateAssetNote }] = useAssetNote(asset?.id);

  useEffect(() => {
    setNoteContent({
      note: {
        text: '',
      },
    });
  }, [openNoteEditor]);

  return (
    <>
      <WatchComponent
        // watchRef={watchRef}
        playerStatus={playerStatus}
        onClick={() => {
          if (!openNoteEditor) {
            setVideoPlaying(false);
            setOpenNoteEditor(true);
          }
        }}
      ></WatchComponent>
      <NoteEditor
        playerStatus={playerStatus}
        open={openNoteEditor}
        onClose={(saved) => {
          if (saved) {
            updateAssetNote({
              ...assetNote,
              notes: [
                ...(assetNote?.notes || []),
                {
                  uId: uuid_v4(),
                  start: Math.floor(playerStatus.playedSeconds),
                  note: noteContent?.note,
                },
              ],
            });
          }
          setOpenNoteEditor(false);
          setVideoPlaying(true);
        }}
        content={noteContent}
        setContent={setNoteContent}
        setVideoPlaying={setVideoPlaying}
      ></NoteEditor>
    </>
  );
}

export default PlayerWatch;

import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackIosNew as IconArrowBackIosNew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function SecondaryTopActionBar({ onBack, title, right, hideBackButton }) {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ backgroundColor: "#24262B", height: 45, width: "100%" }}
    >
      {!hideBackButton ? (
        <IconButton
          onClick={() => {
            if (onBack) onBack();
            else {
              navigate(-1);
            }
          }}
        >
          <IconArrowBackIosNew></IconArrowBackIosNew>
        </IconButton>
      ) : (
        <Box sx={{ width: "48px" }}></Box>
      )}
      <Typography variant="h6">{title}</Typography>
      {right}
    </Box>
  );
}

SecondaryTopActionBar.defaultProps = {
  onBack: null,
  right: <Box sx={{ width: "48px" }}></Box>,
  hideBackButton: false,
};

export default SecondaryTopActionBar;

import React from "react";
import { Box } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";

const barCount = 20;
const array = _.fill(Array(barCount), 0);

function AudioEqualizer({ loading, isPlaying }) {
  const [percents, setPercents] = useState([]);

  useEffect(() => {
    console.log(isPlaying, loading, "status changed");
    if (loading) {
      setPercents(array.map(() => 0));
    } else {
      if (isPlaying) {
        const timer = setInterval(() => {
          setPercents(array.map(() => Math.floor(Math.random() * 100)));
        }, 150);
        return () => clearInterval(timer);
      } else {
      }
    }
  }, [isPlaying, loading]);

  return (
    <Box
      sx={{ width: "100%", height: "100%", gap: 1 }}
      display="flex"
      alignItems="flex-end"
      justifyContent="space-between"
    >
      {percents.map((t, index) => {
        return <Box key={index} sx={{ height: `${t}%`, flex: 1, backgroundColor: "#1890FF" }}></Box>;
      })}
    </Box>
  );
}

export default AudioEqualizer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import SecondaryTopActionBar from "../../components/nav/SecondaryTopActionBar";
import { getAllChannels } from "../../store/Channel.slice";
import ChannelPlayer from "../player/ChannelPlayer";

function PageChannels() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const channels = useSelector((store) => store.Channel.channels);

  useEffect(() => {
    if (PrivateInfo?.domain) {
      dispatch(getAllChannels({ domain: PrivateInfo.domain }));
    }
  }, [dispatch, PrivateInfo?.domain]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentChannel, setCurrentChannel] = useState(null);

  const onPrevClicked = () => {
    if (currentIndex < 0) return;
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      return;
    }
    setCurrentIndex(channels.length - 1);
  };

  const onNextClicked = () => {
    if (currentIndex < 0) return;
    if (currentIndex >= channels.length - 1) {
      setCurrentIndex(0);
      return;
    }
    setCurrentIndex(currentIndex + 1);
  };

  useEffect(() => {
    if (!currentChannel) {
      setCurrentIndex(0);
      return;
    }
    if (channels && channels.find((item) => item.id === currentChannel.id)) {
      setCurrentIndex(channels.findIndex((item) => item.id === currentChannel.id));
    }
  }, [channels]); //eslint-disable-line

  useEffect(() => {
    if (currentIndex < 0 || !channels) return;

    if (channels.length > 0) {
      if (channels.length <= currentIndex) {
        setCurrentIndex(0);
        return;
      }
      setCurrentChannel(channels[currentIndex]);
    }
  }, [currentIndex, channels]); //eslint-disable-line

  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          {channels?.length > 0 && (
            <ChannelPlayer
              channels={channels}
              currentChannel={currentChannel}
              onNext={onNextClicked}
              onPrev={onPrevClicked}
            ></ChannelPlayer>
          )}
          {channels && !channels?.length && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Typography variant="h5" color="common.white">
                No Channels
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </SubPageWrapper>
  );
}

export default PageChannels;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllStockAudios } from "../graphQL/stockAudio";

export const getAllStockAudios = createAsyncThunk("stockAudio/read_stockAudios", async (filter) => {
  filter = {
    ...filter,
    ordering: {
      field: "rank",
      order: "Desc",
    },
  };
  return await apiGetAllStockAudios(filter);
});

export const reloadConvertingStockAudios = createAsyncThunk(
  "stockAudio/reload_converting_stockAudios",
  async (filter) => {
    return await apiGetAllStockAudios(filter);
  }
);

const stockAudioSlice = createSlice({
  name: "StockAudio",
  initialState: {
    total: 0,
    stockAudios: null,
  },
  reducers: {
    actionUpdateStockAudio: (state, action) => {
      if (state.stockAudios) {
        if (state.stockAudios.find((item) => item.id === action.payload.id)) {
          state.stockAudios = state.stockAudios.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.stockAudios = [action.payload, ...state.stockAudios];
          state.total = state.stockAudios.length;
        }
      } else {
        state.stockAudios = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteStockAudio: (state, action) => {
      state.stockAudios = state.stockAudios?.filter((item) => item?.id !== action.payload);
      state.total = state.stockAudios.length;
    },
    actionDeleteAllStockAudios: (state, action) => {
      state.stockAudios = state.stockAudios?.filter((t) => t.domain !== action.payload.domain);
      state.total = state.stockAudios.length;
    },
  },
  extraReducers: {
    [getAllStockAudios.fulfilled]: (state, action) => {
      state.stockAudios = action.payload.stockAudios;
      state.total = action.payload.total;
    },
    [getAllStockAudios.rejected]: (state, action) => {
      state.total = 0;
      state.stockAudios = null;
    },
    [reloadConvertingStockAudios.fulfilled]: (state, action) => {
      if (!state.stockAudios) return state;
      let newAssets = [
        ...action.payload.stockAudios?.filter((t) => t.status !== "deleting"),
        ...state.stockAudios?.filter((t) => {
          return !action.payload?.stockAudios?.find((asset) => asset.id === t.id);
        }),
      ];
      return {
        ...state,
        stockAudios: newAssets,
        total: newAssets?.length,
      };
    },
    [reloadConvertingStockAudios.rejected]: (state, action) => {},
  },
});

export const { actionUpdateStockAudio, actionDeleteStockAudio, actionDeleteAllStockAudios } = stockAudioSlice.actions;
export default stockAudioSlice.reducer;

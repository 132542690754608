import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllChannels } from "../graphQL/channels";

export const getAllChannels = createAsyncThunk("channel/read_channels", async (filter = {}) => {
  filter = {
    ...filter,
    ordering: {
      field: "updatedAt",
      order: "Desc",
    },
  };
  return await apiGetAllChannels(filter);
});

const channelSlice = createSlice({
  name: "Channel",
  initialState: {
    total: 0,
    channels: null,
  },
  reducers: {
    actionUpdateChannel: (state, action) => {
      if (state.channels) {
        if (state.channels.find((item) => item.id === action.payload.id)) {
          state.channels = state.channels.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.channels = [...state.channels, action.payload];
          state.total = state.channels.length;
        }
      } else {
        state.channels = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteChannel: (state, action) => {
      state.channels = state.channels?.filter((item) => item?.id !== action.payload);
      state.total = state.channels.length;
    },
    actionDeleteAllChannels: (state, action) => {
      state.channels = state.channels?.filter((t) => t.domain !== action.payload.domain);
      state.total = state.channels.length;
    },
  },
  extraReducers: {
    [getAllChannels.fulfilled]: (state, action) => {
      state.total = action.payload.total;
      state.channels = action.payload.channels;
    },
    [getAllChannels.rejected]: (state, action) => {
      state.total = 0;
      state.channels = null;
    },
  },
});

export const { actionUpdateChannel, actionDeleteChannel, actionDeleteAllChannels } = channelSlice.actions;
export default channelSlice.reducer;

import React, { useCallback, useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import BoxWidget from "./BoxWidget";
import { Box } from "@mui/material";
import BoxWidgetDetail from "./BoxWidgetDetail";

function WidgetOverlay({
  widget = {},
  onClick = () => { },
  onChange = () => { },
  isPreview = false,
}) {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const { width, height, ref: overlayRef } = useResizeDetector();
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    if (width && height) {
      console.log(width, height);
      setSize({
        width,
        height,
      });
    }
  }, [width, height]);

  const handleWidgetActions = (type) => {
    onClick(type);
    if (type === 'detail') {
      setShowDetail(true);
    }
  }

  return (
    <Box sx={{
      position: "absolute",
      top: 0, bottom: 0, right: 0, left: 0,
      background: "transparent",
      pointerEvents: "none",
      zIndex: 1,
    }} ref={overlayRef}>
      {/* {showDetail && ( 
        <BoxWidgetDetail widget={widget} iswidgetEdit={true} onClose={() => {setShowDetail(false)}} />
      )} */}
      <BoxWidget
        widget={widget}
        onClick={handleWidgetActions}
        onChange={onChange}
        overlaySize={size}
        isEditable={!isPreview}
      />
    </Box>
  );
}

export default WidgetOverlay;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { apiGetAssetAnalytics } from "../../graphQL/assetAnalytics";

function DialogAssetAnalytics({ asset, onClose }) {
  const [analytics, setAnalytics] = useState(null);

  useEffect(() => {
    if (asset?.id) {
      apiGetAssetAnalytics(asset?.id).then((res) => {
        setAnalytics(res);
      });
    } else {
      setAnalytics(null);
    }
  }, [asset?.id]);

  return (
    <Dialog open={Boolean(asset)} onClose={onClose} fullWidth maxWidth="sm">
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!analytics}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>{asset?.file?.displayName}</DialogTitle>
      <DialogContent>
        <Box className="flex items-center justify-content-between" mb={1}>
          <Box>
            <Typography variant="h6">Like</Typography>
          </Box>
          <Box>
            <Typography variant="h6">{analytics?.liked || 0}</Typography>
          </Box>
        </Box>
        <Box className="flex items-center justify-content-between" mb={1}>
          <Box>
            <Typography variant="h6">Dislike</Typography>
          </Box>
          <Box>
            <Typography variant="h6">{analytics?.disliked || 0}</Typography>
          </Box>
        </Box>
        <Box className="flex items-center justify-content-between" mb={1}>
          <Box>
            <Typography variant="h6">Bookmark</Typography>
          </Box>
          <Box>
            <Typography variant="h6">{analytics?.bookmarked || 0}</Typography>
          </Box>
        </Box>
        <Box className="flex items-center justify-content-between" mb={1}>
          <Box>
            <Typography variant="h6">Full Played</Typography>
          </Box>
          <Box>
            <Typography variant="h6">{analytics?.fullPlayed || 0}</Typography>
          </Box>
        </Box>
        <Box className="flex items-center justify-content-between">
          <Box>
            <Typography variant="h6">Partial Played</Typography>
          </Box>
          <Box>
            <Typography variant="h6">{analytics?.partialPlayed || 0}</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DialogAssetAnalytics;

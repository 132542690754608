import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

function Public404() {
  return (
    <Box
      className="page-under-review-wrapper"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: "100%",
        height: "100%",
        p: 8,
      }}
    >
      <Typography variant="h3" gutterBottom textAlign={"center"} mb={2}>
        404 Not Found
      </Typography>
      <Typography variant="h6" gutterBottom mb={2} textAlign="center">
        This page isn't available, Sorry about that. Try searching for something else
      </Typography>
    </Box>
  );
}

export default Public404;

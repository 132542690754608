import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, ListSubheader, Menu, MenuItem, Select, Typography } from "@mui/material";
import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import { Add, Autorenew, Edit, Refresh, } from "@mui/icons-material";
import { getUserAvatarPath, getUserThumbnailPath } from "../../utils";
import { setOtherProfile } from "../../store/OtherProfile";
import { apiDeleteUser, apiResetCampaigns, apiUpdateUser } from "../../graphQL/user";
import { updateCurrentUser } from "../../store/auth.slice";
import { actionDeleteUser, actionUpdateUser, getAllUsers } from "../../store/User.slice";
import { getAllLocations } from "../../store/Location.slice";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import DialogCampaign from "./DialogCampaign";
import DialogAddUser from "./DialogAddUser";
import { GUEST } from "../../config";
import ResponsiveTable from "./ResponsiveTable";
import { useLayoutContext } from "../../contexts/LayoutContext";
import DialogAddCampaign from "./DialogAddCampaign";
import DialogManageCampaigns from "./DialogManageCampaigns";
import DialogEditLocation from "./DialogEditLocation";

function PageCampaign() {
  const locations = useSelector((store) => store.Location.locations);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const OtherProfile = useSelector((store) => store.OtherProfile.Profile);
  const users = useSelector((store) => store.User.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [profile, setProfile] = useState();
  const [rankedUsers, setRankedUsers] = useState();
  const [unRankedUsers, setUnRankedUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState();
  const [campaign, setCampaign] = useState({
    locationId: '', name: '', initiative: 0, roll: { major: 0, minor: 0 }, rank: 0
  })
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [usersAnchorEl, setUsersAnchorEl] = useState(null);
  const openUsers = Boolean(usersAnchorEl);
  const [selected, setSelected] = useState(null);
  const [openNewCampaign, setOpenNewCampaign] = useState(false);
  const [openManageCampaign, setOpenManageCampaign] = useState(false);
  const [editLocation, setEditLocation] = useState(null);

  const [
    { showTopAppBar },
  ] = useLayoutContext();


  const confirm = useConfirm();

  const submit = () => {
    console.log(campaign);
    setLoading(true);
    let params = selected?.data?.params;
    if (params) {
      if (params?.find(item => item.locationId === campaign.locationId)) {
        params = params.map(item => {
          if (item.locationId === campaign.locationId) {
            return campaign;
          }
          return item;
        });
      } else {
        params = [...params, campaign];
      }
    } else {
      params = [campaign];
    }

    const variables = {
      id: selected.id,
      data: {
        ...(selected.data || {}),
        type: "campaign",
        ver: 1,
        params,
      }
    }
    apiUpdateUser(variables)
      .then((res) => {
        setLoading(false);
        if (PrivateInfo?.id === selected?.id) {
          dispatch(updateCurrentUser(res));
        } else if (selected?.id === OtherProfile?.id) {
          dispatch(setOtherProfile(res));
        }
        dispatch(actionUpdateUser(res));
        setLoading(false);
        setOpenEditDialog(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const handleDelete = () => {
    setLoading(true);
    if (selected) apiDeleteUser(selected?.id).then((id) => {
      dispatch(actionDeleteUser(id));
      setLoading(false);
      setOpenEditDialog(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      setOpenEditDialog(false);
    });
  }

  const refresh = () => {
    dispatch(getAllUsers({
      domain: profile?.domain,
      pagination: {
        page: 1,
        limit: 32768,
      }
    }));
    dispatch(getAllLocations({
      domain: profile.domain,
      pagination: {
        page: 1,
        limit: 32768,
      }
    }));
  }

  const resetAll = () => {
    confirm({
      title: "Are you going to reset all ranks for this campaign?",
      confirmationText: "RESET",
      cancellationText: "BACK",
      titleProps: {
        textAlign: "center",
      },
      cancellationButtonProps: {
        style: {
          background: "white",
          color: "black",
        },
      },
      confirmationButtonProps: {
        style: {
          background: "white",
          color: "black",
        },
      },
      dialogActionsProps: {
        style: {
          justifyContent: "space-between",
        },
      },
    })
      .then(() => {
        // call reset all api
        apiResetCampaigns({
          domain: profile.domain,
          locationId: campaign.locationId,
        }).then((data) => {
          console.log(data);
          refresh()
        }).catch((err) => {
          console.log(err);
        })
      })
      .catch(() => { });
  }

  const storeStatus = () => {
    localStorage.setItem('campaign', JSON.stringify(campaign));
  }

  const restoreStatus = () => {
    const storedCampaign = localStorage.getItem('campaign');
    console.log(storedCampaign);
    if (storedCampaign) {
      setCampaign(JSON.parse(storedCampaign));
    }
  }

  const handleCloseUsers = () => {
    setUsersAnchorEl(null);
  }

  const handleSelectUser = (user) => {
    console.log(user);
    dispatch(setOtherProfile(user));
  }

  const handleEditLocation = () => {

  }

  const isSysAdmin = (profile) => {
    return ["superAdmin", "sysadmin"].includes(profile?.role);
  }

  useEffect(() => {
    if (OtherProfile) {
      setProfile(OtherProfile);
    } else {
      if (PrivateInfo) setProfile(PrivateInfo);
    }
  }, [PrivateInfo, OtherProfile])

  useEffect(() => {
    if (campaign?.locationId) {
      const usersInLocation = users?.filter(item => item.locationIdList?.includes(campaign.locationId))
        .filter((item) => item.name !== GUEST.email && (!isSysAdmin(item) || PrivateInfo?.id === item.id));
      const ranked = usersInLocation?.filter((user) => {
        if (user.data?.params) {
          return user.data?.params?.map(item => item.locationId).includes(campaign.locationId) &&
            user.data?.params.find((item => item.locationId === campaign.locationId))?.roll?.major !== 0;
        }
        return false;
      }).sort((a, b) => {
        const aRank = a.data?.params?.find((item) => item.locationId === campaign.locationId)?.rank;
        const bRank = b.data?.params?.find((item) => item.locationId === campaign.locationId)?.rank;
        return bRank - aRank;
      });
      const unRanked = usersInLocation?.filter(item => !ranked?.map(user => user.name).includes(item.name))
        .filter((item) => item.name !== GUEST.email && (!isSysAdmin(item) || PrivateInfo?.id === item.id));
      setRankedUsers(ranked);
      setUnRankedUsers(unRanked);
    } else {
      setRankedUsers([]);
      setUnRankedUsers([]);
    }

  }, [campaign?.locationId, users]);

  useEffect(() => {
    if (profile) {
      if (campaign?.locationId && campaign?.locationId !== '') {
        if (!profile?.locationIdList?.includes(campaign.locationId)) {
          setCampaign({
            ...campaign,
            locationId: null
          });
        }
      } else {
        if (profile?.data?.params?.length) {
          setCampaign(profile?.data?.params[0]);
        }
      }
    }
  }, [profile])

  useEffect(() => {
    if (campaign?.locationId) {
      const params = profile?.data?.params;
      if (params) {
        const userCamp = params.find(item => item.locationId === campaign.locationId);
        if (userCamp) {
          setCampaign(userCamp)
        }
      }
    }
  }, [campaign?.locationId, profile?.data?.params]);

  useEffect(() => {
    storeStatus();
    if (campaign && users) {
      let campainLocations = []
      users?.map((user) => {
        if (user.data?.params) {
          user.data?.params?.map(item => {
            if (item.locationId === campaign.locationId) {
              campainLocations = [...campainLocations, item];
            }
            return item;
          })
        }
        return user;
      });
      setCampaigns(campainLocations);
    }
  }, [campaign, users])

  useLayoutEffect(() => {
    restoreStatus();
  }, []);

  const headers = [
    {
      id: "avatar",
      align: "left",
      label: "",
      visible: "xs",
      width: 60,
      render: (row) => {
        return (
          <Avatar src={getUserThumbnailPath(row?.avatar) || getUserAvatarPath(row?.avatar)} sx={{ width: "2.5rem", height: "2.5rem" }} />
        );
      }
    },
    {
      id: "handle",
      align: "left",
      label: "",
      visible: "xs",
      render: (row) => {
        return row?.data?.params?.find(item => item.locationId === campaign?.locationId)?.name || row.username || row.name;
      }
    },
    {
      id: "rank",
      align: "right",
      label: "",
      visible: "xs",
      width: 80,
      render: (row) => {
        return row?.data?.params?.find(item => item.locationId === campaign?.locationId)?.rank || 0;
      }
    },
    {
      id: "initiative",
      align: "right",
      label: "",
      visible: "md",
      width: 80,
      render: (row) => {
        return row?.data?.params?.find(item => item.locationId === campaign?.locationId)?.initiative || 0;
      }
    },
    {
      id: "roll",
      align: "right",
      label: "",
      visible: "md",
      width: 80,
      render: (row) => {
        return `${row?.data?.params?.find(item => item.locationId === campaign?.locationId)?.roll?.major || 0} .` +
          `${row?.data?.params?.find(item => item.locationId === campaign?.locationId)?.roll?.minor || 0}`
      }
    },
    {
      id: "edit",
      align: "right",
      label: "",
      visible: "xs",
      width: 40,
      style: { position: "sticky", right: 0, backgroundColor: "#1E1E1E" },
      render: (row) => {
        return row?.name === PrivateInfo?.name || ['sysadmin', 'superAdmin'].includes(PrivateInfo?.role) ?
          <IconButton
            onClick={() => {
              setSelected(row);
              setOpenEditDialog(true);
              setCampaign(row?.data?.params?.find(item => item.locationId === campaign?.locationId) || {
                ...campaign, initiative: 0, roll: { major: 0, minor: 0 }, rank: 0, name: "",
              });
            }}
          >
            <Edit />
          </IconButton> : <></>
      }
    },
  ]

  if (!PrivateInfo?.locationIdList?.length) {
    return (
      <SubPageWrapper>
        <Box display='flex'
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <Typography mt={2} variant="h5">You are not followed any campaign in this domain.</Typography>
          <Typography mt={2} variant="h5">Please add campaigns from your Profile</Typography>
          <Button sx={{ mt: 2 }} onClick={() => { navigate('/profile') }}>Go to Profile</Button>
        </Box>
      </SubPageWrapper>
    )
  }

  return (
    <SubPageWrapper>
      {isSysAdmin(PrivateInfo) ?
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            top: 2,
            right: 2,
          }}
        >
          <IconButton
            onClick={(e) => { setUsersAnchorEl(e.currentTarget) }}
            size="medium"
            sx={{ mr: 0, zIndex: 1000 }}
            aria-controls={openUsers ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openUsers ? 'true' : undefined}
          >
            <Avatar src={getUserThumbnailPath(profile?.avatar) || getUserAvatarPath(profile?.avatar)} sx={{ width: "2rem", height: "2rem" }} />
          </IconButton>
        </Box> : <Box sx={{ height: "3rem" }}></Box>}
      <Box sx={{
        overflowY: 'auto',
        mt: '60px',
        height: showTopAppBar ? 'calc(100vh - 65px)' : '100vh',
      }}>
        <Grid container
          alignItems="center"
          columns={12}
          spacing={1}
          pt={1}
          pl={2}
          pr={2}
        >
          {!profile?.locationIdList || profile?.locationIdList?.length < 1 ?
            <Typography variant="h5" sx={{ width: '100%', textAlign: 'center', mt: 5 }}>
              User must belong at least to one campaign.
            </Typography> :
            <>
              <Grid item xs={8} sm={8} md={10}>
                <FormControl fullWidth>
                  <InputLabel id="campaign-label">
                    Campaign
                  </InputLabel>
                  <Select
                    labelId="campaign-label"
                    fullWidth
                    variant="filled"
                    value={campaign?.locationId || ""}
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.target.value === "new") {
                        setOpenNewCampaign(true);
                      } else if (e.target.value === "manage") {
                        setOpenManageCampaign(true);
                      } else {
                        setCampaign({
                          ...campaign,
                          locationId: e.target.value,
                        });
                      }
                    }}
                    disabled={!profile?.locationIdList || profile?.locationIdList?.length < 1}
                    sx={{
                      ".MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                        py: 1.2,
                        ".MuiListItemIcon-root": {
                          minWidth: 32,
                        },
                      },
                    }}
                  >
                    {locations?.filter(item => profile?.locationIdList?.includes(item.id) || (isSysAdmin(PrivateInfo) && item.domain === PrivateInfo?.domain)).map((loc) => (
                      <MenuItem key={loc.id} value={loc.id}>
                        {loc.name}
                      </MenuItem>
                    ))}
                    {isSysAdmin(PrivateInfo) &&  <ListSubheader
                      sx={{
                        pr: 0, pl: 0,
                      }}>
                      <Divider variant="fullWidth"
                        sx={{
                          borderColor: "white",
                          mt: 1,
                          mb: 1,
                        }}
                      /></ListSubheader> }
                    {isSysAdmin(PrivateInfo) && <MenuItem value="new">New Campaign</MenuItem>}
                    {isSysAdmin(PrivateInfo) && <MenuItem value="manage">Manage Campaign</MenuItem>}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {["sysadmin", "superAdmin"].includes(PrivateInfo?.role) ? <IconButton disabled={!Boolean(campaign?.locationId)} onClick={resetAll}>
                    <Autorenew />
                  </IconButton> : <></>}
                  <IconButton onClick={refresh}>
                    <Refresh />
                  </IconButton>
                  {["sysadmin", "superAdmin"].includes(PrivateInfo?.role) ? <IconButton disabled={!Boolean(campaign?.locationId)} onClick={() => { setOpenAddUserDialog(true) }}>
                    <Add />
                  </IconButton> : <></>}
                </Box>
              </Grid>
            </>}
        </Grid>
        <ResponsiveTable
          columns={headers}
          rows={rankedUsers}
        />
        <Divider />
        <ResponsiveTable
          columns={headers}
          rows={unRankedUsers}
        />
      </Box>
      <DialogCampaign
        open={openEditDialog}
        onClose={() => { setOpenEditDialog(false) }}
        campaign={campaign}
        setCampaign={setCampaign}
        onSave={submit}
        onDelete={handleDelete}
        profile={PrivateInfo}
        loading={loading}
        selectedUser={selected}
      />
      <DialogAddUser
        open={openAddUserDialog}
        campaign={campaign}
        domain={PrivateInfo?.domain}
        onClose={() => { setOpenAddUserDialog(false) }}
        onSave={() => { setOpenAddUserDialog(false) }}
      />
      <Menu
        anchorEl={usersAnchorEl}
        id="account-menu"
        open={openUsers}
        onClose={handleCloseUsers}
        onClick={handleCloseUsers}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {users?.filter(item => (item.domain === PrivateInfo?.domain) && item.activated && item.name !== GUEST.email && (!isSysAdmin(item) || PrivateInfo?.id === item.id))?.map((item) => (
          <MenuItem key={item.id} value={item.name} onClick={() => { handleSelectUser(item); }}>
            <Avatar src={getUserThumbnailPath(item?.avatar) || getUserAvatarPath(item?.avatar)} sx={{ width: "3rem", height: "3rem" }} />
            <Typography ml={2} variant="body1">{item.username || item.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <DialogAddCampaign 
        open={openNewCampaign}
        profile={profile}
        onClose={() => {setOpenNewCampaign(false)}}
        domain={PrivateInfo?.domain}
      />
      <DialogManageCampaigns
        open={openManageCampaign}
        onClose={() => {setOpenManageCampaign(false)}}
        onEdit={(loc) => {setEditLocation(loc)}}
        profile={profile}
      />
      <DialogEditLocation
        open={Boolean(editLocation)}
        onClose={() => {setEditLocation(null)}}
        onSave={() => {}}
        location={editLocation}
        setLocation={setEditLocation}
      />
    </SubPageWrapper>
  );
}

export default PageCampaign;

import React from "react";
import { Typography, Button } from "@mui/material";
import { Auth } from "aws-amplify";
import { Box } from "@mui/material";

function UnderReview() {
  return (
    <Box
      className="page-under-review-wrapper"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: "100%",
        height: "100%",
        p: 8,
        color: "black",
      }}
    >
      <Typography variant="h3" gutterBottom textAlign={"center"} mb={2}>
        Your account is under review
      </Typography>
      <Typography variant="h6" gutterBottom mb={2} textAlign="center">
        Please contact to administrator
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          Auth.signOut();
        }}
      >
        Go to Login Page
      </Button>
    </Box>
  );
}

export default UnderReview;

import client from "../index.js";
import { GET_TEXT_COMMENT, GET_TEXT_COMMENTS } from "./queries.js";
import { CREATE_TEXT_COMMENT, UPDATE_TEXT_COMMENT, DELETE_TEXT_COMMENT } from "./mutations";

export const apiGetTextComment = (id) => {
  return client
    .query({
      query: GET_TEXT_COMMENT,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.textComment;
    });
};

export const apiGetAllTextComments = (filter) => {
  return client
    .query({
      query: GET_TEXT_COMMENTS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.textComments;
    });
};

export const apiCreateTextComment = (data) => {
  return client
    .mutate({
      mutation: CREATE_TEXT_COMMENT,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.createTextComment?.textComment;
    });
};

export const apiUpdateTextComment = (data) => {
  return client
    .mutate({
      mutation: UPDATE_TEXT_COMMENT,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.updateTextComment?.textComment;
    });
};

export const apiDeleteTextComment = (id) => {
  return client
    .mutate({
      mutation: DELETE_TEXT_COMMENT,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.deleteTextComment?.id;
    });
};

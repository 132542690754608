import client from "../index.js";
import { GET_ALERT_ASSET, GET_ALERT_ASSETS } from "./query.js";
import { CREATE_ALERT_ASSET, UPDATE_ALERT_ASSET, DELETE_ALERT_ASSET, DELETE_ALL_ALERT_ASSETS } from "./mutation";

export const apiGetAlertAsset = (id) => {
  return client
    .query({
      query: GET_ALERT_ASSET,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.alertAsset;
    });
};

export const apiGetAllAlertAssets = (filter = {}) => {
  return client
    .query({
      query: GET_ALERT_ASSETS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.alertAssets;
    });
};

export const apiCreateAlertAsset = (data) => {
  return client
    .mutate({
      mutation: CREATE_ALERT_ASSET,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createAlertAsset.alertAsset;
    });
};

export const apiUpdateAlertAsset = (data) => {
  return client
    .mutate({
      mutation: UPDATE_ALERT_ASSET,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateAlertAsset.alertAsset;
    });
};

export const apiDeleteAlertAsset = (id) => {
  return client
    .mutate({
      mutation: DELETE_ALERT_ASSET,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAlertAsset.id;
    });
};

export const apiDeleteAllAlertAssets = (filter = {}) => {
  return client
    .mutate({
      mutation: DELETE_ALL_ALERT_ASSETS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAllAlertAssets?.total;
    });
};

import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

function ImagePlayer({ imageRef, url, onClick }) {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    axios.get(`https://cors-anywhere.azm.workers.dev/${url}`, { responseType: "blob" }).then((response) => {
      const blob = response.data;
      const url = URL.createObjectURL(blob);
      setImageUrl(url);
    });
  }, [url]);

  return (
    <img
      ref={imageRef}
      src={imageUrl}
      width="100%"
      height="100%"
      style={{
        objectFit: "contain",
        WebkitUserDrag: "none",
      }}
      alt="There is no content to show up"
      draggable="false"
      onClick={() => {
        onClick && onClick();
      }}
      onDragStart={(event) => {
        event.preventDefault();
      }}
    />
  );
}

export default ImagePlayer;

import { Avatar, Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CreatorThumbnail from "../../thumbnails/CreatorThumbnails";

function DescriptionCard({ asset }) {

  const users = useSelector((store) => store.User.users);
  const [owner, setOwner] = useState();

  useEffect(() => {
    if (asset.owner) {
      const user = users.find((item) => item.id === asset.owner);
      setOwner(user);
    }
  }, []);

  return (
    <Box sx={{
      width: '100%',
      height: '100%'
    }}>
      <Card sx={{
        width: '100%',
        height: '100%',
        background: 'white'
      }}>
        <CardHeader
          avatar={
            <CreatorThumbnail
              creator={owner}
              hideName={true}
            ></CreatorThumbnail>
          }
          title={owner?.name}
          subheader={asset?.desc?.title || asset?.name}
          titleTypographyProps={{
            color: 'black'
          }}
          subheaderTypographyProps={{
            color: 'black'
          }}
          sx={{
            paddingBottom: 0
          }}
        >
        </CardHeader>
        <CardContent
          sx={{
            height: 'auto'
          }}
        >
          {asset?.desc?.body && (
            <div
              style={{
                fontSize: "14px",
                fontWeight: 500,
                marginTop: "0px",
                borderTop: "1px solid",
                paddingTop: "0px",
                color: "black",
              }}
              dangerouslySetInnerHTML={{
                __html: asset?.desc?.body || "",
              }}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

export default DescriptionCard;

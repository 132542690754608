import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Drawer,
  Grid,
  Box,
  Typography,
  Avatar,
  IconButton,
  ThemeProvider,
  Button,
  ClickAwayListener,
} from "@mui/material";
import { lightTheme } from "../../../libs/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  Cancel as IconCancel,
  Delete as IconDelete,
  CloudUpload as IconCloudUpload,
  Link as IconLink,
  Close as IconClose,
} from "@mui/icons-material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AvatarUpload from "../../common/AvatarUpload";
import { getAvatarPath } from "../../../utils";
import { apiCreateUserGroup, apiUpdateUserGroup } from "../../../graphQL/userGroup";
import { actionUpdateUserGroup } from "../../../store/UserGroup.slice";
import { useUploadContext } from "../../../contexts/UploadContext";
import myToast from "../../../libs/toast";

function UserGroupDrawer({ editUserGroup, setEditUserGroup }) {
  const dispatch = useDispatch();
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const form = useRef(null);
  const paddingWidth = window.innerWidth > 1200 ? (window.innerWidth - 1200) / 2 : 0;

  const [, { uploadAvatar }] = useUploadContext();

  const [avatarFile, setAvatarFile] = useState(null);

  const open = Boolean(editUserGroup);
  const onClose = () => {
    setEditUserGroup(null);
  };

  const uploadUserGroupAvatar = (userGroup) => {
    if (avatarFile) {
      uploadAvatar(`${userGroup.domain}/${userGroup.id}`, avatarFile, (err, result, fileName) => {
        if (err) {
          myToast.error("Upload avatar was failed");
          return;
        }
        apiUpdateUserGroup({
          id: userGroup?.id,
          avatar: {
            name: fileName,
            dir: userGroup.id,
            base: userGroup.domain,
            status: "uploaded",
          },
        }).then((res) => {
          dispatch(actionUpdateUserGroup(res));
          onClose();
        });
      });
    } else {
      onClose();
    }
  };

  return (
    <Box
      sx={{
        p: 0.5,
        background: "#A4A2A2",
        position: "fixed",
        right: { xs: 0, sm: 15 },
        bottom: { xs: open ? 0 : -400, sm: open ? 15 : -400 },
        width: { xs: "100%", sm: "calc(100% - 20px)" },
        maxWidth: "420px",
        zIndex: 9999,
        borderRadius: { xs: 0, sm: 2 },
        transition: "all 0.75s ease-in-out",
        mr: `${paddingWidth}px`,
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <Box sx={{ color: "#232323", width: "100%" }}>
          <ValidatorForm
            ref={form}
            onSubmit={async () => {
              const data = _.pick(editUserGroup, [
                "id",
                "domain",
                "locationId",
                "name",
                "desc",
                "avatar",
                "ownerId",
                "authors",
              ]);
              if (data?.id) {
                await apiUpdateUserGroup(data).then((res) => {
                  dispatch(actionUpdateUserGroup(res));
                  uploadUserGroupAvatar(res);
                });
              } else {
                await apiCreateUserGroup(data).then((res) => {
                  dispatch(actionUpdateUserGroup(res));
                  uploadUserGroupAvatar(res);
                });
              }
            }}
          >
            <Box display="flex" justifyContent="flex-end" sx={{ mb: 0, position: "relative", top: -4, right: -4 }}>
              <IconButton
                size="small"
                onClick={() => {
                  onClose();
                }}
              >
                <IconClose fontSize="small" sx={{ cursor: "pointer" }}></IconClose>
              </IconButton>
            </Box>
            <Box display="flex" alignItems="stretch" sx={{ width: "100%", px: 0.5 }}>
              <Box sx={{ position: "relative", width: "120px" }}>
                <Box sx={{ width: "100%", height: "100%" }}>
                  <AvatarUpload
                    variant="rounded"
                    url={getAvatarPath(editUserGroup?.avatar)}
                    onChange={(e) => {
                      setAvatarFile(e);
                    }}
                    onDelete={() => {
                      setAvatarFile(null);
                    }}
                  ></AvatarUpload>
                </Box>
              </Box>
              <Box sx={{ ml: 0.5 }} flexGrow={1}>
                <TextValidator
                  size="small"
                  fullWidth={true}
                  placeholder="Enter title"
                  value={editUserGroup?.name}
                  onChange={(e) => {
                    setEditUserGroup({ ...editUserGroup, name: e.target.value });
                  }}
                  sx={{ mb: 0.5 }}
                  autoComplete="off"
                ></TextValidator>
                <TextValidator
                  size="small"
                  multiline
                  fullWidth={true}
                  placeholder={"Enter description"}
                  rows={3}
                  value={editUserGroup?.desc?.title}
                  onChange={(e) => {
                    setEditUserGroup({
                      ...editUserGroup,
                      desc: {
                        ...(editUserGroup?.desc || {}),
                        title: e.target.value,
                      },
                    });
                  }}
                  autoComplete="off"
                ></TextValidator>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ width: "100%", p: 0.5 }}>
              <Box display={"flex"} alignItems="center" sx={{ cursor: "pointer" }} onClick={() => {}}>
                <IconButton size="small">
                  <IconDelete></IconDelete>
                </IconButton>
              </Box>
              <Box display={"flex"} alignItems="center" sx={{ pl: 1, cursor: "pointer" }}>
                <Button
                  onClick={() => {
                    form.current.submit();
                  }}
                >
                  <IconCloudUpload sx={{ color: "#232323" }}></IconCloudUpload>
                  <Typography variant="body1" sx={{ pl: 0.5, color: "#232323" }} fontWeight="500">
                    Save
                  </Typography>
                </Button>
              </Box>
            </Box>
          </ValidatorForm>
        </Box>
      </ThemeProvider>
    </Box>
  );
}

export default UserGroupDrawer;

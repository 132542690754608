import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./auth.slice";
import DomainSlice from "./Domain.slice";
import LocationSlice from "./Location.slice";
import UserFolderSlice from "./UserFolder.slice";
import ChannelSlice from "./Channel.slice";
import AssetSlice from "./Asset.slice";
import AssetFolderSlice from "./AssetFolder.slice";
import AlertAssetSlice from "./AlertAsset.slice";
import AlertFolderSlice from "./AlertFolder.slice";
import MenuSlice from "./Menu.slice";
import UserGroupSlice from "./UserGroup.slice";
import UserSlice from "./User.slice";
import CategorySlice from "./Category.slice";
import StockAudioSlice from "./StockAudio.slice";
import OtherProfileSlice from "./OtherProfile";

export default configureStore({
  reducer: {
    auth: authSlice,
    Domain: DomainSlice,
    Location: LocationSlice,
    Channel: ChannelSlice,
    Category: CategorySlice,
    UserFolder: UserFolderSlice,
    Asset: AssetSlice,
    AssetFolder: AssetFolderSlice,
    AlertAsset: AlertAssetSlice,
    AlertFolder: AlertFolderSlice,
    UserGroup: UserGroupSlice,
    Menu: MenuSlice,
    User: UserSlice,
    StockAudio: StockAudioSlice,
    OtherProfile: OtherProfileSlice,
  },
});

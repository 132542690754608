import { gql } from "@apollo/client";

const GET_RESERVATION = gql`
  query reservation($id: ID!) {
    reservations(id: $id) {
      id
      schemaVer
      authorId
      creatorId
      assetId
      comment
      reply
      count
      status
      createdAt
      updatedAt
    }
  }
`;

const GET_RESERVATIONS = gql`
  query reservations($input: ReservationsInput!) {
    reservations(input: $input) {
      total
      reservations {
        id
        schemaVer
        authorId
        creatorId
        assetId
        comment
        reply
        count
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export { GET_RESERVATION, GET_RESERVATIONS };

import client from "../index.js";
import { GET_CHANNELS } from "./query.js";
import {
  CREATE_CHANNEL,
  UPDATE_CHANNEL,
  DELETE_CHANNEL,
  DELETE_ALL_CHANNELS,
  COPY_CHANNEL_TO_OTHER_LOCATION,
  COPY_CHANNEL_LINE_UP_TO_OTHER_LOCATION,
} from "./mutation";

export const apiGetAllChannels = (filter = {}) => {
  return client
    .query({
      query: GET_CHANNELS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.channels;
    });
};

export const apiCreateChannel = (data) => {
  return client
    .mutate({
      mutation: CREATE_CHANNEL,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createChannel.channel;
    });
};

export const apiCopyChannelToOtherLocation = (data) => {
  return client
    .mutate({
      mutation: COPY_CHANNEL_TO_OTHER_LOCATION,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.copyChannelToOtherLocation.channel;
    });
};

export const apiCopyChannelLineUpToOtherLocation = (data) => {
  return client
    .mutate({
      mutation: COPY_CHANNEL_LINE_UP_TO_OTHER_LOCATION,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.copyChannelLineUpToOtherLocation.channels;
    });
};

export const apiUpdateChannel = (data) => {
  return client
    .mutate({
      mutation: UPDATE_CHANNEL,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateChannel.channel;
    });
};

export const apiDeleteChannel = (id) => {
  return client
    .mutate({
      mutation: DELETE_CHANNEL,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteChannel.id;
    });
};

export const apiDeleteAllChannels = (filter = {}) => {
  return client
    .mutate({
      mutation: DELETE_ALL_CHANNELS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAllChannels?.total;
    });
};

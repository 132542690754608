import { gql } from "@apollo/client";

const CREATE_CATEGORY = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      category {
        id
        name
        domain
        locationId
        desc {
          title
          short
          body
        }
        thumbnail {
          altText
          desc
          emoji
          faIcon
          guid
          image
          muiIcon
          name
          title
          type
          style
        }
        data
        style
        timestamp
        updatedAt
      }
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation updateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      category {
        id
        name
        domain
        locationId
        desc {
          title
          short
          body
        }
        thumbnail {
          altText
          desc
          emoji
          faIcon
          guid
          image
          muiIcon
          name
          title
          type
          style
        }
        style
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      id
    }
  }
`;

const DELETE_ALL_CATEGORYS = gql`
  mutation deleteAllCategories($input: DeleteAllInput!) {
    deleteAllCategories(input: $input) {
      total
    }
  }
`;

export { CREATE_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY, DELETE_ALL_CATEGORYS };

import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_LOCATION = gql`
  subscription onCreateLocation{
    onCreateLocation{
      location{
        id
        name
        data
        authCode
        domain
        avatar {
            name
            dir
            base
            mimetype
        }
        geoLocation {
            pinPoints {
            lat
            lng
            }
            polygon {
            lat
            lng
            }
            zipCode
            fips
            region {
            center {
                lat
                lng
            }
            radius
            unit
            }
        }
        defaultFolderId
        timestamp
        updatedAt
      }
    }
  }
`;

export const ON_UPDATE_LOCATION = gql`
  subscription onUpdateLocation{
    onUpdateLocation{
      location{
        id
        name
        data
        authCode
        domain
        avatar {
            name
            dir
            base
            mimetype
        }
        geoLocation {
            pinPoints {
            lat
            lng
            }
            polygon {
            lat
            lng
            }
            zipCode
            fips
            region {
            center {
                lat
                lng
            }
            radius
            unit
            }
        }
        defaultFolderId
        timestamp
        updatedAt
      }
    }
  }
`;

export const ON_DELETE_LOCATION = gql`
  subscription onDeleteLocation{
    onDeleteLocation{
      id
    }
  }
`;

const useOnCreateLocation= () => useSubscription(ON_CREATE_LOCATION);
const useOnUpdateLocation= () => useSubscription(ON_UPDATE_LOCATION);
const useOnDeleteLocation= () => useSubscription(ON_DELETE_LOCATION);

export { useOnCreateLocation, useOnUpdateLocation, useOnDeleteLocation};

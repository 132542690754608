import React from "react";
import { useSelector } from "react-redux";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import SecondaryTopActionBar from "../../components/nav/SecondaryTopActionBar";
import { useNavigate, useParams } from "react-router-dom";
import CreatorPlayer from "./CreatorPlayer";

import "./PageCreatorDetail.scss";

function PageCreatorDetail() {
  const navigate = useNavigate();
  const assets = useSelector((store) => store.Asset.assets);
  const users = useSelector((store) => store.User.users);

  const { creatorId } = useParams();

  const user = users?.find((t) => t.id === creatorId);

  if (!assets || !creatorId)
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>;

  if (users && !user) navigate("/stuffs");

  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <CreatorPlayer playList={assets?.filter((asset) => asset.owner === creatorId)}></CreatorPlayer>
      </Box>
      <SecondaryTopActionBar title={user?.username}></SecondaryTopActionBar>
    </SubPageWrapper>
  );
}

export default PageCreatorDetail;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Avatar, Box, Typography } from "@mui/material";
import { Favorite as IconFavorite } from "@mui/icons-material";
import { getUserThumbnailPath } from "../../utils";
import { apiCreateUserFolder, apiUpdateUserFolder } from "../../graphQL/userFolder";
import { actionUpdateUserFolder } from "../../store/UserFolder.slice";
import Icons from "../../assets/Icons";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/pro-solid-svg-icons";
const colorArray = ["#FF4344", "#FDB015", "#00C216", "#0196FD"];

const hashedInt = (str, mod) => {
  let hash = 0;
  if (str || str?.length < 1) return hash;
  for (let i = 0; i < str?.length; i++) {
    let char = str.charCodeAt(i);
    // shift the bits left by 5, add the new hash value
    hash = (hash << 5) - hash + char;
    // convert to 32-bit integer
    hash = hash & hash;

    hash %= mod;
  }
  return hash;
};

function CreatorThumbnails({ creator, hideName, onClick, type = "" }) {
  const userFolders = useSelector((store) => store.UserFolder?.userFolders);
  const dispatch = useDispatch();
  const defaultBookmarkFolder = userFolders?.length > 0 ? userFolders[0] : null;

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const isFollowing = useSelector((store) => store.UserFolder?.userFolders)?.some((t) =>
    t.creatorIds?.includes(creator?.id)
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ position: "relative", cursor: "pointer" }}
    >
      <IconButton
        // ref={buttonRef}
        sx={{
          position: "absolute",
          top: "-0.5rem",
          right: "-0.5rem",
          zIndex: 20,
          color: "#D3D3D3",
        }}
        size="small"
        onClick={async (e) => {
          e.stopPropagation();
          if (!creator?.id) return;
          if (isFollowing) {
            await userFolders?.map(async (userFolder) => {
              if (userFolder?.creatorIds?.includes(creator?.id)) {
                apiUpdateUserFolder({
                  id: userFolder?.id,
                  creatorIds: userFolder?.creatorIds?.filter((t) => t !== creator?.id),
                }).then((res) => {
                  dispatch(actionUpdateUserFolder(res));
                });
              }
            });
          } else {
            if (defaultBookmarkFolder) {
              apiUpdateUserFolder({
                id: defaultBookmarkFolder?.id,
                creatorIds: [...(defaultBookmarkFolder.creatorIds || []), creator?.id],
              }).then((res) => {
                dispatch(actionUpdateUserFolder(res));
              });
            } else {
              apiCreateUserFolder({
                owner: PrivateInfo?.id,
                name: "Default",
                rank: new Date().valueOf(),
                creatorIds: [creator?.id],
              }).then((res) => {
                dispatch(actionUpdateUserFolder(res));
              });
            }
          }
        }}
      >
        <IconFavorite
          fontSize="small"
          sx={{
            color: isFollowing ? "#F13256" : "#D3D3D3",
          }}
        ></IconFavorite>
      </IconButton>

      <Avatar
        src={getUserThumbnailPath(creator?.avatar)}
        sx={{
          // width:
          //   type === "assetPlayer"
          //     ? { xs: "2.4rem", sm: "2.7rem", md: "3rem" }
          //     : { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
          // height:
          //   type === "assetPlayer"
          //     ? { xs: "2.4rem", sm: "2.7rem", md: "3rem" }
          //     : { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
          // color: colorArray[hashedInt(creator?.id, 5)],
          // color: isMobile ? "white" : "grey",
          backgroundColor: isMobile ? "#D3D3D3" : "#D3D3D3",
          // fontSize: { xs: isMobile?"2.75rem":"1.75rem", sm: isMobile?"3rem":"2rem", md: "3.25rem" },
          fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
        }}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {/* <Icons.IconDefaultAvatar></Icons.IconDefaultAvatar> */}
        <FontAwesomeIcon
          icon={faCircleUser}
          size="xl"
          sx={{
            fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
          }}
        />
      </Avatar>

      {!hideName && (
        <Box sx={{ height: "1.25rem" }}>
          <Typography variant="body2">{creator?.username || ""}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default CreatorThumbnails;

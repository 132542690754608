import React from "react";
import { Paper, Box, Typography, ClickAwayListener, IconButton, Avatar } from "@mui/material";
import { PushPin as IconPushPin } from "@mui/icons-material";
import config from "../../../config";

function NoteDrawer({ open, onClose, onPin, noteState }) {
  return (
    <ClickAwayListener
      onClickAway={() => {
        onClose();
      }}
    >
      <Paper
        elevation={2}
        sx={{
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          borderRadius: 8,
          position: "absolute",
          bottom: open ? 76 : -100,
          opacity: open ? 1 : 0,
          zIndex: 35,
          p: 2,
          left: "calc( 50% - 188px )",
          width: 320,
          transition: "all 0.5s",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {noteState?.note?.file?.name && (
            <Avatar
              sx={{ mr: 1 }}
              src={`${config.ENV.REACT_APP_HLS_BASE_URL}/${noteState?.note?.file?.directory}/${noteState?.note?.file?.name}`}
            ></Avatar>
          )}
          <Typography variant="body1" textAlign={"center"} flexGrow={1}>
            {noteState?.note?.note?.text}
          </Typography>
          <IconButton
            size="small"
            onClick={() => {
              // onPin();
              onClose();
            }}
          >
            x
          </IconButton>
        </Box>
      </Paper>
    </ClickAwayListener>
  );
}

export default NoteDrawer;

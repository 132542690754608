import { Avatar, Box, IconButton, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getDateTime, getTimeDifference, getUserThumbnailPath } from '../../../utils'
import Icons from '../../../assets/Icons'
import { useSelector } from 'react-redux';
import { Delete, Reply } from '@mui/icons-material';

function ReservationItem({
  comment = {},
  onClick =() => {},
  PrivateInfo = {}
}) {

  const [creator, setCreator] = useState();
  const [author, setAuthor] = useState();
  const users = useSelector((store) => store.User.users);
  const [status, setStatus] = useState("Reserved");

  const handleActions = (type, status) => {
    onClick(type, status);
  }
  
  useEffect(() => {
    if (comment?.creatorId) {
      const user = users?.find((t) => t.id === comment?.creatorId);
      console.log(user);
      setCreator(user);
      const user1 = users?.find((t) => t.id === comment?.authorId);
      setAuthor(user1);
    }
    if (comment) {
      setStatus(comment.status);
    }
  }, [comment])

  return (
    <Box sx={{display: 'flex', color: 'black'}}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', px: 1, pt: 1 }}>
        <Avatar
          src={getUserThumbnailPath(creator?.avatar)}
          sx={{
            width: { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
            height: { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
            color: 'gray',
            backgroundColor: "#fff",
            fontSize: { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
          }}
        >
          <Icons.IconDefaultAvatar></Icons.IconDefaultAvatar>
        </Avatar>
      </Box>
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
      }}>
          <Typography variant='body2' sx={{fontWeight: 600}}>{creator?.username || creator?.name || ""}</Typography>
          <Typography variant='body1'>{comment?.count || "1"}</Typography>
          <Typography variant='body1'>{comment?.comment || ""}</Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <Select
                value={status || "Reserved"}
                onChange={(e) => {
                  setStatus(e.target.value);
                  handleActions('status', e.target.value);
                }}
                disabled={comment.authorId !== PrivateInfo?.id}
                sx={{
                  ".MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    py: 0.5,
                    ".MuiListItemIcon-root": {
                      minWidth: 32,
                    },
                  },
                }}
              >
                {["Reserved", "Completed"]?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {/* <Typography sx={{mr: 1}} color='gray'>{comment?.status || 'Reserved'}</Typography> */}
              <Typography ml={1} variant='body2' color='gray'>{getDateTime(comment?.createdAt)}</Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row'
            }}>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <IconButton disabled={comment?.authorId !== PrivateInfo?.id} onClick={() => {handleActions('reply')}}><Reply/></IconButton>
              </Box>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <IconButton disabled={comment?.creatorId !== PrivateInfo?.id} onClick={() => {handleActions('delete')}}><Delete/></IconButton>
              </Box>
            </Box>
          </Box>
          {comment?.reply && comment?.reply !== null ? <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              mt: 1,
            }}
          >
            <Avatar
              src={getUserThumbnailPath(author?.avatar)}
              sx={{
                width: { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
                height: { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
                color: 'gray',
                backgroundColor: "#fff",
                fontSize: { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
              }}
            >
              <Icons.IconDefaultAvatar></Icons.IconDefaultAvatar>
            </Avatar>
            <Box ml={1}>
              <Typography variant='body1'>{comment?.reply || "comment here"}</Typography>
              <Typography variant='body2' color='gray'>{getDateTime(comment?.updatedAt)}</Typography>
            </Box>
          </Box> : <></>}
      </Box>
    </Box>
  )
}

export default ReservationItem;

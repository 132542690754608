import { createTheme } from "@mui/material/styles";
// import { green, purple } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    mode: "dark",
    common: {
      black: "#030303",
      white: "#e2e2e2",
    },
    primary: {
      main: "#1890FF",
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body2: {
          lineHeight: 1.5,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          paddingLeft: "4px",
          paddingRight: "4px",
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#1890FF",
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          background: "#ffffff",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          input: {
            padding: "6px 9px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          [`& fieldset`]: {
            borderRadius: 0,
          },
        },
        // root: { background: "#ffffff", borderRadius: 1 },
      },
    },
  },
});

export default theme;

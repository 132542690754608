import { gql } from "@apollo/client";

const CREATE_USER_GROUP = gql`
  mutation createUserGroup($input: CreateUserGroupInput!) {
    createUserGroup(input: $input) {
      userGroup {
        id
        name
        type
        domain
        locationId
        parentNodeId
        avatar {
          name
          dir
          base
          status
        }
        desc {
          title
          short
          body
          caption
        }
        keywords
        categories
        ownerId
        authors
        accessPermission
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const UPDATE_USER_GROUP = gql`
  mutation updateUserGroup($input: UpdateUserGroupInput!) {
    updateUserGroup(input: $input) {
      userGroup {
        id
        name
        type
        domain
        locationId
        parentNodeId
        avatar {
          name
          dir
          base
          status
        }
        desc {
          title
          short
          body
          caption
        }
        keywords
        categories
        ownerId
        authors
        accessPermission
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const DELETE_USER_GROUP = gql`
  mutation deleteUserGroup($id: ID!) {
    deleteUserGroup(id: $id) {
      id
    }
  }
`;

export { CREATE_USER_GROUP, UPDATE_USER_GROUP, DELETE_USER_GROUP };

import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Box, TextField } from "@mui/material";
import EditDialogHeader from "./EditDialogHeader";
import EditDialogActions from "./EditDialogActions";
import { useSelector } from "react-redux";
import { v4 as uuid_v4 } from "uuid";
import { useUploadContext } from "../../contexts/UploadContext";
import useAssetNote from "../../hooks/useAssetNote";

function DialogCapturedNote({ file, asset, playerStatus, open, onClose, onSaveEnded }) {
  const [isLoading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [noteContent, setNoteContent] = useState({
    note: {
      text: "",
    },
  });
  const userId = useSelector((store) => store.auth.PrivateInfo?.id);
  const [{ assetNote }, { setAssetNote, updateAssetNote }] = useAssetNote(asset?.id);
  const [, { uploadAvatar }] = useUploadContext();

  useEffect(() => {
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      setNoteContent({ note: { text: "" } });
    }
  }, [file]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <EditDialogHeader handleClose={onClose}></EditDialogHeader>
      <DialogContent>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <img
            src={imageUrl}
            alt="Attachment"
            style={{
              width: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <TextField
          multiline
          rows={3}
          value={noteContent?.note?.text}
          sx={{ mt: 1 }}
          onChange={(e) => {
            setNoteContent({
              ...noteContent,
              note: {
                text: e.target.value,
              },
            });
          }}
          variant="outlined"
          fullWidth
          placeholder="Edit Note"
        />
      </DialogContent>
      <EditDialogActions
        isLoading={isLoading}
        onEdit={() => {
          setLoading(true);
          uploadAvatar(`${asset.domain}/${asset?.id}/${userId}`, file, (err, file, fileName) => {
            updateAssetNote({
              ...assetNote,
              notes: [
                ...(assetNote?.notes || []),
                {
                  uId: uuid_v4(),
                  start: Math.floor(playerStatus.playedSeconds),
                  note: noteContent?.note,
                  file: {
                    directory: `${asset.domain}/${asset?.id}/${userId}`,
                    name: fileName,
                    status: "uploaded",
                  },
                },
              ],
            })
              .then((res) => {
                setAssetNote(res);
                onSaveEnded();
              })
              .catch(() => {})
              .then(() => {
                setLoading(false);
              });
          });
        }}
      ></EditDialogActions>
    </Dialog>
  );
}

export default DialogCapturedNote;

import React, { useState, useEffect, useRef } from "react";
import { Avatar, Box, IconButton } from "@mui/material";
import {
  PhotoCamera as IconPhotoCamera,
  AccountCircle as IconAccountCircle,
  Close as IconClose,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinoculars } from "@fortawesome/pro-solid-svg-icons";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

const AvatarUpload = ({ url, onChange, Icon, variant, onDelete }) => {
  const buttonRef = useRef();
  const [avatarSrc, setAvatarSrc] = useState(null);

  const handleOnChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setAvatarSrc(URL.createObjectURL(e.target.files[0]));
      return onChange(e.target.files[0]);
    }
    return onChange(null);
  };

  useEffect(() => {
    setAvatarSrc(url);
  }, [url]);

  return (
    <Box
      sx={{ width: "100%", height: "100%", position: "relative", border: "2px dashed #232323", background: "#FFFFFF" }}
    >
      {
        <Avatar
          alt="Avatar"
          src={avatarSrc}
          imgProps={{
            style: {
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
            },
          }}
          sx={{
            width: "100%",
            height: "100%",
            fontSize: 90,
          }}
          variant={variant}
          onClick={() => {
            buttonRef.current.click();
          }}
        >
          <Icon fontSize="inherit"></Icon>
        </Avatar>
      }
      {(url || avatarSrc) && (
        <IconButton
          variant="contained"
          aria-label="delete picture"
          component="label"
          sx={{
            position: "absolute",
            top: 0,
            right: variant === "circular" ? "8px" : "0px",
            zIndex: 10,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setAvatarSrc(null);
            onDelete();
          }}
        >
          <FontAwesomeIcon icon={faClose} />
        </IconButton>
      )}
      <input hidden accept="image/*" type="file" onChange={handleOnChange} ref={buttonRef} />
      <IconButton
        variant="contained"
        aria-label="upload picture"
        component="label"
        sx={{
          position: "absolute",
          left: variant === "circular" ? "8px" : "0px",
          top: 0,
        }}
      >
        <FontAwesomeIcon icon={faBinoculars} />
      </IconButton>
    </Box>
  );
};

AvatarUpload.defaultProps = {
  Icon: IconAccountCircle,
  variant: "circular",
};

export default AvatarUpload;

import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import { Button, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightAndArrowDownLeftFromCenter } from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import { Delete, Edit } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

function BoxWidget({
  onChange = () => { },
  onClick = () => { },
  widget,
  isEditable = true,
  overlaySize = { width: 100, height: 100 },
}) {

  const [size, setSize] = useState({ width: 240, height: 150 });
  const [position, setPosition] = useState({ x: overlaySize.width / 2 - 80, y: overlaySize.height / 2 - 50 });

  const onStart = (e, data) => { };

  const onStop = (e, data) => {
    const { x, y } = data;
    onChange({ size, position: { x, y }, overlaySize });
  };

  useEffect(() => {
    if (widget && overlaySize?.width) {
      const wRatio = overlaySize.width / (widget.boxInfo?.videoWidth || overlaySize.width);
      const hRatio = overlaySize.height / (widget.boxInfo?.videoHeight || overlaySize.height);
      const s = {
        width: (widget.boxInfo?.width || 100) * wRatio,
        height: (widget.boxInfo?.height || 100) * hRatio
      }
      const p = {
        x: (widget.boxInfo?.x || overlaySize.width / 2) * wRatio,
        y: (widget.boxInfo?.y || overlaySize.height / 2) * hRatio
      }
      setSize(s);
      setPosition(p);
      // if ((widget.boxInfo?.videoWidth && widget.boxInfo?.videoHeight)) {
      //   onChange({ size: s, position: p, overlaySize });
      // }
    }
  }, [widget, overlaySize]);

  const onResize = (size) => {
    onChange({ size, position, overlaySize });
  }

  const handleActions = (type) => {
    onClick(type);
  };

  const cancelDrag = () => {
    if (!isEditable) {
      return "button";
    } else {
      if (isMobile) {
        return ".widgetDelete, .widgetEdit";
      }
      return ".buttonRemove";
    }
  };

  const renderHandle = () => {
    if (isEditable) {
      return (
        <div className="draghandle">
          <FontAwesomeIcon rotation={90} icon={faArrowUpRightAndArrowDownLeftFromCenter} color={"#ffffff"} />
        </div>
      )
    }
    return <></>
  }

  return (
    <Draggable
      bounds="parent"
      handle="strong"
      axis="both"
      onStart={onStart}
      onStop={onStop}
      position={position}
      defaultPosition={{ x: 100, y: 100 }}
      disabled={!isEditable}
      cancel={cancelDrag()}
    >
      <div className="box no-cursor">
        <Resizable
          className="resizable-container"
          height={size.height}
          width={size.width}
          onResize={(e, { element, size, handle }) => {
            onResize({ width: size.width, height: size.height });
          }}
          axis="both"
          minConstraints={[100, 100]}
          maxConstraints={[overlaySize.width - position.x, overlaySize.height - position.y]}
          handle={
            renderHandle()
          }
        >
          <div className="box" style={{ width: size.width + "px", height: size.height + "px" }}>
            <strong
              className={isEditable ? "" : "cursor"}
              style={{ width: "100%", height: "100%" }}
            >
              <Button
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: 0,
                  margin: 0,
                  cursor: "pointer",
                  pointerEvents: "all",
                  border: `2px dashed ${widget?.boxInfo?.foreground || "red"}`
                }}
                onClick={() => {
                  if (!isEditable) {
                    handleActions("detail");
                  }
                }}
              >
                {isEditable ? <>
                  <IconButton
                    className="widgetEdit"
                    sx={{
                      position: "absolute",
                      top: "-18px",
                      right: "-18px",
                      zIndex: 10,
                      background: "rgba(179, 179, 179, 0.328)",
                    }}
                    size="small"
                    onClick={() => { 
                      handleActions("edit");
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton 
                  className="widgetDelete"
                  sx={{
                    position: "absolute",
                    bottom: "-18px",
                    left: "-18px",
                    zIndex: 10,
                    background: "rgba(179, 179, 179, 0.328)",
                  }}
                    size="small"
                    onClick={() => { 
                      handleActions("delete");
                    }}
                  >
                    <Delete />
                  </IconButton>
                </> : <></>}
              </Button>
            </strong>
          </div>
        </Resizable>
      </div>
    </Draggable>
  );
}

export default BoxWidget;

import { gql } from "@apollo/client";

const INC_LIKED_ASSET = gql`
  mutation incLikedAsset($input: AssetAnalyticsInput!) {
    incLikedAsset(input: $input) {
      assetAnalytics {
        id
        assetId
        assetSize
        watched
        bookmarked
        unbookmarked
        skipped
        noted
        read
        liked
        disliked
        timedCount {
          type
          count
          data
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const INC_DISLIKED_ASSET = gql`
  mutation incDislikedAsset($input: AssetAnalyticsInput!) {
    incDislikedAsset(input: $input) {
      assetAnalytics {
        id
        assetId
        assetSize
        watched
        bookmarked
        unbookmarked
        skipped
        noted
        read
        liked
        disliked
        fullPlayed
        partialPlayed
        timedCount {
          type
          count
          data
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const INC_WATCHED_ASSET = gql`
  mutation incWatchedAsset($input: AssetAnalyticsInput!) {
    incWatchedAsset(input: $input) {
      assetAnalytics {
        id
        assetId
        assetSize
        watched
        bookmarked
        unbookmarked
        skipped
        noted
        read
        liked
        disliked
        fullPlayed
        partialPlayed
        timedCount {
          type
          count
          data
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const INC_BOOKMARKED_ASSET = gql`
  mutation incBookmarkedAsset($input: AssetAnalyticsInput!) {
    incBookmarkedAsset(input: $input) {
      assetAnalytics {
        id
        assetId
        assetSize
        watched
        bookmarked
        unbookmarked
        skipped
        noted
        read
        liked
        disliked
        fullPlayed
        partialPlayed
        timedCount {
          type
          count
          data
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const INC_UNBOOKMARKED_ASSET = gql`
  mutation incUnbookmarkedAsset($input: AssetAnalyticsInput!) {
    incUnbookmarkedAsset(input: $input) {
      assetAnalytics {
        id
        assetId
        assetSize
        watched
        bookmarked
        unbookmarked
        skipped
        noted
        read
        liked
        disliked
        fullPlayed
        partialPlayed
        timedCount {
          type
          count
          data
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const INC_NOTED_ASSET = gql`
  mutation incNotedAsset($input: AssetAnalyticsInput!) {
    incNotedAsset(input: $input) {
      assetAnalytics {
        id
        assetId
        assetSize
        watched
        bookmarked
        unbookmarked
        skipped
        noted
        read
        liked
        disliked
        fullPlayed
        partialPlayed
        timedCount {
          type
          count
          data
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const INC_READ_ASSET = gql`
  mutation incReadAsset($input: AssetAnalyticsInput!) {
    incReadAsset(input: $input) {
      assetAnalytics {
        id
        assetId
        assetSize
        watched
        bookmarked
        unbookmarked
        skipped
        noted
        read
        liked
        disliked
        fullPlayed
        partialPlayed
        timedCount {
          type
          count
          data
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const INC_FULL_PLAYED = gql`
  mutation incFullPlayedAsset($input: AssetAnalyticsInput!) {
    incFullPlayedAsset(input: $input) {
      assetAnalytics {
        id
        assetId
        assetSize
        watched
        bookmarked
        unbookmarked
        skipped
        noted
        read
        liked
        disliked
        fullPlayed
        partialPlayed
        timedCount {
          type
          count
          data
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const INC_PARTIAL_PLAYED = gql`
  mutation incPartialPlayedAsset($input: AssetAnalyticsInput!) {
    incPartialPlayedAsset(input: $input) {
      assetAnalytics {
        id
        assetId
        assetSize
        watched
        bookmarked
        unbookmarked
        skipped
        noted
        read
        liked
        disliked
        fullPlayed
        partialPlayed
        timedCount {
          type
          count
          data
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export {
  INC_LIKED_ASSET,
  INC_DISLIKED_ASSET,
  INC_WATCHED_ASSET,
  INC_BOOKMARKED_ASSET,
  INC_UNBOOKMARKED_ASSET,
  INC_NOTED_ASSET,
  INC_READ_ASSET,
  INC_FULL_PLAYED,
  INC_PARTIAL_PLAYED,
};

import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { v4 as uuid_v4 } from "uuid";
import { ButtonCreate, ButtonSave } from "../../components/buttons";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { apiCreateUser } from "../../graphQL/user";
import { actionUpdateUser } from "../../store/User.slice";
import { getRandomIntInclusive } from "../../utils";
import { Close } from "@mui/icons-material";

function DialogAddUser({
  open,
  campaign,
  domain,
  onClose = () => { },
  onSave = () => { },
}) {

    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [checkedRoll, setCheckedRoll] = useState(false);
    const [loading, setLoading] = useState(false);

  const submit = () => {
    setLoading(true);
    let id = uuid_v4();
    const initiative = checkedRoll ? getRandomIntInclusive(-2, 5) : 0;
    const roll = {
      major: checkedRoll ? getRandomIntInclusive(1, 20) : 0,
      minor: checkedRoll ? getRandomIntInclusive(0, 9) : 0,
    };
    const rank = initiative + roll.major + parseFloat(roll.minor) / 10;

    apiCreateUser({
        id: id,
        name,
        username: name,
        role: 'other',
        domain: domain,
        domainList:[domain],
        locationId: campaign.locationId,
        locationIdList: [campaign.locationId],
        data: {
            type: 'campaign',
            ver: 1,
            params: [{
                ...campaign,
                name,
                rank,
                initiative,
                roll,
            }]
        },
        activated: true
      })
        .then((res) => {
          setLoading(false);
          dispatch(actionUpdateUser(res));
          onSave();
        })
        .then(() => {
          setLoading(false);
          setName('');
          setCheckedRoll(false);
        });
  };

  const handleClose = () => {
    setName('');
    setCheckedRoll(false);
    onClose();
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <ValidatorForm onSubmit={submit}>
        <DialogContent>
          <Grid container
            alignItems="center"
            gap={1}
            mt={2}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 3,
                right: 3
              }}
              onClick={onClose}>
              <Close/>
            </IconButton>
            <Grid item xs={12} mt={2}>
              <TextValidator
                fullWidth
                label="Name"
                value={name || ""}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                autoComplete="off"
                validators={["required"]}
                errorMessages={["This field is required"]}
                sx={{ mb: 1.5 }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={<Checkbox checked={checkedRoll} onChange={()=> {setCheckedRoll(!checkedRoll);}}/>} label="Roll for me" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
          </Box>
          <ButtonCreate
            isLoading={loading}
          ></ButtonCreate>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default DialogAddUser;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, IconButton } from "@mui/material";
import { Add as IconAdd } from "@mui/icons-material";

import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import SecondaryTopActionBar from "../../components/nav/SecondaryTopActionBar";
import UserGroupCard from "../../components/CustomCards/UserGroupCard/UserGroupCard";
import UserGroupDrawer from "../../components/CustomDrawers/UserGroupDrawer/UserGroupDrawer";
import { getAllUserGroups } from "../../store/UserGroup.slice";
import { getAllUsers } from "../../store/User.slice";
import { useNavigate } from "react-router-dom";
import { GUEST } from "../../config";

const GroupHeader = ({ userGroup }) => {
  return (
    <Box sx={{ px: 1, borderBottom: "1px solid #D9D9D9", mb: 1 }}>
      <Typography variant="body1" sx={{ mb: 0.25 }}>
        {userGroup.name}
      </Typography>
    </Box>
  );
};

function PageMyGroups() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const userGroups = useSelector((store) => store.UserGroup.userGroups);
  const otherProfile = useSelector((store) => store.OtherProfile.Profile);

  const [editUserGroup, setEditUserGroup] = useState(null);

  if(PrivateInfo?.name === GUEST.email) {
    navigate("/");
  }

  useEffect(() => {
    if (PrivateInfo?.domain) {
      dispatch(getAllUsers({ domain: PrivateInfo.domain }));
      dispatch(getAllUserGroups({ domain: PrivateInfo.domain }));
    }
  }, [dispatch, PrivateInfo?.domain]);

  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            overflowY: "auto",
            px: 1.5,
            pt: 1,
          }}
        >
          {userGroups
            ?.filter((t) => t?.ownerId === PrivateInfo?.id || t?.authors?.includes(PrivateInfo?.id))
            .map((userGroup) => (
              <React.Fragment>
                <GroupHeader userGroup={userGroup}></GroupHeader>
                <Box sx={{ mb: 1 }}>
                  <UserGroupCard
                    userGroup={userGroup}
                    onEdit={setEditUserGroup}
                    onClick={() => {
                      navigate(`/group/detail/${userGroup?.id}`);
                    }}
                  ></UserGroupCard>
                </Box>
              </React.Fragment>
            ))}
        </Box>
      </Box>
      <SecondaryTopActionBar
        title="My Groups"
        right={
          <IconButton
            disabled={otherProfile}
            size="small"
            onClick={() => {
              setEditUserGroup({
                domain: PrivateInfo.domain,
                locationId: PrivateInfo.locationId,
                name: "",
                ownerId: PrivateInfo?.id,
                authors: [PrivateInfo?.id],
                desc: {
                  title: "",
                },
              });
            }}
          >
            <IconAdd fontSize="large"></IconAdd>
          </IconButton>
        }
      ></SecondaryTopActionBar>
      <UserGroupDrawer editUserGroup={editUserGroup} setEditUserGroup={setEditUserGroup}></UserGroupDrawer>
    </SubPageWrapper>
  );
}

export default PageMyGroups;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogContentText,
  DialogActions,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { ButtonSave } from "../../components/buttons";
import { useSelector } from "react-redux";
const DialogUserDomain = ({
  openDialogUserDomain,
  handleClose,
  form,
  setForm,
  isLoading,
  actionSave,
  PrivateInfo,
}) => {
  const domains = useSelector((state) => state.Domain.domains);
  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={openDialogUserDomain}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>{form?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>{form?.desc}</DialogContentText>
        {/* <TextField
          autoFocus
          fullWidth
          variant="standard"
          value={form?.value || ""}
          // onChange={(e) => {
          //   setForm({ ...form, value: e.target.value });
          // }}
          // autoComplete="off"
        /> */}
        <Select
          fullWidth
          value={form?.value}
          onChange={(e) => {
            setForm({ ...form, value: e.target.value });
          }}
        >
          {domains?.map((domain) => (
            <MenuItem key={domain.id} value={domain.name}>
              {domain.name}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <ButtonSave
          loading={isLoading}
          onClick={() => {
            actionSave({
              id: PrivateInfo?.id,
              domain: form?.value,
            }).then(() => {
              handleClose();
            });
          }}
        ></ButtonSave>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUserDomain;

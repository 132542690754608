import { gql } from "@apollo/client";

const CREATE_ALERT_FOLDER = gql`
  mutation createAlertFolder($input: CreateAlertFolderInput!) {
    createAlertFolder(input: $input) {
      alertFolder {
        id
        name
        owner
        parentNode
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        childNodeIds
        keywords
        categories
        style
        rank
        status
        availability {
          from
          to
        }
        schedule {
          deleteAt
          archiveAt
        }
        template
        templateType
        domain
        locationId
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const UPDATE_ALERT_FOLDER = gql`
  mutation updateAlertFolder($input: UpdateAlertFolderInput!) {
    updateAlertFolder(input: $input) {
      alertFolder {
        id
        name
        owner
        parentNode
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        childNodeIds
        keywords
        categories
        style
        rank
        status
        availability {
          from
          to
        }
        schedule {
          deleteAt
          archiveAt
        }
        template
        templateType
        domain
        locationId
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const DELETE_ALERT_FOLDER = gql`
  mutation deleteAlertFolder($id: ID!) {
    deleteAlertFolder(id: $id) {
      id
    }
  }
`;

const DELETE_ALL_ALERT_FOLDERS = gql`
  mutation deleteAllAlertFolders($input: DeleteAllInput!) {
    deleteAllAlertFolders(input: $input) {
      total
    }
  }
`;

export { CREATE_ALERT_FOLDER, UPDATE_ALERT_FOLDER, DELETE_ALERT_FOLDER, DELETE_ALL_ALERT_FOLDERS };

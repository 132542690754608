import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import SubPageWrapper, { SubPageContentWrapper } from "../../layouts/wrappers/SubPageWrapper";
import { getAllUsers } from "../../store/User.slice";
import NewCreatorCard from "../../components/CustomCards/NewCreatorCard/NewCreatorCard";
import CommentDrawer from "../../components/CustomDrawers/CommentDrawer/CommentDrawer";

function Creators() {
  const dispatch = useDispatch();
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const users = useSelector((store) => store.User.users);
  const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
  const [userForComment, setUserForComment] = useState(null);

  useEffect(() => {
    if (PrivateInfo?.domain) {
      dispatch(getAllUsers({ domain: PrivateInfo?.domain }));
    }
  }, [PrivateInfo?.domain, dispatch]);

  return (
    <SubPageWrapper>
      <SubPageContentWrapper>
        <Grid container spacing={{ xs: 1, sm: 1.25, md: 1.5 }}>
          {users?.map((t) => (
            <Grid
              item
              key={t.id}
              xs={6}
              sm={4}
              md={3}
              lg={2}
              // sx={{
              //   width: { xs: "49%", sm: "32%", md: "23.5%", lg: "18.4%", xl: "15%" },
              //   minWidth: { xs: "49%", sm: "32%", md: "23.5%", lg: "18.4%", xl: "15%" },
              // }}
            >
              <NewCreatorCard creator={t} onClick={(type) => {
                if (type === "PrivateComment") {
                  setUserForComment(t);
                  setOpenCommentDrawer(true);
                }
              }}></NewCreatorCard>
            </Grid>
          ))}
        </Grid>
      <CommentDrawer
        open={openCommentDrawer}
        setOpen={setOpenCommentDrawer}
        onClose={() => {
          setUserForComment(null);
          setOpenCommentDrawer(false);
        }}
        creator={userForComment}
        isDirect={true}
      />
      </SubPageContentWrapper>
    </SubPageWrapper>
  );
}

export default Creators;

import React from "react";
import { Avatar } from "@mui/material";
import { getAvatarPath } from "../../utils";
import { Folder as IconFolder } from "@mui/icons-material";
import { getFolderIcon } from "../../utils/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AssetFolderAvatar({ assetFolder, size = "1.25rem" }) {
  if (assetFolder?.style?.icon?.name === "image") {
    if (getAvatarPath(assetFolder?.avatar)) {
      return <Avatar variant="rounded" size={size} src={getAvatarPath(assetFolder?.avatar)}></Avatar>;
    }
    return (
      <Avatar>
        <IconFolder></IconFolder>
      </Avatar>
    );
  }

  return (
    <FontAwesomeIcon
      style={{
        width: size,
        height: size,
      }}
      color="#ffffff"
      icon={getFolderIcon(assetFolder?.style?.icon?.name)}
    />
  );
}

export default AssetFolderAvatar;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllMenus } from "../graphQL/menu";

export const getAllMenus = createAsyncThunk("menu/read_menus", async (filter) => {
  return await apiGetAllMenus(filter);
});

const menuSlice = createSlice({
  name: "Menu",
  initialState: {
    total: 0,
    menus: null,
  },
  reducers: {
    actionUpdateMenu: (state, action) => {
      if (state.menus) {
        if (state.menus.find((item) => item.id === action.payload.id)) {
          state.menus = state.menus.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.menus = [...state.menus, action.payload];
          state.total = state.menus.length;
        }
      } else {
        state.menus = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteMenu: (state, action) => {
      state.menus = state.menus?.filter((item) => item?.id !== action.payload);
      state.total = state.menus.length;
    },
    actionDeleteAllMenus: (state, action) => {
      state.menus = state.menus?.filter((t) => t.domain !== action.payload.domain);
      state.total = state.menus.length;
    },
  },
  extraReducers: {
    [getAllMenus.fulfilled]: (state, action) => {
      state.total = action.payload.total;
      state.menus = action.payload.menus;
    },
    [getAllMenus.rejected]: (state, action) => {
      state.total = 0;
      state.menus = null;
    },
  },
});

export const { actionUpdateMenu, actionDeleteMenu, actionDeleteAllMenus } = menuSlice.actions;
export default menuSlice.reducer;

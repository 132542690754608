import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AssetPlayer from "../player/AssetPlayer";

function StreamPlayerWrapper({ channelId, playList }) {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentAsset, setCurrentAsset] = useState(null);

  const onPrevClicked = () => {
    if (currentIndex < 0) return;
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      return;
    }
    setCurrentIndex(playList.length - 1);
  };

  const onNextClicked = () => {
    if (currentIndex < 0) return;
    if (currentIndex >= playList.length - 1) {
      setCurrentIndex(0);
      return;
    }
    setCurrentIndex(currentIndex + 1);
  };

  const onVideoEnded = () => {
    setCurrentIndex(currentIndex + 1);
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, [channelId]);

  useEffect(() => {
    if (!currentAsset) {
      setCurrentIndex(0);
      return;
    }
    if (playList && playList.find((item) => item.id === currentAsset.id)) {
      setCurrentIndex(playList.findIndex((item) => item.id === currentAsset.id));
    }
  }, [playList]); //eslint-disable-line

  useEffect(() => {
    if (currentIndex < 0 || !playList) return;

    // console.log(playList, currentIndex);

    if (playList.length > 0) {
      if (playList.length <= currentIndex) {
        setCurrentIndex(0);
        return;
      }
      setCurrentAsset(playList[currentIndex]);
    }
  }, [currentIndex, playList]); //eslint-disable-line

  // useEffect(() => {
  //   console.log(currentAsset);
  // }, [currentAsset]);

  if (playList?.length > 0) {
    return (
      <AssetPlayer
        currentIndex={currentIndex}
        playList={playList}
        currentAsset={currentAsset}
        onVideoEnded={onVideoEnded}
        onNext={onNextClicked}
        onPrev={onPrevClicked}
        showChannels={true}
      ></AssetPlayer>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {playList && (
        <Typography variant="h5" color="common.white">
          There is no assets to show up
        </Typography>
      )}
    </Box>
  );
}

export default StreamPlayerWrapper;

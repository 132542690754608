import React, { useState, useRef } from "react";
import clsx from "clsx";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import { Bookmark as IconBookmark, BookmarkBorder as IconBookmarkBorder } from "@mui/icons-material";
import BookmarkMenu from "../../../components/menus/BookmarkMenu";
import { getAvatarPath } from "../../../utils";
import { apiUpdateUserFolder } from "../../../graphQL/userFolder";
import { actionUpdateUserFolder } from "../../../store/UserFolder.slice";
import "./ChannelCard.scss";

const ChannelCard = ({ channel, enableBookmark }) => {
  const dispatch = useDispatch();

  const userFolders = useSelector((store) => store.UserFolder.userFolders);
  const bookmarkButtonRef = useRef(null);

  const [openBookmarkMenu, setOpenBookmarkMenu] = useState(false);

  const isBookmarked = (channel) => {
    if (!userFolders || userFolders?.length === 0) return false;
    return userFolders?.some((userFolder) => {
      return userFolder?.channelIds?.includes(channel?.id);
    });
  };

  const addChannelBookmark = async (userFolder, channel) => {
    if (userFolder) {
      let res = await apiUpdateUserFolder({
        id: userFolder?.id,
        channelIds: [...(userFolder?.channelIds?.filter((t) => t !== channel?.id) || []), channel?.id],
      });
      dispatch(actionUpdateUserFolder(res));
    }
  };

  const removeChannelBookmark = async (channel) => {
    const userFolder = userFolders?.find((userFolder) => {
      return userFolder?.channelIds?.includes(channel?.id);
    });
    if (userFolder) {
      let res = await apiUpdateUserFolder({
        id: userFolder?.id,
        channelIds: userFolder?.channelIds?.filter((t) => t !== channel?.id) || [],
      });
      dispatch(actionUpdateUserFolder(res));
    }
  };

  return (
    <Box
      className={clsx("channel-card-container")}
      sx={{
        width: "100%",
        maxWidth: "420px",
      }}
    >
      <Box sx={{ background: "#D9D9D9", borderRadius: 1 }}>
        <Box display="flex" alignItems="center" sx={{ px: 0.5, py: 0.5, color: "#232323", minHeight: "2.5rem" }}>
          <Box flexGrow={1} sx={{ pl: 0.5, color: "#232323" }}></Box>
          <Box></Box>
        </Box>
        <Box display="flex" alignItems="stretch" sx={{ p: 1, background: "#575757" }}>
          <Box sx={{ width: "6rem", height: "6rem", background: "#D9D9D9", borderRadius: 1 }}>
            {getAvatarPath(channel?.avatar) && (
              <Avatar
                sx={{ width: "6rem", height: "6rem" }}
                src={getAvatarPath(channel?.avatar)}
                variant="rounded"
              ></Avatar>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            sx={{ pl: 1 }}
            flexGrow={1}
          >
            <Typography variant="body1" fontWeight={500}>
              {channel?.desc?.title}
            </Typography>
            <Box flexGrow="1">
              <Typography variant="body2">{channel?.desc?.short}</Typography>
            </Box>
            <Box className="flex items-center justify-content-end w-full"></Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pl: 1, color: "#1E1E1E" }}>
          <Typography variant="body2" fontSize="0.75rem">
            {moment(parseFloat(channel?.timestamp)).utc().fromNow()}
          </Typography>
          <Box className="flex items-center">
            {enableBookmark && (
              <>
                <IconButton
                  sx={{ color: "#232323" }}
                  size="small"
                  onClick={() => {
                    if (isBookmarked(channel)) {
                      removeChannelBookmark(channel).then(() => {
                        setOpenBookmarkMenu(false);
                      });
                    } else {
                      setOpenBookmarkMenu(true);
                    }
                  }}
                  ref={bookmarkButtonRef}
                >
                  {isBookmarked(channel) ? <IconBookmark></IconBookmark> : <IconBookmarkBorder></IconBookmarkBorder>}
                </IconButton>
                <BookmarkMenu
                  open={openBookmarkMenu}
                  onClose={() => {
                    setOpenBookmarkMenu(false);
                  }}
                  anchorEl={openBookmarkMenu ? bookmarkButtonRef?.current : null}
                  onBookmark={(userFolder) => {
                    addChannelBookmark(userFolder, channel);
                  }}
                ></BookmarkMenu>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ChannelCard.defaultProps = {
  enableCardAction: true,
  hideMoreLikeThis: false,
  enableBookmark: true,
  enableStar: true,
};

export default ChannelCard;

import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_CHANNEL = gql`
  subscription onCreateChannel{
    onCreateChannel{
      channel{
        id
        name
        desc {
            title
            short
            body
            website
            style
            rows
        }
        avatar {
            name
            dir
            base
            mimetype
            status
            faIcon
            emoji
            thumbnail
            style
        }
        availability {
            from
            to
        }
        style
        type
        category
        domain
        locationId
        status
        data
        menuId
        channelURI {
            type
            protocol
            address
            locator
            port
        }
        altURI
        serviceId
        globalServiceId
        channelNum {
            major
            minor
        }
        timestamp
        updatedAt
      }
    }
  }
`;

export const ON_UPDATE_CHANNEL = gql`
  subscription onUpdateChannel{
    onUpdateChannel{
      channel{
        id
        name
        desc {
            title
            short
            body
            website
            style
            rows
        }
        avatar {
            name
            dir
            base
            mimetype
            status
            faIcon
            emoji
            thumbnail
            style
        }
        availability {
            from
            to
        }
        style
        type
        category
        domain
        locationId
        status
        data
        menuId
        channelURI {
            type
            protocol
            address
            locator
            port
        }
        altURI
        serviceId
        globalServiceId
        channelNum {
            major
            minor
        }
        timestamp
        updatedAt
      }
    }
  }
`;

export const ON_DELETE_CHANNEL = gql`
  subscription onDeleteChannel{
    onDeleteChannel{
      id
    }
  }
`;

const useOnCreateChannel= () => useSubscription(ON_CREATE_CHANNEL);
const useOnUpdateChannel= () => useSubscription(ON_UPDATE_CHANNEL);
const useOnDeleteChannel= () => useSubscription(ON_DELETE_CHANNEL);

export { useOnCreateChannel, useOnUpdateChannel, useOnDeleteChannel};

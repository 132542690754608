import React from 'react';
import { Box, Slider, IconButton, useTheme, Typography } from '@mui/material';
import {
  SkipNext as IconSkipNext,
  SkipPrevious as IconSkipPrev,
  VolumeMute as IconVolumeMute,
  VolumeUp as IconVolumeUp,
  VolumeDown as IconVolumeDown,
} from '@mui/icons-material';

import { useAppContext } from '../../contexts/AppContext';
import { isMobile } from 'react-device-detect';
import { getPlayerTime } from '../../utils';

function CustomControls({ numberOfAssets, onPrev, onNext, playerStatus, onSeek, disableSeek, currentAsset }) {
  const theme = useTheme();
  const [{ volume }, { setVolume }] = useAppContext();
  return (
    <Box
      className="asset-player-footer"
      sx={{
        position: 'absolute',
        bottom: 0,
        zIndex: 29,
        width: '100%',
        transition: 'bottom 0.5s',
      }}
    >
      <Box
        sx={{
          background: '#353535AA',
          px: {
            xs: 1,
            md: 2,
          },
        }}
      >
        {!disableSeek && (
          <Box
            sx={{
              position: 'relative',
              zIndex: 50,
              width: '100%',
              paddingTop: '4px',
            }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {!isMobile && (
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  display: {
                    xs: 'none',
                    md: 'flex',
                  },
                }}
              >
                <Box display="flex">
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (volume === 0) setVolume(50);
                      else setVolume(0);
                    }}
                  >
                    {volume === 0 && <IconVolumeMute></IconVolumeMute>}
                    {volume > 0 && volume < 50 && <IconVolumeDown></IconVolumeDown>}
                    {volume >= 50 && <IconVolumeUp></IconVolumeUp>}
                  </IconButton>
                  <Slider
                    size="small"
                    value={volume || 0}
                    min={0}
                    max={100}
                    step={1}
                    valueLabelDisplay="off"
                    onChange={(_, value) => {
                      setVolume(value);
                    }}
                    sx={{
                      mx: 1,
                      width: 60,
                      color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                      height: 6,
                      display: {
                        xs: 'none',
                        md: 'block',
                      },
                      '& .MuiSlider-thumb': {
                        width: 16,
                        height: 16,
                        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                        '&:before': {
                          boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                        },
                        '&:hover, &.Mui-focusVisible': {
                          boxShadow: `0px 0px 0px 8px ${
                            theme.palette.mode === 'dark' ? 'rgb(255 255 255 / 16%)' : 'rgb(0 0 0 / 16%)'
                          }`,
                        },
                        '&.Mui-active': {
                          width: 24,
                          height: 24,
                        },
                      },
                      '& .MuiSlider-rail': {
                        opacity: 0.28,
                      },
                    }}
                  />
                </Box>
              </Box>
            )}
            {currentAsset?.desc?.title && (
              <Typography
                sx={{
                  // background: "#eeeeee08",
                  color: 'white',

                  zIndex: 10,
                  fontSize: { sm: 20, md: 24 },
                }}
              >
                {currentAsset?.desc?.title}
              </Typography>
            )}

            <Typography
              variant="body1"
              color="common.white"
              ml={1}
              sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                },
              }}
            >
              {getPlayerTime(playerStatus.playedSeconds) + ' / ' + getPlayerTime(playerStatus.duration)}
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            position: 'relative',
            zIndex: 50,
          }}
        >
          <Typography
            variant="body1"
            color="common.white"
            ml={1}
            sx={{
              fontSize: {
                xs: '12px',
                md: '16px',
              },
              display: {
                xs: 'block',
                md: 'none',
              },
            }}
          >
            {getPlayerTime(playerStatus.playedSeconds)}
          </Typography>
          {numberOfAssets > 1 && (
            <IconButton
              size="small"
              onClick={() => {
                onPrev();
              }}
              sx={{ display: { xs: 'none', sm: 'flex' } }}
            >
              <IconSkipPrev></IconSkipPrev>
            </IconButton>
          )}
          <Box sx={{ px: 1 }} flexGrow={1} display="flex" alignItems="center">
            <Slider
              disabled={disableSeek}
              aria-label="time-indicator"
              size="small"
              value={playerStatus?.playedSeconds}
              min={0}
              step={0.1}
              max={playerStatus?.duration}
              onChange={(_, value) => {
                onSeek(value);
              }}
              sx={{
                padding: '8px 0 !important',
                color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                height: 4,
                '& .MuiSlider-thumb': {
                  width: 12,
                  height: 12,
                  transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                  '&:before': {
                    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                  },
                  '&:hover, &.Mui-focusVisible': {
                    boxShadow: `0px 0px 0px 8px ${
                      theme.palette.mode === 'dark' ? 'rgb(255 255 255 / 16%)' : 'rgb(0 0 0 / 16%)'
                    }`,
                  },
                  '&.Mui-active': {
                    width: 20,
                    height: 20,
                  },
                },
                '& .MuiSlider-rail': {
                  opacity: 0.28,
                },
              }}
            />
          </Box>
          {numberOfAssets > 1 && (
            <IconButton
              size="small"
              onClick={() => {
                onNext();
              }}
              sx={{ display: { xs: 'none', sm: 'flex' } }}
            >
              <IconSkipNext></IconSkipNext>
            </IconButton>
          )}
          <Typography
            variant="body1"
            color="common.white"
            ml={1}
            sx={{
              fontSize: {
                xs: '12px',
                md: '16px',
              },
              display: {
                xs: 'block',
                md: 'none',
              },
            }}
          >
            {getPlayerTime(playerStatus.duration)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomControls;

import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const BottomActionButton = styled(Button)(({ theme }) => ({
  color: "#333333",
}));

export default function ButtonMore({ open, ...props }) {
  return (
    <BottomActionButton {...props} sx={{ px: 0.5 }}>
      {open ? <ExpandLess></ExpandLess> : <ExpandMore></ExpandMore>}
      More
    </BottomActionButton>
  );
}

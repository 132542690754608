import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CancelRounded as IconCancel } from "@mui/icons-material";
import { Box, Avatar, Typography, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserThumbnailPath } from "../../../utils";
import "./CreatorCard.scss";
import { apiUpdateUserFolder } from "../../../graphQL/userFolder";
import { actionUpdateUserFolder } from "../../../store/UserFolder.slice";
import { FollowButton } from "../../buttons/CardButtons";

export default function CreatorCard({ creator, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userFolders = useSelector((store) => store.UserFolder?.userFolders);

  const isFollowing = useSelector((store) => store.UserFolder?.userFolders)?.some((t) =>
    t.creatorIds?.includes(creator?.id)
  );

  return (
    <Box
      sx={{ cursor: "pointer" }}
      className="relative w-full creator-card"
    >
      <Box sx={{ position: "absolute", top: -6, right: -6, display: { xs: "none", sm: "block" } }}>
        <IconButton
          // sx={{ padding: 0 }}
          onClick={() => {
            onClose();
          }}
          sx={{ padding: 0, margin: 0, color: "#232323" }}
        >
          <IconCancel />
        </IconButton>
      </Box>
      <Box sx={{ width: "7rem", height: "7rem", borderRadius: 1, mr: 1.5 }}
        onClick={() => {
          if (!location.pathname?.includes("/creator/play")) {
            navigate(`/creator/play/${creator?.id}`);
          }
        }}>
        {/* {getUserThumbnailPath(creator?.avatar) && ( */}
        <Avatar
          sx={{ width: "7rem", height: "7rem" }}
          src={getUserThumbnailPath(creator?.avatar)}
          variant="rounded"
        ></Avatar>
        {/* )} */}
      </Box>
      <Box flexGrow={1} display="flex" flexDirection="column">
        <Box sx={{ color: "#232323", flex: 1 }}>
          <Typography variant="h6" fontWeight={500}>
            {creator?.username}
          </Typography>
          <Typography variant="body1" fontWeight={500}>
            {creator?.desc?.title}
          </Typography>
          <Typography variant="body2">{creator?.desc?.short}</Typography>
        </Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <FollowButton
            followed={userFolders?.some((t) => t?.creatorIds?.includes(creator?.id))}
            onClick={async (e) => {
              e.stopPropagation();
              if (isFollowing) {
                await userFolders?.map(async (userFolder) => {
                  if (userFolder?.creatorIds?.includes(creator?.id)) {
                    apiUpdateUserFolder({
                      id: userFolder?.id,
                      creatorIds: userFolder?.creatorIds?.filter((t) => t !== creator?.id),
                    }).then((res) => {
                      dispatch(actionUpdateUserFolder(res));
                    });
                  }
                });
              } else {
                if (userFolders?.length > 0) {
                  const folder = userFolders[0];
                  apiUpdateUserFolder({
                    id: folder?.id,
                    creatorIds: [...(folder.creatorIds || []), creator?.id],
                  }).then((res) => {
                    dispatch(actionUpdateUserFolder(res));
                  });
                }
              }
            }}
          ></FollowButton>
        </Box>
      </Box>
    </Box>
  );
}

import client from "../index.js";
import { GET_USER_GROUP, GET_USER_GROUPS } from "./queries.js";
import { CREATE_USER_GROUP, UPDATE_USER_GROUP, DELETE_USER_GROUP } from "./mutations";

export const apiGetUserGroup = (id) => {
  return client
    .query({
      query: GET_USER_GROUP,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.userGroup;
    });
};

export const apiGetAllUserGroups = (filter) => {
  return client
    .query({
      query: GET_USER_GROUPS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.userGroups;
    });
};

export const apiCreateUserGroup = (data) => {
  return client
    .mutate({
      mutation: CREATE_USER_GROUP,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.createUserGroup?.userGroup;
    });
};

export const apiUpdateUserGroup = (data) => {
  return client
    .mutate({
      mutation: UPDATE_USER_GROUP,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.updateUserGroup?.userGroup;
    });
};

export const apiDeleteUserGroup = (id) => {
  return client
    .mutate({
      mutation: DELETE_USER_GROUP,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.deleteUserGroup?.id;
    });
};

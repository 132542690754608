import React, { useEffect, useRef, useState } from "react";
// import Appbar from "./Appbar/Appbar";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import SubscriptionWrapper from "../wrapper/SubscriptionWrapper";
import TopAppBar from "./TopAppBar/TopAppBar";

import { getAllUsers } from "../store/User.slice";
import { getAllUserFolders } from "../store/UserFolder.slice";
import { getAllAssetFolders } from "../store/AssetFolder.slice";
import MainPageWrapper from "./wrappers/MainPageWrapper";
import BottomNavigationBar from "./components/BottomNavigationBar";
import { useAppContext } from "../contexts/AppContext";
import { useLayoutContext } from "../contexts/LayoutContext";
import { getAllStockAudios } from "../store/StockAudio.slice";

function MainLayout() {
  const myRef = useRef(null);

  const dispatch = useDispatch();
  const [{ domain }] = useAppContext();
  const [
    { isMobile, showTopAppBar, showBottomNavigationBar, mouseOnTopAppBar },
    { setMouseOnTopAppBar, setShowTopAppBar, setShowBottomNavigationBar },
  ] = useLayoutContext();

  const authenticated = useSelector((store) => store.auth.authenticated);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const [timer, setTimer] = useState();

  //Move to Full Screen Mode
  useEffect(() => {
    // function goToFullScreen() {
    //   if (document.fullscreenEnabled) {
    //     if (myRef.current) {
    //       myRef.current
    //         .requestFullscreen()
    //         .then(() => {
    //           console.log("Entered full screen mode");
    //         })
    //         .catch((err) => {
    //           console.error("Error entering full screen mode:", err);
    //         });
    //     }
    //   }
    // }
    // const timer = setTimeout(() => {
    //   goToFullScreen();
    // }, 2000);

    // return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (authenticated && domain) {
      dispatch(getAllAssetFolders({ domain }));
      dispatch(getAllUsers({ domain }));
      dispatch(
        getAllUserFolders({
          owner: PrivateInfo?.id,
        })
      );
      dispatch(getAllStockAudios({ domain }));
    }
  }, [domain, dispatch, authenticated, PrivateInfo?.id]);

  useEffect(() => {
    dismissBars();

    return () => clearTimeout(timer);
  }, [showTopAppBar]); //eslint-disable-line

  // useEffect(() => {
  //   if (isMobile) {
  //     const timer = setTimeout(() => {
  //       setMouseOnTopAppBar(false);
  //       setShowTopAppBar(false);
  //     }, 10000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [showTopAppBar, isMobile]); //eslint-disable-line

  const dismissBars = () => {
    if (timer) clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setShowTopAppBar(false);
      setShowBottomNavigationBar(false);
    }, 10000);

    setTimer(newTimer);
  }

  return (
    <Box
      className="app-wrapper no-select"
      onClick={() => {
        setShowTopAppBar(true);
        setShowBottomNavigationBar(true);
        dismissBars();
      }}
      onTouchEnd={() => {
        dismissBars();
      }}
      ref={myRef}
    >
      <Box
        className="flex items-center justify-content-between"
        sx={{
          position: "absolute",
          zIndex: 999,
          top: 0,
          left: 0,
          width: "100%",
          transition: "all 0.5s",
          height: 50,
        }}
        onMouseEnter={(e) => {
          e.stopPropagation();
          setMouseOnTopAppBar(true);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          setMouseOnTopAppBar(false);
        }}
        onMouseOver={(e) => {
          e.stopPropagation();
          setMouseOnTopAppBar(true);
        }}
        onClick={(e) => {
          if (!showTopAppBar) {
            e.stopPropagation();
            setMouseOnTopAppBar(true);
          }
        }}
        onTouchStart={(e) => {
          if (!showTopAppBar) {
            e.stopPropagation();
            setMouseOnTopAppBar(true);
          }
        }}
      >
        <TopAppBar></TopAppBar>
      </Box>
      <MainPageWrapper>
        <Box sx={{ height: showBottomNavigationBar ? "calc(100% - 66px)" : "100%", transition: "all 0.5s" }}>
          <Outlet></Outlet>
        </Box>
        <BottomNavigationBar></BottomNavigationBar>
      </MainPageWrapper>
      <SubscriptionWrapper></SubscriptionWrapper>
    </Box>
  );
}

export default MainLayout;

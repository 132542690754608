import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_USER_FOLDER = gql`
  subscription onCreateUserFolder {
    onCreateUserFolder {
      userFolder {
        id
        name
        owner
        avatar {
          type
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          caption
        }
        assetIds {
          id
          note
        }
        widgetIds {
          id
          note
        }
        clipIds {
          id
          note
        }
        creatorIds
        noteIds
        channelIds
        style
        rank
        status
        timestamp
      }
    }
  }
`;

export const ON_UPDATE_USER_FOLDER = gql`
  subscription onUpdateUserFolder {
    onUpdateUserFolder {
      userFolder {
        id
        name
        owner
        avatar {
          type
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          caption
        }
        assetIds {
          id
          note
        }
        widgetIds {
          id
          note
        }
        clipIds {
          id
          note
        }
        creatorIds
        noteIds
        channelIds
        style
        rank
        status
        timestamp
      }
    }
  }
`;

export const ON_DELETE_USER_FOLDER = gql`
  subscription onDeleteUserFolder {
    onDeleteUserFolder {
      id
    }
  }
`;

const useOnCreateUserFolder = () => useSubscription(ON_CREATE_USER_FOLDER);
const useOnUpdateUserFolder = () => useSubscription(ON_UPDATE_USER_FOLDER);
const useOnDeleteUserFolder = () => useSubscription(ON_DELETE_USER_FOLDER);

export { useOnCreateUserFolder, useOnUpdateUserFolder, useOnDeleteUserFolder };

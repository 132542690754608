import { gql } from "@apollo/client";

const GET_DOMAIN = gql`
  query domain($id: ID!) {
    domain(id: $id) {
      id
      name
      avatar {
        name
        dir
        base
        mimetype
      }
      desc {
        title
        short
        body
        website
        style
        rows
      }
      data
      groupNum
      authCode
      userRole
      accessList
      state
      componentList
      company {
        name
        url
        phone
        address
        imageURL
      }
      SE_conversionTypes
      isTranscribeEnabled
      state
      type
      domainLinkIds
      fetchCount
      bannerList {
        title
        short
        imageUrl
      }
      imageList
      timestamp
      updatedAt
    }
  }
`;

const GET_DOMAINS = gql`
  query domains($input: DomainsInput!) {
    domains(input: $input) {
      total
      domains {
        id
        name
        avatar {
          name
          dir
          base
          mimetype
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        data
        groupNum
        authCode
        userRole
        company {
          name
          url
          phone
          address
          imageURL
        }
        state
        accessList
        componentList
        SE_conversionTypes
        isTranscribeEnabled
        state
        type
        domainLinkIds
        fetchCount
        bannerList {
          title
          short
          imageUrl
        }
        imageList
        timestamp
        updatedAt
      }
    }
  }
`;

export { GET_DOMAIN, GET_DOMAINS };

import React from "react";
import { Box, Dialog, Slide, DialogContent } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MenuButton = ({ children, ...props }) => {
  return (
    <Box
      sx={{ background: "#D9D9D9", borderRadius: 8, py: 0.5, cursor: "pointer", width: 100 }}
      textAlign="center"
      {...props}
    >
      {children}
    </Box>
  );
};

function DialogTypeSelector({ open, onClose, onSelectType }) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          backgroundImage: "none",
          position: "fixed",
          bottom: "10px",
        },
      }}
    >
      <DialogContent sx={{ background: "transparent" }}>
        <Box display="flex" alignItems="center" sx={{ color: "#232323", gap: 1 }}>
          <MenuButton onClick={() => {}}>Collection</MenuButton>
          <MenuButton>Media</MenuButton>
          <MenuButton
            onClick={() => {
              onSelectType("group");
              onClose();
            }}
          >
            Group
          </MenuButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DialogTypeSelector;

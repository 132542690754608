import React, { } from "react";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { useLayoutContext } from "../../contexts/LayoutContext";

function ResponsiveTable({
  columns = [],
  rows = [],
  isHeader = false,
  visibleIndex = true,
}) {

  const [, { isColumnVisible }] = useLayoutContext();

  return (
    <Box pt={1} pr={2} pl={2} pb={1}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 30 }} size="small" aria-label="a dense table">
          {isHeader && <TableHead>
            <TableRow>
              {columns?.length > 0 && columns?.filter((column) => isColumnVisible(column)).map((column, index) => (
                 <TableCell style={column.style} key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>}
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {visibleIndex && <TableCell 
                  sx={{
                    paddingRight: '5px',
                    textAlign: "center"
                  }} width={30}
                >
                  {index + 1}
                </TableCell>}
                {columns?.filter((column) => isColumnVisible(column)).map((column, index) => (
                  <TableCell key={index} style={column.style} width={column.width} align={column.align}>
                    {column.render ? column.render(row) : row[column["id"]]}
                  </TableCell>  
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ResponsiveTable;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import {
  Download as IconDownload,
  MoreHoriz as IconMoreHoriz,
  Delete as IconDelete,
  Comment as IconComment,
} from "@mui/icons-material";
import moment from "moment";
import { useConfirm } from "material-ui-confirm";
import FacebookCircularProgress from "../../common/FacebookCircularProgress";
import { MoreOptionMenu } from "../../menus";
import {
  checkImageExist,
  downloadUsingFetch,
  getAssetFilePath,
  getAvatarPath,
  getHLSUrl,
  isImageAsset,
} from "../../../utils/index";
import { apiDeleteAsset } from "../../../graphQL/asset";
import { actionDeleteMyAsset } from "../../../store/Asset.slice";
import DialogAssetPreview from "../../dialogs/DialogAssetPreview";

function GroupAssetCard({ asset, onEdit, onAnalytics }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [thumbnailPath, setThumbnailPath] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!asset?.file?.name) return setThumbnailPath(null);

    if (isImageAsset(asset)) {
      return setThumbnailPath(getAssetFilePath(asset));
    }
    if (asset?.avatar) {
      if (asset?.avatar?.name) {
        return setThumbnailPath(getAvatarPath(asset?.avatar));
      }
      const imgUrl = getHLSUrl(`/${asset?.file?.directory}/0001.png`);
      checkImageExist(imgUrl)
        .then(() => {
          setThumbnailPath(imgUrl);
        })
        .catch(setThumbnailPath(null));
    }
    console.log(asset);
  }, [asset]);

  const handleCardClick = (event) => {
    console.log(event);
    setPreviewOpen(true);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      {asset.status === "Converting" && (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            borderRadius: 2,
            background: "#24262B",
          }}
        >
          <Box
            sx={{
              borderRadius: 2,
              height: 220,
              maxHeight: 220,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <FacebookCircularProgress></FacebookCircularProgress>
            <Typography variant="h6" textAlign="center" mt={2}>
              Converting ...
            </Typography>
          </Box>
        </Box>
      )}
      {asset.status === "Error" && (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            borderRadius: 2,
            background: "#24262B",
          }}
        >
          <Box
            sx={{
              borderRadius: 2,
              height: 220,
              maxHeight: 220,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="h6" textAlign="center" mt={2}>
              Error ...
            </Typography>
          </Box>
        </Box>
      )}
      {asset.status !== "Converting" && asset.status !== "Error" && (
        <Box
          sx={{
            height: 220,
            maxHeight: 220,
            position: "relative",
          }}
        >
          <Box sx={{ width: "100%", height: "100%" }} onClick={handleCardClick}>
            {thumbnailPath && (
              <img
                src={thumbnailPath}
                alt="Preview"
                loading="lazy"
                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "8px" }}
              ></img>
            )}
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 0.5, pl: 0.5 }}>
        <Box>
          <Typography variant="body2">{asset?.file?.displayName}</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
            {moment(parseInt(asset?.timestamp)).fromNow()}
          </Typography>
          <IconButton
            size="small"
            sx={{ p: 0, position: "relative", left: "0.125rem" }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <IconMoreHoriz></IconMoreHoriz>
          </IconButton>
        </Box>
      </Box>
      <MoreOptionMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        menus={[
          {
            icon: <IconDownload></IconDownload>,
            title: "Download",
            onClick: () => {
              downloadUsingFetch(asset?.file);
              handleMenuClose();
            },
          },
          {
            icon: <IconComment></IconComment>,
            title: "Comments",
            onClick: () => {
              handleMenuClose();
            },
          },
          {
            icon: <IconDelete></IconDelete>,
            title: "Delete",
            onClick: () => {
              handleMenuClose();
              confirm({ title: "Delete an asset?", description: "Are you sure to remove this asset?" })
                .then(() => {
                  apiDeleteAsset(asset?.id).then((id) => {
                    dispatch(actionDeleteMyAsset(asset?.id));
                  });
                })
                .catch(() => {});
            },
          },
        ]}
      ></MoreOptionMenu>
      <DialogAssetPreview
        asset={asset}
        onClose={() => {
          setPreviewOpen(false);
        }}
        open={isPreviewOpen}
      />
    </Box>
  );
}

GroupAssetCard.defaultProps = {
  onAnalytics: () => {},
};

export default GroupAssetCard;

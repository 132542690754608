import React from "react";
import { Box } from "@mui/material";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";

export const Editor = ({ state, setState, placeholder, readOnly }) => {
  const handleChange = (value) => {
    setState({ value: value });
  };
  return (
    <Box>
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={state.value}
        readOnly={readOnly}
        onChange={handleChange}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
        style={{ minHeight: "200px", maxHeight: "300px", height: "250px" }}
      />
    </Box>
  );
};

Editor.defaultProps = {
  placeholder: "Write about description...",
  readOnly: false,
};

export default Editor;

import React from "react";
import { Drawer, Popover } from "@mui/material";
import AssetCard from "../../CustomCards/AssetCard/AssetCard";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import { assetCardPaperPropsLeft } from "../../../utils/styles";

function AssetDrawerWrapper({ open, onClose, anchorEl, children, ...props }) {
  const [{ isSmScreen }] = useLayoutContext();
  const paddingWidth = window.innerWidth > 1200 ? (window.innerWidth - 1200) / 2 : 0;
  if (!isSmScreen) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          sx: {
            bgcolor: "#000",
          },
        }}
      >
        {children}
      </Drawer>
    );
  }

  return (
    <Popover
      PaperProps={assetCardPaperPropsLeft}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      onClose={onClose}
      style={{ marginLeft: paddingWidth }}
    >
      {children}
    </Popover>
  );
}

function AssetDrawer({ open, onClose, asset, playerStatus, anchorEl }) {
  return (
    <AssetDrawerWrapper open={open} onClose={onClose} anchorEl={anchorEl}>
      <AssetCard
        asset={asset}
        onClose={() => {
          onClose();
        }}
        playerStatus={playerStatus}
      ></AssetCard>
    </AssetDrawerWrapper>
  );
}

export default AssetDrawer;

import React, { useState, useReducer, useContext, useMemo, createContext, useEffect } from "react";
import { Storage } from "aws-amplify";
import { AWS } from "../config";
import { upsertObjectToArray } from "../utils/helpers";
import { getFileExt } from "../utils/helpers";
import { v4 as uuid_v4 } from "uuid";

Storage.configure({
  bucket: AWS.Storage.bucket,
  region: AWS.Storage.region,
  identityPoolId: AWS.aws_cognito_identity_pool_id,
  customPrefix: {
    public: "",
    protected: "",
    private: "",
  },
});

const UploadContext = createContext(null);
UploadContext.displayName = "UploadContext";

export function useUploadContext() {
  return useContext(UploadContext);
}

function reducer(state, { type, payload }) {
  return {
    ...state,
    [type]: payload,
  };
}

const INIT_STATE = {
  queue: [],
  updated: null,
};

export function UploadProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const { queue, updated } = state;

  const [endedQueueId, setEndedQueueId] = useState(null);

  const setQueue = (value) => {
    dispatch({ type: "queue", payload: value });
  };

  const setUpdated = (value) => {
    dispatch({ type: "updated", payload: value });
  };

  const upload = (file, category, id, key, callback, onProgress = () => {}) => {
    setQueue([
      {
        id,
        category,
        progress: 0,
      },
      ...queue,
    ]);
    return Storage.put(key, file, {
      contentType: file.type,
      progressCallback: (progress) => {
        const pro = Math.round((progress.loaded * 100) / progress.total);
        setUpdated({ id, category, progress: pro });
        onProgress(pro);
      },
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    })
      .then((result) => {
        console.log("File was uploaded successfully:", result);
        callback(null, result, () => {
          setEndedQueueId(id);
        });
      })
      .catch((err) => {
        console.log("File upload was failed", err);
        callback(err, null, () => {
          setEndedQueueId(id);
        });
      });
  };

  const uploadAvatar = (id, file, callback) => {
    const uploadFileName = `${uuid_v4()}.${getFileExt(file.name)}`;
    const key = `${id}/${uploadFileName}`;
    return Storage.put(key, file, {
      contentType: file.type,
      progressCallback: (progress) => {},
    })
      .then((result) => {
        callback(null, result, uploadFileName);
        return uploadFileName;
      })
      .catch((err) => {
        callback(err, null, null);
        return null;
      });
  };

  useEffect(() => {
    if (updated) {
      const t = { ...updated };
      setUpdated(null);
      setQueue(upsertObjectToArray(queue, t));
    }
  }, [updated]); //eslint-disable-line

  useEffect(() => {
    if (endedQueueId) {
      setQueue(queue?.filter((item) => item.id !== endedQueueId));
      setEndedQueueId(null);
    }
  }, [endedQueueId]); //eslint-disable-line

  useEffect(() => {
    // console.log(queue);
  }, [queue]);

  const value = useMemo(() => [state, { upload, uploadAvatar }], [state]); //eslint-disable-line

  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
}

export default UploadContext;

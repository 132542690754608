import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

function SecondaryPageLayout() {
  return (
    <Box className="app-wrapper no-select">
      <Outlet></Outlet>
    </Box>
  );
}

export default SecondaryPageLayout;

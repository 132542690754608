import { gql } from "@apollo/client";

const GET_USER_GROUP = gql`
  query userGroup($id: ID!) {
    userGroups(id: $id) {
      id
      name
      type
      domain
      locationId
      parentNodeId
      avatar {
        name
        dir
        base
        status
      }
      desc {
        title
        short
        body
        caption
      }
      keywords
      categories
      ownerId
      authors
      accessPermission
      data
      timestamp
      updatedAt
    }
  }
`;

const GET_USER_GROUPS = gql`
  query userGroups($input: UserGroupsInput!) {
    userGroups(input: $input) {
      total
      userGroups {
        id
        name
        type
        domain
        locationId
        parentNodeId
        avatar {
          name
          dir
          base
          status
        }
        desc {
          title
          short
          body
          caption
        }
        keywords
        categories
        ownerId
        authors
        accessPermission
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export { GET_USER_GROUP, GET_USER_GROUPS };

import React from "react";
import { Box, Drawer, ThemeProvider, IconButton } from "@mui/material";
import { lightTheme } from "../../../libs/theme";
import { CancelRounded as IconCancel } from "@mui/icons-material";
function DescriptionDrawer({ open = false, body = {}, onClose = () => {} }) {
  const paddingWidth = window.innerWidth > 1200 ? (window.innerWidth - 1200) / 2 : 0;

  const handleClose = () => {
    onClose();
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="left"
      PaperProps={{
        sx: {
          background: "#ffffff80",
          pt: 1,
          height: "100%",
          width: "50%",
          left: paddingWidth,
        },
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <Box>
          <Box sx={{ position: "absolute", top: paddingWidth > 0 ? 5 : 2, right: paddingWidth > 0 ? 5 : 2 }}>
            <IconButton
              // sx={{ padding: 0 }}
              onClick={() => {
                onClose();
              }}
              sx={{ padding: 0, margin: 0, color: "#232323" }}
            >
              <IconCancel />
            </IconButton>
          </Box>
          <div dangerouslySetInnerHTML={{ __html: body }} style={{ color: "black", padding: "10px" }} />
        </Box>
      </ThemeProvider>
    </Drawer>
  );
}

export default DescriptionDrawer;

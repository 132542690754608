import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import {
    ArrowDropDown,
  ArrowDropUp,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';

function CategoryBar({ 
  onPrev = () => {},
  onNext = () => {},
  filter = {},
  isImage = false,
  asset = {},
}) {

  const categories = useSelector((store) => store.Category.categories);
  const [category, setCategory] = useState();
  
  useEffect(() => {
    if (filter?.categories?.length && categories) {
      setCategory(categories?.find((cat) => cat.id === filter.categories[0]));
    }
  }, [categories, filter]);

  const handleNext = () => {
    if (category) {
      const index = categories?.findIndex((cat) => cat.id === category.id);
      if (index < categories.length - 1) {
        onNext(categories[index + 1]);
      }
    } else {
      if (categories?.length) {
        onNext(categories[0])
      }
    }
  }
  
  const handlePrev = () => {
    if (category) {
      const index = categories?.findIndex((cat) => cat.id === category.id);
      if(index !== 0) {
        onPrev(categories[index - 1]);
      }
    } else {
      if (categories?.length) {
        onPrev(categories[0])
      }
    }
  }

  return (
    <Box
      className="asset-player-footer"
      sx={{
        position: 'absolute',
        bottom: {
          xs: asset?.desc?.title ? 50 : 24,
          sm: asset?.desc?.title ? 70 : 32,
          md: !isImage || asset?.desc?.title ? 70 : 32,
        },
        zIndex: 29,
        width: '100%',
        transition: 'bottom 0.5s',
      }}
    >
      <Box
        sx={{
          background: category?.style?.bg || '#000000',
          height: '25px',
          px: {
            xs: 1,
            md: 2,
          },
        }}
      > 
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            height: '100%',
            position: 'relative',
            zIndex: 50,
          }}
        >
            <IconButton
              size="small"
              onClick={handlePrev}
              sx={{ display: { width: '20px', height: '20px', xs: 'none', sm: 'flex', background: 'black' } }}
              disabled={categories?.findIndex((cat) => cat.id === category?.id) === 0}
            >
              <ArrowDropUp></ArrowDropUp>
            </IconButton>
            <Typography color={category?.style?.fg || 'white'}>{category?.name}</Typography>
            <IconButton
              size="small"
              onClick={handleNext}
              sx={{ display: { width: '20px', height: '20px', xs: 'none', sm: 'flex', background: 'black' } }}
              disabled={categories?.findIndex((cat) => cat.id === category?.id) === categories?.length - 1}
              >
              <ArrowDropDown></ArrowDropDown>
            </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default CategoryBar;

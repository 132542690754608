import client from "../index.js";
import { GET_ASSET_FOLDERS } from "./query.js";
import { CREATE_ASSET_FOLDER, UPDATE_ASSET_FOLDER, DELETE_ASSET_FOLDER, DELETE_ALL_ASSET_FOLDERS } from "./mutation";

export const apiGetAllAssetFolders = (filter) => {
  return client
    .query({
      query: GET_ASSET_FOLDERS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.mediaFolders;
    });
};

export const apiCreateAssetFolder = (data) => {
  return client
    .mutate({
      mutation: CREATE_ASSET_FOLDER,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createMediaFolder.mediaFolder;
    });
};

export const apiUpdateAssetFolder = (data) => {
  return client
    .mutate({
      mutation: UPDATE_ASSET_FOLDER,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateMediaFolder.mediaFolder;
    });
};

export const apiDeleteAssetFolder = (id) => {
  return client
    .mutate({
      mutation: DELETE_ASSET_FOLDER,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteMediaFolder.id;
    });
};

export const apiDeleteAllAssetFolders = (filter) => {
  return client
    .mutate({
      mutation: DELETE_ALL_ASSET_FOLDERS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAllMediaFolders?.total;
    });
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { MoreHoriz as IconMoreHoriz, Edit as IconEdit, Delete as IconDelete } from "@mui/icons-material";
import { getAvatarPath } from "../../../utils";
import moment from "moment";
import { MoreOptionMenu } from "../../menus";
import { apiDeleteUserGroup, apiUpdateUserGroup } from "../../../graphQL/userGroup";
import { actionDeleteUserGroup, actionUpdateUserGroup } from "../../../store/UserGroup.slice";
import { useConfirm } from "material-ui-confirm";
import CreatorThumbnail from "../../thumbnails/CreatorThumbnails";

const JoinButton = ({ joined, onClick }) => {
  return (
    <Box
      sx={{ borderRadius: 4, background: "#540195", px: 3, py: 0.125 }}
      onClick={() => {
        onClick();
      }}
    >
      <Typography variant="body2">{joined ? "Joined" : "Join"}</Typography>
    </Box>
  );
};

function UserGroupCard({ userGroup, onEdit, onClick }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const users = useSelector((store) => store.User.users);

  const Joined = userGroup?.authors?.includes(PrivateInfo?.id);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ background: "#D9D9D9", borderRadius: 1, width: "100%", maxWidth: "420px" }}>
      <Box display="flex" alignItems="center" sx={{ p: 0.75 }}>
        {userGroup?.authors
          ?.map((t) => users.find((user) => user.id === t))
          ?.filter((t) => Boolean(t))
          ?.map((user) => (
            <Box sx={{ mr: 1, color: "#232323" }}>
              <CreatorThumbnail creator={user}></CreatorThumbnail>
            </Box>
          ))}
      </Box>
      <Box
        display="flex"
        alignItems="stretch"
        sx={{ p: 1, background: "#575757" }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {getAvatarPath(userGroup?.avatar) && (
          <Box sx={{ width: "6rem", height: "6rem", background: "#D9D9D9", borderRadius: 1, mr: 1 }}>
            <Avatar
              sx={{ width: "6rem", height: "6rem" }}
              src={getAvatarPath(userGroup?.avatar)}
              variant="rounded"
            ></Avatar>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
          flexGrow={1}
          sx={{ minHeight: "6rem" }}
        >
          <Typography variant="h6">{userGroup?.desc?.title}</Typography>
          <Box flexGrow="1">
            <Typography variant="body1">{userGroup?.desc?.short}</Typography>
          </Box>
          <Box className="flex items-center justify-content-end w-full">
            {PrivateInfo?.id !== userGroup?.ownerId && (
              <JoinButton
                joined={userGroup?.authors?.includes(PrivateInfo?.id) || PrivateInfo?.id === userGroup?.ownerId}
                onClick={() => {
                  apiUpdateUserGroup({
                    id: userGroup?.id,
                    authors: Joined
                      ? userGroup?.authors?.filter((t) => t !== PrivateInfo?.id)
                      : [...(userGroup?.authors || []), PrivateInfo?.id],
                  }).then((res) => {
                    dispatch(actionUpdateUserGroup(res));
                  });
                }}
              >
                {userGroup?.authors?.includes(PrivateInfo?.id)}
              </JoinButton>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 1, color: "#232323", height: "1.5rem" }}
      >
        <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
          {moment(parseInt(userGroup?.updatedAt)).fromNow()}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
            Members:{" "}
            {userGroup?.authors?.map((t) => users.find((user) => user.id === t))?.filter((t) => Boolean(t))?.length}
          </Typography>
          {userGroup?.ownerId === PrivateInfo?.id && (
            <IconButton
              size="small"
              sx={{ p: 0, position: "relative", left: "0.125rem", color: "#232323", ml: 1 }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <IconMoreHoriz></IconMoreHoriz>
            </IconButton>
          )}
        </Box>
      </Box>
      <MoreOptionMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        menus={[
          {
            icon: <IconEdit></IconEdit>,
            title: "Edit",
            onClick: () => {
              handleMenuClose();
              onEdit(userGroup);
            },
          },
          {
            icon: <IconDelete></IconDelete>,
            title: "Delete",
            onClick: () => {
              handleMenuClose();
              confirm({ title: "Delete user group?", description: "Are you sure to remove this user group?" })
                .then(() => {
                  apiDeleteUserGroup(userGroup?.id).then((id) => {
                    dispatch(actionDeleteUserGroup(userGroup?.id));
                  });
                })
                .catch(() => {});
            },
          },
        ]}
      ></MoreOptionMenu>
    </Box>
  );
}

UserGroupCard.defaultProps = {
  onClick: null,
};

export default UserGroupCard;

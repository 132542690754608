import React, { useEffect, useState } from "react";
import { getTime } from "../../../utils";
import { Box, Slider, SliderThumb, Typography, styled } from "@mui/material";
import { PauseOutlined, PlayArrowOutlined } from "@mui/icons-material";

const RangeSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '10px 0',
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 4px rgba(58, 133, 137, 0.16)',
    },
    '& .bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

const SingleSlider = styled(Slider)(({ theme }) => (
  {
    color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
    height: 4,
    '& .MuiSlider-thumb': {
      width: 8,
      height: 8,
      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
      '&:before': {
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
      },
      '&:hover, &.Mui-focusVisible': {
        boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark'
            ? 'rgb(255 255 255 / 16%)'
            : 'rgb(0 0 0 / 16%)'
          }`,
      },
      '&.Mui-active': {
        width: 20,
        height: 20,
      },
    },
    '& .MuiSlider-rail': {
      opacity: 0.28,
    },
  }
))

function RangeThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </SliderThumb>
  );
}

function VideoEditorControls({
  editingWidget = null,
  duration = 100,
  playing = false,
  progress = 0,
  onPlay = () => { },
  onPause = () => { },
  onSeek = () => { },
  onChange = () => { },
  visibleTimer = true,
}) {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [current, setCurrent] = useState(progress / 1000);

  function handleRangeChange(min, max, index) {
    setMinValue(min);
    setMaxValue(max);
    if (index === 0) {
      onSeek(min);
    } else {
      onSeek(max)
    }
    onChange(min, max);
  }

  function handleChange(value) {
    onSeek(value)
  }

  function handlePlay() {
    onPlay();
  }

  function handlePause() {
    onPause();
  }

  useEffect(() => {
    setCurrent(progress / 1000);
  }, [progress]);

  useEffect(() => {
    if (editingWidget) {
      setMinValue(editingWidget.videoPos?.startAt);
      const max = editingWidget.videoPos?.startAt + editingWidget.videoPos?.duration;
      setMaxValue(max);
    }
  }, [editingWidget]);

  return (
    <Box sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0
    }}>
      <Box
        display='flex'
        alignItems='center'
        sx={{
          marginBottom: '35px',
          marginLeft: '10px'
        }}
      >
        {playing ? (
          <PauseOutlined onClick={handlePause} />
        ) : (
          <PlayArrowOutlined onClick={handlePlay} />
        )}
        {visibleTimer ? (
          <Typography bottom={20}>{getTime(current) + " / " + getTime(duration)}</Typography>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={{
        position: 'absolute',
        right: 0,
        left: 0,
        bottom: '0px',
        paddingRight: '30px',
        paddingLeft: '20px'
      }}>
        { editingWidget != null ? <RangeSlider
          slots={{ thumb: RangeThumbComponent }}
          valueLabelDisplay="auto"
          defaultValue={[minValue, maxValue]}
          value={[minValue, maxValue]}
          max={duration}
          step={0.1}
          onChange={(e, value, thumbIndex) => {
            handleRangeChange(value[0], value[1], thumbIndex)
          }}
        /> :
        <SingleSlider
          max={duration}
          step={0.1}
          value={current}
          valueLabelDisplay='auto'
          onChange={(e, value) => {
            console.log(value)
            handleChange(value)
          }}
        />}
      </Box>
    </Box>
  );
}

export default VideoEditorControls;

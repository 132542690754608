import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllCategories } from "../graphQL/category";

export const getAllCategories = createAsyncThunk("category/read_categories", async (filter) => {
  return await apiGetAllCategories(filter);
});

const categorySlice = createSlice({
  name: "Category",
  initialState: {
    total: 0,
    categories: null,
  },
  reducers: {
    actionUpdateCategory: (state, action) => {
      if (state.categories) {
        if (state.categories.find((item) => item.id === action.payload.id)) {
          state.categories = state.categories.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.categories = [action.payload, ...state.categories];
          state.total = state.categories.length;
        }
      } else {
        state.categories = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteCategory: (state, action) => {
      state.categories = state.categories?.filter((item) => item?.id !== action.payload);
      state.total = state.categories.length;
    },
    actionDeleteAllCategories: (state, action) => {
      state.categories = state.categories?.filter((t) => t.domain !== action.payload.domain);
      state.total = state.categories.length;
    },
  },
  extraReducers: {
    [getAllCategories.fulfilled]: (state, action) => {
      state.total = action.payload.total;
      state.categories = action.payload.categories;
    },
    [getAllCategories.rejected]: (state, action) => {
      state.total = 0;
      state.categories = null;
    },
  },
});

export const { actionUpdateCategory, actionDeleteCategory, actionDeleteAllCategories } = categorySlice.actions;
export default categorySlice.reducer;

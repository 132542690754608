import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { ButtonSave } from "../../components/buttons";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { apiUpdateUser } from "../../graphQL/user";
import { updateCurrentUser } from "../../store/auth.slice";
import { actionUpdateUser } from "../../store/User.slice";
import MultiSelectPopup from "../../components/common/MultiSelectPopup";
import { setOtherProfile } from "../../store/OtherProfile";

function DialogLocations({ open, handleClose, userInfo }) {
  const locations = useSelector((store) => store.Location.locations);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const OtherProfile = useSelector((store) => store.OtherProfile.Profile);

  useEffect(() => {
    setSelected(userInfo?.locationIdList || []);
  }, [open, userInfo]);

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogContent>
      <MultiSelectPopup
        title="Location List"
        options={locations?.filter(item => item.domain === userInfo?.domain).map((location) => {
            return {
            label: location.name,
            value: location.id,
            };
        })}
        selected={selected}
        handleSelect={(selectedItems) => {
            setSelected(selectedItems);
        }}
        sx={{ mb: 1.5 }}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <ButtonSave
          loading={isLoading}
          onClick={() => {
            setLoading(true);
            return apiUpdateUser({
              id: userInfo.id,
              locationIdList: selected,
            })
              .then((res) => {
                setLoading(false);
                if (userInfo?.id === PrivateInfo?.id) {
                  dispatch(updateCurrentUser(res));
                } else if (userInfo?.id === OtherProfile?.id) {
                  dispatch(setOtherProfile(res));
                }
                dispatch(actionUpdateUser(res));
              })
              .catch((err) => console.log(err))
              .then(() => {
                handleClose();
              });
          }}
        ></ButtonSave>
      </DialogActions>
    </Dialog>
  );
}

export default DialogLocations;

import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  IconButton,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid_v4 } from "uuid";
import { ButtonCreate } from "../../components/buttons";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Close, Delete, Edit } from "@mui/icons-material";
import { apiCreateLocation, apiDeleteLocation } from "../../graphQL/locations";
import { actionDeleteLocation, actionUpdateLocation } from "../../store/Location.slice";
import ResponsiveTable from "./ResponsiveTable";
import { useConfirm } from "material-ui-confirm";

function DialogManageCampaigns({
  open,
  profile,
  onClose = () => { },
  onEdit = () => {},
}) {

  const dispatch = useDispatch();
  const confirm = useConfirm();
  const locations = useSelector((store) => store.Location.locations);

  const handleClose = () => {
    onClose();
  }

  const handleDelete = (row) => {
    confirm({
      title: "All data and users associated with this campaign will be lost. Are you sure you want to delete this campaign?",
      confirmationText: "YES",
      cancellationText: "NO",
      titleProps: {
        textAlign: "center",
      },
      cancellationButtonProps: {
        style: {
          background: "white",
          color: "black",
        },
      },
      confirmationButtonProps: {
        style: {
          background: "white",
          color: "black",
        },
      },
      dialogActionsProps: {
        style: {
          justifyContent: "space-between",
        },
      },
    }).then(() => {
      apiDeleteLocation(row.id).then(() => {
        dispatch(actionDeleteLocation(row.id));
      });
    }).catch(() => {});
  }

  const headers = [
    {
      id: "name",
      align: "left",
      label: "",
      visible: "xs",
      width: 60,
    },
    {
      id: "edit",
      align: "right",
      label: "",
      visible: "xs",
      width: 50,
      style: { position: "sticky", right: 0, backgroundColor: "#1E1E1E" },
      render: (row) => {
        return (
          <Box sx={{
            display: "flex",
            justifyContent: "end"
          }}>
            <IconButton
              onClick={() => {
                onEdit(row);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                handleDelete(row);
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        )
      }
    }
  ]

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogTitle alignItems="center" textAlign="center">
        Campaigns
        <IconButton
          sx={{
            position: 'absolute',
            top: 3,
            right: 3
          }}
          onClick={handleClose}>
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ResponsiveTable
          columns={headers}
          rows={locations?.filter((loc) => loc.domain === profile?.domain)}
          visibleIndex={false}
        />
      </DialogContent>
    </Dialog>
  );
}

export default DialogManageCampaigns;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  Edit as IconEdit,
  Note as IconNote,
  MoreHoriz as IconMoreHoriz,
  EmojiEmotions as IconEmojiEmotions,
  Star as IconStar,
  Delete as IconDelete,
  Comment as IconComment,
  Analytics as IconAnalytics,
  Visibility as IconVisibility,
  VisibilityOff as IconVisibilityOff,
  ExpandLess as IconExpandLess,
  ExpandMore as IconExpandMore,
  Extension as IconExtension,
  PlayCircleOutlineOutlined as IconPlay,
} from "@mui/icons-material";
import moment from "moment";
import { useConfirm } from "material-ui-confirm";
import FacebookCircularProgress from "../../common/FacebookCircularProgress";
import { MoreOptionMenu } from "../../menus";
import { checkImageExist, getAssetFilePath, getAvatarPath, getHLSUrl, isImageAsset, downloadUsingFetch } from "../../../utils/index";
import { apiDeleteAsset, apiUpdateAsset } from "../../../graphQL/asset";
import { actionDeleteMyAsset, actionUpdateAsset, actionUpdateMyAsset } from "../../../store/Asset.slice";
import AssetFolderAvatar from "../../avatars/AssetFolderAvatar";
import DialogAssetPreview from "../../dialogs/DialogAssetPreview";
import DialogVideoCaption from "../../dialogs/DialogVideoCaption";
import { Description } from "@mui/icons-material";
import DescriptionModal from "../../modals/DescriptionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentExclamation, faDownload } from "@fortawesome/pro-solid-svg-icons";

function MyAssetCard({ asset, onEdit, onChangeFolder = () => {}, onAnalytics, onMenuSelect = () => {} }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const myAssetFolders = useSelector((store) => store.AssetFolder.myAssetFolders);
  const assetFolder = myAssetFolders?.find((item) => item.id === asset.parentNodeId);

  const [thumbnailPath, setThumbnailPath] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [folderAnchorEl, setFolderAnchorEl] = React.useState(null);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isCaptionDialogOpen, setCaptionDialogOpen] = useState(false);
  const [caption, setCaption] = useState("");
  const [openDescBodyEditor, setOpenDescBodyEditor] = useState(false);

  const handleFolderMenuClose = () => {
    setFolderAnchorEl(null);
  };

  const handleMenuClose = (type) => {
    onMenuSelect(type);
    setAnchorEl(null);
  };

  const updateAsset = (variables) => {
    apiUpdateAsset(variables).then((res) => {
      dispatch(actionUpdateMyAsset(res));
      dispatch(actionUpdateAsset(res));
    });
  };

  useEffect(() => {
    if (!asset?.file?.name) return setThumbnailPath(null);

    if (isImageAsset(asset)) {
      return setThumbnailPath(getAssetFilePath(asset));
    }
    if (asset?.avatar) {
      if (asset?.avatar?.name) {
        return setThumbnailPath(getAvatarPath(asset?.avatar));
      }
      const imgUrl = getHLSUrl(`/${asset?.file?.directory}/0001.png`);
      checkImageExist(imgUrl)
        .then(() => {
          setThumbnailPath(imgUrl);
        })
        .catch(setThumbnailPath(null));
    }
    // console.log(asset);
  }, [asset]);

  const handleCardClick = (event) => {
    console.log(event);
    setPreviewOpen(true);
  };

  const handleDescriptionSave = (state) => {
    updateAsset({
      id: asset?.id,
      desc: {
        ...asset?.desc,
        body: state.value,
      },
    });
    setOpenDescBodyEditor(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      {asset.status === "Converting" && (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            borderRadius: 2,
            background: "#24262B",
          }}
        >
          <Box
            sx={{
              borderRadius: 2,
              height: 220,
              maxHeight: 220,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <FacebookCircularProgress></FacebookCircularProgress>
            <Typography variant="h6" textAlign="center" mt={2}>
              Converting ...
            </Typography>
          </Box>
        </Box>
      )}
      {asset.status === "Error" && (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            borderRadius: 2,
            background: "#24262B",
          }}
        >
          <Box
            sx={{
              borderRadius: 2,
              height: 220,
              maxHeight: 220,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="h6" textAlign="center" mt={2}>
              Error ...
            </Typography>
          </Box>
        </Box>
      )}
      {asset.status !== "Converting" && asset.status !== "Error" && (
        <Box
          sx={{
            height: 220,
            maxHeight: 220,
            position: "relative",
          }}
        >
          {["Ready", "Published", "Converted"].includes(asset?.status) && (
            <IconButton
              sx={{
                position: "absolute",
                zIndex: 10,
                top: 1,
                right: 1,
              }}
              aria-label="add to favorites"
              size="small"
              onClick={() => {
                updateAsset({
                  id: asset?.id,
                  highlight: !asset?.highlight,
                });
              }}
            >
              <IconStar color={asset?.highlight ? "warning" : ""} fontSize="large" />
            </IconButton>
          )}
          <Box
            sx={{
              position: "absolute",
              zIndex: 10,
              top: "35%",
              right: "35%",
            }}
          >
            <IconButton>
              <IconPlay fontSize="large" onClick={handleCardClick}></IconPlay>
            </IconButton>
          </Box>
          <Box sx={{ width: "100%", height: "100%" }}>
            {thumbnailPath && (
              <img
                src={thumbnailPath}
                alt="Preview"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              ></img>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              width: "calc( 100% + 2px )",
              position: "absolute",
              bottom: -1,
              left: -1,
              right: -1,
              pl: 1,
              pr: 0.5,
              py: 0.75,
              borderBottomLeftRadius: "6px",
              borderBottomRightRadius: "6px",
              background: assetFolder?.style?.bg || "#24262B",
            }}
          >
            <IconButton
              size="small"
              sx={{ p: 0, position: "relative", left: "0.125rem" }}
              onClick={(e) => {
                setFolderAnchorEl(e.currentTarget.parentElement);
              }}
            >
              <AssetFolderAvatar assetFolder={assetFolder}></AssetFolderAvatar>
            </IconButton>
            <Typography variant="body2" sx={{ flex: 1, pl: 1 }}>
              {assetFolder?.name}
            </Typography>
            {/* {Boolean(folderAnchorEl) ? <IconExpandLess /> : <IconExpandMore />} */}
            <IconButton
              size="small"
              sx={{ p: 0, position: "relative", left: "0.125rem" }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <IconMoreHoriz></IconMoreHoriz>
            </IconButton>
          </Box>
          <Menu
            anchorEl={folderAnchorEl}
            open={Boolean(folderAnchorEl)}
            onClose={handleFolderMenuClose}
            MenuListProps={{
              sx: { width: folderAnchorEl && folderAnchorEl.offsetWidth }, // <-- The line that does all
            }}
          >
            {myAssetFolders?.map((folder) => (
              <MenuItem
                key={folder?.id}
                dense
                onClick={() => {
                  onChangeFolder(folder);
                  handleFolderMenuClose();
                }}
              >
                <ListItemIcon>
                  <AssetFolderAvatar assetFolder={folder}></AssetFolderAvatar>
                </ListItemIcon>
                <ListItemText>{folder?.name}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
      <MoreOptionMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        menus={[
          {
            icon: <IconStar color={asset?.highlight ? "" : "warning"} />,
            title: asset?.highlight ? "Un-Highlight" : "Highlight",
            onClick: () => {
              handleMenuClose();
              updateAsset({
                id: asset?.id,
                highlight: !asset?.highlight,
              });
            },
          },
          {
            icon: <IconEmojiEmotions></IconEmojiEmotions>,
            title: "Widgets",
            onClick: () => {
              handleMenuClose();
              navigate(`/asset/${asset?.id}/widgets`);
              // onEdit(asset);
            },
          },
          {
            icon: <IconNote></IconNote>,
            title: "Note",
            onClick: () => {
              handleMenuClose();
              // onEdit(asset);
            },
          },
          {
            icon: <IconEdit></IconEdit>,
            title: "Info",
            onClick: () => {
              onEdit();
              handleMenuClose();
              // onEdit(asset);
            },
          },
          {
            icon: <Description></Description>,
            title: "Description",
            onClick: () => {
              handleMenuClose();
              setOpenDescBodyEditor(true);
            },
          },
          {
            icon: <IconExtension></IconExtension>,
            title: "View Caption",
            disabled: !Boolean(asset?.desc?.caption),
            onClick: () => {
              setCaptionDialogOpen(true);
              setCaption(asset?.desc?.caption || "");
              handleMenuClose();
            },
          },
          {
            icon: <IconAnalytics></IconAnalytics>,
            title: "Analytics",
            onClick: () => {
              handleMenuClose();
              onAnalytics();
            },
          },
          {
            icon: <IconComment></IconComment>,
            title: "Comments",
            onClick: () => {
              handleMenuClose("Comments");
            },
          },
          {
            icon: <FontAwesomeIcon icon={faCommentExclamation} />,
            title: "PrivateComments",
            onClick: () => {
              handleMenuClose("PrivateComments");
            },
          },
          {
            icon: <FontAwesomeIcon icon={faDownload} />,
            title: "Download",
            onClick: () => {
              downloadUsingFetch(asset?.file);
              handleMenuClose("Download");
            },
          },
          {
            icon: <IconDelete></IconDelete>,
            title: "Delete",
            onClick: () => {
              handleMenuClose();
              confirm({
                title: "Delete an asset?",
                description: "Are you sure to remove this asset?",
              })
                .then(() => {
                  apiDeleteAsset(asset?.id).then((id) => {
                    dispatch(actionDeleteMyAsset(asset?.id));
                  });
                })
                .catch(() => {});
            },
          },
        ]}
      ></MoreOptionMenu>
      <DialogAssetPreview
        asset={asset}
        onClose={() => {
          setPreviewOpen(false);
        }}
        open={isPreviewOpen}
        onDelete={() => {
          confirm({
            title: "Delete an asset?",
            description: "Are you sure to remove this asset?",
          })
            .then(() => {
              apiDeleteAsset(asset?.id).then((id) => {
                dispatch(actionDeleteMyAsset(asset?.id));
                setPreviewOpen(false);
              });
            })
            .catch(() => {});
        }}
      />
      <DialogVideoCaption
        isCaptionDialogOpen={isCaptionDialogOpen}
        setCaptionDialogOpen={setCaptionDialogOpen}
        caption={caption}
      ></DialogVideoCaption>
      <DescriptionModal
        open={openDescBodyEditor}
        onClose={() => {
          setOpenDescBodyEditor(false);
        }}
        onSave={handleDescriptionSave}
      />
    </Box>
  );
}

MyAssetCard.defaultProps = {
  onAnalytics: () => {},
};

export default MyAssetCard;

import React, { useEffect, useState } from "react";
import { Backdrop, Box, Modal, Fade, IconButton, Avatar } from "@mui/material";
import { Close, CloudUpload } from "@mui/icons-material";
import DescriptionEditor from "../DescriptionEditor/Editor";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { isMobile } from "react-device-detect";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

export default function DescriptionModal({ open, onClose, asset, onSave, showTitle = false }) {
  const { isSmScreen, isMdScreen } = useLayoutContext();

  const [state, setState] = useState();
  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();

  useEffect(() => {
    setState({ value: asset?.desc?.body });
    setTitle(asset?.desc?.title);
    setDesc(asset?.desc?.short);
  }, [asset]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Fade in={open}>
        <Box
          sx={
            isMobile
              ? {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  bgcolor: "white",
                  boxShadow: 12,
                  maxHeight: "100%",
                  overflow: "auto",
                  p: 1,
                  zIndex: 1,
                }
              : {
                  bgcolor: "white",
                  boxShadow: 12,
                  maxHeight: "100%",
                  width: isSmScreen ? "500px" : isMdScreen ? "600px" : "800px",
                  overflow: "auto",
                  p: 1,
                  zIndex: 1,
                }
          }
        >
          <Box display="flex" justifyContent="space-between">
            <IconButton
              onClick={() => {
                onSave(state, title, desc);
              }}
            >
              <CloudUpload sx={{ color: "black" }}></CloudUpload>
            </IconButton>
            <IconButton onClick={onClose} sx={{ position: "relative", top: 1, right: 1 }}>
              <Close sx={{ color: "black" }}></Close>
            </IconButton>
          </Box>
          <Box sx={{ background: "#fff", color: "#232323" }}>
            {showTitle ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar sx={{ width: 100, height: 100, m: 1 }}></Avatar>
                <ValidatorForm onSubmit={() => {}} style={{ width: "100%", px: 2 }}>
                  <TextValidator
                    size="small"
                    type="text"
                    label="Title"
                    placeholder="title..."
                    fullWidth
                    name="title"
                    value={title || ""}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    sx={{
                      mb: 1,
                      color: "black",
                      "&>div": {
                        color: "black",
                        "&>fieldset": {
                          border: "1px solid gray",
                        },
                      },
                      "&>label": {
                        color: "gray",
                      },
                    }}
                    // floatingLabelStyle={{ color: "black" }}
                  />
                  <TextValidator
                    size="small"
                    type="text"
                    label="Description"
                    placeholder="description..."
                    fullWidth
                    name="title"
                    value={desc || ""}
                    onChange={(e) => {
                      setDesc(e.target.value);
                    }}
                    sx={{
                      mb: 1,
                      color: "black",
                      "&>div": {
                        color: "black",
                        "&>fieldset": {
                          border: "1px solid gray",
                        },
                      },
                      "&>label": {
                        color: "gray",
                      },
                    }}
                    // floatingLabelStyle={{ color: "black" }}
                  />
                </ValidatorForm>
              </Box>
            ) : (
              <></>
            )}
            <DescriptionEditor state={state} setState={setState}></DescriptionEditor>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export const AUTH_USER_TOKEN_KEY = "ReactAmplify.TokenKey";
export const AUTH_USER_ROLES = [
  {
    type: "ClickZ Creator",
    value: "creator",
  },
];

export const GRADIENT_CARD_BACKGROUND = [
  "radial-gradient(#1fe4f5, #3fbafe)",
  "radial-gradient(#fbc1cc, #fa99b2)",
  "radial-gradient(#76b2fe, #b69efe)",
  "radial-gradient(#60efbc, #58d5c9)",
  "radial-gradient(#f588d8, #c0a3e5)",
];

export const CAROUSEL_MENU_ITEMS = [
  {
    path: "/",
    title: "Highlights",
  },
  {
    path: "/desc",
    title: "Desc",
  },
  {
    path: "/groups",
    title: "Groups",
  },
  // {
  //   path: "/following",
  //   title: "Following",
  // },
  // {
  //   path: "/streams",
  //   title: "Streams",
  // },
  {
    path: "/collections",
    title: "Collections",
  },
  {
    path: "/creators",
    title: "Creators",
  },
  {
    path: "/channels",
    title: "Channels",
  },
];

export const ACCEPT_ASSET_TYPES =
  ".mp4, .m4v, .m4p, .mov, .wmv, .avi, .webm, .mpeg, .swf, .flv, .ogg, .avch, .png, .gif, .jpg, .jpeg, .svg, .mp3";

export const DEFAULT_COLORS_FOR_COLOR_PICKER = [
  "#b71c1c",
  "#f44336",
  "#880e4f",
  "#e91e63",
  "#4a148c",
  "#9c27b0",
  "#311b92",
  "#673ab7",
  "#1a237e",
  "#3f51b5",
  "#0d47a1",
  "#2196f3",
  "#006064",
  "#00bcd4",
  "#004d40",
  "#009688",
  "#194d33",
  "#4caf50",
  "#e65100",
  "#ff9800",
  "#bf360c",
  "#ff5722",
  "#263238",
  "#607d8b",
  "#000000",
  "#525252",
];

export const WIDGET_TYPES = {
  faIcon: "faIcon",
  muiIcon: "muiIcon",
  emoji: "emoticon",
  text: "text",
  box: "box",
  image: "image",
};

export const IMAGE_RATIO = [
  {key: "Square", value: 1},
  {key: "2 : 3", value: 2 / 3},
  {key: "3 : 2", value: 3 / 2},
  {key: "9 : 16", value: 9 / 16},
  {key: "16 : 9", value: 16 / 9},
]
import React, { useState } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import { Domain as IconDomain, Add as IconAdd, Remove as IconRemove, Cancel as IconCancel } from "@mui/icons-material";
import { getAvatarPathWithDomain } from "../../../utils";
import { v4 as uuid_v4 } from "uuid";
import "./DomainCard.scss";
import { apiUpdateUser } from "../../../graphQL/user";
import { updateCurrentUser } from "../../../store/auth.slice";
import { actionUpdateUser } from "../../../store/User.slice";

const DomainCard = ({ domain, onClose }) => {
  const dispatch = useDispatch();
  const auth = useSelector((store) => store.auth);
  const [isLoading, setLoading] = useState(false);

  const addDomainToDomainList = () => {
    setLoading(true);
    const { PrivateInfo } = auth;
    const domainLists = PrivateInfo.domainList === null ? [domain?.name] : [...PrivateInfo.domainList, domain?.name];
    apiUpdateUser({
      id: auth?.PrivateInfo?.id,
      domainList: domainLists,
    })
      .then((res) => {
        setLoading(false);
        dispatch(updateCurrentUser(res));
        dispatch(actionUpdateUser(res));
      })
      .catch((err) => console.log(err));
  };
  const removeDomainFromDomainList = () => {
    setLoading(true);
    const { PrivateInfo } = auth;
    const domainLists = PrivateInfo?.domainList?.filter((item) => item !== domain?.name);
    apiUpdateUser({
      id: auth?.PrivateInfo?.id,
      domainList: domainLists,
    })
      .then((res) => {
        setLoading(false);
        dispatch(updateCurrentUser(res));
        dispatch(actionUpdateUser(res));
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box
      className={clsx("asset-card-container")}
      sx={{
        width: "100%",
        maxWidth: "600px",
        position: "relative",
      }}
    >
      <Box sx={{ position: "absolute", top: -8, right: -8, display: { xs: "none", sm: "block" } }}>
        <IconButton
          // sx={{ padding: 0 }}
          onClick={() => {
            onClose();
          }}
          sx={{ padding: 0, margin: 0, color: "#232323" }}
        >
          <IconCancel />
        </IconButton>
      </Box>
      <Box sx={{ background: "#D9D9D9", borderRadius: 1 }}>
        <Box display="flex" alignItems="center" sx={{ px: 1, py: 0.5, color: "#232323" }}>
          {/* <Avatar
            src={getUserThumbnailPath(assetUser?.avatar)}
            sx={{ width: "1.75rem", height: "1.75rem" }}
          ></Avatar> */}
          {getAvatarPathWithDomain(domain?.avatar, domain?.name) ? (
            <Avatar
              src={getAvatarPathWithDomain(domain?.avatar, domain?.name)}
              sx={{ width: "1.75rem", height: "1.75rem" }}
            ></Avatar>
          ) : (
            <IconDomain fontSize="large" sx={{ width: "1.75rem", height: "1.75rem" }}></IconDomain>
          )}
          <Box flexGrow={1} sx={{ pl: 1, color: "#232323" }}>
            <Typography variant="body2" fontWeight={500}>
              {/* {assetUser?.username} */}
            </Typography>
            <Typography variant="body1" fontWeight={500} sx={{ pr: 1 }}>
              {domain?.desc?.title}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="stretch" sx={{ p: 1, background: "#575757" }}>
          {getAvatarPathWithDomain(domain?.avatar, domain?.name) && (
            <Box
              sx={{
                width: "6rem",
                height: "6rem",
                background: "#D9D9D9",
                borderRadius: 1,
                mr: 1,
              }}
            >
              <Avatar
                sx={{ width: "6rem", height: "6rem" }}
                src={getAvatarPathWithDomain(domain?.avatar, domain?.name)}
                variant="rounded"
              ></Avatar>
            </Box>
          )}
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            flexGrow={1}
            sx={{
              minHeight: "6rem",
            }}
          >
            <Box flexGrow="1">
              <Typography variant="body2">{domain?.desc?.short}</Typography>
            </Box>
            <Box className="flex items-center justify-content-end w-full"></Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pl: 1, color: "#1E1E1E" }}>
          <Typography variant="body2" fontSize="0.75rem">
            {auth?.PrivateInfo?.domainList?.filter((item) => item === domain.name).length === 0
              ? "This domain has been removed from your favorite domains."
              : "This domain has been added already."}
          </Typography>
          <Box className="flex items-center">
            {auth?.PrivateInfo?.domainList?.filter((item) => item === domain.name).length === 0 ? (
              <IconButton sx={{ color: "#232323" }} onClick={addDomainToDomainList} loading={isLoading}>
                <IconAdd />
              </IconButton>
            ) : (
              <IconButton sx={{ color: "#232323" }} onClick={removeDomainFromDomainList}>
                <IconRemove />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

DomainCard.defaultProps = {};

export default DomainCard;

import { Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { getMediaUrl } from '../../../utils';
import { ImagePlayer, VideoPlayer } from '../../../components/players';
import WaveForm from '../../../components/Waveform';
import BoxWidget from './BoxWidget';
import { v4 as uuid_v4 } from "uuid";
import { useLayoutContext } from '../../../contexts/LayoutContext';
import VideoEditorControls from './VideoEditorControls';
import { Check, Publish, PublishOutlined } from '@mui/icons-material';
import { apiUpdateAsset } from '../../../graphQL/asset';
import { useDispatch } from 'react-redux';
import { actionUpdateMyAsset } from '../../../store/Asset.slice';
import { actionUpdateAsset } from '../../../store/Asset.slice';
import WidgetOverlay from './WidgetOverlay';
import WidgetEditDrawer from '../../../components/CustomDrawers/WidgetDrawer/WidgetDrawer';

export default function VideoEditor({
  asset = {},
  setAsset = () => {},
  addNew = false,
  setAddNew = () => { },
}) {
  const dispatch = useDispatch();
  const playerRef = useRef();
  const containerRef = useRef();
  const [isPlaying, setPlaying] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isMuted, setMuted] = useState(true);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(15);
  const [editWidget, setEditWidget] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [changed, setChanged] = useState(false);

  const onChangeWidget = ({
    size, position, overlaySize
  }) => {
    setEditWidget({
      ...editWidget,
      boxInfo: {
        ...editWidget.boxInfo,
        x: position.x,
        y: position.y,
        width: size.width,
        height: size.height,
        videoWidth: overlaySize.width,
        videoHeight: overlaySize.height,
      }
    });
    setChanged(true);
  }

  const onSave = (widget) => {
    console.log(widget);
    let isEdit = false;
    let widgets = asset?.videoWidgets?.map((item) => {
      if (item.id === widget?.id) {
        isEdit = true;
        return widget;
      }
      return item;
    }) || [];
    if (!isEdit && widget) {
      widgets = [...widgets, widget];
    }
    // save asset here
    apiUpdateAsset({
      id: asset?.id,
      videoWidgets: widgets
    }).then(res => {
      dispatch(actionUpdateAsset(res));
      dispatch(actionUpdateMyAsset(res));
      setAsset(res);
      setChanged(false);
    });
    setOpenEdit(false);
  }

  const deleteWidget = (widget) => {
    let widgets = asset?.videoWidgets?.filter(item => item.id !== widget.id) || [];
    // save asset here
    apiUpdateAsset({
      id: asset?.id,
      videoWidgets: widgets
    }).then(res => {
      dispatch(actionUpdateAsset(res));
      dispatch(actionUpdateMyAsset(res));
      setAsset(res);
      setEditWidget(null)
    });
    setOpenEdit(false);
  }

  const handleEditWidgetClose = () => {
    setOpenEdit(false);
  }

  const handleEditWidgetActions = (type) => {
    console.log(type);
    if (type === "delete") {
      deleteWidget(editWidget);
    } else if (type === "edit") {
      setOpenEdit(true);
    } else if (type === "detail") {
      
    }
  }

  const handleWidgetSelect = (widget) => {
    let isEdit = false;
    if (editWidget) {
      let widgets = asset?.videoWidgets?.map((item) => {
        if (item.id === editWidget.id) {
          isEdit = true;
          return editWidget;
        }
        return item;
      }) || [];
      if (!isEdit) {
        widgets = [...widgets, editWidget];
      }
      setAsset({
        ...asset,
        videoWidgets: widgets,
      });
    }
    setEditWidget(widget);
  }

  useEffect(() => {
    if (addNew) {
      setEditWidget({
        id: uuid_v4(),
        assetId: asset?.id,
        name: "",
        type: "box",
        desc: null,
        boxInfo: {
          // x: size.width / 2,
          // y: size.height / 2,
          width: 100,
          height: 100,
          // videoWidth: size.width,
          // videoHeight: size.height,
          foreground: "red"
        },
        videoPos: {
          startAt: Math.abs(duration - playedSeconds) < 1 ? 0 : playedSeconds,
          duration: Math.abs(duration - playedSeconds) < 1 ? duration : duration - playedSeconds
        },
        keyword: "",
        videoWidgetType: "box",
        data: null
      });
      setOpenEdit(true);
      setAddNew(false);
    }
  }, [addNew])

  const renderContent = (asset = {
    file: {
      contentType: "", directory: "",
      displayName: "", name: "",
      outputDash: null, outputHls: null,
    }
  }) => {
    const url = getMediaUrl(asset)
    if (asset?.file?.contentType?.toLocaleLowerCase().startsWith('video')) {
      return (
        <Box>
          <VideoPlayer
            playerRef={playerRef}
            url={url}
            playing={isPlaying}
            muted={isMuted}
            onProgress={(played, playedSeconds) => {
              setPlayedSeconds(playedSeconds);
            }}
            onReady={() => {
              setPlaying(true);
              setLoading(false);
            }}
            onDuration={(e) => {
              setDuration(Math.floor(e));
            }}
            onBufferEnd={() => {
              setPlaying(true);
              setLoading(false);
            }}
            onStart={() => {
            }}
            onPlay={() => {
              setPlaying(true);
              setLoading(false);
            }}
            onPause={() => {
              setPlaying(false);
            }}
            onEnded={() => {
            }}
            onError={(error) => {
              console.error(`Error : ${error.name}`);
              if (error === "hlsError") {
              }
              if (error.name === "NotAllowedError") {
                setPlaying(false);
              }
            }}
            controls={false}
            caption={asset?.file?.outputCC}
          />
          <VideoEditorControls
            duration={duration}
            playing={isPlaying}
            progress={playedSeconds * 1000}
            onPlay={() => {
              setPlaying(true);
            }}
            onPause={() => {
              setPlaying(false);
            }}
            onSeek={(seconds) => {
              playerRef.current?.seekTo(seconds, "seconds");
            }}
            editingWidget={editWidget}
            onChange={(min, max) => {
              setEditWidget({
                ...editWidget,
                videoPos: {
                  startAt: min,
                  duration: max - min
                }
              });
            }}
          />
        </Box>)
    } else if (asset?.file?.contentType?.toLocaleLowerCase().startsWith('image')) {
      return <ImagePlayer url={url} />
    } else if (asset?.file?.contentType?.toLocaleLowerCase().startsWith('audio')) {
      return <WaveForm audioUrl={url} playing={isPlaying} setPlaying={setPlaying} />
    } else {
      return <></>
    }
  }

  const renderSelection = (asset) => {
      return <Box sx={{
        display: "flex",
        zIndex: 1,
        alignItems: "center",
        justifyContent: "space-between",
        position: "absolute",
        top: 0,
        width: "100%",
        height: "2.2rem",
        background: "#ffffff6f",
      }}>
        <Box sx={{
          display: "flex",
          overflowX: "auto",
          gap: 1,
        }}>
          {asset?.videoWidgets?.length ? 
            asset?.videoWidgets.map((item, index) => {
              return <Box key={index} sx={{
                width: "2rem",
                height: "2rem",
                border: `2px dashed ${item.boxInfo?.foreground || "red"}`,
                background: item.id === editWidget?.id ? '#fffffff0' : '',
              }}>
                <Button sx={{width: "100%", height: "100%", pt: 0, px: 0}} onClick={() => {handleWidgetSelect(item)}}/>
              </Box>
            }) : <></>}
        </Box>
        <IconButton onClick={() => {onSave()}} disabled={!changed}> { changed ? <PublishOutlined/> : <Publish/> } </IconButton>
      </Box>
  };

  return (
    <Box>
      {renderSelection(asset)}
      <Box sx={{
        width: '100%',
        height: '100%',
      }} ref={containerRef}>
        {renderContent(asset)}
        {editWidget &&
          <WidgetOverlay widget={editWidget} onChange={onChangeWidget} onClick={handleEditWidgetActions}/>
        }
        {asset?.videoWidgets?.length > 0 && asset?.videoWidgets?.filter((item) => item.id !== editWidget?.id).map((widget, index) => (
          <WidgetOverlay key={index} widget={widget} onClick={() => handleWidgetSelect(widget)} isPreview={true}/>
        ))}
        <WidgetEditDrawer open={openEdit} widget={editWidget} setWidget={setEditWidget} onSave={onSave} onClose={handleEditWidgetClose}/>
      </Box>
    </Box>
  )
}
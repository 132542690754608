import { createSlice } from "@reduxjs/toolkit";

export const otherProfileSlice = createSlice({
    name: "otherProfile",
    initialState: {
      Profile: null,
    },
    reducers: {
      setOtherProfile: (state, action) => {
        // console.log(action.payload);
        return {
          Profile: action.payload,
        };
      },
      removeOtherProfile: (state, action) => {
        return {
            Profile: null
        }
      },
    },
  });
  
  // Action creators are generated for each case reducer function
  export const { setOtherProfile, removeOtherProfile } = otherProfileSlice.actions;
  
  export default otherProfileSlice.reducer;
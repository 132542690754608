import client from "../index.js";
import { GET_MENUS } from "./query.js";
import { CREATE_MENU, UPDATE_MENU, DELETE_MENU, DELETE_ALL_MENUS } from "./mutation";

export const apiGetAllMenus = (filter) => {
  return client
    .query({
      query: GET_MENUS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.menus;
    });
};

export const apiCreateMenu = (data) => {
  return client
    .mutate({
      mutation: CREATE_MENU,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createMenu.menu;
    });
};

export const apiUpdateMenu = (data) => {
  return client
    .mutate({
      mutation: UPDATE_MENU,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateMenu.menu;
    });
};

export const apiDeleteMenu = (id) => {
  return client
    .mutate({
      mutation: DELETE_MENU,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteMenu.id;
    });
};

export const apiDeleteAllMenus = (filter = {}) => {
  return client
    .mutate({
      mutation: DELETE_ALL_MENUS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAllMenus?.total;
    });
};

import React from "react";
import { Box } from "@mui/material";
import AudioEqualizer from "../equalizer/AudioEqualizer";

function ImagePlayer({ isPlaying, loading, onClick }) {
  return (
    <Box
      width="100%"
      height="100%"
      sx={{ px: "5%", py: "10%" }}
      onClick={() => {
        onClick();
      }}
    >
      <AudioEqualizer isPlaying={isPlaying} loading={loading}></AudioEqualizer>
    </Box>
  );
}

export default ImagePlayer;

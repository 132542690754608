import React from "react";
import { Box, Typography } from "@mui/material";
import { Favorite as IconFavorite, FavoriteBorder as IconFavoriteBorder } from "@mui/icons-material";

const defaultSx = { borderRadius: 4 };

const FollowButton = ({ followed, onClick, sx, ...props }) => {
  return (
    <Box
      sx={{
        ...defaultSx,
        ...(sx || {}),
        background: !followed ? "#FF0012" : "#ffffff",
        color: !followed ? "#ffffff" : "#FF0012",
        border: !followed ? "2px solid #FF0012" : "2px solid #FF0012",
        width: 112,
        pl: 1,
      }}
      {...props}
      onClick={onClick}
      display="flex"
      alignItems="center"
    >
      <IconFavorite fontSize="small" sx={{ mr: 0.75 }}></IconFavorite>
      <Typography variant="body1" fontWeight={600}>
        {followed ? "Unfollow" : "Follow"}
      </Typography>
    </Box>
  );
};

FollowButton.defaultProps = {};

export { FollowButton };

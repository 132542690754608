import { gql } from "@apollo/client";

const GET_ASSET_FOLDER = gql`
  query mediaFolder($id: ID!) {
    mediaFolder(id: $id) {
      id
      name
      owner
      parentNode
      avatar {
        name
        dir
        base
        mimetype
        status
        faIcon
        emoji
        thumbnail
        style
      }
      desc {
        title
        short
        body
        website
        style
        rows
      }
      childNodeIds
      keywords
      categories
      style
      status
      timestamp
      updatedAt
      availability {
        from
        to
      }
      locationId
      domain
      data
    }
  }
`;

const GET_ASSET_FOLDERS = gql`
  query mediaFolders($input: MediaFoldersInput!) {
    mediaFolders(input: $input) {
      total
      mediaFolders {
        id
        name
        owner
        parentNode
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        childNodeIds
        keywords
        categories
        style
        status
        timestamp
        updatedAt
        availability {
          from
          to
        }
        locationId
        domain
        data
      }
    }
  }
`;

export { GET_ASSET_FOLDER, GET_ASSET_FOLDERS };

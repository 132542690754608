import React, { useState } from "react";
import { Box, Typography, Slider, IconButton } from "@mui/material";
import { getPlayerTime, isImageAsset } from "../../utils";
import {
  VolumeOff as IconVolumeMute,
  VolumeDown as IconVolumeDown,
  VolumeUp as IconVolumeUp,
} from "@mui/icons-material";

function AssetPreviewCustomControls({ currentAsset, playerStatus, onSeek }) {
  const [volume, setVolume] = useState(50);

  const isImage = isImageAsset(currentAsset);

  const hideControl = currentAsset?.type === "live";

  return (
    <Box
      className="asset-player-footer"
      sx={{
        position: "absolute",
        bottom: 0,
        zIndex: 45,
        width: "100%",
        transition: "bottom 0.5s",
      }}
    >
      <Box>
        <Box
          sx={{
            px: {
              xs: 1,
              md: 2,
            },
            position: "relative",
            zIndex: 50,
            width: "100%",
          }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box></Box>
          <Box display="flex" alignItems="center">
            {!isImage && (
              <Box
                display="flex"
                alignItems="center"
                onClick={() => {
                  if (volume === 0) setVolume(50);
                  else setVolume(0);
                }}
              >
                {volume === 0 && (
                  <IconVolumeMute
                    sx={{
                      color: "#e2e2e2",
                      fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                    }}
                  ></IconVolumeMute>
                )}
                {volume > 0 && volume < 50 && (
                  <IconVolumeDown
                    sx={{
                      color: "#e2e2e2",
                      fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                    }}
                  ></IconVolumeDown>
                )}
                {volume >= 50 && (
                  <IconVolumeUp
                    sx={{
                      color: "#e2e2e2",
                      fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                    }}
                  ></IconVolumeUp>
                )}
              </Box>
            )}
            {/* {!isImage && (
              <Slider
                size="small"
                value={volume || 0}
                min={0}
                max={100}
                step={1}
                valueLabelDisplay="off"
                onChange={(_, value) => {
                  setVolume(value);
                }}
                sx={{
                  padding: "10px 0 !important",
                  mx: 1,
                  width: 60,
                  color: "#fff",
                  height: 6,
                  display: {
                    xs: "none",
                    md: "block",
                  },
                  "& .MuiSlider-thumb": {
                    width: 16,
                    height: 16,
                    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                    "&:before": {
                      boxShadow: "0 2px 8px 0 rgba(0,0,0,0.4)",
                    },
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: `0px 0px 0px 6px ${
                        theme.palette.mode === "dark"
                          ? "rgb(255 255 255 / 16%)"
                          : "rgb(0 0 0 / 16%)"
                      }`,
                    },
                    "&.Mui-active": {
                      width: 24,
                      height: 24,
                    },
                  },
                  "& .MuiSlider-rail": {
                    opacity: 0.28,
                  },
                }}
              />
            )} */}
            {!isImage && !hideControl && (
              <Typography
                variant="body1"
                color="common.white"
                sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
              >
                {/* {getPlayerTime(playerStatus.playedSeconds) +
                  " / " +
                  getPlayerTime(playerStatus.duration)} */}
                {getPlayerTime(playerStatus.playedSeconds)}
              </Typography>
            )}
          </Box>
        </Box>
        {!isImage && (
          <Box
            display="flex"
            sx={{
              position: "relative",
              zIndex: 50,
              marginBottom: -0.5,
            }}
          >
            {!hideControl && (
              <Slider
                aria-label="time-indicator"
                size="small"
                value={playerStatus?.playedSeconds}
                min={0}
                step={0.1}
                max={playerStatus?.duration}
                onChange={(_, value) => {
                  onSeek(value);
                }}
                sx={{
                  padding: "4px 0 !important",
                  color: "#fff",
                  height: 4,
                  "& .MuiSlider-thumb": {
                    width: "0.875rem",
                    height: "0.875rem",
                    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                    "&:before": {
                      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.4)",
                    },
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: `0px 0px 0px 3px ${"rgb(255 255 255 / 16%)"}`,
                    },
                    "&.Mui-active": {
                      width: "1.125rem",
                      height: "1.125rem",
                    },
                    "&:after": {
                      width: "1.5rem",
                      height: "1.5rem",
                    },
                  },
                  "& .MuiSlider-rail": {
                    opacity: 0.28,
                  },
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default AssetPreviewCustomControls;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllAlertAssets } from "../graphQL/alertAsset";

export const getAllAlertAssets = createAsyncThunk(
  "alertAsset/read_alert_assets",
  async (
    filter = {
      ordering: {
        field: "updatedAt",
        order: "Desc",
      },
      pagination: {
        page: 1,
        limit: 32768,
      },
    }
  ) => {
    return await apiGetAllAlertAssets(filter);
  }
);

const alertAssetSlice = createSlice({
  name: "AlertAsset",
  initialState: {
    total: 0,
    alertAssets: null,
  },
  reducers: {
    actionUpdateAlertAsset: (state, action) => {
      if (state.alertAssets) {
        if (state.alertAssets.find((item) => item.id === action.payload.id)) {
          state.alertAssets = state.alertAssets.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.alertAssets = [action.payload, ...state.alertAssets];
          state.total = state.alertAssets.length;
        }
      } else {
        state.alertAssets = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteAlertAsset: (state, action) => {
      state.alertAssets = state.alertAssets?.filter((item) => item?.id !== action.payload);
      state.total = state.alertAssets.length;
    },
    actionDeleteAllAlertAssets: (state, action) => {
      state.alertAssets = state.alertAssets?.filter((t) => t.domain !== action.payload.domain);
      state.total = state.alertAssets.length;
    },
  },
  extraReducers: {
    [getAllAlertAssets.fulfilled]: (state, action) => {
      state.alertAssets = action.payload.alertAssets;
      state.total = action.payload.total;
    },
    [getAllAlertAssets.rejected]: (state, action) => {
      state.total = 0;
      state.alertAssets = null;
    },
  },
});

export const { actionUpdateAlertAsset, actionDeleteAlertAsset, actionDeleteAllAlertAssets } = alertAssetSlice.actions;
export default alertAssetSlice.reducer;

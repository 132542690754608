import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_ASSET_FOLDER = gql`
  subscription onCreateMediaFolder{
    onCreateMediaFolder{
      mediaFolder{
        id
        name
        owner
        parentNode
        avatar {
            name
            dir
            base
            mimetype
            status
            faIcon
            emoji
            thumbnail
            style
        }
        desc {
            title
            short
            body
            website
            style
            rows
        }
        childNodeIds
        keywords
        categories
        style
        status
        timestamp
        updatedAt
        availability {
            from
            to
        }
        locationId
        domain
        data
      }
    }
  }
`;

export const ON_UPDATE_ASSET_FOLDER = gql`
  subscription onUpdateMediaFolder{
    onUpdateMediaFolder{
      mediaFolder{
        id
        name
        owner
        parentNode
        avatar {
            name
            dir
            base
            mimetype
            status
            faIcon
            emoji
            thumbnail
            style
        }
        desc {
            title
            short
            body
            website
            style
            rows
        }
        childNodeIds
        keywords
        categories
        style
        status
        timestamp
        updatedAt
        availability {
            from
            to
        }
        locationId
        domain
        data
      }
    }
  }
`;

export const ON_DELETE_ASSET_FOLDER = gql`
  subscription onDeleteMediaFolder{
    onDeleteMediaFolder{
      id
    }
  }
`;

const useOnCreateAssetFolder= () => useSubscription(ON_CREATE_ASSET_FOLDER);
const useOnUpdateAssetFolder= () => useSubscription(ON_UPDATE_ASSET_FOLDER);
const useOnDeleteAssetFolder= () => useSubscription(ON_DELETE_ASSET_FOLDER);

export { useOnCreateAssetFolder, useOnUpdateAssetFolder, useOnDeleteAssetFolder};

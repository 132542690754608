import React from "react";
import { DialogTitle, Box, Typography, IconButton } from "@mui/material";
import { Close as IconClose } from "@mui/icons-material";

function EditDialogHeader({ title, handleClose, ...props }) {
  return (
    <DialogTitle sx={{ p: 0.5 }} {...props}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box flex={1} sx={{ pl: 1 }}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <IconClose></IconClose>
        </IconButton>
      </Box>
    </DialogTitle>
  );
}

export default EditDialogHeader;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import "./ForYou.scss";
import AssetPlayer from "../player/AssetPlayer";
import CirclesLoader from "../../components/loader/CirclesLoader";
import { apiGetAllAssets } from "../../graphQL/asset";
import { useAppContext } from "../../contexts/AppContext";

function PageForYou() {
  // const allAssets = useSelector((store) => store.Asset.assets);
  const categories = useSelector((store) => store.Category.categories);
  const domains = useSelector((store) => store.Domain.domains);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const OtherProfile = useSelector((store) => store.OtherProfile.Profile);

  const [profile, setProfile] = useState();
  const [assets, setAssets] = useState();
  const [filter, setFilter] = useState({
    domain: PrivateInfo?.name,
    pagination: {
      page: 1,
      limit: 99,
    },
    highlight: true,
    // categories: null,
  });

  useEffect(() => {
    if ((PrivateInfo || OtherProfile) && domains) {
      if (OtherProfile) {
        setProfile(OtherProfile);
      } else {
        setProfile(PrivateInfo);
      }
      const domainName = OtherProfile?.domain || PrivateInfo?.domain;
      const domain = domains.find((d) => d.name === domainName);
      setFilter({
        ...filter,
        domain: domainName,
        pagination: {
          page: 1,
          limit: domain?.fetchCount || 99,
        }
      })
    }
  }, [PrivateInfo, OtherProfile, domains]);

  useEffect(() => {
    if (profile) {
      setAssets(null);
      apiGetAllAssets(filter).then((data) => {
        console.log(data);
        setAssets(data.mediaAssets);
      });
    }
  }, [profile, filter, categories]);

  if (!assets)
    return (
      <Box sx={{ width: "100%", height: "100%" }} display="flex" alignItems="center" justifyContent="center">
        <CirclesLoader></CirclesLoader>
      </Box>
    );

/*  if (assets?.length < 1) {
    return <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="h5" color="common.white">
        No asset available at this time
      </Typography>
    </Box>;
  }*/

  return (
    <Box className="foryou-player-wrapper">
      <AssetPlayer assets={assets} filter={filter} setFilter={setFilter}></AssetPlayer>
    </Box>
  );
}

export default PageForYou;

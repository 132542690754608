import { gql } from "@apollo/client";

const GET_CHANNEL = gql`
  query channel($id: ID!) {
    channel(id: $id) {
      id
      name
      desc {
        title
        short
        body
        website
        style
        rows
      }
      avatar {
        name
        dir
        base
        mimetype
        status
        faIcon
        emoji
        thumbnail
        style
      }
      availability {
        from
        to
      }
      style
      type
      category
      domain
      locationId
      status
      data
      menuId
      channelURI {
        type
        protocol
        address
        locator
        port
      }
      altURI
      serviceId
      globalServiceId
      channelNum {
        major
        minor
      }
      timestamp
      updatedAt
    }
  }
`;

const GET_CHANNELS = gql`
  query channels($input: ChannelsInput!) {
    channels(input: $input) {
      total
      channels {
        id
        name
        desc {
          title
          short
          body
          website
          style
          rows
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        availability {
          from
          to
        }
        style
        type
        category
        domain
        locationId
        status
        data
        menuId
        channelURI {
          type
          protocol
          address
          locator
          port
        }
        altURI
        serviceId
        globalServiceId
        channelNum {
          major
          minor
        }
        timestamp
        updatedAt
      }
    }
  }
`;

export { GET_CHANNEL, GET_CHANNELS };

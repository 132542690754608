import React from "react";
import { Box, Typography } from "@mui/material";
import AssetPlayer from "../player/AssetPlayer";

function CreatorPlayer({ playList }) {
  return (
    <Box className="user-folder-player-wrapper">
      {playList?.length > 0 && <AssetPlayer assets={playList}></AssetPlayer>}
      {playList && !playList?.length && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%",
            height: "100%",
            px: 4,
          }}
        >
          <Typography variant="h5" color="common.white">
            No assets to show up
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default CreatorPlayer;

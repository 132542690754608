import React, { useState, useRef } from "react";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Box, Button, IconButton } from "@mui/material";
import { Search as IconSearch, Domain as IconDomain, Menu } from "@mui/icons-material";
import { useAppContext } from "../../contexts/AppContext";
import { getAvatarPathWithDomain } from "../../utils";

import "./TopAppBar.scss";
import { useSelector } from "react-redux";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { myToast } from "../../libs";
import { isMobile } from "react-device-detect";
import DomainEditDrawer from "../../components/CustomDrawers/DomainEditDrawer/DomainEditDrawer";
import DomainDrawer from "../../components/CustomDrawers/DomainDrawer/DomainDrawer";

const TopBarNavLink = ({ title, to, active = false, disabled = false }) => {
  const navigate = useNavigate();
  if (disabled) {
    return;
  } else {
    return (
      <Box
        className={clsx("topbar-navlink", { active, disabled })}
        onClick={() => {
          if (disabled) {
            myToast.error("No channels");
            return;
          }
          navigate(to);
        }}
      >
        {title}
      </Box>
    );
  }
};

function CarouselMenu() {
  const location = useLocation();
  const InfoButtonRef = useRef();
  const menus = useSelector((store) => store.Menu.menus);
  const channels = useSelector((store) => store.Channel.channels);

  const [{ domainInfo }, { setOpenComponent }] = useAppContext();

  const [{ showTopAppBar }, { setShowTopAppBar }] = useLayoutContext();

  const [editDomain, setEditDomain] = useState();
  const [openDomainDrawer, setOpenDomainDrawer] = useState(false);
  return (
    <>
      <Box
        sx={{
          p: 1,
          left: 0,
          position: "absolute",
          zIndex: 999,
          top: 0,
          display: "flex",
        }}
        onClick={() => {
          setShowTopAppBar(true);
        }}
      >
        {/* {!isMobile ? (
          <Button
            sx={{ color: "white" }}
            onClick={() => {
              setOpenComponent(true);
            }}
          >
            {<Menu />}
          </Button>
        ) : (
          <></>
        )} */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setEditDomain(domainInfo);
            setOpenDomainDrawer(true);
          }}
        >
          {getAvatarPathWithDomain(domainInfo?.avatar, domainInfo?.name) ? (
            <Avatar
              src={getAvatarPathWithDomain(domainInfo?.avatar, domainInfo?.name)}
              sx={{ width: "2rem", height: "2rem" }}
            ></Avatar>
          ) : (
            <IconDomain></IconDomain>
          )}
        </Box>
      </Box>
      <Box
        // className="flex items-center justify-content-between"
        className="flex items-centern"
        sx={{
          position: "absolute",
          top: showTopAppBar ? 0 : -50,
          zIndex: 99,
          transition: "all 0.5s ease-in-out",
          width: "100%",
          p: 1,
          pl: isMobile ? 1 : 3,
          backgroundColor: "#33333380",
          display: menus?.length > 1 ? "initial" : "none"
        }}
      >
        <Box flexGrow={1} sx={{ overflow: "auto", ml: "2.25rem", mr: 1 }}>
          <Box sx={{ width: "100%" }} className="flex items-center">
            {menus?.map((menu) => (
              <TopBarNavLink
                title={menu?.name}
                to={menu?.params?.to}
                key={menu.name}
                active={menu?.params?.to === location.pathname}
                disabled={menu?.params?.to === "/channels" && channels?.length < 1}
              />
            ))}
          </Box>
        </Box>
        {/* <Box>
          <IconButton size="small">
            <IconSearch></IconSearch>
          </IconButton>
        </Box> */}
      </Box>
      <DomainDrawer
        domain={editDomain}
        setDomain={setEditDomain}
        anchorEl={InfoButtonRef?.current}
        open={openDomainDrawer}
        onClose={() => {
          // setPlaying(true);
          setOpenDomainDrawer(false);
        }}
        // playerStatus={playerStatus}
      />
    </>
  );
}

export default CarouselMenu;

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
/* Apollo */
import { ApolloProvider } from "@apollo/client";
import { Backdrop, CircularProgress } from "@mui/material";
import { AuthenticatorProvider } from "@z_packages/react-mui-aws-auth-amplify";
/* Main App */
import App from "./App";
import store from "./store/index.js";
import theme from "./libs/theme";
import client from "./graphQL/index";

import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";
// root
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <AuthenticatorProvider>
          <CssBaseline></CssBaseline>
          <BrowserRouter>
            <Suspense
              fallback={
                <Backdrop open={true}>
                  <CircularProgress color="inherit" size={60} />
                </Backdrop>
              }
            >
              <App />
            </Suspense>
          </BrowserRouter>
        </AuthenticatorProvider>
      </ThemeProvider>
      <ToastContainer></ToastContainer>
    </ApolloProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

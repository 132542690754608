import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import TopAppBar from "./TopAppBar/TopAppBar";
import { useLayoutContext } from "../contexts/LayoutContext";
import { useEffect } from "react";
import BottomNavigationBar from "./components/BottomNavigationBar";

function SubPageLayout() {
  const [, { setShowTopAppBar }] = useLayoutContext();

  useEffect(() => {
    setShowTopAppBar(true);
  }, []); //eslint-diseble-line

  return (
    <Box className="app-wrapper no-select">
      <Box display="flex" flexDirection="column" sx={{ width: "100%", height: "100%" }}>
        <TopAppBar></TopAppBar>
        <Box flexGrow={1} sx={{ position: "relative", marginTop: "50px" }}>
          <Box sx={{ position: "absolute", height: "calc( 100% - 66px )", width: "100%", overflowY: "auto" }}>
            <Outlet></Outlet>
          </Box>
          <BottomNavigationBar></BottomNavigationBar>
        </Box>
      </Box>
    </Box>
  );
}

export default SubPageLayout;

import { gql } from "@apollo/client";

const CREATE_USER_FOLDER = gql`
  mutation createUserFolder($input: CreateUserFolderInput!) {
    createUserFolder(input: $input) {
      userFolder {
        id
        name
        owner
        avatar {
          type
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          caption
        }
        assetIds {
          id
          note
        }
        widgetIds {
          id
          note
        }
        clipIds {
          id
          note
        }
        creatorIds
        noteIds
        channelIds
        style
        rank
        status
        timestamp
      }
    }
  }
`;

const UPDATE_USER_FOLDER = gql`
  mutation updateUserFolder($input: UpdateUserFolderInput!) {
    updateUserFolder(input: $input) {
      userFolder {
        id
        name
        owner
        avatar {
          type
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          caption
        }
        assetIds {
          id
          note
        }
        widgetIds {
          id
          note
        }
        clipIds {
          id
          note
        }
        creatorIds
        noteIds
        channelIds
        style
        rank
        status
        timestamp
      }
    }
  }
`;

const DELETE_USER_FOLDER = gql`
  mutation deleteUserFolder($id: ID!) {
    deleteUserFolder(id: $id) {
      id
    }
  }
`;

export { CREATE_USER_FOLDER, UPDATE_USER_FOLDER, DELETE_USER_FOLDER };

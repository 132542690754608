import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography, Avatar, ThemeProvider, Button, Backdrop, CircularProgress } from "@mui/material";
import {
  Cancel as IconCancel,
  Delete as IconDelete,
  CheckCircle as IconCheckCircle,
  Link as IconLink,
  Close as IconClose,
} from "@mui/icons-material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AvatarUpload from "./AvatarUpload";
import { getAvatarPath, getAvatarThumbnailPath } from "../../../utils";
import { lightTheme } from "../../../libs/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { myToast } from "../../../libs";
import { useUploadContext } from "../../../contexts/UploadContext";
import KeywordInput from "../../form/KeywordInput";
import { apiDeleteUser, apiUpdateUser } from "../../../graphQL/user";
import { updateCurrentUser } from "../../../store/auth.slice";
import { useConfirm } from "material-ui-confirm";
import { Auth } from "aws-amplify";
import { actionUpdateUser } from "../../../store/User.slice";

function ProfileComponent({ open, onClose }) {
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);

  const dispatch = useDispatch();
  const confirm = useConfirm();

  const form = useRef();
  const [isLoading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [avatarFile, setAvatarFile] = useState(null);

  const [, { uploadAvatar }] = useUploadContext();

  useEffect(() => {
    if (PrivateInfo) {
      setProfile(PrivateInfo);
      setAvatarFile(null);
    }
  }, [open, PrivateInfo]);

  const actionSave = (variables) => {
    return apiUpdateUser(variables)
      .then((res) => {
        dispatch(updateCurrentUser(res));
        dispatch(actionUpdateUser(res));
      })
      .catch((err) => console.log(err));
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{ maxWidth: "420px", color: "#232323", width: "100%" }}>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography variant="body1">{PrivateInfo?.name}</Typography>
          <IconButton
            size="small"
            onClick={() => {
              onClose();
            }}
            sx={{ position: "relative", top: -4, right: -8 }}
          >
            <IconClose fontSize="small" sx={{ cursor: "pointer" }}></IconClose>
          </IconButton>
        </Box>
        <ValidatorForm
          ref={form}
          onSubmit={() => {
            setLoading(true);
            let variables = _.pick(profile, ["id", "name", "username", "avatar", "desc", "keywords"]);
            if (avatarFile) {
              uploadAvatar(PrivateInfo?.id, avatarFile, (err, result, fileName) => {
                if (err) {
                  myToast.error("Upload avatar was failed");
                  return;
                }
                variables.avatar = {
                  ...(variables.avatar || {}),
                  thumbnail: fileName,
                  dir: PrivateInfo?.id,
                  base: "",
                  status: "uploaded",
                };
                actionSave(variables).then(() => {
                  setLoading(false);
                  onClose();
                });
              });
            } else {
              actionSave(variables).then(() => {
                setLoading(false);
                onClose();
              });
            }
          }}
        >
          <Box display="flex" alignItems="stretch" sx={{ width: "100%" }}>
            <Box sx={{ position: "relative", width: "130px", mr: 0.5 }}>
              <Box sx={{ width: "100%", height: "100%" }}>
                <AvatarUpload
                  variant="rounded"
                  url={getAvatarThumbnailPath(PrivateInfo?.avatar)}
                  onChange={(e) => {
                    setAvatarFile(e);
                  }}
                  onDelete={() => {
                    setAvatarFile(null);
                  }}
                ></AvatarUpload>
              </Box>
            </Box>
            <Box flexGrow={1}>
              <TextValidator
                size="small"
                type="text"
                label="User Handle"
                placeholder="User Handle"
                fullWidth
                name="username"
                value={profile?.username || ""}
                onChange={(e) => {
                  setProfile({
                    ...profile,
                    username: e.target.value,
                  });
                }}
                sx={{ mb: 1 }}
              ></TextValidator>
              <Box sx={{ width: "100%", mb: 1 }} display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <TextValidator
                    size="small"
                    type="text"
                    fullWidth={true}
                    label="Title"
                    placeholder="Title"
                    name=""
                    value={profile?.desc?.title || ""}
                    onChange={(e) => {
                      setProfile({
                        ...profile,
                        desc: {
                          ...(profile?.desc || {}),
                          title: e.target.value,
                        },
                      });
                    }}
                  ></TextValidator>
                </Box>
                {/* <Box
                  sx={{
                    backgroundColor: "#F8F9FB",
                    width: "2rem",
                    height: "2rem",
                    "&:hover": {
                      backgroundColor: "#f2f3f5",
                    },
                  }}
                  className="flex items-center justify-content-center"
                >
                  <FontAwesomeIcon icon={faLink} />
                </Box> */}
              </Box>
              <TextValidator
                size="small"
                type="text"
                name="name"
                multiline
                fullWidth={true}
                label="Description"
                placeholder="Description"
                value={profile?.desc?.short || ""}
                onChange={(e) => {
                  setProfile({
                    ...profile,
                    desc: {
                      ...(profile?.desc || {}),
                      short: e.target.value,
                    },
                  });
                }}
                rows={2}
              ></TextValidator>
            </Box>
          </Box>
        </ValidatorForm>
        <Box sx={{ pt: 1 }}>
          <KeywordInput
            options={[]}
            value={profile?.keywords || []}
            onChange={(e, value) => {
              setProfile({
                ...profile,
                keywords: value,
              });
            }}
            limitTags={3}
            label="Keywords"
            placeholder="Keywords"
          ></KeywordInput>
        </Box>
        <Box display="flex" justifyContent="space-between" sx={{ width: "100%" }}>
          <Box display={"flex"} alignItems="center">
            <IconButton
              size="small"
              onClick={() => {
                confirm({
                  title: "Delete User Account",
                  description: "Are you sure you want to delete your account?",
                }).then(() => {
                  apiDeleteUser(profile?.id).then((id) => {
                    onClose();
                    Auth.signOut();
                  });
                });
              }}
            >
              <IconDelete></IconDelete>
            </IconButton>
          </Box>
          <Box display={"flex"} alignItems="center" sx={{ cursor: "pointer" }}>
            <Button
              onClick={() => {
                form.current.submit();
              }}
            >
              <IconCheckCircle sx={{ color: "#03A758" }}></IconCheckCircle>
              <Typography variant="body1" sx={{ pl: 0.5, color: "#232323" }} fontWeight="500">
                Save
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ProfileComponent;

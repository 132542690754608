import { gql } from "@apollo/client";

const GET_STOCK_AUDIO = gql`
  query stockAudio($id: ID!) {
    stockAudio(id: $id) {
      id
      domain
      locationId
      parentNodeId
      groupId
      name
      type
      typeClass
      media {
        type
        uri
        extUrl
      }
      file {
        name
        directory
        contentType
        signedUrl
        outputHls
        outputDash
        outputMP4
        displayName
      }
      availability {
        from
        to
        status
      }
      desc {
        title
        short
        body
        caption
        website
      }
      status
      rank
      duration
      autoPublish
      categories
      keywords
      data
      updatedAt
      timestamp
    }
  }
`;

const GET_STOCK_AUDIOS = gql`
  query stockAudios($input: StockAudiosInput!) {
    stockAudios(input: $input) {
      total
      stockAudios {
        id
        domain
        locationId
        parentNodeId
        groupId
        name
        type
        typeClass
        media {
          type
          uri
          extUrl
        }
        file {
          name
          directory
          contentType
          signedUrl
          outputHls
          outputDash
          outputMP4
          displayName
        }
        availability {
          from
          to
          status
        }
        desc {
          title
          short
          body
          caption
          website
        }
        status
        rank
        duration
        autoPublish
        categories
        keywords
        data
        updatedAt
        timestamp
      }
    }
  }
`;

export { GET_STOCK_AUDIO, GET_STOCK_AUDIOS };

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllDomains } from "../graphQL/domains";

export const getAllDomains = createAsyncThunk("domain/read_domains", async () => {
  return await apiGetAllDomains();
});

const domainSlice = createSlice({
  name: "Domain",
  initialState: {
    total: 0,
    domains: null,
  },
  reducers: {
    actionUpdateDomain: (state, action) => {
      if (state.domains) {
        if (state.domains.find((item) => item.id === action.payload.id)) {
          state.domains = state.domains.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.domains = [action.payload, ...state.domains];
          state.total = state.domains.length;
        }
      } else {
        state.domains = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteDomain: (state, action) => {
      state.domains = state.domains?.filter((item) => item?.id !== action.payload);
      state.total = state.domains.length;
    },
  },
  extraReducers: {
    [getAllDomains.fulfilled]: (state, action) => {
      state.total = action.payload.total;
      state.domains = action.payload.domains;
    },
    [getAllDomains.rejected]: (state, action) => {
      state.total = 0;
      state.domains = null;
    },
  },
});

export const { actionUpdateDomain, actionDeleteDomain } = domainSlice.actions;
export default domainSlice.reducer;

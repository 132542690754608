import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Close as IconClose,
  Delete as IconDelete,
  PlayArrow as IconPlay,
  Pause as IconPause,
} from "@mui/icons-material";
import VideoPlayer from "../players/VideoPlayer";
import {
  getMediaUrl,
  isImageAsset,
  isAudioAsset,
  isVideoAsset,
} from "../../utils";
import AssetPreviewCustomControls from "../players/AssetPreviewCustomControls";
import WaveForm from "../Waveform";

function DialogAssetPreview({ asset, open, onClose, onDelete }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const playerRef = useRef();
  const [videoPlaying, setVideoPlaying] = useState(true);
  const [playerStatus, setPlayerStatus] = useState({
    loading: false,
    duration: 0,
    playedSeconds: 0,
  });

  useEffect(() => {
    setVideoPlaying(false);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          background: "transparent",
        },
      }}
      fullScreen={fullScreen}
    >
      <DialogContent sx={{ p: 0, position: "relative" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          className="w-full h-full"
          sx={{ overflow: "hidden" }}
        >
          <Box
            sx={{
              width: "100%",
              paddingTop: "58%",
              position: "relative",
            }}
          >
            {!isImageAsset(asset) && !videoPlaying && (
              <Box
                className="icon-replay"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={(e) => {
                  e.stopPropagation();
                  setVideoPlaying(!videoPlaying);
                }}
                sx={{
                  width: { xs: "4rem", sm: "5rem", md: "6rem" },
                  height: { xs: "4rem", sm: "5rem", md: "6rem" },
                  borderRadius: "50%",
                  backgroundColor: "#454545aa",
                  color: "#f2f2f2",
                  cursor: "pointer",
                  left: {
                    xs: "calc( 50% - 2rem )",
                    sm: "calc( 50% - 2.5rem )",
                    md: "calc( 50% - 3rem )",
                  },
                  top: {
                    xs: "calc( 50% - 2rem )",
                    sm: "calc( 50% - 2.5rem )",
                    md: "calc( 50% - 3rem )",
                  },
                  position: "absolute",
                  zIndex: 99,
                }}
              >
                <IconPlay
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "2.5rem", sm: "3rem", md: "3.5rem" },
                  }}
                ></IconPlay>
              </Box>
            )}
            <Box sx={{ position: "absolute", top: 0, left: 0, zIndex: 99 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ p: { xs: 0.5, sm: 1, md: 1.5 } }}
                onClick={() => {
                  onDelete();
                }}
              >
                <IconDelete
                  sx={{
                    color: "#e2e2e2",
                    fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" },
                  }}
                ></IconDelete>
              </Box>
            </Box>
            <Box sx={{ position: "absolute", top: 0, right: 0, zIndex: 99 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ p: { xs: 0.5, sm: 1, md: 1.5 } }}
                onClick={() => {
                  onClose();
                }}
              >
                <IconClose
                  sx={{
                    color: "#e2e2e2",
                    fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" },
                  }}
                ></IconClose>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              {isImageAsset(asset) && (
                <img
                  src={getMediaUrl(asset)}
                  alt="Attachment"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
              {isAudioAsset(asset) && (
                <WaveForm
                  audioUrl={getMediaUrl(asset)}
                  playing={videoPlaying}
                  setPlaying={setVideoPlaying}
                />
              )}
              {isVideoAsset(asset) && (
                <Box
                  sx={{
                    width: "100%",
                    paddingTop: "56.25%",
                    position: "relative",
                    background: "#232323",
                  }}
                >
                  <AssetPreviewCustomControls
                    videoPlaying={videoPlaying}
                    setVideoPlaying={setVideoPlaying}
                    currentAsset={asset}
                    playerStatus={playerStatus}
                    onSeek={(value) => {
                      playerRef?.current?.seekTo(value, "seconds");
                    }}
                  ></AssetPreviewCustomControls>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      zIndex: 30,
                      top: 0,
                      left: 0,
                    }}
                    onClick={() => {
                      if (videoPlaying) {
                        setVideoPlaying(false);
                      }
                    }}
                  >
                    <VideoPlayer
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      playerRef={playerRef}
                      url={getMediaUrl(asset)}
                      playing={videoPlaying}
                      onProgress={(played, playedSeconds) => {
                        if (playerStatus.duration === 0) {
                          setPlayerStatus({
                            ...playerStatus,
                            playedSeconds,
                            loading: false,
                            duration: playerRef.current?.getDuration(),
                          });
                        } else {
                          setPlayerStatus({
                            ...playerStatus,
                            playedSeconds,
                            loading: false,
                          });
                        }
                      }}
                      onDuration={(e) => {
                        setPlayerStatus({
                          ...playerStatus,
                          duration: e,
                          loading: false,
                        });
                      }}
                      onReady={() => {
                        setPlayerStatus({ ...playerStatus, loading: false });
                        setVideoPlaying(true);
                        console.log("Video Ready");
                      }}
                      onEnded={() => {
                        setPlayerStatus({ ...playerStatus, loading: false });
                        setVideoPlaying(false);
                      }}
                      onError={(error) => {
                        setPlayerStatus({ ...playerStatus, loading: false });
                        if (error === "hlsError") {
                          setVideoPlaying(false);
                        }
                        if (error.name === "NotAllowedError") {
                          setVideoPlaying(false);
                        }
                      }}
                      caption={asset?.file?.outputCC}
                    ></VideoPlayer>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DialogAssetPreview;

import { gql } from "@apollo/client";

const GET_ASSET_ANALYTICS = gql`
  query assetAnalytics($input: AssetAnalyticsInput!) {
    assetAnalytics(input: $input) {
      assetAnalytics {
        id
        assetId
        assetSize
        watched
        bookmarked
        unbookmarked
        skipped
        noted
        read
        liked
        disliked
        fullPlayed
        partialPlayed
      }
    }
  }
`;

export { GET_ASSET_ANALYTICS };

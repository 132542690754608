import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogContentText,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Box,
  Chip,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ButtonSave } from "../../components/buttons";
import { useSelector } from "react-redux";
const DialogUserDomainList = ({
  openDialogUserDomainList,
  handleClose,
  form,
  setForm,
  isLoading,
  actionSave,
  publicDomainLists,
  PrivateInfo,
}) => {
  const handleChange = (domain) => {
    const isInclude = form?.value?.filter((item) => item !== domain);
    if (isInclude?.length === form?.value?.length) {
      setForm({ ...form, value: [...form.value, domain] });
    } else {
      setForm({ ...form, value: isInclude });
    }
  };
  
  if (!openDialogUserDomainList) {
    return <></>;
  }
  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={openDialogUserDomainList}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>{form?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>{form?.desc}</DialogContentText>
        <Grid container>
          {publicDomainLists?.map((domain, index) => (
            <Grid item md={6} xs={12} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      form?.value?.filter((item) => item === domain.name)
                        ?.length > 0
                    }
                    onChange={() => {
                      handleChange(domain.name);
                    }}
                  />
                }
                label={domain.name}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <ButtonSave
          loading={isLoading}
          onClick={() => {
            actionSave({
              id: PrivateInfo?.id,
              domainList: form.value === null ? [] : form.value,
            }).then(() => {
              handleClose();
            });
          }}
        ></ButtonSave>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUserDomainList;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import { MoreHoriz as IconMoreHoriz } from "@mui/icons-material";
import moment from "moment";
import { useConfirm } from "material-ui-confirm";

function MyAssetFolderCard({ assetFolder }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const myAssetFolders = useSelector((store) => store.AssetFolder.myAssetFolders);

  const [thumbnailPath, setThumbnailPath] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [folderAnchorEl, setFolderAnchorEl] = React.useState(null);

  const handleFolderMenuClose = () => {
    setFolderAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: 220,
          maxHeight: 220,
          position: "relative",
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          {thumbnailPath && (
            <img
              src={thumbnailPath}
              alt="Preview"
              loading="lazy"
              style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "8px" }}
            ></img>
          )}
        </Box>
      </Box>
      <Box sx={{ mt: 0.5, pl: 0.5 }}>
        <Box>
          <Typography variant="body2">{assetFolder?.file?.displayName}</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
            {moment(parseInt(assetFolder?.timestamp)).fromNow()}
          </Typography>
          <IconButton
            size="small"
            sx={{ p: 0, position: "relative", left: "0.125rem" }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <IconMoreHoriz></IconMoreHoriz>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default MyAssetFolderCard;

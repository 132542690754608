import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllAlertFolders } from "../graphQL/alertFolder";

export const getAllAlertFolders = createAsyncThunk("alertFolder/read_alert_folders", async () => {
  return await apiGetAllAlertFolders({});
});

const alertFolderSlice = createSlice({
  name: "AlertFolder",
  initialState: {
    total: 0,
    alertFolders: null,
  },
  reducers: {
    actionUpdateAlertFolder: (state, action) => {
      if (state.alertFolders) {
        if (state.alertFolders.find((item) => item.id === action.payload.id)) {
          state.alertFolders = state.alertFolders.map((item) =>
            item.id === action.payload.id ? action.payload : item
          );
        } else {
          state.alertFolders = [action.payload, ...state.alertFolders];
          state.total = state.alertFolders.length;
        }
      } else {
        state.alertFolders = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteAlertFolder: (state, action) => {
      state.alertFolders = state.alertFolders?.filter((item) => item?.id !== action.payload);
      state.total = state.alertFolders.length;
    },
    actionDeleteAllAlertFolders: (state, action) => {
      state.alertFolders = state.alertFolders?.filter((t) => t.domain !== action.payload.domain);
      state.total = state.alertFolders?.length;
    },
  },
  extraReducers: {
    [getAllAlertFolders.fulfilled]: (state, action) => {
      state.total = action.payload.total;
      state.alertFolders = action.payload.alertFolders;
    },
    [getAllAlertFolders.rejected]: (state, action) => {
      state.total = 0;
      state.alertFolders = null;
    },
  },
});

export const { actionUpdateAlertFolder, actionDeleteAlertFolder, actionDeleteAllAlertFolders } =
  alertFolderSlice.actions;
export default alertFolderSlice.reducer;

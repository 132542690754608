import client from "../index.js";
import { GET_ASSET_ANALYTICS } from "./query.js";
import {
  INC_LIKED_ASSET,
  INC_DISLIKED_ASSET,
  INC_WATCHED_ASSET,
  INC_BOOKMARKED_ASSET,
  INC_UNBOOKMARKED_ASSET,
  INC_NOTED_ASSET,
  INC_READ_ASSET,
  INC_FULL_PLAYED,
  INC_PARTIAL_PLAYED,
} from "./mutation";

const apiGetAssetAnalytics = (assetId) => {
  return client
    .query({
      query: GET_ASSET_ANALYTICS,
      variables: {
        input: {
          assetId,
        },
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.assetAnalytics.assetAnalytics;
    });
};

const apiAssetAnalyticsLikeAsset = (id) => {
  return client
    .mutate({
      mutation: INC_LIKED_ASSET,
      variables: {
        input: {
          assetId: id,
        },
      },
    })
    .then((res) => {
      return res.data.incLikedAsset.assetAnalytics;
    });
};

const apiAssetAnalyticsDislikeAsset = (id) => {
  return client
    .mutate({
      mutation: INC_DISLIKED_ASSET,
      variables: {
        input: {
          assetId: id,
        },
      },
    })
    .then((res) => {
      return res.data.incDislikedAsset.assetAnalytics;
    });
};

const apiAssetAnalyticsWatchedAsset = (id) => {
  return client
    .mutate({
      mutation: INC_WATCHED_ASSET,
      variables: {
        input: {
          assetId: id,
        },
      },
    })
    .then((res) => {
      return res.data.incWatchedAsset.assetAnalytics;
    });
};

const apiAssetAnalyticsBookmarkedAsset = (id) => {
  return client
    .mutate({
      mutation: INC_BOOKMARKED_ASSET,
      variables: {
        input: {
          assetId: id,
        },
      },
    })
    .then((res) => {
      return res.data.incBookmarkedAsset.assetAnalytics;
    });
};

const apiAssetAnalyticsUnbookmarkedAsset = (id) => {
  return client
    .mutate({
      mutation: INC_UNBOOKMARKED_ASSET,
      variables: {
        input: {
          assetId: id,
        },
      },
    })
    .then((res) => {
      return res.data.incUnbookmarkedAsset.assetAnalytics;
    });
};

const apiAssetAnalyticsNotedAsset = (id) => {
  return client
    .mutate({
      mutation: INC_NOTED_ASSET,
      variables: {
        input: {
          assetId: id,
        },
      },
    })
    .then((res) => {
      return res.data.incNotedAsset.assetAnalytics;
    });
};

const apiAssetAnalyticsReadAsset = (id) => {
  return client
    .mutate({
      mutation: INC_READ_ASSET,
      variables: {
        input: {
          assetId: id,
        },
      },
    })
    .then((res) => {
      return res.data.incReadAsset.assetAnalytics;
    });
};

const apiAssetAnalyticsFullPlayed = (id) => {
  return client
    .mutate({
      mutation: INC_FULL_PLAYED,
      variables: {
        input: {
          assetId: id,
        },
      },
    })
    .then((res) => {
      return res.data.incFullPlayedAsset.assetAnalytics;
    });
};

const apiAssetAnalyticsPartialPlayed = (id) => {
  return client
    .mutate({
      mutation: INC_PARTIAL_PLAYED,
      variables: {
        input: {
          assetId: id,
        },
      },
    })
    .then((res) => {
      return res.data.incPartialPlayedAsset.assetAnalytics;
    });
};

export {
  apiGetAssetAnalytics,
  apiAssetAnalyticsLikeAsset,
  apiAssetAnalyticsDislikeAsset,
  apiAssetAnalyticsWatchedAsset,
  apiAssetAnalyticsBookmarkedAsset,
  apiAssetAnalyticsUnbookmarkedAsset,
  apiAssetAnalyticsNotedAsset,
  apiAssetAnalyticsReadAsset,
  apiAssetAnalyticsFullPlayed,
  apiAssetAnalyticsPartialPlayed,
};

import React from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, ThemeProvider } from "@mui/material";
import { lightTheme } from "../../libs/theme";

function DialogReservationReply({ isOpen, setOpen, reply, setReply = () => {}, onReply = () => {} }) {
  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle> Reply </DialogTitle>
        <DialogContent>
          <TextField fullWidth value={reply} multiline={true} rows={1} onChange={(e) => {
            setReply(e.target.value);
          }}></TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onReply();
            }}
          >
            Reply
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default DialogReservationReply;

import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import {
  Bookmark as IconBookmark,
  BookmarkBorder as IconBookmarkBorder,
  Comment as IconComment,
  CommentOutlined as IconCommentOutlined,
  Person as IconProfile,
  CancelRounded as IconCancel,
} from "@mui/icons-material";
import BookmarkMenu from "../../../components/menus/BookmarkMenu";
import NoteCreateModal from "../../../components/modals/NoteCreateModal";
import { getAssetAvatarPath, getUserThumbnailPath } from "../../../utils";
import { apiUpdateUserFolder } from "../../../graphQL/userFolder";
import { actionUpdateUserFolder } from "../../../store/UserFolder.slice";
import { FollowButton } from "../../buttons/CardButtons";
import { v4 as uuid_v4 } from "uuid";
import "./AssetCard.scss";
import { updateCurrentUser } from "../../../store/auth.slice";
import { actionUpdateUser } from "../../../store/User.slice";
import { apiUpdateUser } from "../../../graphQL/user";
import useAssetNote from "../../../hooks/useAssetNote";

const AssetCard = ({ asset, playerStatus, enableBookmark, onClose }) => {
  const dispatch = useDispatch();

  const [{ assetNote }, { setAssetNote, updateAssetNote }] = useAssetNote(asset?.id);

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const users = useSelector((store) => store.User.users);
  const userFolders = useSelector((store) => store.UserFolder.userFolders);
  const bookmarkButtonRef = useRef(null);

  const Followed = PrivateInfo?.subscriptions?.includes(asset?.owner);

  const [assetUser, setAssetUser] = useState(null);
  const [openBookmarkMenu, setOpenBookmarkMenu] = useState(false);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);

  const isBookmarked = (asset) => {
    if (!userFolders || userFolders?.length === 0) return false;
    return userFolders?.some((userFolder) => {
      return userFolder?.assetIds?.map((t) => t.id).includes(asset?.id);
    });
  };

  const addAssetToDefaultBookmarkFolder = async (asset) => {
    const defaultFolder = userFolders?.length > 0 ? userFolders[0] : null;
    if (defaultFolder) {
      addAssetBookmark(defaultFolder, asset);
    }
  };

  const addAssetBookmark = async (userFolder, asset) => {
    if (userFolder) {
      let res = await apiUpdateUserFolder({
        id: userFolder?.id,
        assetIds: [
          ...(userFolder?.assetIds
            ?.filter((t) => t.id !== asset?.id)
            .map((t) => ({
              id: t?.id,
              note: t?.note,
            })) || []),
          { id: asset?.id, note: "" },
        ],
      });
      dispatch(actionUpdateUserFolder(res));
    }
  };

  const removeAssetBookmark = async (asset) => {
    const userFolder = userFolders?.find((userFolder) => {
      return userFolder?.assetIds?.map((t) => t.id).includes(asset?.id);
    });
    if (userFolder) {
      let res = await apiUpdateUserFolder({
        id: userFolder?.id,
        assetIds: userFolder?.assetIds
          ?.filter((t) => t.id !== asset?.id)
          .map((t) => ({
            id: t?.id,
            note: t?.note,
          })),
      });
      dispatch(actionUpdateUserFolder(res));
    }
  };

  useEffect(() => {
    if (asset && users) {
      setAssetUser(users?.find((user) => user.id === asset?.owner));
    } else {
      setAssetUser(null);
    }
  }, [users, asset]);

  return (
    <Box
      className={clsx("asset-card-container")}
      sx={{
        width: "100%",
        maxWidth: "600px",
        position: "relative",
      }}
    >
      <Box sx={{ position: "absolute", top: -8, right: -8, display: { xs: "none", sm: "block" } }}>
        <IconButton
          // sx={{ padding: 0 }}
          onClick={() => {
            onClose();
          }}
          sx={{ padding: 0, margin: 0, color: "#232323" }}
        >
          <IconCancel />
        </IconButton>
      </Box>
      <Box sx={{ background: "#D9D9D9", borderRadius: 1 }}>
        <Box display="flex" alignItems="center" sx={{ px: 0.5, py: 0.5, color: "#232323" }}>
          <Avatar src={getUserThumbnailPath(assetUser?.avatar)} sx={{ width: "1.75rem", height: "1.75rem" }}>
            <IconProfile sx={{ color: "#fff" }}></IconProfile>
          </Avatar>
          <Box flexGrow={1} sx={{ pl: 0.5, color: "#232323" }}>
            <Typography variant="body2" fontWeight={500}>
              {assetUser?.username}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", pr: 2.5 }}>
            {PrivateInfo?.id !== asset?.owner && (
              <FollowButton
                followed={Followed}
                onClick={() => {
                  apiUpdateUser({
                    id: PrivateInfo?.id,
                    subscriptions: Followed
                      ? PrivateInfo?.subscriptions?.filter((t) => t !== asset?.owner)
                      : [...(PrivateInfo?.subscriptions || []), asset?.owner],
                  }).then((res) => {
                    dispatch(updateCurrentUser(res));
                    dispatch(actionUpdateUser(res));
                  });
                }}
              ></FollowButton>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="stretch" sx={{ p: 1, background: "#575757" }}>
          {getAssetAvatarPath(asset) && (
            <Box sx={{ width: "6rem", height: "6rem", background: "#D9D9D9", borderRadius: 1, mr: 1 }}>
              <Avatar sx={{ width: "6rem", height: "6rem" }} src={getAssetAvatarPath(asset)} variant="rounded"></Avatar>
            </Box>
          )}
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            flexGrow={1}
            sx={{
              minHeight: "6rem",
            }}
          >
            <Typography variant="body1" fontWeight={500}>
              {asset?.desc?.title}
            </Typography>
            <Box flexGrow="1">
              <Typography variant="body2">{asset?.desc?.short}</Typography>
            </Box>
            <Box className="flex items-center justify-content-end w-full"></Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pl: 1, color: "#1E1E1E" }}>
          <Typography variant="body2" fontSize="0.75rem">
            {moment(parseFloat(asset?.timestamp)).utc().fromNow()}
          </Typography>
          <Box className="flex items-center">
            {playerStatus && assetNote?.notes?.length > 0 && (
              <IconButton
                sx={{ color: "#232323" }}
                size="small"
                onClick={async () => {
                  if (assetNote?.notes?.length > 0) {
                    addAssetToDefaultBookmarkFolder(asset);
                    setOpenNoteDialog(true);
                  }
                }}
              >
                {assetNote && assetNote?.notes?.length > 0 ? (
                  <IconComment></IconComment>
                ) : (
                  <IconCommentOutlined></IconCommentOutlined>
                )}
              </IconButton>
            )}
            {enableBookmark && (
              <>
                <IconButton
                  sx={{ color: "#232323" }}
                  size="small"
                  onClick={() => {
                    if (isBookmarked(asset)) {
                      removeAssetBookmark(asset).then(() => {
                        setOpenBookmarkMenu(false);
                      });
                    } else {
                      setOpenBookmarkMenu(true);
                    }
                  }}
                  ref={bookmarkButtonRef}
                >
                  {isBookmarked(asset) ? <IconBookmark></IconBookmark> : <IconBookmarkBorder></IconBookmarkBorder>}
                </IconButton>
                <BookmarkMenu
                  open={openBookmarkMenu}
                  onClose={() => {
                    setOpenBookmarkMenu(false);
                  }}
                  anchorEl={openBookmarkMenu ? bookmarkButtonRef?.current : null}
                  onBookmark={(userFolder) => {
                    addAssetBookmark(userFolder, asset);
                  }}
                ></BookmarkMenu>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {playerStatus && (
        <NoteCreateModal
          open={openNoteDialog}
          onClose={() => {
            setAssetNote({
              ...assetNote,
              notes: assetNote?.notes?.filter((t) => !!t?.note?.text),
            });
            setOpenNoteDialog(false);
          }}
          noteItems={assetNote?.notes}
          onChange={(notes) => {
            setAssetNote({
              ...assetNote,
              notes,
            });
          }}
          onSave={() => {
            updateAssetNote(assetNote);
            setOpenNoteDialog(false);
          }}
          onDelete={(uId) => {
            const newAssetNote = {
              ...(assetNote || {}),
              notes: assetNote.notes.filter((t) => t.uId !== uId),
            };
            updateAssetNote(newAssetNote);
            if (newAssetNote?.notes?.length === 0) {
              setOpenNoteDialog(false);
            }
          }}
          assetId={asset?.id}
          currentAsset={asset}
        ></NoteCreateModal>
      )}
    </Box>
  );
};

AssetCard.defaultProps = {
  enableCardAction: true,
  hideMoreLikeThis: false,
  enableBookmark: true,
  enableStar: true,
};

export default AssetCard;

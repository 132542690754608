import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllAssets } from "../graphQL/asset";
import { isAudioAsset, isImageAsset } from "../utils";
import { isEmpty, upsertObjectToArray } from "../utils/helpers";

export const getAllAssets = createAsyncThunk("asset/read_assets", async (filter) => {
  filter = {
    ...filter,
    ordering: {
      field: "timestamp",
      order: "Desc",
    },
    pagination: {
      page: 1,
      limit: 32768,
    },
  };
  return await apiGetAllAssets(filter);
});

export const getAllMyAssets = createAsyncThunk("asset/read_my_assets", async (data, { getState }) => {
  const userId = getState().auth.userId;
  return await apiGetAllAssets({
    owner: userId,
    ordering: {
      field: "timestamp",
      order: "Desc",
    },
    pagination: {
      page: 1,
      limit: 32768,
    },
  });
});

export const getAllOthersAssets = createAsyncThunk("asset/read_others_assets", async (filter) => {
  return await apiGetAllAssets({
    ...filter,
    ordering: {
      field: "timestamp",
      order: "Desc",
    },
    pagination: {
      page: 1,
      limit: 32768,
    },
  });
});

export const reloadConvertingAssets = createAsyncThunk("asset/reload_converting_assets", async (filter) => {
  return await apiGetAllAssets(filter);
});
const assetSlice = createSlice({
  name: "Asset",
  initialState: {
    total: 0,
    totalMyAssets: 0,
    assets: null,
    myAssets: null,
  },
  reducers: {
    actionUpdateAsset: (state, action) => {
      const newAsset = action.payload;
      console.log(newAsset);
      if (["Ready", "Published", "Converted"].includes(newAsset?.status)) {
        if (state.assets) {
          if (state.assets.find((item) => item.id === action.payload.id)) {
            state.assets = state.assets.map((item) => (item.id === action.payload.id ? action.payload : item));
          } else {
            state.assets = [action.payload, ...state.assets];
            state.total = state.assets.length;
          }
        }
      }
    },
    actionUpdateMyAsset: (state, action) => {
      const newAsset = action.payload;
      if (state.myAssets) {
        state.myAssets = upsertObjectToArray(state.myAssets, newAsset);
      }
      if (state.assets && newAsset?.status === "Ready" && newAsset?.highlight) {
        state.assets = upsertObjectToArray(state.assets, newAsset);
      }
    },
    actionDeleteAsset: (state, action) => {
      state.assets = state.assets?.filter((item) => item?.id !== action.payload);
      state.total = state.assets.length;
    },
    actionDeleteMyAsset: (state, action) => {
      state.myAssets = state.myAssets?.filter((item) => item?.id !== action.payload);
    },
    actionDeleteAllAssets: (state, action) => {
      state.assets = state.assets?.filter((t) => t.domain !== action.payload.domain);
      state.total = state.assets?.length;
    },
  },
  extraReducers: {
    [getAllAssets.fulfilled]: (state, action) => {
      state.assets = action.payload?.mediaAssets.filter((t) => {
        // if (t.status !== "Ready") return false;
        //if (!isEmpty(t?.extVideoURL)) return true;
        if (isImageAsset(t) || isAudioAsset(t)) return true;
        if (!isEmpty(t?.file?.outputHls)) return true;
        return false;
      });
      state.total = state.assets?.length;
    },
    [getAllAssets.rejected]: (state, action) => {
      state.total = 0;
      state.assets = null;
    },
    [getAllMyAssets.fulfilled]: (state, action) => {
      state.myAssets = action.payload?.mediaAssets?.filter((t) => t.status !== "deleting");
    },
    [getAllMyAssets.rejected]: (state, action) => {
      state.myAssets = [];
    },
    [getAllOthersAssets.fulfilled]: (state, action) => {
      state.myAssets = action.payload?.mediaAssets?.filter((t) => t.status !== "deleting");
    },
    [getAllOthersAssets.rejected]: (state, action) => {
      state.myAssets = [];
    },
    [reloadConvertingAssets.fulfilled]: (state, action) => {
      if (!state.myAssets) return state;
      let newAssets = [
        ...action.payload.mediaAssets?.filter((t) => t.status !== "deleting"),
        ...state.myAssets?.filter((t) => {
          return !action.payload?.mediaAssets?.find((asset) => asset.id === t.id);
        }),
      ];
      return {
        ...state,
        myAssets: newAssets,
        totalMyAssets: newAssets?.length,
      };
    },
    [reloadConvertingAssets.rejected]: (state, action) => {
      state.myAssets = [];
    },
  },
});

export const { actionUpdateAsset, actionUpdateMyAsset, actionDeleteAsset, actionDeleteMyAsset, actionDeleteAllAssets } =
  assetSlice.actions;
export default assetSlice.reducer;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import { ReadMore as IconReadMore } from "@mui/icons-material";
import { useAppContext } from "../../../contexts/AppContext";

import "./AlertFolderCard.scss";

const AlertFolderCard = ({ alertFolder }) => {
  const alertAssets = useSelector((store) => store.AlertAsset.alertAssets);
  const [{ visibleAlertFolderSidebar }, { setNewAlertFolder, setVisibleAlertFolderSidebar }] = useAppContext();

  useEffect(() => {
    if (!alertAssets?.filter((t) => t.parentNode === alertFolder?.id)?.length) {
      const timer = setTimeout(() => {
        setNewAlertFolder(null);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alertAssets, alertFolder]); //eslint-disable-line

  return (
    <Box
      className={clsx("alert-folder-card-container")}
      sx={{
        width: "100%",
      }}
    >
      <div className="alert-folder-card">
        <div
          className="alert-folder-card-header"
          onClick={() => {
            // if (enableCardAction) onAlertFolderCardClicked();
          }}
        ></div>
        <div className="alert-folder-card-body">
          <div className="alert-folder-card-desc-title">{alertFolder?.desc?.title}</div>
          <div className="alert-folder-card-desc-short">{alertFolder?.desc?.short}</div>
        </div>
        <div className="alert-folder-card-action">
          <div className="alert-folder-card-action__left">
            <div className="alert-folder-card-action__time">
              {alertFolder?.timestamp && parseFloat(alertFolder?.timestamp) && (
                <Typography variant="body2" color={"#a2a2a2"}>
                  {moment(parseFloat(alertFolder?.timestamp)).utc().fromNow()}
                </Typography>
              )}
            </div>
          </div>
          <div className="alert-folder-card-action__right">
            <div className="alert-folder-card-action__icon">
              {alertAssets?.filter((t) => t.parentNode === alertFolder?.id)?.length > 0 && (
                <Button
                  size="small"
                  color="primary"
                  startIcon={<IconReadMore></IconReadMore>}
                  onClick={() => {
                    if (!visibleAlertFolderSidebar) {
                      setVisibleAlertFolderSidebar(true);
                    } else {
                      setVisibleAlertFolderSidebar(false);
                      setNewAlertFolder(null);
                    }
                  }}
                >
                  More
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

AlertFolderCard.defaultProps = {};

export default AlertFolderCard;

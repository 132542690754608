import React from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { actionCardMenuPaperProps } from "../../utils/styles";

function MoreOptionMenu({ menus, anchorEl, onClose }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      PaperProps={actionCardMenuPaperProps}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {menus
        .filter((t) => !!t)
        .map((menu, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (!menu?.disabled) {
                menu.onClick && menu.onClick();
              }
            }}
            disabled={!!menu?.disabled}
            dense
            sx={{
              px: 1,
            }}
          >
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText>{menu.title}</ListItemText>
          </MenuItem>
        ))}
    </Menu>
  );
}

MoreOptionMenu.defaultProps = { menus: [], anchorEl: null };

export default MoreOptionMenu;

import React from "react";
import { Drawer } from "@mui/material";
import CreatorCard from "../../CustomCards/CreatorCard/CreatorCard";
import { Popover } from "@mui/material";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import { assetCardPaperPropsLeft } from "../../../utils/styles";

function CreatorDrawerWrapper({ open, onClose, anchorEl, children, ...props }) {
  const [{ isSmScreen }] = useLayoutContext();
  const paddingWidth = window.innerWidth > 1200 ? (window.innerWidth - 1200) / 2 : 0;

  if (!isSmScreen) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          sx: {
            bgcolor: "#000",
          },
        }}
      >
        {children}
      </Drawer>
    );
  }

  return (
    <Popover
      PaperProps={assetCardPaperPropsLeft}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      onClose={onClose}
      style={{ marginLeft: paddingWidth }}
    >
      {children}
    </Popover>
  );
}

function CreatorDrawer({ open, onClose, creator, anchorEl }) {
  return (
    <CreatorDrawerWrapper open={open} onClose={onClose} anchorEl={anchorEl}>
      <CreatorCard creator={creator} onClose={onClose}></CreatorCard>
    </CreatorDrawerWrapper>
  );
}

export default CreatorDrawer;

import client from "../index.js";
import { GET_ALERT_FOLDERS } from "./query.js";
import { CREATE_ALERT_FOLDER, UPDATE_ALERT_FOLDER, DELETE_ALERT_FOLDER, DELETE_ALL_ALERT_FOLDERS } from "./mutation";

export const apiGetAllAlertFolders = (filter) => {
  return client
    .query({
      query: GET_ALERT_FOLDERS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.alertFolders;
    });
};

export const apiCreateAlertFolder = (data) => {
  return client
    .mutate({
      mutation: CREATE_ALERT_FOLDER,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createAlertFolder.alertFolder;
    });
};

export const apiUpdateAlertFolder = (data) => {
  return client
    .mutate({
      mutation: UPDATE_ALERT_FOLDER,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateAlertFolder.alertFolder;
    });
};

export const apiDeleteAlertFolder = (id) => {
  return client
    .mutate({
      mutation: DELETE_ALERT_FOLDER,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAlertFolder.id;
    });
};

export const apiDeleteAllAlertFolders = (filter = {}) => {
  return client
    .mutate({
      mutation: DELETE_ALL_ALERT_FOLDERS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAllAlertFolders?.total;
    });
};

import { gql } from "@apollo/client";

const CREATE_STOCK_AUDIO = gql`
  mutation createStockAudio($input: CreateStockAudioInput!) {
    createStockAudio(input: $input) {
      stockAudio {
        id
        domain
        locationId
        parentNodeId
        groupId
        name
        type
        typeClass
        media {
          type
          uri
          extUrl
        }
        file {
          name
          directory
          contentType
          signedUrl
          outputHls
          outputDash
          outputMP4
          displayName
        }
        status
        availability {
          from
          to
          status
        }
        desc {
          title
          short
          body
          caption
          website
        }
        rank
        duration
        autoPublish
        categories
        keywords
        data
        updatedAt
        timestamp
      }
    }
  }
`;

const UPDATE_STOCK_AUDIO = gql`
  mutation updateStockAudio($input: UpdateStockAudioInput!) {
    updateStockAudio(input: $input) {
      stockAudio {
        id
        domain
        locationId
        parentNodeId
        groupId
        name
        type
        typeClass
        media {
          type
          uri
          extUrl
        }
        file {
          name
          directory
          contentType
          signedUrl
          outputHls
          outputDash
          outputMP4
          displayName
        }
        desc {
          title
          short
          body
          caption
          website
        }
        availability {
          from
          to
          status
        }
        status
        rank
        duration
        autoPublish
        categories
        keywords
        data
        updatedAt
        timestamp
      }
    }
  }
`;

const DELETE_STOCK_AUDIO = gql`
  mutation deleteStockAudio($id: ID!) {
    deleteStockAudio(id: $id) {
      id
    }
  }
`;

const DELETE_ALL_STOCK_AUDIOS = gql`
  mutation deleteAllStockAudios($input: DeleteAllInput!) {
    deleteAllStockAudios(input: $input) {
      total
    }
  }
`;

export { CREATE_STOCK_AUDIO, UPDATE_STOCK_AUDIO, DELETE_STOCK_AUDIO, DELETE_ALL_STOCK_AUDIOS };

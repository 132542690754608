import client from "../index.js";
import { GET_CATEGORYS } from "./query.js";
import { CREATE_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY, DELETE_ALL_CATEGORYS } from "./mutation";

export const apiGetAllCategories = (filter = {}) => {
  return client
    .query({
      query: GET_CATEGORYS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.categories;
    });
};

export const apiCreateCategory = (data) => {
  return client
    .mutate({
      mutation: CREATE_CATEGORY,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createCategory.category;
    });
};

export const apiUpdateCategory = (data) => {
  return client
    .mutate({
      mutation: UPDATE_CATEGORY,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateCategory.category;
    });
};

export const apiDeleteCategory = (id) => {
  return client
    .mutate({
      mutation: DELETE_CATEGORY,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteCategory.id;
    });
};

export const apiDeleteAllCategories = (filter = {}) => {
  return client
    .mutate({
      mutation: DELETE_ALL_CATEGORYS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAllCategories?.total;
    });
};

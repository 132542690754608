import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  apiCreateAssetFolder,
  apiGetAllAssetFolders,
} from "../graphQL/assetFolder";
import { apiUpdateUser } from "../graphQL/user";
import { updateCurrentUser } from "./auth.slice";
import { actionUpdateUser } from "./User.slice";

export const getAllAssetFolders = createAsyncThunk(
  "assetFolder/read_asset_folders",
  async (filter) => {
    filter = {
      ...filter,
      ordering: {
        field: "updatedAt",
        order: "Desc",
      },
      pagination: {
        page: 1,
        limit: 32768,
      },
    };
    return await apiGetAllAssetFolders(filter);
  }
);

export const getAllOthersAssetFolders = createAsyncThunk(
  "assetFolder/read_others_asset_folders",
  async (filter) => {
    filter = {
      ...filter,
      ordering: {
        field: "updatedAt",
        order: "Desc",
      },
      pagination: {
        page: 1,
        limit: 32768,
      },
    };
    return await apiGetAllAssetFolders(filter);
  }
);

export const getAllMyAssetFolders = createAsyncThunk(
  "assetFolder/read_my_asset_folders",
  async (data, { getState, dispatch }) => {
    const PrivateInfo = getState().auth.PrivateInfo;
    const userId = getState().auth.userId;
    return await apiGetAllAssetFolders({
      owner: userId,
    })
      .then(async (res) => {
        if (res?.mediaFolders?.length > 0) {
          return res;
        }
        const assetFolder = await apiCreateAssetFolder({
          domain: PrivateInfo.domain,
          locationId: PrivateInfo.locationId,
          owner: userId,
          name: "default",
          // rank: new Date().valueOf(),
        });
        await apiUpdateUser({
          id: userId,
          defaultMediaFolderId: assetFolder.id,
        }).then((res) => {
          dispatch(updateCurrentUser(res));
          dispatch(actionUpdateUser(res));
        });
        return { total: 1, assetFolders: [assetFolder] };
      })
      .catch((err) => {
        return null;
      });
  }
);
const assetFolderSlice = createSlice({
  name: "AssetFolder",
  initialState: {
    total: 0,
    assetFolders: null,
  },
  reducers: {
    actionUpdateAssetFolder: (state, action) => {
      if (state.assetFolders) {
        if (state.assetFolders.find((item) => item.id === action.payload.id)) {
          state.assetFolders = state.assetFolders.map((item) =>
            item.id === action.payload.id ? action.payload : item
          );
        } else {
          state.assetFolders = [action.payload, ...state.assetFolders];
          state.total = state.assetFolders.length;
        }
      } else {
        state.assetFolders = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteAssetFolder: (state, action) => {
      state.assetFolders = state.assetFolders?.filter(
        (item) => item?.id !== action.payload
      );
      state.total = state.assetFolders.length;
    },
    actionDeleteAllAssetFolders: (state, action) => {
      state.assetFolders = state.assetFolders?.filter(
        (t) => t.domain !== action.payload.domain
      );
      state.total = state.assetFolders?.length;
    },
  },
  extraReducers: {
    [getAllAssetFolders.fulfilled]: (state, action) => {
      state.total = action.payload.total;
      state.assetFolders = action.payload.mediaFolders;
    },
    [getAllAssetFolders.rejected]: (state, action) => {
      state.total = 0;
      state.assetFolders = null;
    },

    [getAllMyAssetFolders.fulfilled]: (state, action) => {
      state.myAssetFolders = action.payload?.mediaFolders;
    },
    [getAllMyAssetFolders.rejected]: (state, action) => {
      state.myAssetFolders = null;
    },
    [getAllOthersAssetFolders.fulfilled]: (state, action) => {
      state.myAssetFolders = action.payload.mediaFolders;
    },
    [getAllOthersAssetFolders.rejected]: (state, action) => {
      state.myAssetFolders = null;
    },
  },
});

export const {
  actionUpdateAssetFolder,
  actionDeleteAssetFolder,
  actionDeleteAllAssetFolders,
} = assetFolderSlice.actions;
export default assetFolderSlice.reducer;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import VerificationCodeInput from "../../components/verify/VerificationCodeInput";
import { Typography, Button, Box, Alert } from "@mui/material";
import { Auth } from "aws-amplify";
import { apiUpdateUser } from "../../graphQL/user";
import { updateCurrentUser } from "../../store/auth.slice";
import { actionUpdateUser } from "../../store/User.slice";
import { GUEST } from "../../config";

function PageLocationVerification() {
  const dispatch = useDispatch();

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const domains = useSelector((store) => store.Domain.domains);
  const locations = useSelector((store) => store.Location.locations);

  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    if (value?.length < 6) return;
    let location = locations?.find((t) => t.authCode && t.authCode === value);
    let domain = domains?.find((t) => t.authCode && t.authCode === value);
    if (location || domain) {
      let variables = {};
      if (domain) {
        variables = {
          id: PrivateInfo?.id,
          domain: domain.name,
          access: domain.accessList,
          activated: true,
          role: 'sysadmin'
        }
      } else {
        let domain = domains?.find((t) => t.name === location.domain);
        variables = { 
          id: PrivateInfo?.id,
          domain: domain.name,
          access: domain.accessList,
          activated: true,
          locationId: location.id,
          role: domain.userRole || 'creator',
        }
      }
      setSuccess(true);
      setMessage(
        `Your domain was verified successfully. Domain : ${domain.name}`
      );

      apiUpdateUser(variables).then((res) => {
        dispatch(updateCurrentUser(res));
        dispatch(actionUpdateUser(res));
      });
    } else {
      setSuccess(false);
      setMessage(`Unknown domain. Please re-enter your verification code.`);
      setValue("");
    }
  }, [PrivateInfo?.id, dispatch, domains, value]);

  useEffect(() => {
    if (PrivateInfo?.id) {
      if (PrivateInfo.name === GUEST.email) {
        console.log(PrivateInfo);
        const domain = domains?.find((t) => t.name === window.location.hostname) || domains?.find((t) => t.name === 'Test');
        const location = locations?.find((loc) => loc.domain === domain?.name && loc.name.toLocaleLowerCase() === 'default');
        dispatch(updateCurrentUser({
          ...PrivateInfo,
          domain: domain?.name,
          access: domain?.accessList,
          locationId: location?.id,
          activated: true,
        }));
      } else {
        let urlDomain = domains?.find((t) => t.name === window.location.hostname) || domains?.find((t) => t.name === 'Test');
        if (urlDomain ) {
          let variables = {
            id: PrivateInfo?.id,
            domain: urlDomain.name,
            access: urlDomain.accessList,
            activated: true,
            role: 'sysadmin'
          }
          apiUpdateUser(variables).then((res) => {
            dispatch(updateCurrentUser(res));
            dispatch(actionUpdateUser(res));
          });
        }
      }
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem",
        backgroundColor: "#000000",
      }}
    >
      <Typography variant="h4" gutterBottom textAlign={"center"} mb={2}>
        Verify your location
      </Typography>
      <Typography variant="h5" gutterBottom textAlign={"center"} mb={3}>
        Please enter your location verification code(6 digits)
      </Typography>
      <Typography variant="h6" gutterBottom mb={4} textAlign="center">
        <VerificationCodeInput
          value={value}
          setValue={setValue}
          autoFocus={true}
        ></VerificationCodeInput>
      </Typography>
      {message && (
        <Box mb={4}>
          <Alert severity={isSuccess ? "success" : "error"}>{message}</Alert>
        </Box>
      )}
      <Button
        variant="contained"
        onClick={() => {
          Auth.signOut();
        }}
      >
        Go to Login Page
      </Button>
      <Typography variant="body2" align="center" sx={{ mt: 3 }}>
        Copyright ©2023 OpenZNet, Inc. All Rights Reserved.
      </Typography>
    </Box>
  );
}

export default PageLocationVerification;

import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography, AppBar, Toolbar } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Public404 from "../../layouts/pages/Public404";
import { apiGetAsset } from "../../graphQL/asset";
import { ArrowForwardIos as IconArrowForwardIos } from "@mui/icons-material";
import AssetPlayer from "../player/AssetPlayer";

function PagePublicAsset() {
  const [isLoading, setLoading] = useState(true);
  const [params, setParams] = useSearchParams();
  const [currentAsset, setCurrentAsset] = useState(null);
  const assetId = params.get("s");

  useEffect(() => {
    apiGetAsset(assetId)
      .then((res) => {
        setCurrentAsset(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setLoading(false);
      });
  }, [assetId]);

  if (!assetId || (!isLoading && !currentAsset)) return <Public404></Public404>;

  return (
    <Box
      className="public-asset-wrapper"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box sx={{ height: "calc(100% - 50px)" }}>
        <AssetPlayer
          playList={[currentAsset]}
          currentIndex={0}
          currentAsset={currentAsset}
          onVideEnded={() => {}}
        ></AssetPlayer>
      </Box>
      <Box className="bottom-navigation-bar">
        <AppBar position="static">
          <Toolbar variant="dense">
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
              <Typography variant="h6" color="inherit" component="div">
                More content like this
              </Typography>
              <IconButton edge="end" color="inherit" aria-label="menu">
                <IconArrowForwardIos />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </Box>
  );
}

export default PagePublicAsset;

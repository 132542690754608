import React, { useReducer, useContext, useMemo, createContext } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Storage, { getAssets } from "../libs/storage";

const AppContext = createContext();

export function useAppContext() {
  return useContext(AppContext);
}

function reducer(state, { type, payload }) {
  return {
    ...state,
    [type]: payload,
  };
}

const INIT_STATE = {
  domain: null,
  domainInfo: null,
  openAssetFolderModal: false,
  volume: 0,
  newAlertFolder: null,
  visibleAlertFolderSidebar: false,
  selectedAlertAsset: null,
  selectedChannel: null,
  statusOfAssets: getAssets(),
  isFirstLoading: true,

  assetNote: null,
  openComponent: false,
};

export default function AppProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const userDomain = useSelector((store) => store.auth.PrivateInfo?.domain);
  const domains = useSelector((store) => store.Domain.domains);

  const setOpenAssetFolderModal = (value) => dispatch({ type: "openAssetFolderModal", payload: value });
  const setVolume = (value) => dispatch({ type: "volume", payload: value });
  const setNewAlertFolder = (value) => dispatch({ type: "newAlertFolder", payload: value });
  const setVisibleAlertFolderSidebar = (value) => dispatch({ type: "visibleAlertFolderSidebar", payload: value });

  const setSelectedChannel = (value) => dispatch({ type: "selectedChannel", payload: value });
  const setSelectedAlertAsset = (value) => dispatch({ type: "selectedAlertAsset", payload: value });
  const setDomain = (value) => dispatch({ type: "domain", payload: value });
  const setDomainInfo = (value) => dispatch({ type: "domainInfo", payload: value });
  const setFirstLoading = (value) => dispatch({ type: "isFirstLoading", payload: value });
  const setAssetNote = (value) => dispatch({ type: "assetNote", payload: value });
  const setOpenComponent = (value) => dispatch({type: "openComponent", payload: value});

  const actionLikeAnAsset = (assetId, status) => {
    dispatch({ type: "statusOfAssets", payload: Storage.setAssetLiked(assetId, status) });
  };
  const actionDislikeAnAsset = (assetId, status) => {
    dispatch({ type: "statusOfAssets", payload: Storage.setAssetDisliked(assetId, status) });
  };

  useEffect(() => {
    if (userDomain) setDomain(userDomain);
    else setDomain("Admin");
  }, [userDomain]);

  useEffect(() => {
    if (domains && state.domain) setDomainInfo(domains.find((t) => t.name === state.domain));
  }, [domains, state.domain]);

  return (
    <AppContext.Provider
      value={useMemo(
        () => [
          state,
          {
            setOpenAssetFolderModal,
            setVolume,
            setNewAlertFolder,
            setVisibleAlertFolderSidebar,
            setSelectedAlertAsset,
            setSelectedChannel,
            setDomain,
            setFirstLoading,
            actionLikeAnAsset,
            actionDislikeAnAsset,
            setAssetNote,
            setOpenComponent,
          },
        ],
        [state]
      )}
    >
      {children}
    </AppContext.Provider>
  );
}

export const actionMenuPaperProps = {
  style: {
    width: 180,
  },
  elevation: 0,
  sx: {
    overflow: "visible",
    bgcolor: "#24262B",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    // "&:before": {
    //   content: '""',
    //   display: "block",
    //   position: "absolute",
    //   top: 0,
    //   right: 14,
    //   width: 10,
    //   height: 10,
    //   bgcolor: "#24262B",
    //   transform: "translateY(-50%) rotate(45deg)",
    //   zIndex: 0,
    // },
    ".MuiList-root": {
      py: 0.5,
    },
    ".MuiListItemIcon-root": {
      minWidth: 28,
    },
  },
};

export const actionCardMenuPaperProps = {
  ...actionMenuPaperProps,
  style: {
    width: 165,
  },
};

export const assetCardPaperPropsLeft = {
  style: {
    width: 400,
  },
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    borderRadius: 8,
    //   "&:before": {
    //     content: '""',
    //     display: "block",
    //     position: "absolute",
    //     bottom: -7,
    //     right: 20,
    //     width: 10,
    //     height: 10,
    //     bgcolor: "background.paper",
    //     transform: "translateY(-50%) rotate(45deg)",
    //     zIndex: 0,
    //   },
  },
};
export const DomainCardPaperPropsLeft = {
  style: {
    width: 400,
  },
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    borderRadius: 8,
    //   "&:before": {
    //     content: '""',
    //     display: "block",
    //     position: "absolute",
    //     bottom: -7,
    //     right: 20,
    //     width: 10,
    //     height: 10,
    //     bgcolor: "background.paper",
    //     transform: "translateY(-50%) rotate(45deg)",
    //     zIndex: 0,
    //   },
  },
};
export const defaultFolderBackgroundColor = "#454545";

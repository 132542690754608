import { ApolloClient, InMemoryCache, split, ApolloLink, concat } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { getMainDefinition } from "@apollo/client/utilities";
import config from "../config";
import { AUTH_USER_TOKEN_KEY } from "../utils/constants";

const wsClient = new createClient({
  url: config.ENV.REACT_APP_BACKEND_WS,
});

const wsLink = new GraphQLWsLink(wsClient);

const uploadLink = createUploadLink({
  uri: config.ENV.REACT_APP_BACKEND_URL,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem(AUTH_USER_TOKEN_KEY)}` || null,
    },
  }));

  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  uploadLink
);

const client = new ApolloClient({
  link: concat(authMiddleware, splitLink),
  cache: new InMemoryCache({ addTypename: false }),
});

export default client;

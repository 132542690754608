import {
  faShoppingCart,
  faTags,
  faFaceSmile,
  faBasketShoppingSimple,
  faList,
  faFolder,
  faBooks,
  faFolderBookmark,
  faFilmSimple,
  faScissors,
  faEye,
  faEyeSlash,
  faStarSharp,
} from "@fortawesome/pro-solid-svg-icons";

export const WIDGET_ICON_KEYS = {
  cart: "cart",
  tags: "tags",
  emoji: "emoji",
  basket: "basket",
  list: "list",
  folder: "folder",
  books: "books",
  folderBookmark: "folderBookmark",
  film: "film",
  scissors: "scissors",
  eye: "eye",
  eyeSlash: "eyeSlash",
  star: "star",
};

export const FOLDER_ICON_KEYS = {
  noIcon: "no-icon",
  tags: "tags",
  basket: "basket",
  list: "list",
  folder: "folder",
  emoji: "emoji",
  cart: "cart",
  books: "books",
  folderBookmark: "folderBookmark",
  film: "film",
  scissors: "scissors",
  eye: "eye",
  eyeSlash: "eyeSlash",
  star: "star",
};

export const WIDGET_ICONS_ARRAY = [
  { key: WIDGET_ICON_KEYS.cart, icon: faShoppingCart },
  { key: WIDGET_ICON_KEYS.tags, icon: faTags },
  { key: WIDGET_ICON_KEYS.emoji, icon: faFaceSmile },
  { key: WIDGET_ICON_KEYS.basket, icon: faBasketShoppingSimple },
  { key: WIDGET_ICON_KEYS.list, icon: faList },
  { key: WIDGET_ICON_KEYS.folder, icon: faFolder },
  { key: WIDGET_ICON_KEYS.books, icon: faBooks },
  { key: WIDGET_ICON_KEYS.folderBookmark, icon: faFolderBookmark },
  { key: WIDGET_ICON_KEYS.film, icon: faFilmSimple },
  { key: WIDGET_ICON_KEYS.scissors, icon: faScissors },
  { key: WIDGET_ICON_KEYS.eye, icon: faEye },
  { key: WIDGET_ICON_KEYS.eyeSlash, icon: faEyeSlash },
  { key: WIDGET_ICON_KEYS.star, icon: faStarSharp },
];

export const FOLDER_ICONS_ARRAY = [
  { key: FOLDER_ICON_KEYS.tags, icon: faTags },
  { key: FOLDER_ICON_KEYS.cart, icon: faShoppingCart },
  { key: FOLDER_ICON_KEYS.basket, icon: faBasketShoppingSimple },
  { key: FOLDER_ICON_KEYS.list, icon: faList },
  { key: FOLDER_ICON_KEYS.folder, icon: faFolder },
  { key: FOLDER_ICON_KEYS.books, icon: faBooks },
  { key: FOLDER_ICON_KEYS.folderBookmark, icon: faFolderBookmark },
  { key: FOLDER_ICON_KEYS.film, icon: faFilmSimple },
  { key: FOLDER_ICON_KEYS.scissors, icon: faScissors },
  { key: FOLDER_ICON_KEYS.eye, icon: faEye },
  { key: FOLDER_ICON_KEYS.eyeSlash, icon: faEyeSlash },
  { key: FOLDER_ICON_KEYS.star, icon: faStarSharp },
];

export const getFolderIcon = (iconKey) => {
  if (iconKey == null || iconKey === FOLDER_ICON_KEYS.noIcon) return faFolder;
  let folderIcon = FOLDER_ICONS_ARRAY.find((item) => item.key === iconKey)?.icon;
  if (folderIcon) return folderIcon;
  return faFolder;
};

import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  ChangeCircle,
  ArrowForwardIos as IconArrowForwardIos,
  Logout as IconLogout,
  Person as IconPerson,
  SyncAltOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarThumbnailPath, getUserAvatarPath } from "../../utils";
import { apiUpdateUser } from "../../graphQL/user";
import { updateCurrentUser } from "../../store/auth.slice";
import { useUploadContext } from "../../contexts/UploadContext";
import { myToast } from "../../libs";
import { useState } from "react";
import { ButtonSave } from "../../components/buttons";
import { actionUpdateUser } from "../../store/User.slice";
import { Auth } from "aws-amplify";
import moment from "moment";
import LogoutGuardView from "../../layouts/LogoutGuardView";
import config from "../../config";
import SecondaryTopActionBar from "../../components/nav/SecondaryTopActionBar";
import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import ThumbnailUploadWithEditor from "../../components/upload/ThumbnailUploadWithEditor";
import { useEffect } from "react";
import { getAllCategories } from "../../store/Category.slice";
import DialogUserCategory from "./DialogUserCategory";
import { useConfirm } from "material-ui-confirm";
import { useLayoutContext } from "../../contexts/LayoutContext";
import DialogUserDomain from "./DialogUserDomain";
import DialogUserDomainList from "./DialogUserDomainList";
import { useNavigate } from "react-router-dom";
import DialogLocations from "./DialogLocations";
import DialogSelectUser from "./DialogSelectUser";
import { setOtherProfile } from "../../store/OtherProfile";
import { useAppContext } from "../../contexts/AppContext";

function PageMyProfile() {
  const dispatch = useDispatch();
  const locations = useSelector((store) => store.Location.locations);
  const categories = useSelector((store) => store.Category.categories);
  const [, { uploadAvatar }] = useUploadContext();
  const [{ isSmScreen }] = useLayoutContext();
  const [{ domainInfo }] = useAppContext();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const OtherProfile = useSelector((store) => store.OtherProfile.Profile);
  const DomainLists = useSelector((store) => store.Domain.domains);
  const publicDomainLists = DomainLists ? DomainLists?.filter((domain) => domain?.state === "public") : [];

  const [profile, setProfile] = useState();

  const [logoutGuardStyle, setLogoutGuardStyle] = useState({});
  const [form, setForm] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDialogUserDomain, setOpenDialogUserDomain] = useState(false);
  const [openDialogCategory, setOpenDialogCategory] = useState(false);
  const [openDialogUserDomainList, setOpenDialogUserDomainList] = useState(false);
  const [openLocationsDialog, setOpenLocationsDialog] = useState(false);
  const [openUserSelect, setOpenUserSelect] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => {
    setForm(null);
    setOpen(false);
    setLoading(false);
  };

  if (PrivateInfo?.name === config.GUEST.email) {
    navigate("/");
  }

  const actionSave = (variables) => {
    setLoading(true);
    return apiUpdateUser(variables)
      .then((res) => {
        setLoading(false);
        console.log(res);
        if (profile.id === PrivateInfo.id) dispatch(updateCurrentUser(res));
        else dispatch(setOtherProfile(res));
        dispatch(actionUpdateUser(res));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (PrivateInfo?.domain) {
      dispatch(getAllCategories({ domain: PrivateInfo?.domain }));
    }
  }, [dispatch, PrivateInfo?.domain]);

  useEffect(() => {
    if (OtherProfile) {
      setProfile(OtherProfile);
    } else {
      setProfile(PrivateInfo);
    }
  }, [PrivateInfo, OtherProfile]);
  
  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            overflowY: "auto",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ p: 0.5 }}>
            <Box
              display="flex"
              alignItems="center"
              onClick={() => {
                // Auth.signOut();
                if (isSmScreen) {
                  confirm({
                    title: "Are you sure you want to logout?",
                    confirmationText: "LOGOUT",
                    cancellationText: "BACK",
                    titleProps: {
                      textAlign: "center",
                    },
                    cancellationButtonProps: {
                      style: {
                        background: "white",
                        color: "black",
                      },
                    },
                    confirmationButtonProps: {
                      style: {
                        background: "white",
                        color: "black",
                      },
                    },
                    dialogActionsProps: {
                      style: {
                        justifyContent: "space-between",
                      },
                    },
                  })
                    .then(() => {
                      Auth.signOut();
                    })
                    .catch(() => {});
                } else {
                  setLogoutGuardStyle({
                    height: "120px",
                    width: "100%",
                    maxWidth: "100%",
                    bottom: 0,
                    left: 0,
                    opacity: 1,
                    zIndex: 10,
                  });
                }
              }}
            >
              <IconLogout sx={{ transform: "rotate(180deg)" }}></IconLogout>
              <Typography variant="h6" sx={{ pl: 1 }}>
                Logout
              </Typography>
            </Box>
            {PrivateInfo?.role === "sysadmin" || PrivateInfo?.role === "superAdmin" ? <Box
              display="flex"
              alignItems="center"
              onClick={() => {
                setOpenUserSelect(true);
              }}
            >
              <Typography variant="h6" sx={{ pl: 1 }}>
                Switch Profile
              </Typography>
              <SyncAltOutlined sx={{ transform: "rotate(180deg)" }}></SyncAltOutlined>
            </Box> : <></>}
          </Box>
          <Box sx={{ pl: 2, pr: 1.5, pt: 1.5 }}>
            <Box className="flex items-center justify-content-center" sx={{ width: "100%", gap: 4, pl: 1, pr: 2 }}>
              <Box className="flex flex-col justify-content-center items-center">
                <Box>
                  <ThumbnailUploadWithEditor
                    url={getUserAvatarPath(profile?.avatar)}
                    aspect={3 / 4.0}
                    width="6rem"
                    height="6rem"
                    variant="rounded"
                    PrivateInfo={profile}
                    isAvatar={true}
                    isSame={profile?.avatar && profile?.avatar?.thumbnail}
                  ></ThumbnailUploadWithEditor>
                </Box>
                <Box sx={{ my: 1 }}>Change Avatar</Box>
              </Box>
              <Box className="flex flex-col justify-content-center items-center">
                <Box>
                  <ThumbnailUploadWithEditor
                    url={
                      getAvatarThumbnailPath(profile?.avatar)
                        ? getAvatarThumbnailPath(profile?.avatar)
                        : getUserAvatarPath(profile?.avatar)
                    }
                    aspect={1}
                    width="6rem"
                    height="6rem"
                    variant="circle"
                    Icon={IconPerson}
                    PrivateInfo={profile}
                    isAvatar={false}
                    isSame={profile?.avatar && !profile?.avatar?.thumbnail}
                  ></ThumbnailUploadWithEditor>
                </Box>
                <Box sx={{ my: 1 }}>Change Thumbnail</Box>
              </Box>
            </Box>
            <Box className="flex items-center justify-content-between" sx={{ my: 2 }}>
              <Box>
                <Typography variant="h6"> Domain </Typography>
              </Box>
              <Box className="flex items-center">
                <Typography variant="body1" sx={{ pr: 1 }}>
                  {" "}
                  {profile?.domain}{" "}
                </Typography>
                {/* <IconButton
                  size="small"
                  onClick={() => {
                    setForm({
                      field: "userDomain",
                      title: "UserDomain",
                      desc: "Please select user domain",
                      value: PrivateInfo?.domain,
                    });
                    setOpenDialogUserDomain(true);
                  }}
                >
                  <IconArrowForwardIos fontSize="inherit" />
                </IconButton> */}
              </Box>
            </Box>
            {publicDomainLists.length > 0 && (
              <Box className="flex items-center justify-content-between" sx={{ my: 2 }}>
                <Box>
                  <Typography variant="h6"> Domain List </Typography>
                </Box>
                <Box className="flex items-center">
                  {/* <Typography variant="body1" sx={{ pr: 1 }}>
                  {" "}
                  {PrivateInfo?.domain}{" "}
                </Typography> */}
                  <IconButton
                    size="small"
                    onClick={() => {
                      setForm({
                        field: "domainList",
                        title: "Domain List",
                        desc: "Please select your favorite domain list.",
                        value: profile?.domainList ? profile?.domainList : [],
                      });
                      setOpenDialogUserDomainList(true);
                    }}
                  >
                    <IconArrowForwardIos fontSize="inherit" />
                  </IconButton>
                </Box>
              </Box>
            )}
            <Box className="flex items-center justify-content-between" sx={{ my: 2 }}>
              <Box>
                <Typography variant="h6"> Email </Typography>
              </Box>
              <Box className="flex items-center" sx={{ pr: 1 }}>
                <Typography variant="body1"> {profile?.name} </Typography>
              </Box>
            </Box>
            <Box className="flex items-center justify-content-between" sx={{ mb: 2 }}>
              <Box>
                <Typography variant="h6"> Handle </Typography>
              </Box>
              <Box className="flex items-center">
                <Typography variant="body1"> {profile?.username} </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    setForm({
                      field: "username",
                      title: "Username",
                      desc: "Please input username",
                      value: profile?.username,
                    });
                    setOpen(true);
                  }}
                >
                  <IconArrowForwardIos fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
            <Box className="flex items-center justify-content-between" sx={{ mb: 2 }}>
              <Box>
                <Typography variant="h6"> My hook </Typography>
              </Box>
              <Box className="flex items-center">
                <Typography variant="body1"> {profile?.desc?.title} </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    setForm({
                      field: "desc.title",
                      title: "",
                      desc: "Please input My Hook",
                      value: profile?.desc?.title,
                    });
                    setOpen(true);
                  }}
                >
                  <IconArrowForwardIos fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
            <Box className="flex items-center justify-content-between" sx={{ mb: 2 }}>
              <Box>
                <Typography variant="h6"> About Me </Typography>
              </Box>
              <Box className="flex items-center">
                <Typography variant="body1"> {profile?.desc?.short} </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    setForm({
                      field: "desc.short",
                      title: "Description",
                      desc: "Please input description",
                      value: profile?.desc?.short,
                    });
                    setOpen(true);
                  }}
                >
                  <IconArrowForwardIos fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
            {categories?.find((category) => category.domain === profile?.domain) ? <Box className="flex items-center justify-content-between" sx={{ mb: 2 }}>
              <Box>
                <Typography variant="h6"> Area of Interests </Typography>
              </Box>
              <Box className="flex items-center">
                <Typography variant="body1"> {profile?.categories?.length ? `${profile?.categories?.length} Category(s)` : "Select an interest..."} </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    setOpenDialogCategory(true);
                  }}
                >
                  <IconArrowForwardIos fontSize="inherit" />
                </IconButton>
              </Box>
            </Box> : <></>}
            <Box className="flex items-center justify-content-between" sx={{ mb: 2 }}>
              <Box>
                <Typography variant="h6"> Locations </Typography>
              </Box>
              <Box className="flex items-center">
                <Typography variant="body1"> {
                  profile?.locationIdList ? 
                    locations?.filter(item => profile?.locationIdList?.includes(item.id))
                      .map(item => item.name).toString() : ""} 
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    setOpenLocationsDialog(true);
                  }}
                >
                  <IconArrowForwardIos fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
            <Box className="flex items-center justify-content-between" sx={{ my: 2 }}>
              <Box>
                <Typography variant="h6"> Member since </Typography>
              </Box>
              <Box className="flex items-center" sx={{ pr: 1 }}>
                <Typography variant="body1">
                  {moment(parseInt(profile?.timestamp)).format("MMM Do YYYY, h:mm a")}{" "}
                </Typography>
              </Box>
            </Box>
            <Box className="flex items-center justify-content-between" sx={{ my: 2 }}>
              <Box>
                <Typography variant="h6"> Last logged in </Typography>
              </Box>
              <Box className="flex items-center" sx={{ pr: 1 }}>
                <Typography variant="body1">
                  {moment(parseInt(profile?.loginAt)).format("MMM Do YYYY, h:mm a")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <SecondaryTopActionBar title="Edit Profile"></SecondaryTopActionBar>
      <div style={logoutGuardStyle} className={"logout-guard-view"}>
        <LogoutGuardView
          handleClickBack={(value) => {
            if (value === "logout") {
              Auth.signOut();
            } else {
              setLogoutGuardStyle({
                height: 0,
                opacity: 0,
                right: 0,
                bottom: -120,
              });
            }
          }}
        />
      </div>
      <DialogLocations
        open={openLocationsDialog}
        handleClose={() => {
          setOpenLocationsDialog(false);
        }}
        userInfo={profile}
      />
      <DialogUserCategory
        open={openDialogCategory}
        handleClose={() => {
          setOpenDialogCategory(false);
        }}
        userId={profile?.id}
        userCategories={profile?.categories}
      ></DialogUserCategory>
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle>{form?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>{form?.desc}</DialogContentText>
          <TextField
            autoFocus
            fullWidth
            variant="standard"
            value={form?.value || ""}
            onChange={(e) => {
              setForm({ ...form, value: e.target.value });
            }}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <ButtonSave
            loading={isLoading}
            onClick={() => {
              switch (form?.field) {
                case "username":
                  actionSave({
                    id: profile?.id,
                    username: form?.value,
                  }).then(() => {
                    handleClose();
                  });
                  break;
                case "desc.title":
                  actionSave({
                    id: profile?.id,
                    desc: {
                      ...(profile?.desc || {}),
                      title: form?.value,
                    },
                  }).then(() => {
                    handleClose();
                  });
                  break;
                case "desc.short":
                  actionSave({
                    id: profile?.id,
                    desc: {
                      ...(profile?.desc || {}),
                      short: form?.value,
                    },
                  }).then(() => {
                    handleClose();
                  });
                  break;
                default:
                  handleClose();
              }
            }}
          ></ButtonSave>
        </DialogActions>
      </Dialog>
      <DialogUserDomain
        openDialogUserDomain={openDialogUserDomain}
        handleClose={() => setOpenDialogUserDomain(false)}
        form={form}
        setForm={setForm}
        isLoading={isLoading}
        PrivateInfo={profile}
        actionSave={actionSave}
      ></DialogUserDomain>

      <DialogUserDomainList
        openDialogUserDomainList={openDialogUserDomainList}
        handleClose={() => {
          setOpenDialogUserDomainList(false);
          setLoading(false);
        }}
        isLoading={isLoading}
        publicDomainLists={publicDomainLists}
        PrivateInfo={profile}
        form={form}
        setForm={setForm}
        actionSave={actionSave}
      ></DialogUserDomainList>
      <DialogSelectUser
        open={openUserSelect}
        handleClose={() => {setOpenUserSelect(false)}}
        userInfo={OtherProfile}
      />
    </SubPageWrapper>
  );
}

export default PageMyProfile;

import React from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, ThemeProvider } from "@mui/material";
import { lightTheme } from "../../libs/theme";

function DialogVideoCaption({ isCaptionDialogOpen, setCaptionDialogOpen, caption }) {
  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog
        open={isCaptionDialogOpen}
        onClose={() => {
          setCaptionDialogOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle> Caption </DialogTitle>
        <DialogContent>
          <TextField fullWidth value={caption} multiline={true} rows={10} readonly></TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCaptionDialogOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default DialogVideoCaption;

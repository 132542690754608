import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppContext } from "../contexts/AppContext";
import { apiCreateNote, apiGetNote, apiUpdateNote } from "../graphQL/note";

const useAssetNote = (assetId) => {
  const userId = useSelector((store) => store.auth.PrivateInfo?.id);
  const [{ assetNote }, { setAssetNote }] = useAppContext();

  const updateAssetNote = useCallback(
    (assetNote) => {
      if (assetNote?.id) {
        return apiUpdateNote({
          id: assetNote?.id,
          owner: userId,
          linked: {
            type: "asset",
            id: assetId,
          },
          notes: assetNote?.notes?.filter((t) => !!t?.note?.text),
        })
          .then((res) => {
            setAssetNote(res);
          })
          .catch((err) => console.log(err))
          .then(() => {});
      } else {
        return apiCreateNote({
          owner: userId,
          linked: {
            type: "asset",
            id: assetId,
          },
          notes: assetNote?.notes?.filter((t) => !!t?.note?.text),
        })
          .then((res) => {
            setAssetNote(res);
          })
          .catch((err) => console.log(err))
          .then(() => {});
      }
    },
    [assetId, setAssetNote, userId]
  );

  useEffect(() => {
    if (assetId && userId && (assetNote?.owner !== userId || assetNote?.linked?.id !== assetId)) {
      apiGetNote(userId, "asset", assetId)
        .then((note) => {
          setAssetNote(note);
        })
        .catch((err) => {
          setAssetNote(null);
        });
    }
  }, [userId, assetId, assetNote?.owner, assetNote?.linked?.id]); //eslint-disable-line

  return [
    {
      assetNote,
    },
    {
      setAssetNote,
      updateAssetNote,
    },
  ];
};

export default useAssetNote;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { Container, Box } from "@mui/material";
import CirclesLoader from "../components/loader/CirclesLoader";

import PageLocationVerification from "../pages/auth/LocationVerification";

import MainLayout from "../layouts/MainLayout";
import SubPageLayout from "../layouts/SubPageLayout";

import PageUnderReview from "../layouts/pages/UnderReview";

/** Main Pages */
import PageForYou from "../pages/ForYou/ForYou";
import PageCreators from "../pages/Creators/Creators";
import PageStreams from "../pages/Streams/PageStreams";

/** Sub Pages */
import PageMyVideos from "../pages/MyVideos/PageMyVideos";
import PageMyCollections from "../pages/MyCollections/PageMyCollections";
import PageMyAssetWidgetEditor from "../pages/MyAssetWidgetEditor/MyAssetWidgetEditor";
import PageMore from "../pages/More/PageMore";
import PageGroups from "../pages/Groups/PageGroups";
import PageMyGroups from "../pages/MyGroups/PageMyGroups";
import PageMyProfile from "../pages/MyProfile/PageMyProfile";
import PageMyBookmarks from "../pages/Bookmarks/PageMyBookmarks";
import PageBookmarksPlay from "../pages/BookmarksPlay/PageBookmarksPlay";
import PageCreatorDetail from "../pages/CreatorDetail/PageCreatorDetail";
import PageChannels from "../pages/Channels/PageChannels";
import PageDesc from "../pages/Desc/Desc";
import PageCampaign from "../pages/Campaign/PageCampaign";

import { getAllChannels } from "../store/Channel.slice";
import { getAllAssets } from "../store/Asset.slice";
import { getAllDomains } from "../store/Domain.slice";
import { getAllMenus } from "../store/Menu.slice";
import { getAllUserFolders } from "../store/UserFolder.slice";
import SecondaryPageLayout from "../layouts/SecondaryPageLayout";
import PageCollections from "../pages/Collections/PageCollections";
import { getAllUsers } from "../store/User.slice";
import PageGroupDetail from "../pages/GroupDetail/PageGroupDetail";
import { getAllCategories } from "../store/Category.slice";
import PageAssetCardDetail from "../pages/Bookmarks/AssetCardDetail/PageAssetCardDetail";
import { getAllLocations } from "../store/Location.slice";

const params = {
  ordering: {
    field: "rank",
    order: "Desc",
  },
  pagination: {
    page: 1,
    limit: 32768,
  },
};
// function for Router
function PrivateRoutesWrapper() {
  const dispatch = useDispatch();
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);

  useEffect(() => {
    if (PrivateInfo?.domain) {
      dispatch(getAllUsers({}));
      dispatch(
        getAllChannels({
          domain: PrivateInfo.domain,
        })
      );
      dispatch(getAllCategories({
        domain: PrivateInfo.domain,
      }));
      dispatch(
        getAllMenus({
          domain: PrivateInfo.domain,
          ...params,
        })
      );
      dispatch(
        getAllAssets({
          domain: PrivateInfo.domain,
          // status: "Published", //"Ready",
          ...params,
        })
      );
    }
  }, [dispatch, PrivateInfo?.domain]);

  useEffect(() => {
    if (PrivateInfo?.id) {
      dispatch(getAllUserFolders({ owner: PrivateInfo?.id, ...params }));
    }
  }, [dispatch, PrivateInfo?.id]);

  return <Outlet></Outlet>;
}

function PrivateRoutes() {
  const dispatch = useDispatch();
  const authenticated = useSelector((store) => store.auth.authenticated);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);

  useEffect(() => {
    dispatch(getAllDomains());
    dispatch(getAllLocations());
  }, [dispatch]);

  if (authenticated) {
    if (!PrivateInfo)
      return (
        <Box sx={{ width: "100%", height: "100%" }} display="flex" alignItems="center" justifyContent="center">
          <CirclesLoader></CirclesLoader>
        </Box>
      );
    if (!PrivateInfo?.domain || (!PrivateInfo?.locationId && !["sysadmin", "superAdmin"].includes(PrivateInfo.role))) {
      return <PageLocationVerification></PageLocationVerification>;
    }
    if (!PrivateInfo?.activated) return <PageUnderReview></PageUnderReview>;
  }

  return (
    <Container maxWidth="lg" sx={{ height: "100%", px: { xs: 0, sm: 0, md: 0 }, position: "relative" }}>
      <Routes>
        <Route path="" element={<PrivateRoutesWrapper></PrivateRoutesWrapper>}>
          <Route path="/" element={<MainLayout></MainLayout>}>
            <Route path="" index element={<PageForYou></PageForYou>}></Route>
            <Route path="campaign" element={<PageCampaign></PageCampaign>}></Route>
            {/* <Route path="streams" element={<PageStreams></PageStreams>}></Route> */}
          </Route>
          <Route path="/" element={<SecondaryPageLayout></SecondaryPageLayout>}>
            <Route path="more" element={<PageMore></PageMore>}></Route>
            <Route path="profile" element={<PageMyProfile></PageMyProfile>}></Route>
            <Route path="stuffs/play/:userFolderId" element={<PageBookmarksPlay></PageBookmarksPlay>}></Route>
            <Route path="stuffs" element={<PageMyBookmarks></PageMyBookmarks>}></Route>
            <Route path="myvideos" element={<PageMyVideos></PageMyVideos>}></Route>
            <Route path="mygroups" element={<PageMyGroups></PageMyGroups>}></Route>
            <Route path="mycollections" element={<PageMyCollections></PageMyCollections>}></Route>
            <Route path="group/detail/:userGroupId" element={<PageGroupDetail></PageGroupDetail>}></Route>
            <Route path="creator/play/:creatorId" element={<PageCreatorDetail></PageCreatorDetail>}></Route>
            <Route path="asset/play/:assetId" element={<PageAssetCardDetail></PageAssetCardDetail>}></Route>
            <Route path="bookmarks" element={<Navigate to="/stuffs"></Navigate>}></Route>
            <Route path="*" element={<Navigate to="/"></Navigate>}></Route>
          </Route>
          <Route path="/" element={<SubPageLayout></SubPageLayout>}>
            <Route path="creators" element={<PageCreators></PageCreators>}></Route>
            <Route path="collections" element={<PageCollections></PageCollections>}></Route>
            <Route path="channels" element={<PageChannels></PageChannels>}></Route>
            <Route path="groups" element={<PageGroups></PageGroups>}></Route>
            <Route path="desc" element={<PageDesc></PageDesc>}></Route>
            <Route path="asset/:assetId/widgets" element={<PageMyAssetWidgetEditor></PageMyAssetWidgetEditor>}></Route>
            <Route path="*" element={<Navigate to="/"></Navigate>}></Route>
          </Route>
        </Route>
      </Routes>
    </Container>
  );
}

export default PrivateRoutes;

import React from "react";
import { LoadingButton } from "@mui/lab";
import { Delete, Add as IconAdd } from "@mui/icons-material";

function ButtonRemove({ onClick, isLoading, label }) {
  return (
    <LoadingButton
      color="error"
      type="button"
      loading={isLoading}
      loadingPosition="start"
      startIcon={<Delete />}
      variant="contained"
      onClick={() => {
        onClick && onClick();
      }}
    >
      {label}
    </LoadingButton>
  );
}

ButtonRemove.defaultProps = {
  onClick: () => {},
  isLoading: false,
  label: "Delete",
};

export default ButtonRemove;

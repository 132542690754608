import client from "../index.js";
import { GET_RESERVATION, GET_RESERVATIONS } from "./queries.js";
import { CREATE_RESERVATION, UPDATE_RESERVATION, DELETE_RESERVATION } from "./mutations";

export const apiGetReservation = (id) => {
  return client
    .query({
      query: GET_RESERVATION,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.reservation;
    });
};

export const apiGetAllReservations = (filter) => {
  return client
    .query({
      query: GET_RESERVATIONS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.reservations;
    });
};

export const apiCreateReservation = (data) => {
  return client
    .mutate({
      mutation: CREATE_RESERVATION,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.createReservation?.reservation;
    });
};

export const apiUpdateReservation = (data) => {
  return client
    .mutate({
      mutation: UPDATE_RESERVATION,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.updateReservation?.reservation;
    });
};

export const apiDeleteReservation = (id) => {
  return client
    .mutate({
      mutation: DELETE_RESERVATION,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.deleteReservation?.id;
    });
};

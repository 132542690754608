import React from "react";
import { Drawer, Popover } from "@mui/material";
import DomainCard from "../../CustomCards/DomainCard/DomainCard";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import { DomainCardPaperPropsLeft } from "../../../utils/styles";

function DomainDrawerWrapper({ open, onClose, domain, setDomain, anchorEl, children, ...props }) {
  const [{ isSmScreen }] = useLayoutContext();
  const paddingWidth = window.innerWidth > 1200 ? (window.innerWidth - 1200) / 2 : 0;
  if (!isSmScreen) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          sx: {
            bgcolor: "#000",
          },
        }}
      >
        {children}
      </Drawer>
    );
  }

  return (
    <Popover
      PaperProps={DomainCardPaperPropsLeft}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      onClose={onClose}
      style={{ marginLeft: paddingWidth }}
    >
      {children}
    </Popover>
    // <Drawer
    //   open={!!domain?.id}
    //   onClose={() => {
    //     setDomain(null);
    //   }}
    //   anchor="bottom"
    //   PaperProps={{
    //     sx: {
    //       background: "#d2d2d2",
    //       pt: 1,
    //       width: "100%",
    //       maxWidth: "1200px",
    //       left: "50%",
    //       right: "unset",
    //       transform: "translateX(-50%) !important",
    //       borderRadius: "10px 10px 0px 0px",
    //     },
    //   }}
    // >
    //   {children}
    // </Drawer>
  );
}

function DomainDrawer({ open, onClose, domain, setDomain, anchorEl }) {
  return (
    <DomainDrawerWrapper open={open} setDomain={setDomain} onClose={onClose} anchorEl={anchorEl} domain={domain}>
      <DomainCard
        domain={domain}
        onClose={() => {
          onClose();
        }}
      ></DomainCard>
    </DomainDrawerWrapper>
  );
}

export default DomainDrawer;

import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_RESERVATION = gql`
  subscription onCreateReservation{
    onCreateReservation{
      reservation{
        id
        schemaVer
        authorId
        creatorId
        assetId
        comment
        reply
        count
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const ON_UPDATE_RESERVATION = gql`
  subscription onUpdateReservation{
    onUpdateReservation{
      reservation{
        id
        schemaVer
        authorId
        creatorId
        assetId
        comment
        reply
        count
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const ON_DELETE_RESERVATION = gql`
  subscription onDeleteReservation{
    onDeleteReservation{
      id
    }
  }
`;

const useOnCreateReservation= () => useSubscription(ON_CREATE_RESERVATION);
const useOnUpdateReservation= () => useSubscription(ON_UPDATE_RESERVATION);
const useOnDeleteReservation= () => useSubscription(ON_DELETE_RESERVATION);

export { useOnCreateReservation, useOnUpdateReservation, useOnDeleteReservation};

import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import StreamPlayerWrapper from "./StreamPlayerWrapper";
import ChannelNavigation from "./ChannelNavigation";

function PageStreams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const assets = useSelector((store) => store.Asset.assets);
  const channels = useSelector((store) => store.Channel.channels);

  const [{ selectedChannel }, { setSelectedChannel }] = useAppContext();
  const [filteredChannels, setFilteredChannels] = useState(null);
  const [playList, setPlayList] = useState(null);

  useEffect(() => {
    const k = searchParams.get("s");
    if (!assets || !channels || !filteredChannels) return;

    if (k) {
      const channel = channels?.find((item) => item.id === k);
      if (!channel || !assets.filter((asset) => asset.categories?.includes(channel?.id))?.length) {
        navigate("/");
      } else {
        setSelectedChannel(channel);
      }
    } else {
      if (!assets || !channels || !filteredChannels) return;
      if (filteredChannels.length > 0) {
        setSearchParams({
          s: filteredChannels[0].id,
        });
      } else {
        navigate("/");
      }
    }
  }, [searchParams, filteredChannels, navigate]); //eslint-disable-line

  useEffect(() => {
    setFilteredChannels(
      channels?.filter((channel) => {
        if (assets && assets.filter((asset) => asset.categories?.includes(channel?.id)).length > 0) return true;
        return false;
      })
    );
  }, [assets, channels]);

  useEffect(() => {
    if (selectedChannel?.id && assets) {
      setPlayList(assets.filter((asset) => asset.categories?.includes(selectedChannel?.id)));
    } else {
      setPlayList(null);
    }
  }, [selectedChannel?.id, assets]);

  return (
    <Box className="w-full h-full" sx={{ position: "relative" }}>
      {selectedChannel?.id && (
        <ChannelNavigation selectedChannelId={selectedChannel?.id} channels={filteredChannels}></ChannelNavigation>
      )}
      {selectedChannel && playList && (
        <StreamPlayerWrapper playList={playList} channel={selectedChannel}></StreamPlayerWrapper>
      )}
    </Box>
  );
}

export default PageStreams;

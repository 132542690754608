import { gql } from "@apollo/client";

const CREATE_LOCATION = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      location {
        id
        name
        data
        authCode
        domain
        avatar {
          name
          dir
          base
          mimetype
        }
        geoLocation {
          pinPoints {
            lat
            lng
          }
          polygon {
            lat
            lng
          }
          zipCode
          fips
          region {
            center {
              lat
              lng
            }
            radius
            unit
          }
        }
        defaultFolderId
        timestamp
        updatedAt
      }
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation updateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      location {
        id
        name
        data
        authCode
        domain
        avatar {
          name
          dir
          base
          mimetype
        }
        geoLocation {
          pinPoints {
            lat
            lng
          }
          polygon {
            lat
            lng
          }
          zipCode
          fips
          region {
            center {
              lat
              lng
            }
            radius
            unit
          }
        }
        defaultFolderId
        timestamp
        updatedAt
      }
    }
  }
`;

const DELETE_LOCATION = gql`
  mutation deleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      id
    }
  }
`;

const DELETE_ALL_LOCATIONS = gql`
  mutation deleteAllLocations($input: DeleteAllInput!) {
    deleteAllLocations(input: $input) {
      total
    }
  }
`;

export { CREATE_LOCATION, UPDATE_LOCATION, DELETE_LOCATION, DELETE_ALL_LOCATIONS };

import React from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";

function KeywordInput({ label, placeholder, ...props }) {
  return (
    <Autocomplete
      size="small"
      multiple
      id="tags-filled"
      defaultValue={[]}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} placeholder={placeholder} size="small" />
      )}
      {...props}
    />
  );
}

export default KeywordInput;

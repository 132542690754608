import React, { useState } from "react";
import { Box } from "@mui/material";

function SwipeActionCatcher({ children, onSwipeLeft, onSwipeRight, onSwipeUp, onSwipeDown }) {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 100;

  const onDragAndDropEnd = () => {
    if (!touchStart || !touchEnd) return;

    const xDistance = touchEnd?.x - touchStart?.x;
    const yDistance = touchEnd?.y - touchStart?.y;

    const isLeftSwipe = xDistance > minSwipeDistance;
    const isRightSwipe = xDistance < -minSwipeDistance;
    const isDownSwipe = yDistance > minSwipeDistance;
    const isUpSwipe = yDistance < -minSwipeDistance;

    if (isLeftSwipe) {
      console.log("Left Swipe Actions");
      if (onSwipeLeft) onSwipeLeft();
    } else if (isRightSwipe) {
      console.log("Right Swipe Actions");
      if (onSwipeRight) onSwipeRight();
    } else if (isUpSwipe) {
      console.log("Up Swipe Actions");
      if (onSwipeUp) onSwipeUp();
    } else if (isDownSwipe) {
      console.log("Down Swipe Actions");
      if (onSwipeDown) onSwipeDown();
    }
  };

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart({ x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY });
  };

  const onTouchMove = (e) => setTouchEnd({ x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY });

  const onTouchEnd = () => {
    onDragAndDropEnd();
  };

  return (
    <div
      style={{ width: "100%", height: "100%", position: "relative" }}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {children}
    </div>
  );
}

SwipeActionCatcher.defaultProps = {
  events: {
    onSwipeUp: () => {},
    onSwipeDown: () => {},
    onSwipeLeft: () => {},
    onSwipeRight: () => {},
  },
};

export default SwipeActionCatcher;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Box,
} from "@mui/material";
import { ButtonSave } from "../../components/buttons";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Close } from "@mui/icons-material";
import { apiGetAllLocations, apiUpdateLocation } from "../../graphQL/locations";
import { apiGetAllDomains } from "../../graphQL/domains";
import { useDispatch } from "react-redux";
import { actionUpdateLocation } from "../../store/Location.slice";

function DialogEditLocation({
  open,
  onClose = () => { },
  onSave = () => { },
  location,
  setLocation,
}) {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [domains, setDomains] = useState([]);

  ValidatorForm.addValidationRule("isLocationExist", (value) => {
    if (locations?.find((t) => t.name?.toLowerCase() === value?.toLowerCase() && t.id !== location?.id && t.domain === location?.domain)) {
      return false;
    }
    return true;
  });
  
  ValidatorForm.addValidationRule("isAuthCodeExists", (value) => {
    const locAuthCodes = locations?.filter((loc) => loc.id !== location?.id).map((loc) => loc.authCode);
    const domainAuthCodes = domains?.map((domain) => domain.authCode);
    if (locAuthCodes.includes(location?.authCode) || domainAuthCodes.includes(location?.authCode)) {
      return false;
    }
    return true;
  });

  useEffect(() => {
    apiGetAllLocations({
      pagination: {
        limit: 32768,
        page: 1,
      }
    }).then((resp) => {
      setLocations(resp?.locations);
    });

    apiGetAllDomains()
    .then((resp) => {
      setDomains(resp?.domains);
    })
  }, [])

  const submit = () => {
    setLoading(true);
    apiUpdateLocation({
      id: location?.id,
      authCode: location?.authCode,
      name: location?.name,
    }).then((resp) => {
      dispatch(actionUpdateLocation(resp));
      setLoading(false);
      onClose();
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });
    onSave();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <ValidatorForm onSubmit={submit}>
        <DialogContent>
          <Grid container
            alignItems="center"
            mt={2}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 3,
                right: 3
              }}
              onClick={onClose}>
              <Close/>
            </IconButton>
            <Grid item xs={11}>
              <TextValidator
                fullWidth
                label="Campaign Name"
                value={location?.name || ""}
                onChange={(e) => {
                  setLocation({
                    ...location,
                    name: e.target.value,
                  });
                }}
                validators={["required", "isLocationExist"]}
                errorMessages={["This field is required", "Campaign name already exists in this domain"]}
                sx={{ mb: 1.5 }}
              />
            </Grid>
            <Grid item xs={11}>
              <TextValidator
                fullWidth
                type="number"
                label="Authorization Code"
                value={location?.authCode}
                onChange={(e) => {
                  setLocation({
                    ...location,
                    authCode: e.target.value,
                  });
                }}
                validators={["required", "matchRegexp:^[0-9]{6}$", "isAuthCodeExists"]}
                errorMessages={["This field is required", "Authorization code must be 6 digits", "This authorization token has been used, please choose another one..."]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box></Box>
          <ButtonSave
            loading={loading}
          ></ButtonSave>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default DialogEditLocation;

import styled from "styled-components";

export const WaveformContianer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #232323;
`;

export const Wave = styled.div`
  width: 100%;
  height: 120px;
`;

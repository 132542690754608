import { gql } from "@apollo/client";

const CREATE_ASSET = gql`
  mutation createMediaAsset($input: CreateMediaAssetInput!) {
    createMediaAsset(input: $input) {
      mediaAsset {
        id
        domain
        locationId
        name
        type
        owner
        desc {
          title
          short
          body
          website
          caption
        }
        categories
        file {
          name
          directory
          contentType
          # signedUrl
          outputHls
          outputDash
          displayName
          outputCC
        }
        status
        keywords
        parentNodeId
        stockAudioId
        timestamp
        highlight
        rank
        updatedAt
        videoWidgets {
          id
          assetId
          name
          type
          desc {
            title
            short
            body
            website
            caption
            style
            rows
          }
          boxInfo {
            x
            y
            width
            height
            videoWidth
            videoHeight
            foreground
          }
          videoPos {
            startAt
            duration
          }
          data
          keyword
          videoWidgetType
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
      }
    }
  }
`;

const UPDATE_ASSET = gql`
  mutation updateMediaAsset($input: UpdateMediaAssetInput!) {
    updateMediaAsset(input: $input) {
      mediaAsset {
        id
        domain
        locationId
        name
        type
        owner
        desc {
          title
          short
          body
          website
          caption
        }
        categories
        file {
          name
          directory
          contentType
          # signedUrl
          outputHls
          outputDash
          displayName
          outputCC
        }
        status
        keywords
        parentNodeId
        stockAudioId
        timestamp
        highlight
        rank
        updatedAt
        videoWidgets {
          id
          assetId
          name
          type
          desc {
            title
            short
            body
            website
            caption
            style
            rows
          }
          boxInfo {
            x
            y
            width
            height
            videoWidth
            videoHeight
            foreground
          }
          videoPos {
            startAt
            duration
          }
          data
          keyword
          videoWidgetType
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
      }
    }
  }
`;

const DELETE_ASSET = gql`
  mutation deleteMediaAsset($id: ID!) {
    deleteMediaAsset(id: $id) {
      id
    }
  }
`;

const DELETE_ALL_ASSETS = gql`
  mutation deleteAllMediaAssets($input: DeleteAllInput!) {
    deleteAllMediaAssets(input: $input) {
      total
    }
  }
`;

export { CREATE_ASSET, UPDATE_ASSET, DELETE_ASSET, DELETE_ALL_ASSETS };

import React from "react";
import { useSelector } from "react-redux";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import SecondaryTopActionBar from "../../components/nav/SecondaryTopActionBar";
import { useNavigate, useParams } from "react-router-dom";
import UserFolderPlayer from "./UserFolderPlayer";

import "./PageBookmarksPlay.scss";

function PageBookmarksPlay() {
  const navigate = useNavigate();
  const assets = useSelector((store) => store.Asset.assets);
  const userFolders = useSelector((store) => store.UserFolder.userFolders);

  const { userFolderId } = useParams();

  const userFolder = userFolders?.find((t) => t.id === userFolderId);

  if (!assets || !userFolders)
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>;

  if (!userFolder) navigate("/stuffs");

  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <UserFolderPlayer
            playList={assets?.filter((asset) => userFolder?.assetIds?.find((t) => t?.id === asset?.id))}
          ></UserFolderPlayer>
        </Box>
      </Box>
      <SecondaryTopActionBar
        title={userFolder?.name}
        onBack={() => {
          navigate("/stuffs");
        }}
      ></SecondaryTopActionBar>
    </SubPageWrapper>
  );
}

export default PageBookmarksPlay;

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useAppContext } from "../../../contexts/AppContext";
import {
  faComment,
  faBookmark as faBookmarkLight,
  faThumbsUp as faThumbsUpLight,
  faThumbsDown as faThumbsDownLight,
  faCircleInfo,
  faCamera,
  faCommentExclamation,
  faTicket,
} from "@fortawesome/pro-light-svg-icons";
import { faBookmark, faThumbsUp, faThumbsDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlayerWatch from "../../players/PlayerWatch";
import CreatorThumbnails from "../../thumbnails/CreatorThumbnails";
import { ClickAwayListener } from "@mui/material";
import { faMemoCircleInfo } from "@fortawesome/pro-regular-svg-icons";

export default function ComponentDrawer({
  currentAsset,
  playerStatus,
  setPlaying,
  assetOwner,
  isBookmarked,
  onClick = () => {},
  openNoteEditor,
  setOpenNoteEditor,
}) {
  const [{ domainInfo }] = useAppContext();
  const [{ statusOfAssets, openComponent }, { setOpenComponent }] = useAppContext();
  const [isOpened, setOpened] = React.useState(false);

  const handleItemClick = (type) => {
    onClick(type);
  };

  const getIcon = (text) => {
    if (text === "PrivateComment") {
      return <FontAwesomeIcon icon={faCommentExclamation} size="xl" />;
    } else if (text === "Comment") {
      return <FontAwesomeIcon icon={faComment} size="xl" />;
    } else if (text === "Bookmark") {
      return (
        <>
          {isBookmarked ? (
            <FontAwesomeIcon
              icon={faBookmark}
              sx={{ fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" } }}
              size="xl"
            />
          ) : (
            <FontAwesomeIcon
              icon={faBookmarkLight}
              sx={{ fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" } }}
              size="xl"
            />
          )}
        </>
      );
    } else if (text === "Thumb up") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {statusOfAssets[currentAsset?.id]?.liked ? (
            <FontAwesomeIcon
              icon={faThumbsUp}
              sx={{ fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" } }}
              size="xl"
            />
          ) : (
            <FontAwesomeIcon
              icon={faThumbsUpLight}
              sx={{ fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" } }}
              size="xl"
            />
          )}
        </Box>
      );
    } else if (text === "Thumb down") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {statusOfAssets[currentAsset?.id]?.disliked ? (
            <FontAwesomeIcon
              icon={faThumbsDown}
              sx={{ fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" } }}
              size="xl"
            />
          ) : (
            <FontAwesomeIcon
              icon={faThumbsDownLight}
              sx={{ fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" } }}
              size="xl"
            />
          )}
        </Box>
      );
    } else if (text === "Information") {
      return <FontAwesomeIcon size="xl" icon={faCircleInfo} />;
    } else if (text === "Screenshot") {
      return <FontAwesomeIcon size="xl" icon={faCamera} />;
    } else if (text === "PlayerWatch") {
      return <PlayerWatch 
        openNoteEditor={openNoteEditor}
        setOpenNoteEditor={setOpenNoteEditor}
        playerStatus={playerStatus} 
        asset={currentAsset} 
        setVideoPlaying={setPlaying}>
        </PlayerWatch>;
    } else if (text === "CreatorThumbnail") {
      return (
        <Box sx={{ mx: 0.5 }}>
          <CreatorThumbnails creator={assetOwner} hideName={true} type="assetPlayer"></CreatorThumbnails>
        </Box>
      );
    } else if (text === "Info+") {
      return <FontAwesomeIcon icon={faMemoCircleInfo} size="xl" />;
    } else if (text === "Reservation") {
      return <FontAwesomeIcon size="xl" icon={faTicket} />;
    }
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => {
        setOpenComponent(false);
        setOpened(false);
      }}
      onKeyDown={() => {
        setOpenComponent(false);
        setOpened(false);
      }}
    >
      <List>
        {domainInfo?.componentList?.includes("CreatorThumbnail") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("CreatorThumbnail");
              }}
            >
              <ListItemIcon>{getIcon("CreatorThumbnail")}</ListItemIcon>
              <ListItemText primary={""} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {domainInfo?.componentList?.includes("PlayerWatch") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("PlayerWatch");
              }}
            >
              <ListItemIcon>{getIcon("PlayerWatch")}</ListItemIcon>
              <ListItemText primary={""} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {domainInfo?.componentList?.includes("Screenshot") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("Screenshot");
              }}
            >
              <ListItemIcon>{getIcon("Screenshot")}</ListItemIcon>
              <ListItemText primary={"Screenshot"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {domainInfo?.componentList?.includes("Information") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("Information");
              }}
            >
              <ListItemIcon>{getIcon("Information")}</ListItemIcon>
              <ListItemText primary={"Information"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {domainInfo?.componentList?.includes("Thumb up/down") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("Thumb up");
              }}
            >
              <ListItemIcon>{getIcon("Thumb up")}</ListItemIcon>
              <ListItemText primary={"Thumb up"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {domainInfo?.componentList?.includes("Thumb up/down") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("Thumb down");
              }}
            >
              <ListItemIcon>{getIcon("Thumb down")}</ListItemIcon>
              <ListItemText primary={"Thumb down"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {domainInfo?.componentList?.includes("Bookmark") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("Bookmark");
              }}
            >
              <ListItemIcon>{getIcon("Bookmark")}</ListItemIcon>
              <ListItemText primary={"Bookmark"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {domainInfo?.componentList?.includes("Comment") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("Comment");
              }}
            >
              <ListItemIcon>{getIcon("Comment")}</ListItemIcon>
              <ListItemText primary={"Comment"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {domainInfo?.componentList?.includes("Info+") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("Info+");
              }}
            >
              <ListItemIcon>{getIcon("Info+")}</ListItemIcon>
              <ListItemText primary={"Info+"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {domainInfo?.componentList?.includes("PrivateComment") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("PrivateComment");
              }}
            >
              <ListItemIcon>{getIcon("PrivateComment")}</ListItemIcon>
              <ListItemText primary={"Private Comment"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {domainInfo?.componentList?.includes("Reservation") ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleItemClick("Reservation");
              }}
            >
              <ListItemIcon>{getIcon("Reservation")}</ListItemIcon>
              <ListItemText primary={"Reservation"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
      </List>
    </Box>
  );

  return (
    <ClickAwayListener
      onClickAway={() => {
        console.log(openComponent);
        if (isOpened) {
          setOpened(false);
          setOpenComponent(false);
        } else {
          setOpened(openComponent);
        }
      }}
    >
      <Drawer
        anchor={"left"}
        open={openComponent}
        PaperProps={{
          sx: {
            position: "relative", //imp
            width: 220, //drawer width
            height: "100vh",
            "& .MuiDrawer-paper": {
              width: 220, //drawer width
              position: "absolute", //imp
              transition: "none !important",
            },
          },
        }}
        variant="persistent"
      >
        {domainInfo?.componentList ? list() : <></>}
      </Drawer>
    </ClickAwayListener>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import { Add as IconAdd } from "@mui/icons-material";

import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import SecondaryTopActionBar from "../../components/nav/SecondaryTopActionBar";
import { getAllMyAssetFolders } from "../../store/AssetFolder.slice";

function PageCollections() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllMyAssetFolders());
  }, [dispatch]);

  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            overflowY: "auto",
            px: 1.5,
            pb: 1.5,
          }}
        ></Box>
      </Box>
    </SubPageWrapper>
  );
}

export default PageCollections;

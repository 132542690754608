import React, { useState, useCallback } from "react";
import { Avatar, Box, Collapse, IconButton, Typography } from "@mui/material";
import {
  PlayArrow as IconPlay,
  ExpandMore as IconExpandMore,
  MoreHoriz as IconMoreHoriz,
  Edit as IconEdit,
  Delete as IconDelete,
} from "@mui/icons-material";
import { MoreOptionMenu } from "../../components/menus";
import { useConfirm } from "material-ui-confirm";
import { useSelector, useDispatch } from "react-redux";
import { AssetCard, ChannelCard, CreatorCard, UserGroupCard } from "../../components/CustomCards";
import { defaultFolderBackgroundColor } from "../../utils/styles";
import { getAvatarPath } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFolderIcon } from "../../utils/icons";
import { apiDeleteUserFolder } from "../../graphQL/userFolder";
import { actionDeleteUserFolder } from "../../store/UserFolder.slice";
import { faFolder } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function UserFolderWrapper({ open, onClick, userFolder, onEdit }) {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const assets = useSelector((store) => store.Asset.assets);
  const channels = useSelector((store) => store.Channel.channels);
  const users = useSelector((store) => store.User.users);
  const userGroups = useSelector((store) => store.UserGroup.userGroups);

  const getItemCountOfUserFolder = useCallback(() => {
    const countOfAssets =
      assets?.filter((asset) => userFolder?.assetIds?.find((t) => t?.id === asset?.id))?.length || 0;
    const countOfChannels =
      channels?.filter((channel) => userFolder?.channelIds?.find((t) => t === channel?.id))?.length || 0;
    const countOfUsers = users?.filter((user) => userFolder?.creatorIds?.find((t) => t === user?.id))?.length || 0;
    const countOfUserGroups =
      userGroups?.filter((userGroup) => userFolder?.groupIds?.find((t) => t === userGroup?.id))?.length || 0;
    return countOfAssets + countOfChannels + countOfUsers + countOfUserGroups;
  }, [
    assets,
    channels,
    users,
    userGroups,
    userFolder?.assetIds,
    userFolder?.channelIds,
    userFolder?.creatorIds,
    userFolder?.groupIds,
  ]);

  const itemCount = getItemCountOfUserFolder();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getFolderAvatarItem = (item) => {
    switch (item?.avatar?.type) {
      case "faIcon":
        return (
          <FontAwesomeIcon
            style={{
              cursor: "pointer",
              width: "2rem",
              height: "2rem",
            }}
            icon={getFolderIcon(item?.avatar?.faIcon)}
          />
        );
      case "image":
        return (
          <Avatar sx={{ width: "2rem", height: "2rem" }} variant="rounded" src={getAvatarPath(item.avatar)}></Avatar>
        );
      case "emoticon":
        return <Box sx={{ fontSize: "1.5rem" }}>{item?.avatar?.emoji}</Box>;
      default:
        return (
          <FontAwesomeIcon
            style={{
              cursor: "pointer",
              width: "2rem",
              height: "2rem",
            }}
            icon={faFolder}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        py: 0.5,
        px: 1,
        background: `${userFolder?.avatar?.style?.bg || defaultFolderBackgroundColor}af`,
        mb: 1,
      }}
    >
      <Box
        sx={{ borderBottom: "1px solid #fff", pb: 0.5 }}
        className="flex items-center justify-content-between"
        onClick={onClick}
      >
        <Box className="flex items-center">
          {getFolderAvatarItem(userFolder)}
          <Typography variant="h6" sx={{ pl: 1 }}>
            {userFolder?.name}
          </Typography>
        </Box>
        {itemCount > 0 && (
          <Box
            className="flex items-center"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/stuffs/play/${userFolder?.id}`);
            }}
          >
            <Typography variant="body1">Play All</Typography>
            <IconPlay></IconPlay>
          </Box>
        )}
      </Box>
      <Box className="flex items-center justify-content-between" sx={{ pt: 0.5, px: 0.5 }} onClick={onClick}>
        <Box className="flex items-center">
          <IconExpandMore sx={{ visibility: itemCount > 0 ? "visible" : "hidden" }}></IconExpandMore>
          <Typography variant="body2">{itemCount} bookmark</Typography>
        </Box>
        <Box>
          <IconButton
            size="small"
            sx={{ p: 0, position: "relative", left: "0.125rem" }}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <IconMoreHoriz></IconMoreHoriz>
          </IconButton>
        </Box>
      </Box>
      {itemCount > 0 && (
        <Collapse in={open}>
          <Box sx={{ pb: 0.5 }}>
            {assets
              ?.filter((asset) => userFolder?.assetIds?.find((t) => t?.id === asset?.id))
              ?.map((asset) => (
                <Box
                  key={asset?.id}
                  sx={{ p: 0.5, cursor: "pointer" }}
                  onClick={(e) => {
                    navigate(`/asset/play/${asset.id}`);
                  }}
                >
                  <AssetCard asset={asset}></AssetCard>
                </Box>
              ))}

            {channels
              ?.filter((channel) => userFolder?.channelIds?.find((t) => t === channel?.id))
              ?.map((channel) => (
                <Box key={channel?.id} sx={{ p: 0.5 }}>
                  <ChannelCard channel={channel}></ChannelCard>
                </Box>
              ))}

            {users
              ?.filter((user) => userFolder?.creatorIds?.find((t) => t === user?.id))
              ?.map((user) => (
                <Box key={user?.id} sx={{ p: 0.5 }}>
                  <CreatorCard creator={user}></CreatorCard>
                </Box>
              ))}

            {userGroups
              ?.filter((userGroup) => userFolder?.groupIds?.find((t) => t === userGroup?.id))
              ?.map((userGroup) => (
                <UserGroupCard key={userGroup?.id} userGroup={userGroup}></UserGroupCard>
              ))}
          </Box>
        </Collapse>
      )}
      <MoreOptionMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        menus={[
          {
            icon: <IconEdit></IconEdit>,
            title: "Edit",
            onClick: () => {
              handleMenuClose();
              onEdit();
            },
          },
          {
            icon: <IconDelete></IconDelete>,
            title: "Delete",
            onClick: () => {
              handleMenuClose();
              confirm({ title: "Delete user folder?", description: "Are you sure to remove this user folder?" })
                .then(() => {
                  apiDeleteUserFolder(userFolder?.id)
                    .then((res) => {
                      dispatch(actionDeleteUserFolder(userFolder?.id));
                    })
                    .catch((err) => {});
                })
                .catch(() => {});
            },
          },
        ]}
      ></MoreOptionMenu>
    </Box>
  );
}

export default UserFolderWrapper;

import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation createAppUser($input: CreateAppUserInput!) {
    createAppUser(input: $input) {
      user {
        id
        domain
        domainList
        locationId
        locationIdList
        name
        username
        desc {
          title
          short
          body
          website
          caption
          style
          rows
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        contact {
          id
          first
          last
          url
          media
          avatar {
            name
            dir
            base
            mimetype
            status
            faIcon
            emoji
            thumbnail
            style
          }
        }
        setting
        bookmarks
        subscriptions
        creatorAssetIds
        widgetBookmarks
        folders
        folderIds {
          id
          collectionName
        }
        linkedIds
        favorites
        zipCodes
        access
        keywords
        categories
        role
        googleCredential {
          clientId
          clientSecret
          redirectUrl
          refreshToken
        }
        activated
        defaultAssetFolder
        defaultUserFolder
        defaultMediaFolderId
        loginAt
        loginCount
        data
        timestamp
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateAppUser($input: UpdateAppUserInput!) {
    updateAppUser(input: $input) {
      user {
        id
        domain
        domainList
        locationId
        locationIdList
        name
        username
        desc {
          title
          short
          body
          website
          caption
          style
          rows
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        contact {
          id
          first
          last
          url
          media
          avatar {
            name
            dir
            base
            mimetype
            status
            faIcon
            emoji
            thumbnail
            style
          }
        }
        setting
        bookmarks
        subscriptions
        creatorAssetIds
        widgetBookmarks
        folders
        folderIds {
          id
          collectionName
        }
        linkedIds
        favorites
        zipCodes
        access
        keywords
        categories
        role
        googleCredential {
          clientId
          clientSecret
          redirectUrl
          refreshToken
        }
        activated
        defaultAssetFolder
        defaultUserFolder
        defaultMediaFolderId
        loginAt
        loginCount
        data
        timestamp
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteAppUser($id: ID!) {
    deleteAppUser(id: $id) {
      id
    }
  }
`;

export const DELETE_ALL_USERS = gql`
  mutation deleteAllUsers($input: DeleteAllInput!) {
    deleteAllUsers(input: $input) {
      total
    }
  }
`;

export const RESET_CAMPAIGN = gql`
  mutation resetBattle($input: ResetBattleInput!) {
    resetBattle(input: $input) {
      total
      users {
        id
        domain
        domainList
        locationId
        locationIdList
        name
        username
        desc {
          title
          short
          body
          website
          caption
          style
          rows
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        contact {
          id
          first
          last
          url
          media
          avatar {
            name
            dir
            base
            mimetype
            status
            faIcon
            emoji
            thumbnail
            style
          }
        }
        setting
        bookmarks
        subscriptions
        creatorAssetIds
        widgetBookmarks
        folders
        folderIds {
          id
          collectionName
        }
        linkedIds
        favorites
        zipCodes
        access
        keywords
        categories
        role
        googleCredential {
          clientId
          clientSecret
          redirectUrl
          refreshToken
        }
        activated
        defaultAssetFolder
        defaultUserFolder
        defaultMediaFolderId
        loginAt
        loginCount
        data
        timestamp
      }
    }
  }
`;
/* eslint-disable no-undef */
/* eslint-disable indent */
const ENV = process.env;

const AWS = {
  aws_project_region: ENV.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id: ENV.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: ENV.REACT_APP_AWS_REGION,
  aws_user_pools_id: ENV.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: ENV.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  oauth: {
    domain: ENV.REACT_APP_COGNITO_OAUTH_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: ENV.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut: ENV.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
    responseType: "token",
  },
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
  Storage: {
    bucket: ENV.REACT_APP_STORAGE_BUCKET_NAME,
    region: ENV.REACT_APP_AWS_REGION,
    identityPoolId: ENV.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
};

const DEV_TOOLS = {
  enableReduxDevTools: true,
  logError: true,
};

const copyWrite = "©2023 OpenView Tech Inc.";

const TINYMCE_API_KEY = ENV.REACT_APP_TINYMCE_API_KEY;

const URL = {
  REACT_APP_HLS_BASE_URL: process.env.REACT_APP_HLS_BASE_URL,
};

const GUEST = {
  email: "guest@openznet.com"
}

module.exports = {
  AWS,
  ENV,
  URL,
  DEV_TOOLS,
  copyWrite,
  TINYMCE_API_KEY,
  GUEST,
};

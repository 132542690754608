import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllUserGroups } from "../graphQL/userGroup";

export const getAllUserGroups = createAsyncThunk("userGroup/read_user_groups", async (filter) => {
  return await apiGetAllUserGroups(filter);
});

const userGroupSlice = createSlice({
  name: "UserGroup",
  initialState: {
    total: 0,
    userGroups: null,
  },
  reducers: {
    actionUpdateUserGroup: (state, action) => {
      if (state.userGroups) {
        if (state.userGroups.find((item) => item.id === action.payload.id)) {
          state.userGroups = state.userGroups.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.userGroups = [...state.userGroups, action.payload];
          state.total = state.userGroups.length;
        }
      } else {
        state.userGroups = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteUserGroup: (state, action) => {
      state.userGroups = state.userGroups?.filter((item) => item?.id !== action.payload);
      state.total = state.userGroups.length;
    },
  },
  extraReducers: {
    [getAllUserGroups.fulfilled]: (state, action) => {
      state.total = action.payload.total;
      state.userGroups = action.payload.userGroups;
    },
    [getAllUserGroups.rejected]: (state, action) => {
      state.total = 0;
      state.userGroups = null;
    },
  },
});

export const { actionUpdateUserGroup, actionDeleteUserGroup } = userGroupSlice.actions;
export default userGroupSlice.reducer;

export const getAssets = () => {
  const assets = localStorage.getItem("assets");
  if (!assets) {
    localStorage.setItem("assets", JSON.stringify({}));
    return {};
  }
  try {
    return JSON.parse(assets);
  } catch {
    localStorage.setItem("assets", JSON.stringify({}));
    return {};
  }
};

const saveAssets = (assets) => {
  localStorage.setItem("assets", JSON.stringify(assets));
};

const Storage = {
  isAssetLiked: (assetId) => {
    const assets = getAssets();
    if (assets[assetId]) {
      return assets[assetId].liked === true;
    }
    return false;
  },
  isAssetDisliked: (assetId) => {
    const assets = getAssets();
    if (assets[assetId]) {
      return assets[assetId].disliked === true;
    }
    return false;
  },
  setAssetLiked: (assetId, status = true) => {
    let assets = getAssets();
    if (assets[assetId]) {
      assets[assetId].liked = status;
      assets[assetId].disliked = false;
    } else {
      assets = {
        ...assets,
        [assetId]: {
          liked: status,
          disliked: false,
        },
      };
    }
    saveAssets(assets);
    return assets;
  },
  setAssetDisliked: (assetId, status = true) => {
    let assets = getAssets();
    if (assets[assetId]) {
      assets[assetId].liked = false;
      assets[assetId].disliked = status;
    } else {
      assets = {
        ...assets,
        [assetId]: {
          liked: false,
          disliked: status,
        },
      };
    }
    saveAssets(assets);
    return assets;
  },
  getAutoBookmarkFolderNo: () => {
    const t = localStorage.getItem("AUTO_BOOKMARK_FOLDER_NO") || 1;
    localStorage.setItem("AUTO_BOOKMARK_FOLDER_NO", parseInt(t) + 1);
    return t;
  },
};

export default Storage;

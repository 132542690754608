import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_USER_GROUP = gql`
  subscription onCreateUserGroup{
    onCreateUserGroup{
      userGroup{
        id
        name
        type
        domain
        locationId
        parentNodeId
        avatar {
            name
            dir
            base
            status
        }
        desc {
            title
            short
            body
            caption
        }
        keywords
        categories
        ownerId
        authors
        accessPermission
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export const ON_UPDATE_USER_GROUP = gql`
  subscription onUpdateUserGroup{
    onUpdateUserGroup{
      userGroup{
        id
        name
        type
        domain
        locationId
        parentNodeId
        avatar {
            name
            dir
            base
            status
        }
        desc {
            title
            short
            body
            caption
        }
        keywords
        categories
        ownerId
        authors
        accessPermission
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export const ON_DELETE_USER_GROUP = gql`
  subscription onDeleteUserGroup{
    onDeleteUserGroup{
      id
    }
  }
`;

const useOnCreateUserGroup= () => useSubscription(ON_CREATE_USER_GROUP);
const useOnUpdateUserGroup= () => useSubscription(ON_UPDATE_USER_GROUP);
const useOnDeleteUserGroup= () => useSubscription(ON_DELETE_USER_GROUP);

export { useOnCreateUserGroup, useOnUpdateUserGroup, useOnDeleteUserGroup};

import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { removeOtherProfile, setOtherProfile } from "../../store/OtherProfile";

function DialogSelectUser({ open, handleClose, userInfo }) {
  const users = useSelector((store) => store.User.users);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const [selected, setSelected] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      setSelected(userInfo);
    } else {
      setSelected(PrivateInfo)
    }
  }, [userInfo, PrivateInfo])

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogContent>
      <Select
        value={selected?.name}
        onChange={(e) => {
          const name = e.target.value;
          const user = users.find(item => item.name === name);
          setSelected(user);
        }}
        sx={{
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            py: 0.5,
            ".MuiListItemIcon-root": {
              minWidth: 32,
            },
          },
        }}
      >
        {users?.filter(item => item.domain === PrivateInfo?.domain)?.map((item) => (
          <MenuItem key={item.id} value={item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleClose();
            if (selected.name !== PrivateInfo?.name) {
              dispatch(setOtherProfile(selected));
            } else {
              dispatch(removeOtherProfile());
            }
          }}
        >
          Switch
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogSelectUser;

import { gql } from "@apollo/client";

const CREATE_ASSET_FOLDER = gql`
  mutation createMediaFolder($input: CreateMediaFolderInput!) {
    createMediaFolder(input: $input) {
      mediaFolder {
        id
        name
        owner
        parentNode
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        childNodeIds
        keywords
        categories
        style
        owner
        status
        timestamp
        updatedAt
        availability {
          from
          to
        }
        locationId
        domain
        data
      }
    }
  }
`;

const UPDATE_ASSET_FOLDER = gql`
  mutation updateMediaFolder($input: UpdateMediaFolderInput!) {
    updateMediaFolder(input: $input) {
      mediaFolder {
        id
        name
        owner
        parentNode
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        childNodeIds
        keywords
        categories
        style
        owner
        status
        timestamp
        updatedAt
        availability {
          from
          to
        }
        locationId
        domain
        data
      }
    }
  }
`;

const DELETE_ASSET_FOLDER = gql`
  mutation deleteMediaFolder($id: ID!) {
    deleteMediaFolder(id: $id) {
      id
    }
  }
`;

const DELETE_ALL_ASSET_FOLDERS = gql`
  mutation deleteAllMediaFolders($input: DeleteAllInput!) {
    deleteAllMediaFolders(input: $input) {
      total
    }
  }
`;

export { CREATE_ASSET_FOLDER, UPDATE_ASSET_FOLDER, DELETE_ASSET_FOLDER, DELETE_ALL_ASSET_FOLDERS };

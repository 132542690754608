import client from "../index.js";
import { GET_LOCATIONS } from "./query.js";
import { CREATE_LOCATION, UPDATE_LOCATION, DELETE_LOCATION, DELETE_ALL_LOCATIONS } from "./mutation";

export const apiGetAllLocations = (filter = {}) => {
  return client
    .query({
      query: GET_LOCATIONS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.locations;
    });
};

export const apiCreateLocation = (data) => {
  return client
    .mutate({
      mutation: CREATE_LOCATION,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createLocation.location;
    });
};

export const apiUpdateLocation = (data) => {
  return client
    .mutate({
      mutation: UPDATE_LOCATION,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateLocation.location;
    });
};

export const apiDeleteLocation = (id) => {
  return client
    .mutate({
      mutation: DELETE_LOCATION,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteLocation.id;
    });
};

export const apiDeleteAllLocations = (filter = {}) => {
  return client
    .mutate({
      mutation: DELETE_ALL_LOCATIONS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAllLocations?.total;
    });
};

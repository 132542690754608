import { gql } from "@apollo/client";

const CREATE_RESERVATION = gql`
  mutation createReservation($input: CreateReservationInput!) {
    createReservation(input: $input) {
      reservation {
        id
        schemaVer
        authorId
        creatorId
        assetId
        comment
        reply
        count
        status
        createdAt
        updatedAt
      }
    }
  }
`;

const UPDATE_RESERVATION = gql`
  mutation updateReservation($input: UpdateReservationInput!) {
    updateReservation(input: $input) {
      reservation {
        id
        schemaVer
        authorId
        creatorId
        assetId
        comment
        reply
        count
        status
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_RESERVATION = gql`
  mutation deleteReservation($id: ID!) {
    deleteReservation(id: $id) {
      id
    }
  }
`;

export { CREATE_RESERVATION, UPDATE_RESERVATION, DELETE_RESERVATION };

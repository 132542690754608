import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import SubPageWrapper, { SubPageContentWrapper } from "../../layouts/wrappers/SubPageWrapper";
import { getAllUsers } from "../../store/User.slice";
import DescriptionCard from "../../components/CustomCards/DescriptionCard/DescriptionCard";
import { apiGetAllAssets } from "../../graphQL/asset";

function Desc() {
  const dispatch = useDispatch();
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  // const assets = useSelector((store) => store.Asset.assets);
  const [assets, setAssets] = useState()

  useEffect(() => {
    apiGetAllAssets({
      //type: "groupAsset",
      domain: PrivateInfo.domain,
      ordering: {
        field: "timestamp",
        order: "Desc",
      },
      pagination: {
        page: 1,
        limit: 32768,
      },
    }).then(res => {
      setAssets(res.mediaAssets);
    })
  }, [])

  useEffect(() => {
    if (PrivateInfo?.domain) {
      dispatch(getAllUsers({ domain: PrivateInfo?.domain }));
    }
  }, [PrivateInfo?.domain, dispatch]);

  return (
    <SubPageWrapper>
      <SubPageContentWrapper>
        <Grid container spacing={{ xs: 1, sm: 1.25, md: 1.5 }}>
          {assets?.map((t) => (
            <Grid
              item
              key={t.id}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              minHeight={'44vh'}
            >
              <DescriptionCard asset={t}></DescriptionCard>
            </Grid>
          ))}
        </Grid>
      </SubPageContentWrapper>
    </SubPageWrapper>
  );
}

export default Desc;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Avatar, Typography, IconButton } from "@mui/material";
import { Favorite as IconFavorite } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarPath, getAvatarThumbnailPath } from "../../../utils";
import { apiUpdateUserFolder } from "../../../graphQL/userFolder";
import { actionUpdateUserFolder } from "../../../store/UserFolder.slice";

import "./NewCreatorCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentExclamation } from "@fortawesome/pro-solid-svg-icons";
import { faImage } from "@fortawesome/pro-regular-svg-icons";

export default function NewCreatorCard({ creator, onClick = () => {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userFolders = useSelector((store) => store.UserFolder?.userFolders);

  const isFollowing = useSelector((store) => store.UserFolder?.userFolders)?.some((t) =>
    t.creatorIds?.includes(creator?.id)
  );

  return (
    <Box className="w-full">
      <Box
        sx={{ paddingTop: "133%", cursor: "pointer" }}
        className="relative w-full new-creator-card"
        onClick={() => {
          navigate(`/creator/play/${creator?.id}`);
        }}
      >
        {(creator?.desc?.title || creator?.desc?.short) && (
          <Box
            className="absolute w-full new-creator-card__content"
            sx={{ bottom: "8%", zIndex: 99, background: "rgba(87, 87, 87, 0.5)", pt: 0.5, pb: 1 }}
          >
            <Box>
              <Typography variant="body2" align="center" sx={{ fontSize: "0.75rem" }}>
                {creator?.desc?.title}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" align="center" sx={{ fontSize: "0.625rem" }}>
                {creator?.desc?.short}
              </Typography>
            </Box>
          </Box>
        )}
        <Box className="absolute" sx={{ top: 0, left: 0, zIndex: 99 }}>
          <IconButton
            size="small"
            onClick={async (e) => {
              e.stopPropagation();
              onClick("PrivateComment");
            }}
          >
            <FontAwesomeIcon icon={faCommentExclamation} />
          </IconButton>{" "}
        </Box>
        <Box className="absolute" sx={{ top: 0, right: 0, zIndex: 99 }}>
          <IconButton
            size="small"
            onClick={async (e) => {
              e.stopPropagation();
              if (isFollowing) {
                await userFolders?.map(async (userFolder) => {
                  if (userFolder?.creatorIds?.includes(creator?.id)) {
                    apiUpdateUserFolder({
                      id: userFolder?.id,
                      creatorIds: userFolder?.creatorIds?.filter((t) => t !== creator?.id),
                    }).then((res) => {
                      dispatch(actionUpdateUserFolder(res));
                    });
                  }
                });
              } else {
                if (userFolders?.length > 0) {
                  const folder = userFolders[0];
                  apiUpdateUserFolder({
                    id: folder?.id,
                    creatorIds: [...(folder.creatorIds || []), creator?.id],
                  }).then((res) => {
                    dispatch(actionUpdateUserFolder(res));
                  });
                }
              }
            }}
          >
            {/* <IconFavorite
              sx={{
                color: isFollowing ? "#F13256" : "#fff",
              }}
            ></IconFavorite> */}
            <Box
              sx={{
                background: !isFollowing ? "#FF0012" : "#ffffff",
                color: !isFollowing ? "#ffffff" : "#FF0012",
                border: !isFollowing ? "2px solid #FF0012" : "2px solid #FF0012",
                width: 108,

                borderRadius: 4,
              }}
              display="flex"
              justifyContent={"center"}
              alignItems="center"
            >
              <IconFavorite fontSize="small" sx={{ mr: 0.75 }}></IconFavorite>
              <Typography variant="body1" fontWeight={600}>
                {isFollowing ? "Unfollow" : "Follow"}
              </Typography>
            </Box>
          </IconButton>
        </Box>
        <Box
          className="absolute w-full h-full new-creator-card__background"
          sx={{
            top: 0,
            left: 0,
            background: "radial-gradient(#1fe4f5, #3fbafe)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {getAvatarThumbnailPath(creator?.avatar) ? (
            <Avatar
              variant="rounded"
              src={getAvatarThumbnailPath(creator?.avatar)}
              style={{ width: "100%", height: "100%" }}
              imgProps={{
                sx: {
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  borderRadius: "12px",
                  draggable: "false",
                },
              }}
              alt="Creator Avatar"
            />
          ) : (
            <FontAwesomeIcon size="4x" icon={faImage} />
          )}
        </Box>
      </Box>
      <Box className="flex items-center justify-content-between" sx={{ mt: 1 }}>
        {/* {getAvatarPath(creator?.avatar) && ( */}
        <Avatar src={getAvatarPath(creator?.avatar)} sx={{ width: "2rem", height: "2rem" }}></Avatar>
        {/* )} */}
        <Box sx={{ pl: 0.5 }} flexGrow={1}>
          <Typography varaint="body2">{creator?.username}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

import { gql } from "@apollo/client";

const GET_NOTES = gql`
  query notes($input: NotesInput!) {
    notes(input: $input) {
      total
      notes {
        id
        owner
        linked {
          id
          type
        }
        notes {
          uId
          start
          duration
          note
          file {
            name
            directory
            url
            status
          }
        }
        data
      }
    }
  }
`;

export { GET_NOTES };

import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  FormControlLabel,
  IconButton,
  Autocomplete,
  TextField,
  DialogTitle,
  Typography,
  Button,
} from "@mui/material";
import { Edit as IconEdit } from "@mui/icons-material";
import { CheckBoxOutlineBlank as IconCheckBoxOutlineBlank, CheckBox as IconCheckBox } from "@mui/icons-material";
import { ButtonSave } from "../buttons";

const icon = <IconCheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <IconCheckBox fontSize="small" />;

const MultiSelectPopup = ({
  handleSelect,
  title,
  options = [],
  selected = [],
  sx = {},
}) => {
  const [isMultiselectDomainOpen, setMultiselectDomainOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(selected || []);

  const isAllSelected = () => {
    let result = true;
    for (let i = 0; i < options.length; i++) {
      if (selectedItems?.find((option) => option === options[i].value) === undefined) {
        result = false;
        break;
      }
    }

    return result;
  };

  return (
    <>
      <Box
        sx={{
          ...sx,
          position: "relative",
        }}
      >
        <Autocomplete
          disableClearable={true}
          multiple
          forcePopupIcon={false}
          id="tags-outlined"
          options={options?.map((t) => t.label)}
          disablePortal={true}
          value={options.filter(item => selected.includes(item.value)).map(item => item.label) || []}
          onChange={(event, value) => {
            handleSelect(options.filter(item => value.includes(item.label)).map(item => item.value));
          }}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label={title} />}
          ListboxProps={{
            style: {
              display: 'none',
              maxHeight: "250px",
              zIndex: 1000,
            },
          }}
        />
        <IconButton
          onClick={() => {
            setMultiselectDomainOpen(true);
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
          size="small"
        >
          <IconEdit fontSize="small"></IconEdit>
        </IconButton>
      </Box>
      <Dialog
        open={isMultiselectDomainOpen}
        onClose={() => setMultiselectDomainOpen(false)}
        maxWidth={"sm"}
        fullWidth
      >
      <DialogTitle sx={{ p: 0.5 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box flex={1} sx={{ pl: 1 }}>
            <Typography variant="h6">{title}</Typography>
          </Box>
        </Box>
      </DialogTitle>
        <DialogContent sx={{ px: 1.5 }}>
          <Box sx={{ pt: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Checkbox checked={isAllSelected()} />}
                  value={"all"}
                  label="All"
                  onChange={(item) => {
                    if (item.target.checked) {
                      let selectedOption = options.map((option) => option.value);
                      setSelectedItems(selectedOption);
                    } else {
                      setSelectedItems([]);
                    }
                  }}
                />
              </Grid>
              {options.map((option, index) => {
                return (
                  <Grid key={`option_${index}`} item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={selectedItems?.find((item) => item === option.value) !== undefined} />
                      }
                      value={option.value}
                      label={option.label}
                      onChange={(item) => {
                        const selectedItemsCopy = [...(selectedItems || [])];
                        if (item.target.checked) {
                          selectedItemsCopy.push(item.target.value);
                        } else {
                          const selectedItemIndex = selectedItemsCopy.findIndex(
                            (itemToRemove) => itemToRemove === item.target.value
                          );
                          if (selectedItemIndex !== -1) {
                            selectedItemsCopy.splice(selectedItemIndex, 1);
                          }
                        }

                        setSelectedItems(selectedItemsCopy);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end", px: 1, pb: 1 }}>
          <Button
            color="warning"
            onClick={() => {
              setSelectedItems([]);
            }}
          >Clear</Button>
          <ButtonSave
            loading={false}
            onClick={() => {
              setMultiselectDomainOpen(false);
              handleSelect(selectedItems);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MultiSelectPopup;

import React, { useEffect, useRef, useState } from "react";
import { PlayArrow as IconPlay, Pause as IconPause } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import WaveSurfer from "wavesurfer.js";
import { WaveformContianer, Wave, PlayButton } from "./Waveform.styled";

function Waveform({ audioUrl = "", playing = false, setPlaying = () => {} }) {
  const track = useRef(null);
  // const [isPlaying, setPlaying] = useState(false);
  const [waveform, setWaveform] = useState(null);

  useEffect(() => {
    const waveform = WaveSurfer.create({
      barWidth: 0,
      cursorWidth: 0,
      container: "#waveform",
      backend: "WebAudio",
      height: 80,
      progressColor: "#2D5BFF",
      responsive: true,
      waveColor: "#EFEFEF",
      cursorColor: "transparent",
    });

    setWaveform(waveform);
  }, []);

  useEffect(() => {
    if (waveform) {
      waveform.load(track.current);
    }
  }, [waveform]);

  useEffect(() => {
    if (waveform) {
      if (playing) {
        waveform.play();
      } else {
        waveform.pause();
      }
    }
  }, [playing]);

  const handlePlay = () => {
    setPlaying(!playing);
  };

  return (
    <WaveformContianer>
      <IconButton size="large" onClick={handlePlay} color="success" sx={{ backgroundColor: "#d2d2d266" }}>
        {playing ? <IconPause sx={{ fontSize: "3rem" }}></IconPause> : <IconPlay sx={{ fontSize: "3rem" }}></IconPlay>}
      </IconButton>
      <Wave id="waveform" />
      <audio id="track" ref={track} src={audioUrl} />
    </WaveformContianer>
  );
}

export default Waveform;

import React from "react";
import { Routes, Route } from "react-router-dom";

import PublicLayout from "../layouts/PublicLayout";
import PagePublicAsset from "../pages/PublicAsset/PublicAsset";
import Public404 from "../layouts/pages/Public404";

function PublicRoutes() {
  return (
    <Routes>
      <Route path="" element={<PublicLayout></PublicLayout>}>
        <Route path="asset" element={<PagePublicAsset></PagePublicAsset>}></Route>
      </Route>
      <Route path="*" element={<Public404></Public404>}></Route>
    </Routes>
  );
}

export default PublicRoutes;

import React from "react";
import { useSelector } from "react-redux";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import SubPageWrapper from "../../../layouts/wrappers/SubPageWrapper";
import SecondaryTopActionBar from "../../../components/nav/SecondaryTopActionBar";
import { useNavigate, useParams } from "react-router-dom";
import AssetCardPlayer from "./AssetCardPlayer";

import "./PageAssetCardDetail.scss";

function PageAssetCardDetail() {
  const navigate = useNavigate();
  const assets = useSelector((store) => store.Asset.assets);
  const users = useSelector((store) => store.User.users);

  const { assetId } = useParams();

  const user = users?.find((t) => t.id === assetId);
  if (!assets || !assetId)
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>;

  if (users && !user) navigate("/stuffs");

  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <AssetCardPlayer playList={assets?.filter((asset) => asset.id === assetId)}></AssetCardPlayer>
      </Box>
      <SecondaryTopActionBar
        title={assets?.filter((asset) => asset.id === assetId)[0]?.desc?.title}
      ></SecondaryTopActionBar>
    </SubPageWrapper>
  );
}

export default PageAssetCardDetail;

import client from "../index.js";
import { GET_ASSET, GET_ASSETS } from "./query.js";
import { CREATE_ASSET, UPDATE_ASSET, DELETE_ASSET, DELETE_ALL_ASSETS } from "./mutation";

export const apiGetAsset = (id) => {
  return client
    .query({
      query: GET_ASSET,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res?.data?.mediaAsset;
    });
};

export const apiGetAllAssets = (filter = {}) => {
  return client
    .query({
      query: GET_ASSETS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.mediaAssets;
    });
};

export const apiCreateAsset = (data) => {
  return client
    .mutate({
      mutation: CREATE_ASSET,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createMediaAsset.mediaAsset;
    });
};

export const apiUpdateAsset = (data) => {
  return client
    .mutate({
      mutation: UPDATE_ASSET,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateMediaAsset.mediaAsset;
    });
};

export const apiDeleteAsset = (id) => {
  return client
    .mutate({
      mutation: DELETE_ASSET,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteMediaAsset.id;
    });
};

export const apiDeleteAllAssets = (filter) => {
  return client
    .mutate({
      mutation: DELETE_ALL_ASSETS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAllMediaAssets?.total;
    });
};

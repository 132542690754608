import client from "../index.js";
import { GET_NOTES } from "./query.js";
import { CREATE_NOTE, UPDATE_NOTE } from "./mutation";

export const apiGetNote = (ownerId, type, id) => {
  return client
    .query({
      query: GET_NOTES, //GET_ASSET_PLAYLISTS,
      variables: {
        input: {
          owner: ownerId,
          linked: {
            id,
            type,
          },
        },
      },
      fetchPolicy: "no-cache",
    })
    .then((data) => {
      const total = data?.data?.notes?.total;
      if (total > 0 && data?.data?.notes?.notes?.length > 0) {
        return data?.data?.notes?.notes[0];
      }
      return null;
    });
};

export const apiCreateNote = (data) => {
  return client
    .mutate({
      mutation: CREATE_NOTE,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createNote.note;
    });
};

export const apiUpdateNote = (data) => {
  return client
    .mutate({
      mutation: UPDATE_NOTE,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateNote.note;
    });
};

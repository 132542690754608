import { gql } from "@apollo/client";

const CREATE_DOMAIN = gql`
  mutation createDomain($input: CreateDomainInput!) {
    createDomain(input: $input) {
      domain {
        id
        name
        avatar {
          name
          dir
          base
          mimetype
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        data
        authCode
        userRole
        accessList
        componentList
        company {
          name
          url
          phone
          address
          imageURL
        }
        groupNum
        SE_conversionTypes
        isTranscribeEnabled
        state
        type
        domainLinkIds
        fetchCount
        bannerList {
          title
          short
          imageUrl
        }
        imageList
        timestamp
        updatedAt
      }
    }
  }
`;

const UPDATE_DOMAIN = gql`
  mutation updateDomain($input: UpdateDomainInput!) {
    updateDomain(input: $input) {
      domain {
        id
        name
        avatar {
          name
          dir
          base
          mimetype
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        data
        authCode
        userRole
        company {
          name
          url
          phone
          address
          imageURL
        }
        accessList
        componentList
        groupNum
        SE_conversionTypes
        isTranscribeEnabled
        state
        type
        domainLinkIds
        fetchCount
        bannerList {
          title
          short
          imageUrl
        }
        imageList
        timestamp
        updatedAt
      }
    }
  }
`;

const DELETE_DOMAIN = gql`
  mutation onDeleteDomain($id: ID!) {
    deleteDomain(id: $id) {
      id
    }
  }
`;

export { CREATE_DOMAIN, UPDATE_DOMAIN, DELETE_DOMAIN };

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Backdrop, CircularProgress, IconButton } from "@mui/material";
import {
  Add as IconAdd,
  HighlightAlt as IconHighlightAlt,
  Title as IconTitle,
  InsertPhoto as IconInsertPhoto,
  EmojiEmotions as IconEmojiEmotions,
} from "@mui/icons-material";
import { apiGetAsset } from "../../graphQL/asset";
import { v4 as uuid_v4 } from "uuid";
import BottomActionBar from "../../components/nav/BottomActionBar";
import { WIDGET_TYPES } from "../../utils/constants";
import { WIDGET_ICONS_ARRAY } from "../../utils/icons";
import VideoEditor from "./components/VideoEditor";

function MyAssetWidgetEditor() {
  const navigate = useNavigate();
  const { assetId } = useParams();

  const [asset, setAsset] = useState(null);
  const [editWidget, setEditWidget] = useState(null);
  const [isOnEditDesc, setOnEditDesc] = useState(false);
  const [addWidget, setAddWidget] = useState(false);

  useEffect(() => {
    if (!assetId) navigate("/myvideos");
    apiGetAsset(assetId)
      .then((asset) => {
        setAsset(asset);
      })
      .catch((err) => {
        console.log(err);
        navigate("/myvideos");
      });
  }, [assetId, navigate]);

  if (!asset)
    return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return (
    <Box className="w-full h-full relative" display="flex" flexDirection="column">
      <Box className="relative" flexGrow="1">
        <Box className="cover-wrapper" sx={{ zIndex: 10 }}>
          <VideoEditor asset={asset} setAsset={setAsset} addNew={addWidget} setAddNew={setAddWidget}/>
        </Box>
      </Box>
      <BottomActionBar
        right={
          <Box>
            <IconButton
              onClick={() => {
                setOnEditDesc(true);
                setEditWidget({
                  guid: uuid_v4(),
                  name: "",
                  type: "box",
                  desc: {},
                });
              }}
              size="small"
              component="label"
            >
              <IconTitle></IconTitle>
            </IconButton>
            <IconButton onClick={() => {}} size="small" component="label">
              <IconInsertPhoto></IconInsertPhoto>
            </IconButton>
            <IconButton onClick={() => {
              setAddWidget(true);
            }} size="small" component="label">
              <IconHighlightAlt></IconHighlightAlt>
            </IconButton>
            <IconButton
              onClick={() => {
                setOnEditDesc(true);
                setEditWidget({
                  guid: uuid_v4(),
                  name: "",
                  type: WIDGET_TYPES.faIcon,
                  faIcon: WIDGET_ICONS_ARRAY[0].key,
                  desc: {},
                  style: {
                    color: "#1890FF",
                  },
                });
              }}
              size="small"
              component="label"
            >
              <IconEmojiEmotions></IconEmojiEmotions>
            </IconButton>
          </Box>
        }
      ></BottomActionBar>
    </Box>
  );
}

export default MyAssetWidgetEditor;

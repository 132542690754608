import React from "react";
import { Box } from "@mui/material";

export function SubPageContentWrapper({ children }) {
  return (
    <Box flexGrow={1} sx={{ position: "relative" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          overflowY: "auto",
          px: { xs: 1, md: 2 },
          py: { xs: 1, md: 2 },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

function SubPageWrapper({ children }) {
  return (
    <Box
      sx={{
        height: "100%",
        maxWidth: "100%",
        width: "100%",
        position: "relative",
      }}
      display="flex"
      flexDirection="column"
    >
      {children}
    </Box>
  );
}

export default SubPageWrapper;

import { Avatar, Box, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getTimeDifference, getUserThumbnailPath } from '../../../utils'
import Icons from '../../../assets/Icons'
import {
  ThumbUp,
  ThumbUpOffAlt,
  ThumbDown,
  ThumbDownOffAlt
} from "@mui/icons-material";
import { useSelector } from 'react-redux';

function CommentItem({
  comment = {},
  onClick =() => {}
}) {

  const [creator, setCreator] = useState();
  const users = useSelector((store) => store.User.users);
  
  useEffect(() => {
    if (comment?.authorId) {
      const user = users?.find((t) => t.id === comment?.authorId);
      setCreator(user);
    }
  }, [comment])

  const handleLike = (type) => {
    const status = getLikeStatus(type);
    if (type === 'liked') {
      const liked = localStorage.getItem('liked') || ""
      if (!status) {
        localStorage.setItem('liked', `${liked}:${comment?.id}`);
      } else {
        localStorage.setItem('liked', liked.replace(`:${comment?.id}`, ''));
      }
    } else if (type === 'disliked') {
      const disliked = localStorage.getItem('disliked') || ""
      if (!status) {
        localStorage.setItem('disliked', `${disliked}:${comment?.id}`);
      } else {
        localStorage.setItem('disliked', disliked.replace(`:${comment?.id}`, ''));
      }
    }
    onClick(type, !status);
  }

  const getLikeStatus = (type) => {
    if (type === 'liked') {
      const liked = localStorage.getItem('liked') || ""
      return liked.includes(comment?.id);
    } else if (type === 'disliked') {
      const disliked = localStorage.getItem('disliked') || ""
      return disliked.includes(comment?.id);
    }
    return false;
  }

  return (
    <Box sx={{display: 'flex', color: 'black'}}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', px: 1, pt: 1 }}>
        <Avatar
          src={getUserThumbnailPath(creator?.avatar)}
          sx={{
            width: { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
            height: { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
            color: 'gray',
            backgroundColor: "#fff",
            fontSize: { xs: "2.75rem", sm: "3rem", md: "3.25rem" },
          }}
        >
          <Icons.IconDefaultAvatar></Icons.IconDefaultAvatar>
        </Avatar>
      </Box>
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
      }}>
          <Typography variant='body2' sx={{fontWeight: 600}}>{creator?.name || "author name"}</Typography>
          <Typography variant='body1'>{comment?.content || "comment here"}</Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}>
            <Typography color='gray'>{getTimeDifference(comment?.createdAt) || '2h'}</Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row'
            }}>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <IconButton onClick={() => {handleLike('liked')}}>{getLikeStatus('liked') ? <ThumbUp/> : <ThumbUpOffAlt/>}</IconButton>
                <Typography variant='caption'>{comment?.liked}</Typography>
              </Box>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <IconButton onClick={() => {handleLike('disliked')}}>{getLikeStatus('disliked') ? <ThumbDown/> : <ThumbDownOffAlt/>}</IconButton>
                <Typography variant='caption'>{comment?.disliked}</Typography>
              </Box>
            </Box>
          </Box>
      </Box>
    </Box>
  )
}

export default CommentItem;

import React, { useState, useEffect, useRef } from "react";
import { Avatar, Box, IconButton } from "@mui/material";
import {
  PhotoCamera as IconPhotoCamera,
  AccountCircle as IconAccountCircle,
  Close as IconClose,
} from "@mui/icons-material";

const AvatarUpload = ({ url, onChange, Icon, variant, onDelete }) => {
  const buttonRef = useRef();
  const [avatarSrc, setAvatarSrc] = useState(null);

  const handleOnChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setAvatarSrc(URL.createObjectURL(e.target.files[0]));
      return onChange(e.target.files[0]);
    }
    return onChange(null);
  };

  useEffect(() => {
    setAvatarSrc(url);
  }, [url]);

  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
      {
        <Avatar
          alt="Avatar"
          src={avatarSrc}
          imgProps={{
            style: {
              maxHeight: 200,
              maxWidth: "100%",
              objectFit: "contain",
            },
          }}
          sx={{
            width: "100%",
            height: "100%",
            fontSize: 90,
          }}
          variant={variant}
          onClick={() => {
            buttonRef.current.click();
          }}
        >
          <Icon fontSize="inherit"></Icon>
        </Avatar>
      }
      {(url || avatarSrc) && (
        <IconButton
          size="small"
          variant="contained"
          aria-label="delete picture"
          component="label"
          sx={{
            position: "absolute",
            top: -8,
            right: variant === "circular" ? "0px" : "-8px",
            zIndex: 10,
          }}
          onClick={() => {
            setAvatarSrc(null);
            onDelete();
          }}
        >
          <IconClose />
        </IconButton>
      )}
      <input hidden accept="image/*" type="file" onChange={handleOnChange} ref={buttonRef} />{" "}
    </Box>
  );
};

AvatarUpload.defaultProps = {
  Icon: IconAccountCircle,
  variant: "circular",
};

export default AvatarUpload;

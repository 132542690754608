import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_ASSET = gql`
  subscription onCreateMediaAsset {
    onCreateMediaAsset {
      mediaAsset {
        id
        domain
        locationId
        keywords
        name
        owner
        type
        desc {
          title
          short
          body
          website
          caption
        }
        categories
        status
        parentNodeId
        stockAudioId
        file {
          name
          # signedUrl
          contentType
          directory
          outputHls
          outputDash
          displayName
          outputCC
        }
        timestamp
        rank
        highlight
        updatedAt
        videoWidgets {
          id
          assetId
          name
          type
          desc {
            title
            short
            body
            website
            caption
            style
            rows
          }
          boxInfo {
            x
            y
            width
            height
            videoWidth
            videoHeight
            foreground
          }
          videoPos {
            startAt
            duration
          }
          data
          keyword
          videoWidgetType
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
      }
    }
  }
`;

export const ON_UPDATE_ASSET = gql`
  subscription onUpdateMediaAsset {
    onUpdateMediaAsset {
      mediaAsset {
        id
        domain
        locationId
        keywords
        name
        owner
        type
        desc {
          title
          short
          body
          website
          caption
        }
        categories
        status
        parentNodeId
        stockAudioId
        file {
          name
          # signedUrl
          contentType
          directory
          outputHls
          outputDash
          displayName
          outputCC
        }
        timestamp
        rank
        highlight
        updatedAt
        videoWidgets {
          id
          assetId
          name
          type
          desc {
            title
            short
            body
            website
            caption
            style
            rows
          }
          boxInfo {
            x
            y
            width
            height
            videoWidth
            videoHeight
            foreground
          }
          videoPos {
            startAt
            duration
          }
          data
          keyword
          videoWidgetType
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
      }
    }
  }
`;

export const ON_DELETE_ASSET = gql`
  subscription onDeleteMediaAsset {
    onDeleteMediaAsset {
      id
    }
  }
`;

const useOnCreateAsset = () => useSubscription(ON_CREATE_ASSET);
const useOnUpdateAsset = () => useSubscription(ON_UPDATE_ASSET);
const useOnDeleteAsset = () => useSubscription(ON_DELETE_ASSET);

export { useOnCreateAsset, useOnUpdateAsset, useOnDeleteAsset };

import { gql } from "@apollo/client";

const GET_LOCATION = gql`
  query location($id: ID!) {
    location(id: $id) {
      id
      name
      data
      authCode
      domain
      avatar {
        name
        dir
        base
        mimetype
      }
      geoLocation {
        pinPoints {
          lat
          lng
        }
        polygon {
          lat
          lng
        }
        zipCode
        fips
        region {
          center {
            lat
            lng
          }
          radius
          unit
        }
      }
      defaultFolderId
      timestamp
      updatedAt
    }
  }
`;

const GET_LOCATIONS = gql`
  query locations($input: LocationsInput!) {
    locations(input: $input) {
      total
      locations {
        id
        name
        data
        authCode
        domain
        avatar {
          name
          dir
          base
          mimetype
        }
        geoLocation {
          pinPoints {
            lat
            lng
          }
          polygon {
            lat
            lng
          }
          zipCode
          fips
          region {
            center {
              lat
              lng
            }
            radius
            unit
          }
        }
        defaultFolderId
        timestamp
        updatedAt
      }
    }
  }
`;

export { GET_LOCATION, GET_LOCATIONS };

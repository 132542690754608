import _ from "lodash";

export const getFileExt = (name) => {
  const re = /(?:\.([^.]+))?$/;

  return re.exec(name)[1];
};

export const getFileName = (name) => {
  if (name.includes(".")) return name.substring(0, name.lastIndexOf("."));
  else return name;
};

export const isEmpty = (value) => {
  if (value === undefined || value === null) return true;
  if (typeof value === "string" && value.length === 0) return true;
  if (typeof value === "object" && Object.keys(value).length === 0) return true;
  return false;
};

export async function checkImageExist(url) {
  const result = await fetch(`https://cors-anywhere.azm.workers.dev/${url}`, {
    method: "GET",
  });
  return result.ok;
}

export const addItem2Array = (array, item) => {
  let ret = [];
  if (array && array?.length > 0) ret = [...ret, ...array];
  if (item) ret = [...ret, item];

  return _.uniq(ret);
};

export const upsertObjectToArray = (array, item) => {
  if (!item) return array;
  if (!array) return [item];
  if (array.find((t) => t.id === item.id)) {
    return array.map((t) => (t.id === item.id ? item : t));
  }
  return [item, ...array];
};

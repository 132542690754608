import client from "../index.js";
import { GET_USER_FOLDERS } from "./queries.js";
import { CREATE_USER_FOLDER, UPDATE_USER_FOLDER, DELETE_USER_FOLDER } from "./mutations";

export const apiGetAllUserFolders = (filter = {}) => {
  return client
    .query({
      query: GET_USER_FOLDERS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.userFolders;
    });
};

export const apiCreateUserFolder = (data) => {
  return client
    .mutate({
      mutation: CREATE_USER_FOLDER,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.createUserFolder?.userFolder;
    });
};

export const apiUpdateUserFolder = (data) => {
  return client
    .mutate({
      mutation: UPDATE_USER_FOLDER,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.updateUserFolder?.userFolder;
    });
};

export const apiDeleteUserFolder = (id) => {
  return client
    .mutate({
      mutation: DELETE_USER_FOLDER,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.deleteUserFolder?.id;
    });
};

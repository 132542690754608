import React, { useContext, useReducer, useMemo, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { isMobile } from "react-device-detect";

const LayoutContext = React.createContext(null);
LayoutContext.displayName = "LayoutContext";

export const useLayoutContext = () => useContext(LayoutContext);

function reducer(state, { type, payload }) {
  return {
    ...state,
    [type]: payload,
  };
}

const INIT_STATE = {
  isPortrait: false,
  videoWindowSize: [400, 400],
  screenSize: [1024, 768],
  showTopAppBar: true,
  showBottomNavigationBar: true,
  mouseOnTopAppBar: false,
  mouseOnBottomNavigationBar: false,
  showPlayerControl: true,
  mouseOnPlayerControl: false,
};

export default function LayoutContextProvider({ children, ...props }) {
  const isPortrait = useMediaQuery("(orientation: portrait)");
  const isSmScreen = useMediaQuery(`(min-width: 601px)`);
  const isMdScreen = useMediaQuery(`(min-width: 901px)`);
  const isLgScreen = useMediaQuery(`(min-width: 1201px)`);
  const isXlScreen = useMediaQuery(`(min-width: 1537px)`);
  const isXxlScreen = useMediaQuery(`(min-width: 1537px)`);
  const isPointCoarse = useMediaQuery(`(pointer: coarse)`);

  const isColumnVisible = (column) => {
    if (!column?.visible) return false;
    switch (column?.visible) {
      case "xs":
        return true;
      case "sm":
        return isSmScreen;
      case "md":
        return isMdScreen;
      case "lg":
        return isLgScreen;
      case "xl":
        return isXlScreen;
      default:
        return isLgScreen;
    }
  };

  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const setVideoWindowSize = (size) => dispatch({ type: "videoWindowSize", payload: size });
  const setScreenSize = (size) => dispatch({ type: "screenSize", payload: size });
  const setShowTopAppBar = (value) => dispatch({ type: "showTopAppBar", payload: value });
  const setShowBottomNavigationBar = (value) => dispatch({ type: "showBottomNavigationBar", payload: value });
  const setMouseOnTopAppBar = (value) => dispatch({ type: "mouseOnTopAppBar", payload: value });
  const setMouseOnBottomNavigationBar = (value) => dispatch({ type: "mouseOnBottomNavigationBar", payload: value });
  const setShowPlayerControl = (value) => dispatch({ type: "showPlayerControl", payload: value });
  const setMouseOnPlayerControl = (value) => dispatch({ type: "mouseOnPlayerControl", payload: value });

  useEffect(() => {
    dispatch({ type: "isPortrait", payload: isPortrait });
  }, [isPortrait]);

  useEffect(() => {
    function handleResize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
      console.log([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <LayoutContext.Provider
      value={useMemo(
        () => [
          {
            ...state,
            isMobile,
            isSmScreen,
            isMdScreen,
            isLgScreen,
            isXlScreen,
            isXxlScreen,
            isPointCoarse,
          },
          {
            setScreenSize,
            setVideoWindowSize,
            setShowTopAppBar,
            setShowBottomNavigationBar,
            setShowPlayerControl,
            setMouseOnTopAppBar,
            setMouseOnBottomNavigationBar,
            setMouseOnPlayerControl,
            isColumnVisible,
          },
        ],
        [state, isSmScreen, isMdScreen, isLgScreen, isXlScreen, isXxlScreen, isPointCoarse]
      )}
      {...props}
    >
      {children}
    </LayoutContext.Provider>
  );
}

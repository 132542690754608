import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  IconButton,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid_v4 } from "uuid";
import { ButtonCreate } from "../../components/buttons";
import { useState } from "react";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Close } from "@mui/icons-material";
import { apiCreateLocation, apiGetAllLocations } from "../../graphQL/locations";
import { actionUpdateLocation } from "../../store/Location.slice";
import { apiUpdateUser } from "../../graphQL/user";
import { updateCurrentUser } from "../../store/auth.slice";
import { setOtherProfile } from "../../store/OtherProfile";
import { actionUpdateUser } from "../../store/User.slice";
import { apiGetAllDomains } from "../../graphQL/domains";
import { useAppContext } from "../../contexts/AppContext";
import { getUniqueAuthCodeList } from "../../utils";

function DialogAddCampaign({
  open,
  domain,
  profile = {},
  onClose = () => { },
}) {

  const dispatch = useDispatch();
  const locations = useSelector((store) => store.Location.locations);
  const domains = useSelector((store) => store.Domain.domains);
  const [locationName, setLocationName] = useState('');
  const [loading, setLoading] = useState(false);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const OtherProfile = useSelector((store) => store.OtherProfile.Profile);
  const [allLocations, setAllLocations] = useState();
  const [authCodes, setAuthCodes] = useState();
  const [authCode, setAuthCode] = useState();


  ValidatorForm.addValidationRule("isLocationExist", (value) => {
    if (locations?.find((t) => t.domain === domain && t.name?.toLowerCase() === value?.toLowerCase())) {
      return false;
    }
    return true;
  });

  const submit = () => {
    setLoading(true);
    apiCreateLocation({
      domain,
      name: locationName,
      authCode,
    }).then((res) => {
      dispatch(actionUpdateLocation(res));
      apiUpdateUser({
        id: profile?.id,
        locationIdList: profile?.locationIdList ? [...profile.locationIdList, res.id] : [res.id],
      }).then((user) => {
        if (profile?.id === PrivateInfo?.id) {
          dispatch(updateCurrentUser(user));
        } else if (profile?.id === OtherProfile?.id) {
          dispatch(setOtherProfile(user));
        }
        dispatch(actionUpdateUser(user));
        setLoading(false);
        setLocationName('');
        onClose();
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }).catch((err) => {
      setLoading(false);
      console.log(err);
    });
  };

  const handleClose = () => {
    setLocationName('');
    setAuthCode('');
    onClose();
  }

  useEffect(() => {
    apiGetAllLocations({
      pagination: {
        limit: 32768,
        page: 1,
      }
    }).then((data) => {
      console.log(data.locations);
      setAllLocations(data.locations);
    })
  }, []);

  useEffect(() => {
    if (domains && allLocations) {
      const domainCodes = domains.map((item) => item.authCode);
      const locationCodes = allLocations.map((item) => item.authCode);
      const codes = getUniqueAuthCodeList([...domainCodes, ...locationCodes], 5);
      setAuthCodes(codes);
    }
  }, [domains, allLocations])

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <ValidatorForm onSubmit={submit}>
        <DialogContent>
          <Grid container
            alignItems="center"
            gap={1}
            mt={2}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 3,
                right: 3
              }}
              onClick={handleClose}>
              <Close />
            </IconButton>
            <Grid item xs={12} mt={2}>
              <TextValidator
                fullWidth
                placeholder="Please input a campaign name"
                label="Campaign Name"
                value={locationName || ""}
                onChange={(e) => {
                  setLocationName(e.target.value);
                }}
                autoComplete="off"
                validators={["required", "isLocationExist"]}
                errorMessages={["Please input a campaign name", "Campaign name already exists."]}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <SelectValidator
                fullWidth
                autoComplete="off"
                label="Authentication Code"
                value={authCode}
                onChange={(e) => {
                  setAuthCode(e.target.value);
                }}
                sx={{ flex: 1, mb: 1.5 }}
                validators={["required"]}
                errorMessages={["Please select an authentication code."]}
              >
                {authCodes?.map((code) => (
                  <MenuItem key={code} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
          </Box>
          <ButtonCreate
            isLoading={loading}
          ></ButtonCreate>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default DialogAddCampaign;

import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactPlayer from "react-player";
import { getCaptionUrl } from "../../utils";
import "./VideoPlayer.scss";

function VideoPlayer({
  playerRef,
  url,
  muted,
  playing,
  onProgress,
  onDuration,
  onEnded,
  onStart,
  onReady,
  onPlay,
  onPause,
  onError,
  onBufferEnd,
  controls = false,
  caption,
}) {
  const updateCue = () => {
    // const tracks = document.getElementsByTagName('track');
    // if (tracks?.length) {
    //   // Move the cue up 5 lines to make room for video controls
    //   const cues = tracks[0].track.activeCues;
    //   if (cues?.length) {
    //     cues[0].line = -5;
    //   }
    // }
  };

  return (
    <ReactPlayer
      ref={playerRef}
      url={url}
      style={{
        position: "absolute",
        top: 0,
        zIndex: 1,
      }}
      volume={0.8}
      muted={muted}
      width={"100%"}
      height={"100%"}
      playing={playing}
      playsinline
      onEnded={() => {
        console.log("Player Ended");
        onEnded();
      }}
      onPause={() => {
        console.log("Player paused");
        onPause();
      }}
      onError={(e) => {
        console.log("Player Error");
        console.log(e);
        onError(e);
      }}
      onReady={(player) => {
        console.log(player.getInternalPlayer());
        console.log("Player Ready");
        onReady();
      }}
      onStart={() => {
        console.log("Player Start");
        onStart();
      }}
      onDuration={(e) => {
        console.log("Player on duration calculated");
        onDuration(e);
      }}
      onBufferEnd={() => {
        console.log("Player on buffer end");
        onBufferEnd();
      }}
      onPlay={() => {
        console.log("Player on playing");
        onPlay();
      }}
      onProgress={({ played, playedSeconds, loaded, loadedSeconds }) => {
        // console.log(`Player on progress ${played} ${playedSeconds} ${loaded} ${loadedSeconds}`);
        onProgress(played, playedSeconds);
        updateCue();
      }}
      progressInterval={100}
      crossOrigin="true"
      config={{
        youtube: {
          playerVars: {
            disablekb: 1,
            showinfo: 0,
            rel: 0,
          },
        },
        file: {
          attributes: {
            crossOrigin: "anonymous",
          },
          tracks: [
            {
              kind: "captions",
              src: getCaptionUrl(caption),
              srcLang: "en",
              default: true,
              mode: "showing",
            },
          ],
        },
      }}
      controls={false}
    ></ReactPlayer>
  );
}

VideoPlayer.defaultProps = {
  onEnded: () => {},
  onError: () => {},
  onReady: () => {},
  onStart: () => {},
  onPlay: () => {},
  onBufferEnd: () => {},
  onPause: () => {},
};

export default VideoPlayer;

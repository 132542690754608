import { gql } from "@apollo/client";
const CREATE_NOTE = gql`
  mutation createNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      note {
        id
        owner
        linked {
          id
          type
        }
        notes {
          uId
          start
          duration
          note
          file {
            name
            directory
            url
            status
          }
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const UPDATE_NOTE = gql`
  mutation updateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        id
        owner
        linked {
          id
          type
        }
        notes {
          uId
          start
          duration
          note
          file {
            name
            directory
            url
            status
          }
        }
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export { CREATE_NOTE, UPDATE_NOTE };

import React, { useEffect, useState } from "react";
import { Drawer, Box, ThemeProvider, TextField, Button, Avatar, Typography, IconButton } from "@mui/material";
import { lightTheme } from "../../../libs/theme";
import { CancelRounded, Send } from "@mui/icons-material";
import CommentItem from "./CommentItem";
import { apiCreateTextComment, apiGetAllTextComments, apiUpdateTextComment } from "../../../graphQL/textComment";
import { useSelector } from "react-redux";
import { useOnCreateTextComment, useOnDeleteTextComment, useOnUpdateTextComment } from "../../../graphQL/textComment/subscription";

function CommentDrawer({
  asset = {},
  creator = {},
  onClose = () => {},
  open = false,
  setOpen = () => {},
  isDirect = false,
}) {
  const [editComment, setEditComment] = useState("");
  const [loading, setLoading] = useState(false);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const [comments, setComments] = useState();

  const { data: createComment, loading: loadingCreateComment, error: errorCreateComment } = useOnCreateTextComment();
  const { data: updateComment, loading: loadingUpdateComment, error: errorUpdateComment } = useOnUpdateTextComment();
  const { data: deleteComment, loading: loadingDeleteComment, error: errorDeleteComment } = useOnDeleteTextComment();

  useEffect(() => {
    if (!errorCreateComment && !loadingCreateComment && createComment) {
      console.log(createComment?.onCreateTextComment);
      fetchComments();
    } 
    if (errorCreateComment) {
      console.log(errorCreateComment)
    }
  }, [
    createComment, loadingCreateComment, errorCreateComment
  ])

  useEffect(() => {
    if (!errorUpdateComment && !loadingUpdateComment && updateComment) {
      console.log(updateComment);
      fetchComments();
    }
    if (errorUpdateComment) {
      console.log(errorUpdateComment)
    }
  }, [
    updateComment, loadingUpdateComment, errorUpdateComment
  ])

  useEffect(() => {
    if (deleteComment) {
      console.log(deleteComment)
      fetchComments();
    }
    if (errorDeleteComment) {
      console.log(errorDeleteComment)
    }

  }, [
    deleteComment, loadingDeleteComment, errorDeleteComment
  ])

  useEffect(() => {
    if (asset?.id && open) {
      setComments([]);
      fetchComments();
    }
  }, [asset, open]);

  useEffect(() => {
    if (creator?.id) {
      setComments([]);
      fetchComments(creator?.id);
    }
  }, [creator]);

  const fetchComments = (userId) => {
    setLoading(true);
    let variables = {};
    if (userId) {
      variables.creatorId = userId;
    } else {
      variables.assetId = asset?.id;
    }
    apiGetAllTextComments(variables).then((data) => {
      console.log(data);
      if (isDirect) {
        console.log(isDirect);
        setComments(
          data?.textComments?.filter(
            (item) => item.creatorId && (item.creatorId === PrivateInfo?.id || item.authorId === PrivateInfo?.id)
          )
        );
      } else {
        setComments(data?.textComments?.filter((item) => !item.creatorId));
      }
      setLoading(false);
    });
  };

  const addComment = () => {
    if (editComment !== "") {
      apiCreateTextComment({
        assetId: asset?.id,
        creatorId: isDirect ? asset?.owner || creator?.id : null,
        authorId: PrivateInfo?.id,
        title: "",
        content: editComment,
      }).then((data) => {
        console.log(data);
        setEditComment("");
        fetchComments();
      });
    }
  };

  const handleCommentAction = (type, status, comment) => {
    console.log(type);
    let variables = {
      id: comment?.id,
    };
    if (type === "liked") {
      variables.liked = status ? 1 : -1;
    } else if (type === "disliked") {
      variables.disliked = status ? 1 : -1;
    }
    apiUpdateTextComment(variables).then((data) => {
      console.log(data);
      fetchComments();
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      PaperProps={{
        sx: {
          background: "white",
          pt: 1,
          height: "70%",
          width: "100%",
          maxWidth: "1200px",
          left: "50%",
          right: "unset",
          transform: "translateX(-50%) !important",
          borderRadius: "10px 10px 0px 0px",
        },
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <Box
          sx={{
            height: 40,
            width: "100%",
          }}
        >
          <Typography color="black" textAlign={"center"}>
            {comments?.length || 0} comments
          </Typography>
          <Box sx={{ position: "absolute", top: 10, right: 10 }}>
            <IconButton
              onClick={() => {
                onClose();
              }}
              sx={{ padding: 0, margin: 0, color: "#232323" }}
            >
              <CancelRounded />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            maxHeight: "85%",
            overflow: "auto",
          }}
        >
          {comments?.length &&
            comments?.map((item, index) => {
              return (
                <CommentItem
                  key={index}
                  comment={item}
                  onClick={(type, status) => {
                    handleCommentAction(type, status, item);
                  }}
                />
              );
            })}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 3,
            left: 3,
            right: 3,
            background: "white",
            px: 1,
            pt: 0,
            border: "2px solid #333333",
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <TextField
            multiline
            rows={1}
            value={editComment}
            sx={{ mt: "1px" }}
            onChange={(e) => {
              setEditComment(e.target.value);
            }}
            variant="standard"
            fullWidth
            placeholder="Add comment..."
            InputProps={{ disableUnderline: true }}
            onKeyDown={(event) => {
              if (event.code === "Enter") {
                addComment();
              }
            }}
          />
          <Button onClick={addComment} disabled={(asset?.owner || creator?.id) === PrivateInfo?.id}>
            <Send />
          </Button>
        </Box>
      </ThemeProvider>
    </Drawer>
  );
}

export default CommentDrawer;

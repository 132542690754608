import React, { useState } from "react";
import { Avatar } from "@mui/material";
import { useEffect } from "react";
import { checkImageExist } from "../../utils";

function AssetThumbnail({ src, ...props }) {
  const [isAvailable, setAvailable] = useState();

  useEffect(() => {
    setAvailable(false);
    checkImageExist(src).then((ok) => {
      setAvailable(Boolean(ok));
    });
  }, [src]);

  if (isAvailable) return <Avatar src={src} {...props}></Avatar>;
  return null;
}

export default AssetThumbnail;

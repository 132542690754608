import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { Add as IconAdd } from "@mui/icons-material";

import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import SecondaryTopActionBar from "../../components/nav/SecondaryTopActionBar";
import UserGroupCard from "../../components/CustomCards/UserGroupCard/UserGroupCard";
import UserGroupDrawer from "../../components/CustomDrawers/UserGroupDrawer/UserGroupDrawer";
import DialogTypeSelector from "./DialogTypeSelector";
import { getAllUserGroups } from "../../store/UserGroup.slice";
import { getAllUsers } from "../../store/User.slice";

const GroupHeader = ({ userGroup }) => {
  return (
    <Box sx={{ px: 1, borderBottom: "1px solid #D9D9D9", mb: 1 }}>
      <Typography variant="body1" sx={{ mb: 0.25 }}>
        {userGroup.name}
      </Typography>
    </Box>
  );
};

function PageGroups() {
  const dispatch = useDispatch();

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const userGroups = useSelector((store) => store.UserGroup.userGroups);

  const [openTypeSelector, setOpenTypeSelector] = useState(false);
  const [editUserGroup, setEditUserGroup] = useState(null);

  useEffect(() => {
    if (PrivateInfo?.domain) {
      dispatch(getAllUsers({ domain: PrivateInfo.domain }));
      dispatch(getAllUserGroups({ domain: PrivateInfo.domain }));
    }
  }, [dispatch, PrivateInfo?.domain]);

  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            overflowY: "auto",
            px: 1.5,
            pb: 1.5,
          }}
        >
          {userGroups?.map((userGroup) => (
            <React.Fragment key={userGroup?.id}>
              <GroupHeader userGroup={userGroup}></GroupHeader>
              <Box sx={{ mb: 1 }}>
                <UserGroupCard userGroup={userGroup} onEdit={setEditUserGroup}></UserGroupCard>
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <DialogTypeSelector
        open={openTypeSelector}
        onClose={() => {
          setOpenTypeSelector(false);
        }}
        onSelectType={(t) => {
          switch (t) {
            case "group":
              setEditUserGroup({
                domain: PrivateInfo.domain,
                locationId: PrivateInfo.locationId,
                name: "",
                ownerId: PrivateInfo?.id,
                authors: [PrivateInfo?.id],
                desc: {
                  title: "",
                },
              });
              break;
            default:
          }
        }}
      ></DialogTypeSelector>
      <UserGroupDrawer editUserGroup={editUserGroup} setEditUserGroup={setEditUserGroup}></UserGroupDrawer>
    </SubPageWrapper>
  );
}

export default PageGroups;

import client from "../index.js";
import { GET_USER, GET_USERS } from "./queries.js";
import { UPDATE_USER, DELETE_USER, CREATE_USER, DELETE_ALL_USERS, RESET_CAMPAIGN } from "./mutations";

export const apiGetUser = (userId) => {
  return client
    .query({
      query: GET_USER,
      variables: {
        id: userId,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.user;
    });
};

export const apiGetUsers = (filter) => {
  return client
    .query({
      query: GET_USERS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.users;
    });
};

export const apiCreateUser = (data) => {
  return client
    .mutate({
      mutation: CREATE_USER,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.createAppUser?.user;
    });
};

export const apiUpdateUser = (data) => {
  return client
    .mutate({
      mutation: UPDATE_USER,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.updateAppUser?.user;
    });
};

export const apiDeleteUser = (id) => {
  return client
    .mutate({
      mutation: DELETE_USER,
      variables: {
        id: id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data?.deleteAppUser?.id;
    });
};

export const apiDeleteAllUsers = (filter = {}) => {
  return client
    .mutate({
      mutation: DELETE_ALL_USERS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAllUsers?.total;
    });
};

export const apiResetCampaigns = (filter = {}) => {
  return client
    .mutate({
      mutation: RESET_CAMPAIGN,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.resetBattle;
    });
};
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Grid, Typography } from "@mui/material";
import { Add as IconAdd, YouTube as IconYoutube } from "@mui/icons-material";
import moment from "moment";

import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import BottomActionBar from "../../components/nav/BottomActionBar";
import GroupAssetCard from "../../components/CustomCards/GroupAssetCard/GroupAssetCard";
import UploadingCard from "../../components/CustomCards/UploadingCard/UploadingCard";

import { ACCEPT_ASSET_TYPES } from "../../utils/constants";
import { actionUpdateMyAsset } from "../../store/Asset.slice";
import { isPossibleTypeForAsset, getAssetDisplayName, isImageType, isAudioType, getFileExt } from "../../utils";
import { myToast } from "../../libs";
import { v4 as uuid_v4 } from "uuid";
import { useUploadContext } from "../../contexts/UploadContext";

import { apiCreateAsset, apiGetAllAssets, apiUpdateAsset } from "../../graphQL/asset";
import AssetEditDrawer from "../../components/CustomDrawers/AssetEditDrawer/AssetEditDrawer";
import { useAppContext } from "../../contexts/AppContext";
import DialogAssetAnalytics from "../../components/dialogs/DialogAssetAnalytics";
import { useNavigate, useParams } from "react-router-dom";

function PageGroupDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userGroupId } = useParams();
  const uploadAssetRef = useRef();

  const [{ domain }] = useAppContext();
  const [{ queue }, { upload }] = useUploadContext();
  const [groupAssets, setGroupAssets] = useState([]);

  const userId = useSelector((store) => store.auth.userId);
  const otherProfile = useSelector((store) => store.OtherProfile.Profile);

  const [editAsset, setEditAsset] = useState(null);
  const [openedAssetAnalytics, setOpenedAssetAnalytics] = useState(null);

  const onUpload = (file) => {
    uploadAssetRef.current.value = "";
    const ext = getFileExt(file.name);
    if (!isPossibleTypeForAsset(file.type)) {
      myToast.error("Unknown file type. Please upload video, audio or image file!");
      return false;
    }
    const id = uuid_v4();
    const displayName = getAssetDisplayName(file.type);
    const fileName = `${id}.${ext}`;

    upload(file, "asset", id, `${domain}/${id}/${fileName}`, (err, result, callbackDone) => {
      if (err) {
        myToast.error("Upload was failed.");
        return;
      }
      const uploadedVideo = {
        id: id,
        name: fileName,
        directory: `${domain}/${id}`,
        contentType: file.type,
        displayName,
      };
      let variables = {
        id: uploadedVideo?.id,
        name: uploadedVideo?.displayName,
        owner: userId,
        domain,
        rank: moment().utc().valueOf(),
        type: "groupAsset",
        status:
          isImageType(uploadedVideo.contentType) || isAudioType(uploadedVideo.contentType) ? "Converted" : "Converting",
        parentNodeId: userGroupId,
        file: {
          name: uploadedVideo?.name,
          directory: uploadedVideo?.directory,
          contentType: uploadedVideo?.contentType,
          displayName: uploadedVideo?.displayName,
        },
        autoPublish: false,
      };
      apiCreateAsset(variables)
        .then((res) => {
          setGroupAssets([res, ...groupAssets]);
        })
        .catch(() => {})
        .then(() => {
          callbackDone && callbackDone();
        });
    });
  };

  const getPermission = () => {
    if (navigator.mediaDevices.getUserMedia !== null) {
      var options = {
        video: true,
        audio: true,
      };
      navigator.getUserMedia(
        options,
        function (stream) {
          console.log(stream, "streaming");
          stream.getTracks().forEach((x) => x.stop());
          //vid.srcObject = stream;
          //localstream = stream;
          //vid.play();
        },
        function (e) {
          console.log("background error : " + e.name);
        }
      );
    }
  };

  useEffect(() => {
    if (!userGroupId) return;
    apiGetAllAssets({
      parentNodeId: userGroupId,
      type: "groupAsset",
      ordering: {
        field: "timestamp",
        order: "Desc",
      },
      pagination: {
        page: 1,
        limit: 32768,
      },
    })
      .then((res) => {
        setGroupAssets(res.mediaAssets);
      })
      .catch((res) => {
        setGroupAssets([]);
      });
  }, [userGroupId]);

  useEffect(() => {
    if (groupAssets?.filter((item) => item.status === "Converting").map((item) => item.id)?.length > 0) {
      const timer = setInterval(() => {
        apiGetAllAssets({
          ids: groupAssets?.filter((item) => item.status === "Converting").map((item) => item.id) || [],
          ordering: {
            field: "timestamp",
            order: "Desc",
          },
          pagination: {
            page: 1,
            limit: 32768,
          },
        })
          .then((res) => {
            let newAssets = [
              ...res.mediaAssets?.filter((t) => t.status !== "deleting"),
              ...groupAssets?.filter((t) => {
                return !res?.mediaAssets?.find((asset) => asset.id === t.id);
              }),
            ];
            setGroupAssets(newAssets);
          })
          .catch((res) => {
            setGroupAssets([]);
          });
      }, 10 * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [dispatch, groupAssets, userId]);

  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            overflowY: "auto",
            px: 2,
            py: 2,
          }}
        >
          <Grid container spacing={2}>
            {queue.map((assetUploading) => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={assetUploading?.id}>
                <UploadingCard assetUploading={assetUploading}></UploadingCard>
              </Grid>
            ))}
            {groupAssets?.map((asset) => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={asset?.id}>
                <GroupAssetCard
                  asset={asset}
                  onEdit={() => {
                    setEditAsset(asset);
                  }}
                  onAnalytics={() => {
                    setOpenedAssetAnalytics(asset);
                  }}
                  onChangeFolder={(folder) => {
                    apiUpdateAsset({ id: asset?.id, parentNode: folder?.id }).then((res) => {
                      dispatch(actionUpdateMyAsset(res));
                    });
                  }}
                ></GroupAssetCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <BottomActionBar
        center={<Typography variant="h6">Group Detail</Typography>}
        onBack={() => {
          navigate("/mygroups");
        }}
        right={
          <Box sx={{ width: "96px" }} display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton onClick={() => {}} size="small" sx={{ mr: 1 }}>
              <IconYoutube fontSize="large"></IconYoutube>
            </IconButton>
            <IconButton
              onClick={() => {
                getPermission();
              }}
              size="small"
              component="label"
              aria-label="Upload Asset"
            >
              <IconAdd fontSize="large"></IconAdd>
              <input
                disabled={Boolean(otherProfile)}
                ref={uploadAssetRef}
                hidden
                accept={ACCEPT_ASSET_TYPES}
                type="file"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    return onUpload(e.target.files[0]);
                  }
                }}
              />
            </IconButton>
          </Box>
        }
      ></BottomActionBar>
      <AssetEditDrawer editAsset={editAsset} setEditAsset={setEditAsset}></AssetEditDrawer>
      <DialogAssetAnalytics
        asset={openedAssetAnalytics}
        onClose={() => {
          setOpenedAssetAnalytics(null);
        }}
      ></DialogAssetAnalytics>
    </SubPageWrapper>
  );
}

export default PageGroupDetail;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, Box, Chip, ThemeProvider, Collapse, MenuItem, Typography } from "@mui/material";
import {
  Done as IconDone,
  CellTower as IconCellTower,
  Close as IconClose,
  Photo as IconPhoto,
} from "@mui/icons-material";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import AvatarUpload from "../../common/AvatarUpload";

import { myToast } from "../../../libs";
import { apiUpdateAsset } from "../../../graphQL/asset";
import { actionUpdateAsset, actionUpdateMyAsset } from "../../../store/Asset.slice";
import { ButtonBack, ButtonSave } from "../../buttons";
import { getAvatarPath, isImageAsset } from "../../../utils";
import { useUploadContext } from "../../../contexts/UploadContext";
import { lightTheme } from "../../../libs/theme";
import Editor from "../../editor/Editor";
import ButtonMore from "../../buttons/ButtonMore";
import KeywordInput from "../../form/KeywordInput";

function AssetEditDrawer({ editAsset, setEditAsset, onSave = () => {} }) {
  const dispatch = useDispatch();
  const categories = useSelector((store) => store.Category.categories);
  const stockAudios = useSelector((store) => store.StockAudio.stockAudios);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const [, { uploadAvatar }] = useUploadContext();

  const [state, setState] = useState({ value: "" });
  const [isLoading, setLoading] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [openDescBodyEditor, setOpenDescBodyEditor] = useState(false);
  const paddingWidth = window.innerWidth > 1200 ? (window.innerWidth - 1200) / 2 : 0;

  useEffect(() => {
    setState({ value: editAsset?.desc?.body });
  }, [Boolean(editAsset?.id)]); //eslint-disable-line

  const handleSubmit = () => {
    setLoading(true);

    const actionSave = (variables) => {
      if (editAsset.isCreate) {
        setLoading(false);
        return onSave(variables);
      }
      apiUpdateAsset(variables)
        .then((res) => {
          setLoading(false);
          dispatch(actionUpdateMyAsset(res));
          dispatch(actionUpdateAsset(res));
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          setEditAsset(null);
        });
    };

    let variables = {
      id: editAsset?.id,
      name: editAsset?.name,
      desc: {
        title: editAsset?.desc?.title,
        short: editAsset?.desc?.short,
        body: state?.value,
      },
      categories: editAsset?.categories,
      keywords: editAsset?.keywords,
      stockAudioId: editAsset?.stockAudioId,
    };

    if (avatarFile) {
      uploadAvatar(editAsset?.id, avatarFile, (err, result, fileName) => {
        if (err) {
          myToast.error("Upload avatar was failed");
          setLoading(false);
          return;
        }
        actionSave({
          ...variables,
          avatar: {
            name: fileName,
            dir: editAsset?.id,
            base: "",
            status: "uploaded",
          },
        });
      });
    } else {
      actionSave(variables);
    }
  };

  const onClickCategoryChip = (id) => {
    let categories = editAsset?.categories;
    if (categories?.find((t) => t === id)) {
      categories = categories.filter((t) => t !== id);
    } else {
      if (categories) {
        categories = [...categories, id];
      } else categories = [id];
    }
    setEditAsset({
      ...editAsset,
      categories,
    });
  };
  
  return (
    <Drawer
      open={!!editAsset?.id}
      onClose={() => {
        setEditAsset(null);
      }}
      anchor="bottom"
      PaperProps={{
        sx: {
          background: "#d2d2d2",
          pt: 1,
          marginRight: `${paddingWidth}px`,
          marginLeft: `${paddingWidth}px`,
          maxWidth: "600px",
        },
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Box sx={{ px: 1, pt: 1 }}>
            <TextValidator
              fullWidth
              size="small"
              label="Title"
              type="text"
              value={editAsset?.desc?.title || ""}
              onChange={(e) => {
                setEditAsset({
                  ...editAsset,
                  desc: {
                    ...(editAsset.desc || {}),
                    title: e.target.value,
                  },
                });
              }}
              sx={{ mb: 1 }}
            />
            <TextValidator
              fullWidth
              size="small"
              label="Description"
              rows={3}
              multiline
              value={editAsset?.desc?.short || ""}
              onChange={(e) => {
                setEditAsset({
                  ...editAsset,
                  desc: {
                    ...(editAsset.desc || {}),
                    short: e.target.value,
                  },
                });
              }}
            />
            <Box sx={{ pt: 1 }}>
              <KeywordInput
                options={[]}
                value={editAsset?.keywords || []}
                onChange={(e, value) => {
                  setEditAsset({
                    ...editAsset,
                    keywords: value,
                  });
                }}
                limitTags={3}
                label="Keywords"
                placeholder="Keywords"
              ></KeywordInput>
            </Box>
            {isImageAsset(editAsset) ? <Box sx={{ pt: 1 }}>
              <SelectValidator
                fullWidth
                autoComplete="off"
                label={"Soundtrack"}
                value={editAsset?.stockAudioId || ""}
                onChange={(e) => {
                  setEditAsset({ ...editAsset, stockAudioId: e.target.value });
                }}
                sx={{ flex: 1, mb: 1.5 }}
              >
                {stockAudios?.map((stockAudio) => (
                  <MenuItem key={stockAudio.id} value={stockAudio.id}>
                    {stockAudio.name}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Box> : <></>}
            {/* <Box sx={{ pt: 1, overflowX: "auto", width: "100%" }} display="flex" alignItems="center">
              {categories?.filter(c => c.domain === PrivateInfo.domain).map((channel) => (
                <Chip
                  key={channel?.id}
                  variant="outlined"
                  size="small"
                  color={editAsset?.categories?.find((t) => t === channel?.id) ? "success" : "primary"}
                  onClick={() => {
                    onClickCategoryChip(channel?.id);
                  }}
                  onDelete={() => {
                    onClickCategoryChip(channel?.id);
                  }}
                  icon={<IconCellTower></IconCellTower>}
                  deleteIcon={editAsset?.categories?.find((t) => t === channel?.id) ? <IconDone /> : <IconClose />}
                  label={channel?.name}
                  sx={{ mr: 1 }}
                />
              ))}
            </Box> */}
            <Box sx={{ pt: 1 }}>
              <SelectValidator
                fullWidth
                autoComplete="off"
                label={"Category"}
                value={editAsset?.categories?.find((cat, index) => index === 0) || ""}
                onChange={(e) => {
                  const locationId = categories?.find((cat) => cat.id == e.target.value)?.locationId;
                  setEditAsset({
                    ...editAsset,
                    categories: [e.target.value],
                    locationId: locationId
                  })
                }}
                sx={{ mb: 1.5 }}
                validators={["required"]}
                errorMessages={["This field is required"]}
              >
                {categories?.filter(c => c.domain === PrivateInfo.domain).map((category) => (
                  <MenuItem key={category?.id} value={category?.id}>
                    <Box sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%"
                    }}>
                      <Typography>{category?.name}</Typography>
                      <Box display="flex" width={40} justifyContent="space-between" alignItems="center">
                        <Box sx={{width: 12, height: 12, background: category?.style?.bg || "#00000000"}}></Box>
                        <Box sx={{width: 12, height: 12, background: category?.style?.fg || "#00000000"}}></Box>
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </SelectValidator>
            </Box>
          </Box>
          {/* <Collapse in={openDescBodyEditor}>
            <Box sx={{ width: "100%", px: 1 }}>
              <Box sx={{ background: "#fff", color: "#232323" }}>
                <Editor state={state} setState={setState}></Editor>
              </Box>
            </Box>
          </Collapse> */}
          <Box
            sx={{ p: 0.5, height: 40, mt: 1 }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="no-select"
          >
            <ButtonBack
              sx={{ px: 1 }}
              size="small"
              onClick={() => {
                setEditAsset(null);
              }}
            >
              Back
            </ButtonBack>
            {/* <ButtonMore
              open={openDescBodyEditor}
              onClick={() => {
                setOpenDescBodyEditor(!openDescBodyEditor);
              }}
            ></ButtonMore> */}
            <ButtonSave loading={isLoading} sx={{ px: 1 }} size="small">
              Save
            </ButtonSave>
          </Box>
        </ValidatorForm>
      </ThemeProvider>
    </Drawer>
  );
}

export default AssetEditDrawer;

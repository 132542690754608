import React from "react";
import { Box, DialogActions, IconButton } from "@mui/material";
import { Delete as IconDelete } from "@mui/icons-material";
import { ButtonCreate, ButtonSave } from "../buttons";

function EditDialogActions({ isLoading, onEdit, onDelete }) {
  return (
    <DialogActions sx={{ display: "flex", justifyContent: "space-between", px: 1, pb: 1 }}>
      <Box flexGrow={1}>
        {onDelete && (
          <IconButton onClick={onDelete}>
            <IconDelete></IconDelete>
          </IconButton>
        )}
      </Box>
      {onEdit ? (
        <ButtonSave loading={isLoading} onClick={onEdit}></ButtonSave>
      ) : (
        <ButtonCreate loading={isLoading} onClick={onEdit}></ButtonCreate>
      )}
    </DialogActions>
  );
}

export default EditDialogActions;

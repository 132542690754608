import { gql } from "@apollo/client";

const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      domain
      domainList
      locationId
      locationIdList
      name
      username
      desc {
        title
        short
        body
        website
        caption
        style
        rows
      }
      avatar {
        name
        dir
        base
        mimetype
        status
        faIcon
        emoji
        thumbnail
        style
      }
      contact {
        id
        first
        last
        url
        media
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
      }
      setting
      bookmarks
      subscriptions
      creatorAssetIds
      widgetBookmarks
      folders
      folderIds {
        id
        collectionName
      }
      linkedIds
      favorites
      zipCodes
      access
      keywords
      categories
      role
      googleCredential {
        clientId
        clientSecret
        redirectUrl
        refreshToken
      }
      activated
      defaultAssetFolder
      defaultUserFolder
      defaultMediaFolderId
      loginAt
      loginCount
      data
      timestamp
    }
  }
`;

const GET_USERS = gql`
  query users($input: AppUsersInput!) {
    users(input: $input) {
      users {
        id
        domain
        domainList
        locationId
        locationIdList
        name
        username
        desc {
          title
          short
          body
          website
          caption
          style
          rows
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        contact {
          id
          first
          last
          url
          media
          avatar {
            name
            dir
            base
            mimetype
            status
            faIcon
            emoji
            thumbnail
            style
          }
        }
        setting
        bookmarks
        subscriptions
        creatorAssetIds
        widgetBookmarks
        folders
        folderIds {
          id
          collectionName
        }
        linkedIds
        favorites
        zipCodes
        access
        keywords
        categories
        role
        googleCredential {
          clientId
          clientSecret
          redirectUrl
          refreshToken
        }
        activated
        defaultAssetFolder
        defaultUserFolder
        defaultMediaFolderId
        loginAt
        loginCount
        data
        timestamp
      }
    }
  }
`;

export { GET_USER, GET_USERS };

import React from "react";
import { Drawer } from "@mui/material";
import ChannelCard from "../../CustomCards/ChannelCard/ChannelCard";

function ChannelDrawer({ open, onClose, channel, playerStatus }) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      PaperProps={{
        sx: {
          bgcolor: "#000",
        },
      }}
    >
      <ChannelCard
        channel={channel}
        onClose={() => {
          onClose();
        }}
        playerStatus={playerStatus}
      ></ChannelCard>
    </Drawer>
  );
}

export default ChannelDrawer;

import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_USER = gql`
  subscription onCreateAppUser{
    onCreateAppUser{
        user {
            id
            domain
            domainList
            locationId
            locationIdList
            name
            username
            desc {
              title
              short
              body
              website
              caption
              style
              rows
            }
            avatar {
              name
              dir
              base
              mimetype
              status
              faIcon
              emoji
              thumbnail
              style
            }
            contact {
              id
              first
              last
              url
              media
              avatar {
                name
                dir
                base
                mimetype
                status
                faIcon
                emoji
                thumbnail
                style
              }
            }
            setting
            bookmarks
            subscriptions
            creatorAssetIds
            widgetBookmarks
            folders
            folderIds {
              id
              collectionName
            }
            linkedIds
            favorites
            zipCodes
            access
            keywords
            categories
            role
            googleCredential {
              clientId
              clientSecret
              redirectUrl
              refreshToken
            }
            activated
            defaultAssetFolder
            defaultUserFolder
            defaultMediaFolderId
            loginAt
            loginCount
            data
            timestamp
          }
    }
  }
`;

export const ON_UPDATE_USER = gql`
  subscription onUpdateAppUser{
    onUpdateAppUser{
        user {
            id
            domain
            domainList
            locationId
            locationIdList
            name
            username
            desc {
              title
              short
              body
              website
              caption
              style
              rows
            }
            avatar {
              name
              dir
              base
              mimetype
              status
              faIcon
              emoji
              thumbnail
              style
            }
            contact {
              id
              first
              last
              url
              media
              avatar {
                name
                dir
                base
                mimetype
                status
                faIcon
                emoji
                thumbnail
                style
              }
            }
            setting
            bookmarks
            subscriptions
            creatorAssetIds
            widgetBookmarks
            folders
            folderIds {
              id
              collectionName
            }
            linkedIds
            favorites
            zipCodes
            access
            keywords
            categories
            role
            googleCredential {
              clientId
              clientSecret
              redirectUrl
              refreshToken
            }
            activated
            defaultAssetFolder
            defaultUserFolder
            defaultMediaFolderId
            loginAt
            loginCount
            data
            timestamp
        }
    }
  }
`;

export const ON_DELETE_USER = gql`
  subscription onDeleteAppUser{
    onDeleteAppUser{
      id
    }
  }
`;

const useOnCreateAppUser= () => useSubscription(ON_CREATE_USER);
const useOnUpdateAppUser= () => useSubscription(ON_UPDATE_USER);
const useOnDeleteAppUser= () => useSubscription(ON_DELETE_USER);

export { useOnCreateAppUser, useOnUpdateAppUser, useOnDeleteAppUser};

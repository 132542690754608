import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_ALERT_FOLDER = gql`
  subscription onCreateAlertFolder {
    onCreateAlertFolder {
      alertFolder {
        id
        name
        owner
        parentNode
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        childNodeIds
        keywords
        categories
        style
        rank
        status
        availability {
          From
          To
        }
        schedule {
          deleteAt
          archiveAt
        }
        template
        domain
        locationId
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export const ON_UPDATE_ALERT_FOLDER = gql`
  subscription onUpdateAlertFolder {
    onUpdateAlertFolder {
      alertFolder {
        id
        name
        owner
        parentNode
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        childNodeIds
        keywords
        categories
        style
        rank
        status
        availability {
          From
          To
        }
        schedule {
          deleteAt
          archiveAt
        }
        template
        domain
        locationId
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export const ON_DELETE_ALERT_FOLDER = gql`
  subscription onDeleteAlertFolder {
    onDeleteAlertFolder {
      id
    }
  }
`;

const useOnCreateAlertFolder = () => useSubscription(ON_CREATE_ALERT_FOLDER);
const useOnUpdateAlertFolder = () => useSubscription(ON_UPDATE_ALERT_FOLDER);
const useOnDeleteAlertFolder = () => useSubscription(ON_DELETE_ALERT_FOLDER);

export { useOnCreateAlertFolder, useOnUpdateAlertFolder, useOnDeleteAlertFolder };

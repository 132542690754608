import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Add as IconAdd,
  YouTube as IconYoutube,
  Movie as IconMovie,
  Star as IconStar,
  Visibility as IconVisibility,
  VisibilityOff as IconVisibilityOff,
  PermMedia as IconPermMedia,
} from "@mui/icons-material";

import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import BottomActionBar from "../../components/nav/BottomActionBar";

import { ACCEPT_ASSET_TYPES } from "../../utils/constants";
import { actionUpdateMyAsset } from "../../store/Asset.slice";
import { useUploadContext } from "../../contexts/UploadContext";

import { apiUpdateAsset } from "../../graphQL/asset";
import AssetFolderAvatar from "../../components/avatars/AssetFolderAvatar";
import { useAppContext } from "../../contexts/AppContext";
import { getAllMyAssetFolders } from "../../store/AssetFolder.slice";

import MyAssetFolderCard from "./MyAssetFolderCard";

function PageMyCollections() {
  const dispatch = useDispatch();
  const uploadAssetRef = useRef();

  const [{ domain }] = useAppContext();
  const [{ queue }, { upload }] = useUploadContext();

  const userId = useSelector((store) => store.auth.userId);
  const currentUser = useSelector((store) => store.auth.PrivateInfo);
  const myAssets = useSelector((store) => store.Asset.myAssets);
  const myAssetFolders = useSelector(
    (store) => store.AssetFolder.myAssetFolders
  );
  const [editAsset, setEditAsset] = useState(null);
  const [filter, setFilter] = useState("all");
  const [filteredAssets, setFilteredAssets] = useState([]);

  useEffect(() => {
    if (userId) dispatch(getAllMyAssetFolders());
  }, [userId, dispatch]);

  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            overflowY: "auto",
            px: 1.5,
            py: 1,
          }}
        >
          <Grid container spacing={1.5}>
            {myAssetFolders?.map((assetFolder) => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={assetFolder?.id}>
                <MyAssetFolderCard
                  assetFolder={assetFolder}
                ></MyAssetFolderCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <BottomActionBar
        right={
          <Box>
            {/* <IconButton onClick={() => {}} size="small" sx={{ p: 0 }} component="label" aria-label="Upload Asset">
              <IconAdd fontSize="large"></IconAdd>
              <input
                ref={uploadAssetRef}
                hidden
                accept={ACCEPT_ASSET_TYPES}
                type="file"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                  }
                }}
              />
            </IconButton> */}
          </Box>
        }
      ></BottomActionBar>
    </SubPageWrapper>
  );
}

export default PageMyCollections;

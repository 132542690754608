import React from "react";
import { Box } from "@mui/material";

function MainPageWrapper({ children }) {
  return (
    <Box
      sx={{
        height: "100%",
        maxWidth: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      {children}
    </Box>
  );
}

export default MainPageWrapper;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import {
  Logout as IconLogout,
  ArrowBackIosNew as IconArrowBackIosNew,
  Person as IconPerson,
} from "@mui/icons-material";
import { Auth } from "aws-amplify";
import LogoutGuardView from "../../layouts/LogoutGuardView/index";
import ProfileDrawer from "../../components/CustomDrawers/ProfileDrawer/ProfileDrawer";
import { useSelector } from "react-redux";
import { GUEST } from "../../config";

function PageMore() {
  const navigate = useNavigate();
  const [logoutGuardStyle, setLogoutGuardStyle] = useState({});
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  if(PrivateInfo?.name === GUEST.email) {
    navigate("/");
  }

  return (
    <Box className="w-full h-full relative">
      <Box
        sx={{ position: "absolute", left: 0, right: 0, top: 0, p: 1 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          onClick={() => {
            // Auth.signOut();
            setLogoutGuardStyle({
              height: "120px",
              width: "100%",
              maxWidth: "100%",
              bottom: 0,
              left: 0,
              opacity: 1,
              zIndex: 10,
            });
          }}
        >
          <IconLogout sx={{ transform: "rotate(180deg)" }}></IconLogout>
          <Typography variant="h6" sx={{ pl: 1 }}>
            Logout
          </Typography>
        </Box>
      </Box>
      <div style={logoutGuardStyle} className={"logout-guard-view"}>
        <LogoutGuardView
          handleClickBack={(value) => {
            if (value === "logout") {
              Auth.signOut();
            } else {
              setLogoutGuardStyle({
                height: 0,
                opacity: 0,
                right: 0,
                bottom: -120,
              });
            }
          }}
        />
      </div>
      <Box
        sx={{ position: "absolute", bottom: 0, right: 0, left: 0, p: 1 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          onClick={() => {
            navigate("/");
          }}
        >
          <IconArrowBackIosNew></IconArrowBackIosNew>
          <Typography variant="h6" sx={{ pl: 1 }}>
            Back
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          onClick={() => {
            setOpenProfileDrawer(true);
          }}
        >
          <IconPerson></IconPerson>
          <Typography variant="h6" sx={{ px: 1 }}>
            Profile
          </Typography>
        </Box>
      </Box>
      <ProfileDrawer
        open={openProfileDrawer}
        onClose={() => {
          setOpenProfileDrawer(false);
        }}
      ></ProfileDrawer>
    </Box>
  );
}

export default PageMore;

import { gql } from "@apollo/client";

const GET_ALERT_FOLDER = gql`
  query alertFolder($id: ID!) {
    alertFolder(id: $id) {
      id
      name
      owner
      parentNode
      avatar {
        name
        dir
        base
        mimetype
        status
        faIcon
        emoji
        thumbnail
        style
      }
      desc {
        title
        short
        body
        website
        style
        rows
      }
      childNodeIds
      keywords
      categories
      style
      rank
      status
      availability {
        from
        to
      }
      schedule {
        deleteAt
        archiveAt
      }
      template
      templateType
      domain
      locationId
      data
      timestamp
      updatedAt
    }
  }
`;

const GET_ALERT_FOLDERS = gql`
  query alertFolders($input: AlertFoldersInput!) {
    alertFolders(input: $input) {
      total
      alertFolders {
        id
        name
        owner
        parentNode
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          body
          website
          style
          rows
        }
        childNodeIds
        keywords
        categories
        style
        rank
        status
        availability {
          from
          to
        }
        schedule {
          deleteAt
          archiveAt
        }
        template
        templateType
        domain
        locationId
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export { GET_ALERT_FOLDER, GET_ALERT_FOLDERS };

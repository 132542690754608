import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu, MenuItem, Typography, ListItemText, ListItemIcon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { actionMenuPaperProps } from "../../utils/styles";
import { getFolderIcon } from "../../utils/icons";
import { Box } from "@mui/system";

function BookmarkMenu({ open, onClose, anchorEl, onBookmark }) {
  const userFolders = useSelector((store) => store.UserFolder.userFolders);
  const paddingWidth = window.innerWidth > 1200 ? (window.innerWidth - 1200) / 2 : 0;

  useEffect(() => {
    if (userFolders?.length === 1) {
      const userFolder = userFolders[0];
      if (open) {
        onBookmark(userFolder);
      }
    }
    if (userFolders?.length <= 1) {
      onClose();
    }
  }, [open]);

  return userFolders?.length <= 1 ? (
    <Box display="none"></Box>
  ) : (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={actionMenuPaperProps}
      transformOrigin={{ horizontal: "left", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      sx={{ left: paddingWidth }}
    >
      {userFolders?.map((userFolder) => (
        <MenuItem
          key={userFolder?.id}
          onClick={() => {
            onBookmark(userFolder);
            onClose();
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={getFolderIcon(userFolder?.style?.icon?.name)} />
          </ListItemIcon>
          <ListItemText>{userFolder?.name}</ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
}

export default BookmarkMenu;

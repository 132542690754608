import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import { Box, IconButton } from "@mui/material";
import { Camera as IconCamera } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightAndArrowDownLeftFromCenter } from "@fortawesome/pro-solid-svg-icons";
import { useLayoutContext } from "../../contexts/LayoutContext";
import _ from "lodash";

function ScreenshotRegionSelector({ openScreenshotSelector, onSelect }) {
  const [{ videoWindowSize }] = useLayoutContext();

  const [state, setState] = useState({ width: 240, height: 150 });
  const [position, setPosition] = useState({ x: videoWindowSize.width / 2 - 80, y: videoWindowSize.height / 2 - 50 });

  const onStart = (e, data) => {};

  const onStop = (e, data) => {
    const { x, y } = data;
    setPosition({ x, y });
  };

  useEffect(() => {
    if (openScreenshotSelector) {
      setState({ width: 240, height: 150 });
      setPosition({ x: videoWindowSize.width / 2 - 80, y: videoWindowSize.height / 2 - 50 });
    }
  }, [openScreenshotSelector]);

  useEffect(() => {
    setPosition({ x: videoWindowSize.width / 2 - 80, y: videoWindowSize.height / 2 - 50 });
  }, [videoWindowSize]);

  useEffect(() => {
    onSelect({ width: state.width, height: state.height, x: position.x, y: position.y });
  }, [state, position]);

  return (
    <Box
      sx={{
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        // margin-top: 111px;
        background: "transparent",
      }}
    >
      <Draggable
        bounds="parent"
        handle=".wrapper"
        axis="both"
        onStart={onStart}
        onStop={onStop}
        position={position}
        defaultPosition={{ x: 100, y: 100 }}
      >
        <div className="box">
          <Resizable
            className="resizable-container"
            height={state.height}
            width={state.width}
            onResize={(e, { element, size, handle }) => {
              setState({ width: size.width, height: size.height });
            }}
            axis="both"
            minConstraints={[100, 100]}
            maxConstraints={[videoWindowSize.width - position.x, videoWindowSize.height - position.y]}
            handle={
              <div className="draghandle">
                <FontAwesomeIcon rotation={90} icon={faArrowUpRightAndArrowDownLeftFromCenter} color={"#ffffff"} />
              </div>
            }
          >
            <div className="box" style={{ width: state.width + "px", height: state.height + "px", border: 'red dashed 1px' }}>
              <div className="wrapper" style={{ width: "100%", height: "100%" }}></div>
            </div>
          </Resizable>
        </div>
      </Draggable>
    </Box>
  );
}

export default ScreenshotRegionSelector;

import { Auth } from "aws-amplify";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiCreateUser, apiGetUser, apiUpdateUser } from "../graphQL/user";
import moment from "moment";
import { GUEST } from "../config";

export const readCurrentUser = createAsyncThunk("auth/read_current_user", async (user, thunkAPI) => {
  return apiGetUser(user.id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.message === "User not found") {
        console.log(window.location.hostname);
        return apiCreateUser({ ...user, role: user.role || 'creator', activated: true, domain: window.location.hostname });
      }
      return Promise.reject();
    })
    .then((res) => {
      const t = window.localStorage.getItem("LOGIN_ACTION");
      if (t) {
        window.localStorage.removeItem("LOGIN_ACTION");
        return apiUpdateUser({
          id: res.id,
          loginAt: `${moment().valueOf()}`,
          loginCount: res.loginCount ? res.loginCount + 1 : 1,
          activated: true,
        });
      }
      if (res.name === GUEST.email) {
        return {
          ...res,
          domain: window.location.hostname
        }
      }
      return res;
    });
});

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticating: true,
    authenticated: false,
    userId: null,
    cognitoUser: {
      id: "",
      name: "",
      role: "",
    },
    PrivateInfo: null,
    error: null,
  },
  reducers: {
    signIn: (state, action) => {
      // console.log(action.payload);
      return {
        ...state,
        isAuthenticating: false,
        authenticated: true,
        userId: action.payload.id,
        cognitoUser: action.payload,
        error: null,
      };
    },
    signOut: (state, action) => {
      state.isAuthenticating = false;
      state.authenticated = false;
      state.cognitoUser = {
        id: "",
        name: "",
        role: "",
      };
      state.userId = state.PrivateInfo = state.error = null;
    },
    updateCurrentUser: (state, action) => {
      state.PrivateInfo = action.payload;
    },
  },
  extraReducers: {
    [readCurrentUser.fulfilled]: (state, action) => {
      state.PrivateInfo = action.payload;
    },
    [readCurrentUser.rejected]: (state, action) => {
      console.log(action);
      Auth.signOut();
      state.isAuthenticating = false;
      state.authenticated = false;
      state.cognitoUser = {
        id: "",
        name: "",
        role: "",
      };
      state.userId = state.PrivateInfo = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { signIn, signUp, signOut, updateCurrentUser } = authSlice.actions;

export default authSlice.reducer;

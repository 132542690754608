import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetUsers } from "../graphQL/user";

export const getAllUsers = createAsyncThunk("user/read_users", async (filter = {}) => {
  const data = await apiGetUsers(filter);
  return data;
});

const userSlice = createSlice({
  name: "User",
  initialState: {
    total: 0,
    users: null,
  },
  reducers: {
    actionUpdateUser: (state, action) => {
      if (state.users) {
        if (state.users.find((item) => item.id === action.payload.id)) {
          state.users = state.users.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.users = [action.payload, ...state.users];
          state.total = state.users.length;
        }
      } else {
        state.users = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteUser: (state, action) => {
      state.users = state.users?.filter((item) => item?.id !== action.payload);
      state.total = state.users.length;
    },
    actionDeleteAllUsers: (state, action) => {
      state.users = state.users?.filter((t) => t.domain !== action.payload.domain);
      state.total = state.users.length;
    },
  },
  extraReducers: {
    [getAllUsers.fulfilled]: (state, action) => {
      state.total = action.payload.total;
      state.users = action.payload.users;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.total = 0;
      state.users = null;
    },
  },
});

export const { actionUpdateUser, actionDeleteUser, actionDeleteAllUsers } = userSlice.actions;
export default userSlice.reducer;

import React from "react";
import { LoadingButton } from "@mui/lab";
import { CloudUpload as IconCouldUpload } from "@mui/icons-material";
function ButtonSave({ loading, label, ...props }) {
  return (
    <LoadingButton
      color="primary"
      type="submit"
      loading={loading}
      loadingPosition="start"
      startIcon={<IconCouldUpload />}
      {...props}
    >
      {label}
    </LoadingButton>
  );
}

ButtonSave.defaultProps = {
  variant: "contained",
  loading: false,
  label: "Save",
  size: "medium",
};

export default ButtonSave;

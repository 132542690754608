import client from "../index.js";
import { GET_STOCK_AUDIO, GET_STOCK_AUDIOS } from "./query.js";
import { CREATE_STOCK_AUDIO, UPDATE_STOCK_AUDIO, DELETE_STOCK_AUDIO, DELETE_ALL_STOCK_AUDIOS } from "./mutation";

export const apiGetStockAudio = (id) => {
  return client
    .query({
      query: GET_STOCK_AUDIO,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.stockAudio;
    });
};

export const apiGetAllStockAudios = (filter) => {
  return client
    .query({
      query: GET_STOCK_AUDIOS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.stockAudios;
    });
};

export const apiCreateStockAudio = (data) => {
  return client
    .mutate({
      mutation: CREATE_STOCK_AUDIO,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createStockAudio.stockAudio;
    });
};

export const apiUpdateStockAudio = (data) => {
  return client
    .mutate({
      mutation: UPDATE_STOCK_AUDIO,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateStockAudio.stockAudio;
    });
};

export const apiDeleteStockAudio = (id) => {
  return client
    .mutate({
      mutation: DELETE_STOCK_AUDIO,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteStockAudio.id;
    });
};

export const apiDeleteAllStockAudios = (filter = {}) => {
  return client
    .mutate({
      mutation: DELETE_ALL_STOCK_AUDIOS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteAllStockAudios?.total;
    });
};

import React from "react";
import { Box, Typography } from "@mui/material";
import CircularProgressWithLabel from "../../common/CircularProgressWithLabel";

function UploadingCard({ assetUploading }) {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        borderRadius: 2,
        background: "#24262B",
      }}
    >
      <Box
        sx={{
          height: 220,
          maxHeight: 220,
          borderRadius: 2,
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <CircularProgressWithLabel
          value={assetUploading?.progress}
          style={{ width: 60, height: 60 }}
        ></CircularProgressWithLabel>
        <Typography variant="h6" mt={2}>
          Uploading...
        </Typography>
      </Box>
    </Box>
  );
}

export default UploadingCard;

import { gql } from "@apollo/client";

const GET_MENU = gql`
  query menu($id: ID!) {
    menu(id: $id) {
      id
      name
      desc {
        title
        short
        body
        website
        style
        rows
      }
      type
      avatar {
        name
        dir
        base
        mimetype
        status
        faIcon
        emoji
        thumbnail
        style
        type
        desc
      }
      action
      params
      rank
      style
      domain
      locationId
      component
      childNodeIds
      data
      timestamp
      updatedAt
    }
  }
`;

const GET_MENUS = gql`
  query menus($input: MenusInput!) {
    menus(input: $input) {
      total
      menus {
        id
        name
        desc {
          title
          short
          body
          website
          style
          rows
        }
        type
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
          type
          desc
        }
        action
        params
        rank
        style
        domain
        locationId
        component
        childNodeIds
        data
        timestamp
        updatedAt
      }
    }
  }
`;

export { GET_MENU, GET_MENUS };

import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { ButtonSave } from "../../components/buttons";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Close } from "@mui/icons-material";
import ButtonRemove from "../../components/buttons/ButtonRemove";

function DialogCampaign({
  open,
  profile = {},
  onClose = () => { },
  campaign,
  setCampaign,
  onSave = () => { },
  onDelete = () => {},
  loading = false,
  selectedUser = {},
}) {

  const submit = () => {
    onSave();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <ValidatorForm onSubmit={submit}>
        <DialogContent>
          <Grid container
            alignItems="center"
            mt={2}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 3,
                right: 3
              }}
              onClick={onClose}>
              <Close/>
            </IconButton>
            <Grid item xs={8}>
              <TextValidator
                fullWidth
                label="Name"
                value={campaign.name || ""}
                onChange={(e) => {
                  setCampaign({
                    ...campaign,
                    name: e.target.value,
                  });
                }}
                validators={["required"]}
                errorMessages={["This field is required"]}
                sx={{ mb: 1.5 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <TextValidator
                  fullWidth
                  type="number"
                  label="Modifier"
                  value={campaign.initiative}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      initiative: parseInt(e.target.value),
                      rank: parseInt(e.target.value) + parseInt(campaign.roll.major) + parseFloat(campaign.roll.minor) / 10
                    });
                  }}
                  validators={["required", "matchRegexp:^(-2|-1|0|1|2|3|4|5)$"]}
                  errorMessages={["This field is required", "Modifier value must be -2-5"]}
                  sx={{ paddingRight: 1}}
                />
                <TextValidator
                  fullWidth
                  type="number"
                  label="Roll-major"
                  value={campaign?.roll?.major}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      roll: {
                        ...campaign?.roll,
                        major: parseInt(e.target.value),
                      },
                      rank: parseInt(campaign.initiative) + parseInt(e.target.value) + parseFloat(campaign.roll.minor) / 10
                    });
                  }}
                  validators={["required", "matchRegexp:^(2[0]|1[0-9]|[1-9])$$"]}
                  errorMessages={["This field is required", "Major must be 1-20"]}
                />
                <Typography sx={{ fontSize: '3rem' }}>.</Typography>
                <TextValidator
                  fullWidth
                  type="number"
                  label="Roll-minor"
                  value={campaign?.roll?.minor}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      roll: {
                        ...campaign?.roll,
                        minor: parseInt(e.target.value)
                      },
                      rank: parseInt(campaign.initiative) + parseInt(campaign.roll.major) + parseFloat(e.target.value) / 10
                    });
                  }}
                  validators={["required", "matchRegexp:^[0-9]{1}$"]}
                  errorMessages={["This field is required", "Minor must be 0-9"]}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
          {['sysadmin', 'superAdmin'].includes(profile?.role) && selectedUser?.role === 'other' ? 
          <ButtonRemove
            onClick={() => {
              onDelete();
            }}
            isLoading={loading}
          >
          </ButtonRemove>: <Box></Box>}
          <ButtonSave
            loading={loading}
          ></ButtonSave>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default DialogCampaign;

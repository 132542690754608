import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Amplify } from "aws-amplify";
import { ConfirmProvider } from "material-ui-confirm";
import { UploadProvider } from "./contexts/UploadContext";
import { readCurrentUser } from "./store/auth.slice";
import LayoutContextProvider from "./contexts/LayoutContext";
import AppContextProvider from "./contexts/AppContext";
import MainRoutes from "./routes/Routes";
import config from "./config";

Amplify.configure(config.AWS);

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((store) => store.auth);

  useEffect(() => {
    if (auth.userId && auth.authenticated) {
      dispatch(readCurrentUser(auth.cognitoUser));
    }
  }, [auth.userId, auth.authenticated, dispatch]); //eslint-disable-line

  return (
    <UploadProvider>
      <LayoutContextProvider>
        <AppContextProvider>
          <ConfirmProvider
            defaultOptions={{
              confirmationButtonProps: {
                color: "primary",
                variant: "contained",
              },
            }}
          >
            <MainRoutes></MainRoutes>
          </ConfirmProvider>
        </AppContextProvider>
      </LayoutContextProvider>
    </UploadProvider>
  );
};

export default App;

import { Box } from "@mui/material";
import React from "react";
import ProfileComponent from "../../Editors/ProfileEditor/ProfileEditorComponent";

function ProfileDrawer({ open, onClose, ...props }) {
  return (
    <Box
      display={open ? "block" : "hiddem"}
      anchor="bottom"
      sx={{
        px: 1,
        py: 0.5,
        background: "#D9D9D9",
        position: "fixed",
        right: { xs: 0, sm: 10 },
        bottom: { xs: open ? 0 : -300, sm: open ? 48 : -300 },
        width: { xs: "100%", sm: "calc(100% - 20px)" },
        maxWidth: "420px",
        zIndex: 99,
        borderRadius: 1,
        transition: "all 0.75s ease-in-out",
      }}
    >
      <ProfileComponent open={open} onClose={onClose}></ProfileComponent>
    </Box>
  );
}

export default ProfileDrawer;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid_v4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Box, BottomNavigation, BottomNavigationAction } from "@mui/material";
import {
  MovieFilter as MovieFilterIcon,
  Work as IconWork,
  Person as IconPerson,
  Groups as IconGroups,
  Add as IconPlus,
} from "@mui/icons-material/";
import { myToast } from "../../libs";
import { getFileExt, getAssetDisplayName, isPossibleTypeForAsset, isImageType } from "../../utils";
import { useUploadContext } from "../../contexts/UploadContext";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { useAppContext } from "../../contexts/AppContext";
import { useConfirm } from "material-ui-confirm";
import { GUEST } from "../../config";
import { Auth } from "aws-amplify";
import { ACCEPT_ASSET_TYPES } from "../../utils/constants";
import AssetEditDrawer from "../../components/CustomDrawers/AssetEditDrawer/AssetEditDrawer";
import { apiCreateAsset } from "../../graphQL/asset";
import { actionUpdateAsset, actionUpdateMyAsset } from "../../store/Asset.slice";
function BottomNavigationBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const userId = useSelector((store) => store.auth.userId);
  const currentUser = useSelector((store) => store.auth.PrivateInfo);
  const myAssetFolders = useSelector((store) => store.AssetFolder.myAssetFolders);

  const [{ queue }, { upload }] = useUploadContext();
  const [{ showBottomNavigationBar }] = useLayoutContext();
  const [{ domain }] = useAppContext();
  const [value, setValue] = useState("");
  const [filter, setFilter] = useState("all");

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const uploadAssetRef = useRef();
  const [editAsset, setEditAsset] = useState(null);
  const [file, setFile] = useState();
  const onUpload = (file, data) => {
    uploadAssetRef.current.value = "";
    const ext = getFileExt(file.name);
    if (!isPossibleTypeForAsset(file.type)) {
      myToast.error("Unknown file type. Please upload video, audio or image file!");
      return false;
    }
    // const id = uuid_v4();
    const displayName = getAssetDisplayName(file.type);
    const fileName = `${uuid_v4()}.${ext}`;
    setEditAsset(null);
    upload(file, "asset", data.id, `${domain}/${data.id}/${fileName}`, (err, result, callbackDone) => {
      if (err) {
        myToast.error("Upload was failed.");
        return;
      }
      const uploadedVideo = {
        name: fileName,
        directory: `${domain}/${data.id}`,
        contentType: file.type,
        displayName,
      };
      let variables = {
        ...data,
        name: uploadedVideo?.displayName,
        owner: userId,
        domain,
        locationId: currentUser.locationId || editAsset.locationId,
        rank: moment().utc().valueOf(),
        status: isImageType(uploadedVideo.contentType) ? "Converted" : "Converting",
        parentNodeId:
          filter && !["all", "highlight", "me", "published", "unpublished"].includes(filter)
            ? filter
            : currentUser?.defaultMediaFolderId || myAssetFolders[0]?.id,
        file: uploadedVideo,
        autoPublish: true,
      };
      apiCreateAsset(variables)
        .then((res) => {
          dispatch(actionUpdateMyAsset(res));
          dispatch(actionUpdateAsset(res));
        })
        .catch(() => {})
        .then(() => {
          callbackDone && callbackDone();
        });
    });
  };

  const isGuestUser = () => {
    if (PrivateInfo?.name === GUEST.email) {
      confirm({
        title: "You are in guest mode. Please register a free account to unlock this feature.",
        confirmationText: "SIGN IN",
        cancellationText: "BACK",
        titleProps: {
          textAlign: "center",
        },
        cancellationButtonProps: {
          style: {
            background: "white",
            color: "black",
          },
        },
        confirmationButtonProps: {
          style: {
            background: "white",
            color: "black",
          },
        },
        dialogActionsProps: {
          style: {
            justifyContent: "space-between",
          },
        },
      })
        .then(() => {
          Auth.signOut();
        })
        .catch(() => {});
      return true;
    }
    return false;
  }

  const handleAction = (action) => {
    if (!isGuestUser()) navigate(action);
  };

  return (
    <Box
      className="bottom-navigation-bar"
      sx={{
        bottom: showBottomNavigationBar ? 0 : -65,
      }}
    >
      <BottomNavigation
        sx={{
          height: 64,
          "&.MuiBottomNavigation-root": {
            background: "#252525",
          },
        }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="Profile"
          icon={<IconPerson />}
          onClick={() => {
            handleAction("/profile");
          }}
        />
        <BottomNavigationAction
          label="My Stuff"
          icon={<IconWork />}
          onClick={() => {
            handleAction("/stuffs");
          }}
        />
        <BottomNavigationAction
          label=""
          icon={
            <label>
              <IconPlus fontSize="large" />{" "}
               {PrivateInfo?.name === GUEST.email ? <></> : <input
                ref={uploadAssetRef}
                hidden
                accept={ACCEPT_ASSET_TYPES}
                type="file"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const id = uuid_v4();
                    setEditAsset({
                      id,
                      isCreate: true,
                    });
                    setFile(e.target.files[0]);
                    // return onUpload(e.target.files[0]);
                  }
                }}
              />}
            </label>
          }
          onClick={() => {
            console.log("---");
            isGuestUser();
          }}
        />
        <BottomNavigationAction
          label="My Groups"
          icon={<IconGroups />}
          onClick={() => {
            handleAction("/mygroups");
          }}
        />
        <BottomNavigationAction
          label="My Media"
          icon={<MovieFilterIcon />}
          onClick={() => {
            handleAction("/myvideos");
          }}
        />
      </BottomNavigation>
      <AssetEditDrawer
        editAsset={editAsset}
        setEditAsset={setEditAsset}
        onSave={(variables) => {
          return onUpload(file, variables);
        }}
      ></AssetEditDrawer>
    </Box>
  );
}

export default BottomNavigationBar;

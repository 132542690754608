import React from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIosNew as IconArrowBackIosNew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function BottomActionBar({ onBack, center, right }) {
  const navigate = useNavigate();
  return (
    <Box
      className="bottom-action-bar"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ backgroundColor: "#24262B", height: 45, width: "100%" }}
    >
      <Box sx={{ width: "96px" }}>
        <IconButton
          onClick={() => {
            if (onBack) onBack();
            else {
              navigate("/");
            }
          }}
        >
          <IconArrowBackIosNew></IconArrowBackIosNew>
        </IconButton>
      </Box>
      {center}
      {right}
    </Box>
  );
}

BottomActionBar.defaultProps = {
  onBack: null,
  center: <Box sx={{ width: "96px" }}></Box>,
  right: <Box sx={{ width: "96px" }}></Box>,
};

export default BottomActionBar;

import { gql } from "@apollo/client";

const GET_USER_FOLDERS = gql`
  query userFolders($input: UserFoldersInput!) {
    userFolders(input: $input) {
      total
      userFolders {
        id
        name
        owner
        avatar {
          type
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
        desc {
          title
          short
          caption
        }
        assetIds {
          id
          note
        }
        widgetIds {
          id
          note
        }
        clipIds {
          id
          note
        }
        creatorIds
        noteIds
        channelIds
        style
        rank
        status
        timestamp
      }
    }
  }
`;

export { GET_USER_FOLDERS };

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllUserFolders } from "../graphQL/userFolder";

export const getAllUserFolders = createAsyncThunk("userFolder/read_user_folders", async (filter) => {
  return await apiGetAllUserFolders(filter);
});

const userFolderSlice = createSlice({
  name: "UserFolder",
  initialState: {
    total: 0,
    userFolders: null,
  },
  reducers: {
    actionUpdateUserFolder: (state, action) => {
      if (state.userFolders) {
        if (state.userFolders.find((item) => item.id === action.payload.id)) {
          state.userFolders = state.userFolders.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.userFolders = [...state.userFolders, action.payload];
          state.total = state.userFolders.length;
        }
      } else {
        state.userFolders = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteUserFolder: (state, action) => {
      state.userFolders = state.userFolders?.filter((item) => item?.id !== action.payload);
      state.total = state.userFolders.length;
    },
  },
  extraReducers: {
    [getAllUserFolders.fulfilled]: (state, action) => {
      state.total = action.payload.total;
      state.userFolders = action.payload.userFolders;
    },
    [getAllUserFolders.rejected]: (state, action) => {
      state.total = 0;
      state.userFolders = null;
    },
  },
});

export const { actionUpdateUserFolder, actionDeleteUserFolder } = userFolderSlice.actions;
export default userFolderSlice.reducer;

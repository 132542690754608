import React, { useState, useEffect } from "react";
import { Drawer, Box, ThemeProvider } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { GithubPicker as ColorPicker } from "react-color";
import { ButtonBack, ButtonSave } from "../../buttons";
import { lightTheme } from "../../../libs/theme";
import Editor from "../../editor/Editor";
import { DEFAULT_COLORS_FOR_COLOR_PICKER } from "../../../utils/constants";

function WidgetEditDrawer({
  widget = {},
  setWidget = () => { },
  onSave = () => { },
  onClose = () => { },
  isLoading = false,
  open = false,
}) {
  const [state, setState] = useState();

  const handleSubmit = () => {
    const edited = {
      ...widget,
      desc: {
        ...(widget.desc || {}),
        body: state?.value,
      },
    }
    setWidget(edited);
    onSave(edited);
  };

  useEffect(() => {
    setState({ value: widget?.desc?.body });
  }, [Boolean(widget?.id)]); //eslint-disable-line

  useEffect(() => {
    setState({value: widget?.desc?.body});
  }, [widget])

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      PaperProps={{
        sx: {
          background: "#d2d2d2",
          pt: 1,
        },
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Box sx={{ px: 1, pt: 1 }}>
            <TextValidator
              fullWidth
              size="small"
              label="Title"
              type="text"
              value={widget?.desc?.title || ""}
              onChange={(e) => {
                setWidget({
                  ...widget,
                  desc: {
                    ...(widget.desc || {}),
                    title: e.target.value,
                  },
                });
              }}
              sx={{ mb: 1 }}
            />
            <TextValidator
              fullWidth
              size="small"
              label="Description"
              rows={3}
              multiline
              value={widget?.desc?.short || ""}
              onChange={(e) => {
                setWidget({
                  ...widget,
                  desc: {
                    ...(widget.desc || {}),
                    short: e.target.value,
                  },
                });
              }}
              sx={{ mb: 1 }}
            />
            <TextValidator
              fullWidth
              size="small"
              label="Keyword"
              rows={1}
              multiline
              value={widget?.keyword || ""}
              onChange={(e) => {
                setWidget({
                  ...widget,
                  keyword: e.target.value
                });
              }}
            />
            <Box
              className="color-popover"
            >
              <ColorPicker
                width={"100%"}
                triangle={"hide"}
                colors={DEFAULT_COLORS_FOR_COLOR_PICKER}
                color={widget?.boxInfo?.foreground}
                onChange={(color) => {
                  setWidget({
                    ...widget,
                    boxInfo: {
                      ...widget.boxInfo,
                      foreground: color.hex
                    }
                  });
                }}
                onChangeComplete={(color, event) => {
                  setWidget({
                    ...widget,
                    boxInfo: {
                      ...widget.boxInfo,
                      foreground: color.hex
                    }
                  });
                }}
              />
            </Box>
            <Box sx={{ background: "#fff", color: "#232323" }}>
              <Editor state={state} setState={setState}></Editor>
            </Box>
          </Box>
          <Box
            sx={{ p: 0.5, height: 40, mt: 1 }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="no-select"
          >
            <ButtonBack
              sx={{ px: 1 }}
              size="small"
              onClick={onClose}
            >
              Cancel
            </ButtonBack>
            <ButtonSave loading={isLoading} sx={{ px: 1 }} size="small">
              Save
            </ButtonSave>
          </Box>
        </ValidatorForm>
      </ThemeProvider>
    </Drawer>
  );
}

export default WidgetEditDrawer;

import React, { useEffect, useState, useRef } from "react";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { PlayArrow as IconPlay, Replay as IconReplay } from "@mui/icons-material";
import SwipeActionCatcher from "../../components/common/SwipeActionCatcher";
import { getMediaUrl, getStockAudio, isAudioAsset, isImageAsset, isVideoAsset } from "../../utils";
import { AudioPlayer, ImagePlayer, VideoPlayer } from "../../components/players";
import { myToast } from "../../libs";
import CustomControls from "./CustomControls";
import { useAppContext } from "../../contexts/AppContext";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { IconButton } from "@mui/material";
import { PhotoCamera as IconPhotoCamera, Close as IconClose } from "@mui/icons-material";
import AssetDrawer from "../../components/CustomDrawers/AssetDrawer/AssetDrawer";
import {
  apiAssetAnalyticsDislikeAsset,
  apiAssetAnalyticsLikeAsset,
  apiAssetAnalyticsFullPlayed,
  apiAssetAnalyticsPartialPlayed,
  apiGetAssetAnalytics,
  apiAssetAnalyticsBookmarkedAsset,
  apiAssetAnalyticsUnbookmarkedAsset,
} from "../../graphQL/assetAnalytics";
import PlayerWatch from "../../components/players/PlayerWatch";
import CreatorThumbnail from "../../components/thumbnails/CreatorThumbnails";
import { apiGetUser } from "../../graphQL/user";
import CreatorDrawer from "../../components/CustomDrawers/CreatorDrawer/CreatorDrawer";
import NoteDrawer from "../../components/CustomDrawers/NoteDrawer/NoteDrawer";
import ScreenshotRegionSelector from "../../components/resize/ScreenshotRegionSelector";
import { useResizeDetector } from "react-resize-detector";
import DialogCapturedNote from "../../components/dialogs/DialogCapturedNote";
import useAssetNote from "../../hooks/useAssetNote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentDrawer from "../../components/CustomDrawers/CommentDrawer/CommentDrawer";
import { useDispatch, useSelector } from "react-redux";
import { apiUpdateUserFolder } from "../../graphQL/userFolder";
import { actionUpdateUserFolder } from "../../store/UserFolder.slice";
import BookmarkMenu from "../../components/menus/BookmarkMenu";
import {
  faComment,
  faBookmark as faBookmarkLight,
  faThumbsUp as faThumbsUpLight,
  faThumbsDown as faThumbsDownLight,
  faCircleInfo,
  faCamera,
  faCommentExclamation,
  faTicket,
} from "@fortawesome/pro-light-svg-icons";
import { faBookmark, faThumbsUp, faThumbsDown } from "@fortawesome/pro-solid-svg-icons";
import { faVolumeSlash } from "@fortawesome/pro-light-svg-icons";
import { faVolume } from "@fortawesome/pro-light-svg-icons";
import { isMobile } from "react-device-detect";
import ComponentDrawer from "../../components/CustomDrawers/ComponentDrawer/ComponentDrawer";
import { faMemoCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import DescriptionDrawer from "../../components/CustomDrawers/DescriptionDrawer/DescriptionDrawer";
import { GUEST } from "../../config";
import { useConfirm } from "material-ui-confirm";
import { Auth } from "aws-amplify";
import ReservationDrawer from "../../components/CustomDrawers/ReservationDrawer/ReservationDrawer";
import CategoryBar from "./CategoryBar";

const isDebug = false;

const BigPlayButton = ({ onClick, isReplay }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 20,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box
        className="icon-replay"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        sx={{
          width: "6rem",
          height: "6rem",
          borderRadius: "50%",
          backgroundColor: "#454545aa",
          color: "#f2f2f2",
          cursor: "pointer",
        }}
      >
        {isReplay ? (
          <IconReplay sx={{ fontSize: "4rem" }}></IconReplay>
        ) : (
          <IconPlay sx={{ fontSize: "4rem" }}></IconPlay>
        )}
      </Box>
      {isReplay && <Typography variant="h6">Replay</Typography>}
    </Box>
  );
};

function ForYouAssetPlayer({ assets, filter = {}, setFilter = () => {} }) {
  const playerRef = useRef();
  const InfoButtonRef = useRef();
  const CreatorButtonRef = useRef();
  const WatchButtonRef = useRef();
  const CaptureRef = useRef();
  const ImageRef = useRef();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const { width, height, ref: assetPlayerBoxRef } = useResizeDetector();

  const [{ statusOfAssets }, { actionLikeAnAsset, actionDislikeAnAsset }] = useAppContext();
  const [, { setShowTopAppBar, setVideoWindowSize }] = useLayoutContext();
  const userFolders = useSelector((store) => store.UserFolder.userFolders);
  const stockAudios = useSelector((store) => store.StockAudio.stockAudios);
  const categories = useSelector((store) => store.Category.categories);
  const [noteState, setNoteState] = useState({
    showed: false,
    pinned: false,
    note: null,
  });

  const [currentIdx, setCurrentIdx] = useState(0);
  const [currentAsset, setCurrentAsset] = useState(null);
  const [soundTrack, setSoundTrack] = useState(null);
  const [{ domainInfo }] = useAppContext();
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);

  const [isReady, setReady] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isMuted, setMuted] = useState(true);
  const [isPlaying, setPlaying] = useState(false);
  const [isReplay, setReplay] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(15);
  const [mediaUrl, setMediaUrl] = useState("");
  const [openScreenshotSelector, setOpenScreenshotSelector] = useState(false);
  const [selectedArea, setSelectedArea] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const [snapshotFile, setSnapshotFile] = useState(null);

  const playerStatus = {
    loading: isLoading,
    muted: isMuted,
    playing: isPlaying,
    replay: setReplay,
    playedSeconds,
    duration,
  };
  const [assetOwner, setAssetOwner] = useState(null);
  const [openAssetDrawer, setOpenAssetDrawer] = useState(false);
  const [openCreatorDrawer, setOpenCreatorDrawer] = useState(false);
  const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
  const [openDirectCommentDrawer, setOpendirectCommentDrawer] = useState(false);
  const bookmarkButtonRef = useRef(null);
  const [openBookmarkMenu, setOpenBookmarkMenu] = useState(false);
  const [openDescriptionDrawer, setOpenDescriptionDrawer] = useState(false);
  const [openNoteEditor, setOpenNoteEditor] = useState(false);
  const [openReservationDrawer, setOpenReservationDrawer] = useState(false);

  const [analytics, setAnalytics] = useState(null);

  useEffect(() => {
    if (currentAsset) {
      apiGetAssetAnalytics(currentAsset?.id).then((res) => {
        setAnalytics(res);
      });
    } else {
      setAnalytics(null);
    }
  }, [currentAsset]);

  const assetType = isImageAsset(currentAsset) ? "Image" : isAudioAsset(currentAsset) ? "Audio" : "Video";
  const [{ assetNote }] = useAssetNote(currentAsset?.id);
  const isVideoPlaying =
    isPlaying &&
    !openAssetDrawer &&
    !openScreenshotSelector &&
    !openCommentDrawer &&
    !openBookmarkMenu &&
    !openDirectCommentDrawer &&
    !openNoteEditor &&
    !openReservationDrawer &&
    !openCreatorDrawer &&
    !openDescriptionDrawer;

  const onPrev = () => {
    setReady(false);
    setReplay(false);
    setCurrentIdx((currentIdx + assets.length - 1) % assets.length);
    setPlayedSeconds(0);
  };

  const onNext = () => {
    setReady(false);
    setReplay(false);
    setCurrentIdx((currentIdx + 1) % assets.length);
    setPlayedSeconds(0);
  };

  const onActionPrev = () => {
    setLoading(true);
    apiAssetAnalyticsPartialPlayed(currentAsset?.id).then(() => {});
    onPrev();
  };

  const onActionNext = () => {
    setLoading(true);
    apiAssetAnalyticsPartialPlayed(currentAsset?.id).then(() => {});
    onNext();
  };

  const addAssetToDefaultBookmarkFolder = async (asset) => {
    const defaultFolder = userFolders?.length > 0 ? userFolders[0] : null;
    if (defaultFolder) {
      addAssetBookmark(defaultFolder, asset);
    }
  };

  const addAssetBookmark = async (userFolder, asset) => {
    if (userFolder) {
      let res = await apiUpdateUserFolder({
        id: userFolder?.id,
        assetIds: [
          ...(userFolder?.assetIds
            ?.filter((t) => t.id !== asset?.id)
            .map((t) => ({
              id: t?.id,
              note: t?.note,
            })) || []),
          { id: asset?.id, note: "" },
        ],
      });
      dispatch(actionUpdateUserFolder(res));
      apiAssetAnalyticsBookmarkedAsset(asset?.id);
    }
  };

  const removeAssetBookmark = async (asset) => {
    const userFolder = userFolders?.find((userFolder) => {
      return userFolder?.assetIds?.map((t) => t.id).includes(asset?.id);
    });
    if (userFolder) {
      let res = await apiUpdateUserFolder({
        id: userFolder?.id,
        assetIds: userFolder?.assetIds
          ?.filter((t) => t.id !== asset?.id)
          .map((t) => ({
            id: t?.id,
            note: t?.note,
          })),
      });
      dispatch(actionUpdateUserFolder(res));
      apiAssetAnalyticsUnbookmarkedAsset(asset?.id);
    }
  };

  const isBookmarked = (asset) => {
    if (!userFolders || userFolders?.length === 0) return false;
    return userFolders?.some((userFolder) => {
      return userFolder?.assetIds?.map((t) => t.id).includes(asset?.id);
    });
  };

  const onHandleComponentAction = (action) => {
    if (PrivateInfo.name === GUEST.email) {
      confirm({
        title: "You are in guest mode. Please register a free account to unlock this feature.",
        confirmationText: "SIGN IN",
        cancellationText: "BACK",
        titleProps: {
          textAlign: "center",
        },
        cancellationButtonProps: {
          style: {
            background: "white",
            color: "black",
          },
        },
        confirmationButtonProps: {
          style: {
            background: "white",
            color: "black",
          },
        },
        dialogActionsProps: {
          style: {
            justifyContent: "space-between",
          },
        },
      })
        .then(() => {
          Auth.signOut();
        })
        .catch(() => {});
      return;
    }
    if (action === "PrivateComment") {
      setOpendirectCommentDrawer(true);
      setPlaying(false);
    } else if (action === "Comment") {
      setOpenCommentDrawer(true);
      setPlaying(false);
    } else if (action === "Bookmark") {
      if (isBookmarked(currentAsset)) {
        removeAssetBookmark(currentAsset).then(() => {
          setOpenBookmarkMenu(false);
        });
      } else {
        setOpenBookmarkMenu(true);
        setPlaying(false);
      }
    } else if (action === "Thumb up") {
      if (!statusOfAssets[currentAsset?.id]?.liked) {
        apiAssetAnalyticsLikeAsset(currentAsset?.id).then((data) => {
          setAnalytics(data);
          actionLikeAnAsset(currentAsset?.id);
        });
      } else {
        actionLikeAnAsset(currentAsset?.id, false);
      }
    } else if (action === "Thumb down") {
      if (!statusOfAssets[currentAsset?.id]?.disliked) {
        apiAssetAnalyticsDislikeAsset(currentAsset?.id).then((data) => {
          setAnalytics(data);
          actionDislikeAnAsset(currentAsset?.id);
        });
      } else {
        actionDislikeAnAsset(currentAsset?.id, false);
      }
    } else if (action === "Information") {
      setPlaying(false);
      setOpenAssetDrawer(true);
    } else if (action === "Screenshot") {
      setPlaying(false);
      setOpenScreenshotSelector(true);
    } else if (action === "PlayerWatch") {
    } else if (action === "CreatorThumbnail") {
      setPlaying(false);
      setOpenCreatorDrawer(true);
    } else if (action === "Info+") {
      setPlaying(false);
      setOpenDescriptionDrawer(true);
    } else if (action === "Reservation") {
      setPlaying(false);
      setOpenReservationDrawer(true);
    }
  };

  useEffect(() => {
    if (assets?.length) {
      if (currentIdx < 0) return;
      console.log(`Move to ${currentIdx + 1}th Asset`);
  
      const asset = assets[currentIdx];
  
      setCurrentAsset(asset);
    }
  }, [assets, currentIdx]);

  useEffect(() => {
    setNoteState({
      showed: false,
      pinned: false,
      note: null,
    });

    setMediaUrl(getMediaUrl(currentAsset));
    if (currentAsset?.stockAudioId) {
      const stockAudio = stockAudios?.find((audio) => audio.id === currentAsset.stockAudioId);
      setSoundTrack(stockAudio);
      console.log(getStockAudio(stockAudio));
      console.log(stockAudio);
    }
    if (isImageAsset(currentAsset)) {
      setPlayedSeconds(0);
      setDuration(15);
      setLoading(false);
      setPlaying(true);
    } else {
      setPlaying(true);
    }
  }, [currentAsset]);

  useEffect(() => {
    if (isImageAsset(currentAsset) && isVideoPlaying) {
      let time = playedSeconds;
      const timer = setInterval(() => {
        time += 0.1;
        setPlayedSeconds(time);
        if (time >= 15) onActionNext();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [currentAsset, isVideoPlaying]); //eslint-disable-line

  useEffect(() => {
    if (currentAsset?.owner) {
      apiGetUser(currentAsset?.owner)
        .then((res) => {
          setAssetOwner(res);
        })
        .catch((err) => {
          console.log(err);
          setAssetOwner(null);
        });
    }
  }, [currentAsset?.owner]);

  useEffect(() => {
    if (assetNote) {
      const { notes } = assetNote;
      if (notes && notes?.length > 0) {
        const note = notes?.find((t) => t.start <= playedSeconds && t.start + 0.5 >= playedSeconds);
        if (note) {
          setNoteState({
            ...noteState,
            showed: true,
            note,
          });
        } else {
          if (noteState?.note?.start <= playedSeconds && noteState?.note?.start + 10 >= playedSeconds) return;
          setNoteState({
            showed: false,
            pinned: false,
            note: null,
          });
        }
      }
    }
  }, [assetNote, playedSeconds]);

  useEffect(() => {
    setVideoWindowSize({ width, height });
  }, [width, height]); //eslint-disable-line
  // console.log(isPlaying, "--------------------------");

  const handleNext = () => {
    const category = categories?.find((cat) => cat.id === filter.categories?.find((cat, index) => index === 0));
    if (category) {
      const index = categories?.findIndex((cat) => cat.id === category.id);
      if (index < categories.length - 1) {
        setFilter({
          ...filter,
          categories: [categories[index + 1].id]
        });
      }
    } else {
      if (categories?.length) {
        setFilter({
          ...filter,
          categories: [categories[0].id]
        });
      }
    }
  }
  
  const handlePrev = () => {
    const category = categories?.find((cat) => cat.id === filter.categories?.find((cat, index) => index === 0));
    if (category) {
      const index = categories?.findIndex((cat) => cat.id === category.id);
      if(index !== 0) {
        setFilter({
          ...filter,
          categories: [categories[index - 1].id]
        });
      }
    } else {
      if (categories?.length) {
        setFilter({
          ...filter,
          categories: [categories[0].id]
        });
      }
    }
  }

  return (
    <>
      <SwipeActionCatcher
        onSwipeDown={() => {
          setShowTopAppBar(true);
          onActionPrev();
        }}
        onSwipeUp={() => {
          onActionNext();
        }}
        onSwipeLeft={() => {
          handlePrev();
        }}
        onSwipeRight={() => {
          handleNext();
        }}
      >
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: 999,
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
          open={openScreenshotSelector}
        >
          <div style={{ width: "100%", height: "100%", position: "relative" }} ref={assetPlayerBoxRef}>
            <Box sx={{ position: "absolute", top: 10, right: 10 }}>
              <IconButton
                onClick={() => {
                  (() => {
                    const canvas = document.createElement("canvas");
                    canvas.width = selectedArea.width;
                    canvas.height = selectedArea.height;
                    const ctx = canvas.getContext("2d");

                    console.log(selectedArea);

                    ctx.drawImage(
                      isImageAsset(currentAsset) ? ImageRef.current : playerRef.current.getInternalPlayer(),
                      selectedArea.x,
                      selectedArea.y,
                      selectedArea.width,
                      selectedArea.height,
                      0,
                      0,
                      selectedArea.width,
                      selectedArea.height
                    );
                    return Promise.resolve(canvas);
                  })().then((canvas) => {
                    canvas.toBlob((file) => {
                      setSnapshotFile(new File([file], "1.png"));
                    }, "image/png");
                  });
                }}
              >
                <IconPhotoCamera fontSize="large"></IconPhotoCamera>
              </IconButton>
              <IconButton
                onClick={() => {
                  setOpenScreenshotSelector(false);
                  setPlaying(true);
                }}
              >
                <IconClose fontSize="large"></IconClose>
              </IconButton>
            </Box>
            <ScreenshotRegionSelector
              openScreenshotSelector={openScreenshotSelector}
              onSelect={({ width: _width, height: _height, x: clientX, y: clientY }) => {
                setSelectedArea({
                  width: _width,
                  height: _height,
                  x: clientX,
                  y: clientY,
                });
              }}
            ></ScreenshotRegionSelector>
          </div>
        </Backdrop>
        {!isVideoPlaying && !isLoading && (
          <BigPlayButton
            onClick={() => {
              if (!isReplay) {
                setPlaying(true);
              } else {
                onActionNext(true);
              }
            }}
            isReplay={isReplay}
          ></BigPlayButton>
        )}
        {assets?.length ? <Backdrop sx={{ color: "#fff", zIndex: 20, position: "absolute" }} open={isLoading || !currentAsset}>
          <CircularProgress sx={{ color: "#fff" }} />
        </Backdrop> : ""}
        {assets?.length < 1 ? <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Typography variant="h5" color="common.white">
              No asset available at this time
            </Typography>
          </Box> : <></>}
        <Box sx={{ height: "100%", width: "100%", position: "relative" }}>
          <Box sx={{ height: "100%", width: "100%", position: "relative" }} ref={CaptureRef}>
            {isImageAsset(currentAsset) && (
              <>
                <ImagePlayer
                  imageRef={ImageRef}
                  url={getMediaUrl(currentAsset)}
                  onClick={() => {
                    console.log("On Click on the player");
                    setPlaying(false);
                  }}
                ></ImagePlayer>
                {soundTrack ? (
                  <audio id="audio" loop autoplay>
                    <source src={getStockAudio(soundTrack)} type={soundTrack?.file?.contentType} />
                  </audio>
                ) : (
                  <></>
                )}
              </>
            )}
            {isAudioAsset(currentAsset) && (
              <AudioPlayer
                asset={currentAsset}
                isPlaying={isVideoPlaying}
                loading={isLoading || playedSeconds < 0.1}
              ></AudioPlayer>
            )}
          </Box>
          <Box
            // width={"100%"}
            // height={"100%"}
            sx={
              isMobile
                ? {
                    width: "100%",
                    height: "90%",
                    position: "absolute",
                    display: !isImageAsset(currentAsset) ? "initial" : "none",
                    zIndex: 15,
                    top: 0,
                    left: 0,
                  }
                : {
                    width: "100%",
                    height: "90%",
                    position: "absolute",
                    display: !isImageAsset(currentAsset) ? "initial" : "none",
                    zIndex: 15,
                    top: 0,
                    left: 0,
                  }
            }
            onClick={() => {
              console.log(isMobile, "this is mobile devicec?");
              if (isDebug) {
                myToast.success("On Click on the player");
              }
              console.log("On Click on the player");
              setPlaying(false);
            }}
          >
            <VideoPlayer
              playerRef={playerRef}
              url={mediaUrl}
              playing={isVideoPlaying}
              muted={isMuted}
              onProgress={(played, playedSeconds) => {
                setPlayedSeconds(playedSeconds);
              }}
              onReady={() => {
                setPlaying(true);
                setLoading(false);
                if (isDebug) {
                  myToast.success(`${assetType} is ready`);
                }
              }}
              onDuration={(e) => {
                setDuration(Math.floor(e));
              }}
              onBufferEnd={() => {
                setPlaying(true);
                setLoading(false);
              }}
              onStart={() => {
                setReady(true);
                if (isDebug) {
                  myToast.success(`${assetType} starts playing`);
                }
              }}
              onPlay={() => {
                setReady(true);
                setPlaying(true);
                setLoading(false);
              }}
              onPause={() => {
                if (isVideoAsset(currentAsset)) {
                  setPlaying(false);
                }
              }}
              onEnded={() => {
                if (isDebug) {
                  myToast.success(`${assetType} is ended`);
                }
                apiAssetAnalyticsFullPlayed(currentAsset?.id);
                if (currentIdx === assets?.length - 1) {
                  setPlaying(false);
                  setPlayedSeconds(0);
                  setReplay(true);
                } else {
                  onActionNext();
                }
              }}
              onError={(error) => {
                console.error(`Error : ${error.name}`);
                if (error === "hlsError") {
                  // myToast.error(`HLS Error: We can't play this video ${videoUrl}`);
                }
                if (error.name === "NotAllowedError") {
                  if (isVideoAsset(currentAsset)) {
                    setPlaying(false);
                  }
                }
              }}
              caption={currentAsset?.file?.outputCC}
            ></VideoPlayer>
          </Box>

          <Box
            alignItems="center"
            flexDirection="column"
            sx={{
              display: {
                xs: "flex",
                md: "flex",
              },
              position: "absolute",
              zIndex: 999,
              // right: isPlaying ? -60 : 10,
              right: 10,
              bottom: "12%",
              // transition: " right  0.5s",
            }}
          >
            {domainInfo?.componentList?.includes("Reservation") ? (
              <IconButton
                sx={{ color: "#D3D3D3" }}
                onClick={() => {
                  onHandleComponentAction("Reservation");
                }}
              >
                <FontAwesomeIcon icon={faTicket} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("PrivateComment") ? (
              <IconButton
                sx={{ color: "#D3D3D3" }}
                onClick={() => {
                  onHandleComponentAction("PrivateComment");
                }}
              >
                <FontAwesomeIcon icon={faCommentExclamation} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Info+") && currentAsset?.desc?.body !== null ? (
              <IconButton
                sx={{ color: "#D3D3D3" }}
                onClick={() => {
                  onHandleComponentAction("Info+");
                }}
              >
                <FontAwesomeIcon icon={faMemoCircleInfo} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Comment") ? (
              <IconButton
                sx={{ color: "#D3D3D3" }}
                onClick={() => {
                  onHandleComponentAction("Comment");
                }}
              >
                <FontAwesomeIcon icon={faComment} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Bookmark") ? (
              <>
                <IconButton
                  sx={{ color: "#D3D3D3" }}
                  onClick={() => {
                    onHandleComponentAction("Bookmark");
                  }}
                >
                  {isBookmarked(currentAsset) ? (
                    <FontAwesomeIcon
                      icon={faBookmark}
                      sx={{
                        fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faBookmarkLight}
                      sx={{
                        fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                      }}
                    />
                  )}
                </IconButton>
              </>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Thumb up/down") ? (
              <>
                <IconButton
                  sx={{ color: "#D3D3D3" }}
                  onClick={() => {
                    onHandleComponentAction("Thumb up");
                  }}
                >
                  {statusOfAssets[currentAsset?.id]?.liked ? (
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      sx={{
                        fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faThumbsUpLight}
                      sx={{
                        fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                      }}
                    />
                  )}
                </IconButton>
                <span style={{ color: "#D3D3D3" }}>{analytics?.liked ? analytics?.liked : 0}</span>
              </>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Thumb up/down") ? (
              <>
                <IconButton
                  onClick={() => {
                    onHandleComponentAction("Thumb down");
                  }}
                >
                  {statusOfAssets[currentAsset?.id]?.disliked ? (
                    <FontAwesomeIcon
                      icon={faThumbsDown}
                      sx={{
                        fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faThumbsDownLight}
                      sx={{
                        fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                      }}
                    />
                  )}
                </IconButton>
                <span style={{ color: "#D3D3D3" }}>{analytics?.disliked ? analytics?.disliked : 0}</span>
              </>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Information") ? (
              <IconButton
                sx={{ color: "#D3D3D3" }}
                size="medium"
                onClick={() => {
                  onHandleComponentAction("Information");
                }}
                // ref={InfoButtonRef}
              >
                <FontAwesomeIcon size="1x" icon={faCircleInfo} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Screenshot") ? (
              <IconButton
                sx={{ color: "#D3D3D3" }}
                size="medium"
                onClick={() => {
                  onHandleComponentAction("Screenshot");
                }}
              >
                <FontAwesomeIcon size="sm" icon={faCamera} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("PlayerWatch") ? (
              <Box sx={{ ml: 0.5, my: 0.5 }}>
                <PlayerWatch
                  openNoteEditor={openNoteEditor}
                  setOpenNoteEditor={setOpenNoteEditor}
                  playerStatus={playerStatus}
                  asset={currentAsset}
                  setVideoPlaying={setPlaying}
                  watchRef={WatchButtonRef}
                ></PlayerWatch>
              </Box>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("CreatorThumbnail") ? (
              <Box sx={{ mx: 0.5, my: 0.5 }}>
                <CreatorThumbnail
                  // buttonRef={CreatorButtonRef}
                  creator={assetOwner}
                  hideName={true}
                  onClick={() => {
                    onHandleComponentAction("CreatorThumbnail");
                  }}
                  type="assetPlayer"
                ></CreatorThumbnail>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <CategoryBar
          asset={currentAsset}
          filter={filter}
          isImage={isImageAsset(currentAsset)}
          onNext={(category) => {
            setFilter({
              ...filter,
              categories: [category.id]
            })
          }}
          onPrev={(category) => {
            setFilter({
              ...filter,
              categories: [category.id]
            })
          }}
          />
        <CustomControls
          playerStatus={playerStatus}
          numberOfAssets={assets?.length ?? 0}
          onPrev={() => {
            onActionPrev();
          }}
          onNext={() => {
            onActionNext();
          }}
          onSeek={(value) => {
            console.log(`Seek to custom seconds ${value}`);
            playerRef?.current?.seekTo(value, "seconds");
            if (isDebug) {
              myToast.success("Seeked to custom seconds");
            }
          }}
          disableSeek={isImageAsset(currentAsset)}
          currentAsset={currentAsset}
        ></CustomControls>
        <Box
          className="asset-player-actions"
          display={"flex"}
          alignItems={"flex-end"}
          justifyContent="space-between"
          sx={{
            position: "absolute",
            right: "0.5rem",
            left: "0.5rem",
            zIndex: 30,
            bottom: 42,
          }}
        >
          {/* <IconButton
          onClick={() => {
            setMuted(!isMuted);
          }}
        >
          {isMuted ? (
            <FontAwesomeIcon
              icon={faVolumeSlash}
              sx={{
                fontSize: { xs: "1.75rem", sm: "2.25rem", md: "2.75rem" },
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faVolume}
              sx={{
                fontSize: { xs: "1.75rem", sm: "2.25rem", md: "2.75rem" },
              }}
            />
          )}
        </IconButton> */}

          {/* {isMobile ? (
          <Box className="flex items-center">
            {domainInfo?.componentList?.includes("PrivateComment") ? (
              <IconButton
                size="small"
                sx={{ color: "#fff" }}
                onClick={() => {
                  setOpendirectCommentDrawer(true);
                }}
              >
                <FontAwesomeIcon icon={faCommentExclamation} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Info+") ? (
              <IconButton
                size="small"
                sx={{ color: "#fff" }}
                onClick={() => {
                  setOpenDescriptionDrawer(true);
                }}
              >
                <FontAwesomeIcon icon={faMemoCircleInfo} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Comment") ? (
              <IconButton
                size="small"
                sx={{ color: "#fff" }}
                onClick={() => {
                  setOpenCommentDrawer(true);
                }}
              >
                <FontAwesomeIcon icon={faComment} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Bookmark") ? (
              <>
                <IconButton
                  sx={{ color: "#ffffff" }}
                  size="small"
                  onClick={() => {
                    if (isBookmarked(currentAsset)) {
                      removeAssetBookmark(currentAsset).then(() => {
                        setOpenBookmarkMenu(false);
                      });
                    } else {
                      setOpenBookmarkMenu(true);
                    }
                  }}
                  ref={bookmarkButtonRef}
                >
                  {isBookmarked(currentAsset) ? (
                    <FontAwesomeIcon
                      icon={faBookmark}
                      sx={{
                        fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faBookmarkLight}
                      sx={{
                        fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                      }}
                    />
                  )}
                </IconButton>
              </>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Thumb up/down") ? (
              <IconButton
                size="small"
                sx={{ color: "#fff" }}
                onClick={() => {
                  if (!statusOfAssets[currentAsset?.id]?.liked) {
                    apiAssetAnalyticsLikeAsset(currentAsset?.id).then(() => {
                      actionLikeAnAsset(currentAsset?.id);
                    });
                  } else {
                    actionLikeAnAsset(currentAsset?.id, false);
                  }
                }}
              >
                {statusOfAssets[currentAsset?.id]?.liked ? (
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    sx={{
                      fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faThumbsUpLight}
                    sx={{
                      fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                    }}
                  />
                )}
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Thumb up/down") ? (
              <IconButton
                size="small"
                onClick={() => {
                  if (!statusOfAssets[currentAsset?.id]?.disliked) {
                    apiAssetAnalyticsDislikeAsset(currentAsset?.id).then(() => {
                      actionDislikeAnAsset(currentAsset?.id);
                    });
                  } else {
                    actionDislikeAnAsset(currentAsset?.id, false);
                  }
                }}
              >
                {statusOfAssets[currentAsset?.id]?.disliked ? (
                  <FontAwesomeIcon
                    icon={faThumbsDown}
                    sx={{
                      fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faThumbsDownLight}
                    sx={{
                      fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                    }}
                  />
                )}
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Information") ? (
              <IconButton
                sx={{ color: "#fff" }}
                size="medium"
                onClick={() => {
                  setPlaying(false);
                  setOpenAssetDrawer(true);
                }}
                ref={InfoButtonRef}
              >
                <FontAwesomeIcon size="1x" icon={faCircleInfo} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("Screenshot") ? (
              <IconButton
                sx={{ color: "#fff" }}
                size="medium"
                onClick={() => {
                  setPlaying(false);
                  setOpenScreenshotSelector(true);
                }}
              >
                <FontAwesomeIcon size="sm" icon={faCamera} />
              </IconButton>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("PlayerWatch") ? (
              <PlayerWatch
                playerStatus={playerStatus}
                asset={currentAsset}
                setVideoPlaying={setPlaying}
                watchRef={WatchButtonRef}
              ></PlayerWatch>
            ) : (
              <></>
            )}
            {domainInfo?.componentList?.includes("CreatorThumbnail") ? (
              <Box sx={{ mx: 0.5 }}>
                <CreatorThumbnail
                  buttonRef={CreatorButtonRef}
                  creator={assetOwner}
                  hideName={true}
                  onClick={() => {
                    setOpenCreatorDrawer(true);
                  }}
                  type="assetPlayer"
                ></CreatorThumbnail>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )} */}
        </Box>

        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            bottom: 0,
          }}
        >
          <ComponentDrawer
            currentAsset={currentAsset}
            playerStatus={playerStatus}
            setPlaying={setPlaying}
            assetOwner={assetOwner}
            isBookmarked={isBookmarked(currentAsset)}
            onClick={onHandleComponentAction}
            openNoteEditor={openNoteEditor}
            setOpenNoteEditor={setOpenNoteEditor}
          />
        </Box>
      </SwipeActionCatcher>
      <BookmarkMenu
        open={openBookmarkMenu}
        onClose={() => {
          setPlaying(true);
          setOpenBookmarkMenu(false);
        }}
        anchorEl={openBookmarkMenu ? bookmarkButtonRef?.current : null}
        onBookmark={(userFolder) => {
          addAssetBookmark(userFolder, currentAsset);
        }}
      ></BookmarkMenu>
      <AssetDrawer
        anchorEl={InfoButtonRef?.current}
        open={openAssetDrawer}
        onClose={() => {
          setPlaying(true);
          setOpenAssetDrawer(false);
        }}
        playerStatus={playerStatus}
        asset={currentAsset}
      ></AssetDrawer>
      <CreatorDrawer
        anchorEl={CreatorButtonRef?.current}
        open={openCreatorDrawer}
        onClose={() => {
          setPlaying(true);
          setOpenCreatorDrawer(false);
        }}
        creator={assetOwner}
      ></CreatorDrawer>
      <NoteDrawer
        open={noteState?.showed}
        onClose={() => {
          setPlaying(true);
          if (!noteState?.pinned) setNoteState({ ...noteState, showed: false });
        }}
        onPin={() => {
          setNoteState((noteState) => ({
            ...noteState,
            pinned: !noteState?.pinned,
          }));
        }}
        noteState={noteState}
      ></NoteDrawer>
      <DialogCapturedNote
        file={snapshotFile}
        asset={currentAsset}
        playerStatus={playerStatus}
        open={Boolean(snapshotFile)}
        onClose={() => {
          setPlaying(true);
          setSnapshotFile(null);
        }}
        onSaveEnded={() => {
          setSnapshotFile(null);
          setOpenScreenshotSelector(false);
          setPlaying(true);
        }}
      ></DialogCapturedNote>
      <CommentDrawer
        open={openCommentDrawer}
        setOpen={setOpenCommentDrawer}
        onClose={() => {
          setPlaying(true);
          setOpenCommentDrawer(false);
        }}
        asset={currentAsset}
      />
      <CommentDrawer
        open={openDirectCommentDrawer}
        setOpen={setOpendirectCommentDrawer}
        onClose={() => {
          setPlaying(true);
          setOpendirectCommentDrawer(false);
        }}
        asset={currentAsset}
        isDirect={true}
      />
      <ReservationDrawer
        open={openReservationDrawer}
        setOpen={setOpenReservationDrawer}
        onClose={() => {
          setPlaying(true);
          setOpenReservationDrawer(false);
        }}
        asset={currentAsset}
      />
      <DescriptionDrawer
        open={openDescriptionDrawer}
        body={currentAsset?.desc?.body}
        onClose={() => {
          setPlaying(true);
          setOpenDescriptionDrawer(false);
        }}
      />
    </>
  );
}

export default ForYouAssetPlayer;

import React, { useState, useRef } from "react";
import _ from "lodash";
import { Modal, Box, Typography, Paper, IconButton, ThemeProvider } from "@mui/material";
import { lightTheme } from "../../../libs/theme";
import { useDispatch } from "react-redux";
import {
  ColorLens as IconColorLens,
  ArrowBackIosNew as IconArrowBackIosNew,
  CloudUpload as IconSave,
} from "@mui/icons-material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { apiCreateUserFolder, apiUpdateUserFolder } from "../../../graphQL/userFolder";
import { actionUpdateUserFolder } from "../../../store/UserFolder.slice";
import { useUploadContext } from "../../../contexts/UploadContext";
import myToast from "../../../libs/toast";
import { MEmojiSelector } from "../../EmojiSelector";
import { GithubPicker as ColorPicker } from "react-color";
import { DEFAULT_COLORS_FOR_COLOR_PICKER } from "../../../utils/constants";
import { FOLDER_ICONS_ARRAY } from "../../../utils/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/pro-solid-svg-icons";

function UserFolderDrawer({ editUserFolder, setEditUserFolder }) {
  const dispatch = useDispatch();
  const form = useRef(null);
  const paddingWidth = window.innerWidth > 1200 ? (window.innerWidth - 1200) / 2 : 0;

  const [, { uploadAvatar }] = useUploadContext();

  const [avatarFile, setAvatarFile] = useState(null);
  const colorPickerRef = useRef(null);

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayUserFolderColorPicker, setDisplayUserFolderColorPicker] = useState(false);
  const [openEmojiSelector, setOpenEmojiSelector] = useState(false);

  const open = Boolean(editUserFolder);
  const handleClose = () => {
    setEditUserFolder(null);
  };

  const uploadUserFolderAvatar = (userFolder) => {
    if (avatarFile) {
      uploadAvatar(`${userFolder.domain}/${userFolder.id}`, avatarFile, (err, result, fileName) => {
        if (err) {
          myToast.error("Upload avatar was failed");
          return;
        }
        apiUpdateUserFolder({
          id: userFolder?.id,
          avatar: {
            type: "image",
            name: fileName,
            dir: userFolder.id,
            base: userFolder.domain,
            status: "uploaded",
          },
        }).then((res) => {
          dispatch(actionUpdateUserFolder(res));
          handleClose();
        });
      });
    } else {
      handleClose();
    }
  };

  return (
    <Box
      sx={{
        p: 0.5,
        background: "#d2d2d2",
        position: "absolute",
        //right: 15,
        bottom: { xs: open ? 0 : -400, sm: open ? 15 : -400 },
        width: { xs: "100%", sm: "calc(100% - 20px)" },
        maxWidth: "420px",
        zIndex: 99,
        borderRadius: { xs: 0, sm: 2 },
        transition: "all 0.75s ease-in-out",
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <Box sx={{ color: "#232323", width: "100%" }}>
          <ValidatorForm
            ref={form}
            onSubmit={async () => {
              const data = _.pick(editUserFolder, [
                "id",
                "domain",
                "locationId",
                "name",
                "desc",
                "avatar",
                "ownerId",
                "authors",
              ]);
              if (data?.id) {
                await apiUpdateUserFolder(data).then((res) => {
                  dispatch(actionUpdateUserFolder(res));
                  uploadUserFolderAvatar(res);
                });
              } else {
                await apiCreateUserFolder(data).then((res) => {
                  dispatch(actionUpdateUserFolder(res));
                  uploadUserFolderAvatar(res);
                });
              }
            }}
          >
            <Box sx={{ overflowX: "auto" }}>
              {displayUserFolderColorPicker && (
                <Box
                  className="color-popover"
                  sx={{ px: 3, position: "absolute", zIndex: 1001, width: "100%", top: 35, left: 0 }}
                >
                  <ColorPicker
                    width={"100%"}
                    triangle={"hide"}
                    colors={DEFAULT_COLORS_FOR_COLOR_PICKER}
                    color={editUserFolder?.avatar?.style?.bg}
                    onChange={(color) => {
                      setEditUserFolder({
                        ...editUserFolder,
                        avatar: {
                          ...(editUserFolder.avatar || {}),
                          style: {
                            ...(editUserFolder?.avatar?.style || {}),
                            bg: color.hex,
                          },
                        },
                      });
                      setDisplayUserFolderColorPicker(false);
                    }}
                    onChangeComplete={(color, event) => {
                      setEditUserFolder({
                        ...editUserFolder,
                        avatar: {
                          ...(editUserFolder.avatar || {}),
                          style: {
                            ...(editUserFolder?.avatar?.style || {}),
                            bg: color.hex,
                          },
                        },
                      });
                      setDisplayUserFolderColorPicker(false);
                    }}
                  />
                </Box>
              )}
              <IconButton
                onClick={() => {
                  setDisplayUserFolderColorPicker(!displayUserFolderColorPicker);
                }}
                size="small"
              >
                <IconColorLens
                  fontSize="large"
                  sx={{ color: editUserFolder?.avatar?.style?.bg || "#32353b" }}
                ></IconColorLens>
              </IconButton>
              <IconButton
                onClick={() => {
                  setOpenEmojiSelector(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faSmile}
                  color={editUserFolder?.avatar?.type === "emoticon" ? "#1890FF" : "inherit"}
                />
              </IconButton>
              {FOLDER_ICONS_ARRAY?.map((item) => (
                <IconButton
                  key={item.key}
                  onClick={(e) => {
                    setEditUserFolder({
                      ...editUserFolder,
                      avatar: {
                        type: "faIcon",
                        faIcon: item.key,
                        style: {
                          bg:
                            editUserFolder?.avatar?.style?.bg?.length === 7
                              ? editUserFolder?.avatar?.style?.bg
                              : "#000000",
                        },
                      },
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    color={editUserFolder?.avatar?.faIcon === item.key ? "#1890FF" : "inherit"}
                  />
                </IconButton>
              ))}
            </Box>
            <Box display="flex" sx={{ position: "relative", my: 1 }} alignItems="stretch">
              <Paper>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ position: "relative", width: 120, height: 120 }}
                >
                  {editUserFolder?.avatar?.type === "emoticon" && (
                    <Typography
                      sx={{
                        fontSize: 48,
                      }}
                    >
                      {editUserFolder?.avatar?.emoji}
                    </Typography>
                  )}
                  {editUserFolder?.avatar?.type === "faIcon" && (
                    <>
                      {FOLDER_ICONS_ARRAY?.find((icon) => icon.key === editUserFolder?.avatar?.faIcon) && (
                        <Typography
                          sx={{
                            fontSize: 48,
                            color: `${editUserFolder?.avatar?.style?.bg} !important`,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={FOLDER_ICONS_ARRAY?.find((icon) => icon.key === editUserFolder?.avatar?.faIcon)?.icon}
                            style={{
                              width: 48,
                              height: 48,
                              background: `${editUserFolder?.avatar?.style?.bg} !important`,
                            }}
                          ></FontAwesomeIcon>
                        </Typography>
                      )}
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: -4,
                          right: 0,
                          width: 32,
                          height: 32,
                          backgroundColor: editUserFolder?.avatar?.style?.bg,
                          border: "1px solid #353535",
                          borderRadius: 1,
                        }}
                        onClick={() => {
                          setDisplayColorPicker(!displayColorPicker);
                        }}
                      ></Box>
                    </>
                  )}
                </Box>
              </Paper>
              <Box flexGrow="1" sx={{ ml: 1 }}>
                <TextValidator
                  size="small"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  label={"Name"}
                  value={editUserFolder?.name || ""}
                  onChange={(e) => {
                    setEditUserFolder({ ...editUserFolder, name: e.target.value });
                  }}
                  sx={{ mb: 1 }}
                />
                <TextValidator
                  size="small"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  label={"Description"}
                  multiline={true}
                  rows={3}
                  value={editUserFolder?.desc?.title || ""}
                  onChange={(e) => {
                    setEditUserFolder({
                      ...editUserFolder,
                      desc: {
                        ...(editUserFolder?.desc || {}),
                        title: e.target.value,
                      },
                    });
                  }}
                />
              </Box>
              {displayColorPicker && (
                <Box
                  className="color-popover"
                  style={{
                    position: "absolute",
                    zIndex: 1001,
                    width: "100%",
                    top: 0,
                    left: 0,
                  }}
                  sx={{
                    px: 2,
                  }}
                  ref={colorPickerRef}
                >
                  <ColorPicker
                    width={"100%"}
                    triangle={"hide"}
                    colors={DEFAULT_COLORS_FOR_COLOR_PICKER}
                    color={editUserFolder?.avatar?.style?.bg}
                    onChange={(color) => {
                      setEditUserFolder({
                        ...editUserFolder,
                        avatar: {
                          ...editUserFolder.avatar,
                          style: {
                            ...editUserFolder?.avatar?.style,
                            bg: color.hex,
                          },
                        },
                      });
                      setDisplayColorPicker(false);
                    }}
                    onChangeComplete={(color, event) => {
                      setEditUserFolder({
                        ...editUserFolder,
                        avatar: {
                          ...editUserFolder.avatar,
                          style: {
                            ...editUserFolder?.avatar?.style,
                            bg: color.hex,
                          },
                        },
                      });
                      setDisplayColorPicker(false);
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ width: "100%" }}>
              <Box display={"flex"} alignItems="center" sx={{ cursor: "pointer" }} onClick={() => {}}>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <IconArrowBackIosNew></IconArrowBackIosNew>
                </IconButton>
              </Box>
              <Box display={"flex"} alignItems="center" sx={{ cursor: "pointer" }}>
                <IconButton
                  onClick={() => {
                    form.current.submit();
                  }}
                >
                  <IconSave></IconSave>
                </IconButton>
              </Box>
            </Box>
          </ValidatorForm>
        </Box>
      </ThemeProvider>
      <Modal open={openEmojiSelector} onClose={handleClose}>
        <Box>
          <MEmojiSelector
            onClose={() => {
              setOpenEmojiSelector(false);
            }}
            output={(emoji) => {
              setEditUserFolder({
                ...editUserFolder,
                avatar: {
                  type: "emoticon",
                  emoji,
                },
              });
            }}
            darkMode={true}
            recently={false}
          ></MEmojiSelector>
        </Box>
      </Modal>
    </Box>
  );
}

export default UserFolderDrawer;

import { gql } from "@apollo/client";

const GET_CATEGORY = gql`
  query category($id: ID!) {
    category(id: $id) {
      id
      name
      domain
      locationId
      desc {
        title
        short
        body
      }
      style
      timestamp
      updatedAt
    }
  }
`;

const GET_CATEGORYS = gql`
  query categories($input: CategoriesInput!) {
    categories(input: $input) {
      total
      categories {
        id
        name
        domain
        locationId
        desc {
          title
          short
          body
        }
        style
        timestamp
        updatedAt
      }
    }
  }
`;

export { GET_CATEGORY, GET_CATEGORYS };

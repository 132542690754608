import { gql } from "@apollo/client";

const GET_TEXT_COMMENT = gql`
  query textComment($id: ID!) {
    textComments(id: $id) {
      id
      authorId
      creatorId
      assetId
      title
      content
      liked
      disliked
      createdAt
      updatedAt
      timestamp
    }
  }
`;

const GET_TEXT_COMMENTS = gql`
  query textComments($input: TextCommentsInput!) {
    textComments(input: $input) {
      total
      textComments {
        id
        authorId
        creatorId
        assetId
        title
        content
        liked
        disliked
        createdAt
        updatedAt
        timestamp
      }
    }
  }
`;

export { GET_TEXT_COMMENT, GET_TEXT_COMMENTS };

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton } from "@mui/material";
import { Add as IconAdd } from "@mui/icons-material";
import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import SecondaryTopActionBar from "../../components/nav/SecondaryTopActionBar";
import { actionUpdateUserFolder, getAllUserFolders } from "../../store/UserFolder.slice";
import { getAllUsers } from "../../store/User.slice";
import UserFolderWrapper from "./UserFolderWrapper";
import Storage from "../../libs/storage";
import { apiCreateUserFolder } from "../../graphQL/userFolder";
import { defaultFolderBackgroundColor } from "../../utils/styles";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import UserFolderDrawer from "../../components/CustomDrawers/UserFolderDrawer/UserFolderDrawer";
import { useNavigate } from "react-router-dom";
import { GUEST } from "../../config";

function PageBookmarks() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const userFolders = useSelector((store) => store.UserFolder.userFolders);
  const otherProfile = useSelector((store) => store.OtherProfile.Profile);

  const [isLoading, setLoading] = useState(false);
  const [activeUserFolderId, setActiveUserFolderId] = useState(null);
  const [editUserFolder, setEditUserFolder] = useState(null);

  if(PrivateInfo?.name === GUEST.email) {
    navigate("/");
  }

  const actionCreateNewUserFolder = () => {
    if (isLoading) return;
    let lastNumber = Storage.getAutoBookmarkFolderNo();

    let timeStamp = Math.floor(new Date().valueOf());
    let variables = {
      name: `List ${lastNumber}`,
      domain: PrivateInfo?.domain,
      owner: PrivateInfo?.id,
      avatar: {
        type: "faIcon",
        faIcon: "folderBookmark",
        style: {
          bg: defaultFolderBackgroundColor,
        },
      },
      rank: timeStamp,
    };
    setLoading(true);
    apiCreateUserFolder(variables)
      .then((res) => {
        dispatch(actionUpdateUserFolder(res));
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (PrivateInfo?.domain) {
      dispatch(getAllUsers({ domain: PrivateInfo?.domain }));
    }
  }, [dispatch, PrivateInfo?.domain]);

  useEffect(() => {
    if (otherProfile) {
      dispatch(getAllUserFolders({ owner: otherProfile?.id }));
    } else {
      if (PrivateInfo?.id) {
        dispatch(getAllUserFolders({ owner: PrivateInfo?.id }));
      }
    }
  }, [dispatch, PrivateInfo?.id, otherProfile]);

  return (
    <SubPageWrapper>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            overflowY: "auto",
            py: 1,
          }}
        >
          <TransitionGroup>
            {userFolders?.map((userFolder) => (
              <CSSTransition key={userFolder.id} timeout={500} classNames="note-item">
                <UserFolderWrapper
                  open={activeUserFolderId === userFolder?.id}
                  userFolder={userFolder}
                  onEdit={() => {
                    setEditUserFolder(userFolder);
                  }}
                  onClick={() => {
                    setActiveUserFolderId(activeUserFolderId === userFolder?.id ? null : userFolder?.id);
                  }}
                ></UserFolderWrapper>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Box>
      </Box>
      <UserFolderDrawer editUserFolder={editUserFolder} setEditUserFolder={setEditUserFolder}></UserFolderDrawer>
      <SecondaryTopActionBar
        onBack={() => {
          navigate("/");
        }}
        title="My Stuff"
        right={
          <IconButton
            disabled={otherProfile}
            size="small"
            onClick={() => {
              actionCreateNewUserFolder();
            }}
          >
            <IconAdd fontSize="large"></IconAdd>
          </IconButton>
        }
      ></SecondaryTopActionBar>
    </SubPageWrapper>
  );
}

export default PageBookmarks;

import React, { useEffect, useState, useRef } from "react";
import { Box, IconButton, Backdrop, CircularProgress, Avatar } from "@mui/material";
import {
  PlayArrow as IconPlay,
  VolumeOff as IconVolumeMute,
  VolumeDown as IconVolumeDown,
  VolumeUp as IconVolumeUp,
  SkipNext as IconSkipNext,
  SkipPrevious as IconSkipPrev,
} from "@mui/icons-material";
import { useAppContext } from "../../contexts/AppContext";
import { VideoPlayer, AudioPlayer } from "../../components/players";
import { getChannelUrlFromURI, getAvatarPath } from "../../utils";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { useResizeDetector } from "react-resize-detector";
import ChannelDrawer from "../../components/CustomDrawers/ChannelDrawer/ChannelDrawer";
// import UserDrawer from "../../components/CustomDrawers/UserDrawer/UserDrawer";
import { myToast } from "../../libs";

const isDebug = false;

function ChannelPlayer({ channels, currentChannel, onPrev, onNext }) {
  const { width, height, ref: ChannelPlayerBoxRef } = useResizeDetector();

  const [{ volume, isFirstLoading }, { setVolume, setFirstLoading }] = useAppContext();
  const [, { setVideoWindowSize, setShowTopAppBar }] = useLayoutContext();

  const playerRef = useRef(null);

  const isAudioChannel = currentChannel?.type === "audio";

  const [videoUrl, setVideoUrl] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [playerStatus, setPlayerStatus] = useState({
    loading: false,
    duration: 0,
    playedSeconds: 0,
  });

  const [openChannelDrawer, setOpenChannelDrawer] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 100;

  const isVideoPlayingNow = videoPlaying && !openChannelDrawer;

  const onActionNext = () => {
    setVideoUrl("");
    setPlayerStatus({
      loading: false,
      duration: 15,
      playedSeconds: 0,
    });
    if (isDebug) {
      myToast.success("Move to Next Channel");
    }
    onNext();
  };

  const onActionPrev = () => {
    setVideoUrl("");
    setPlayerStatus({
      loading: false,
      duration: 15,
      playedSeconds: 0,
    });
    if (isDebug) {
      myToast.success("Move to Prev Channel");
    }
    onPrev();
  };

  const onDragAndDropEnd = () => {
    if (isFirstLoading || !videoPlaying || playerStatus?.loading) return;
    if (!touchStart || !touchEnd) return;
    if (openChannelDrawer) return;

    const xDistance = touchEnd?.x - touchStart?.x;
    const yDistance = touchEnd?.y - touchStart?.y;

    const isLeftSwipe = xDistance > minSwipeDistance;
    const isRightSwipe = xDistance < -minSwipeDistance;
    const isDownSwipe = yDistance > minSwipeDistance;

    if (isLeftSwipe) {
      if (isDebug) {
        myToast.success("Left Swipe Actions");
      }
      onActionPrev();
    } else if (isRightSwipe) {
      if (isDebug) {
        myToast.success("Right Swipe Actions");
      }
      onActionNext();
    }
    if (isDownSwipe) {
      if (isDebug) {
        myToast.success("Down Swipe Actions");
      }
      setShowTopAppBar();
    }
  };

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart({ x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY });
  };

  const onTouchMove = (e) => setTouchEnd({ x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY });

  const onTouchEnd = () => {
    onDragAndDropEnd();
  };

  useEffect(() => {
    if (!currentChannel) {
      setPlayerStatus({
        ...playerStatus,
        loading: true,
      });
      return;
    }
    const mediaUrl = getChannelUrlFromURI(currentChannel?.channelURI);
    if (mediaUrl) {
      setPlayerStatus({
        loading: true,
        playedSeconds: 0,
        duration: 15,
      });
      if (!isFirstLoading) {
        setVideoPlaying(true);
      }
      setVideoUrl(mediaUrl);
    } else {
      onActionNext();
    }
  }, [currentChannel]); //eslint-disable-line

  useEffect(() => {
    setVideoWindowSize({width, height});
  }, [height, width]); //eslint-disable-line

  return (
    <div
      className="channel-player-wrapper"
      style={{
        position: "relative",
        height: "100%",
      }}
      ref={ChannelPlayerBoxRef}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {(isFirstLoading || !videoPlaying) && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 20,
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box
            className="icon-replay"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={(e) => {
              e.stopPropagation();
              setFirstLoading(false);
              setVideoPlaying(true);
              if (isDebug) {
                myToast.success("You clicked Big-One-Play Button");
              }
            }}
            sx={{
              width: "6rem",
              height: "6rem",
              borderRadius: "50%",
              backgroundColor: "#454545aa",
              color: "#f2f2f2",
              cursor: "pointer",
            }}
          >
            <IconPlay sx={{ fontSize: "4rem" }}></IconPlay>
          </Box>
        </Box>
      )}
      <Backdrop sx={{ color: "#fff", zIndex: 20, position: "absolute" }} open={playerStatus?.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {isAudioChannel && (
        <AudioPlayer
          asset={currentChannel}
          isPlaying={isVideoPlayingNow && !playerStatus?.loading}
          onClick={() => {
            if (isDebug) myToast.success("On Click on the player");
            setVideoPlaying(false);
          }}
        ></AudioPlayer>
      )}
      {videoUrl && (
        <Box
          width={"100%"}
          height={"100%"}
          sx={{
            position: "absolute",
            zIndex: 15,
            top: 0,
            left: 0,
            display: isAudioChannel ? "none" : "initial",
          }}
          onClick={() => {
            if (isDebug) myToast.success("On Click on the player");
            setVideoPlaying(false);
          }}
        >
          <VideoPlayer
            playerRef={playerRef}
            url={videoUrl}
            playing={isVideoPlayingNow}
            onProgress={(played, playedSeconds) => {
              const duration = playerRef.current?.getDuration() || 15;
              if (playedSeconds >= duration) {
                if (isDebug) {
                  myToast.success(`${currentChannel?.type} is ended`);
                }
                onActionNext();
              } else {
                setPlayerStatus({
                  playedSeconds,
                  loading: false,
                  duration,
                });
              }
            }}
            onReady={() => {
              if (playerStatus?.loading) {
                setPlayerStatus({ ...playerStatus, loading: false });
              }
              if (isDebug) {
                myToast.success(`${currentChannel?.type} is ready`);
              }
            }}
            onDuration={(e) => {
              setPlayerStatus({ ...playerStatus, duration: e, loading: false });
            }}
            onStart={() => {
              setPlayerStatus({ ...playerStatus, loading: false });
              if (!isFirstLoading) setVideoPlaying(true);
              if (isDebug) {
                myToast.success(`${currentChannel?.type} starts playing`);
              }
            }}
            onPlay={() => {
              if (!isFirstLoading) setVideoPlaying(true);
              if (playerStatus?.loading) {
                setPlayerStatus({ ...playerStatus, loading: false });
              }
              if (isDebug) {
                // myToast.success(`${currentChannel?.type} continues playing`);
              }
            }}
            onEnded={() => {
              if (isDebug) {
                myToast.success(`${currentChannel?.type} is ended`);
              }
              setPlayerStatus({ ...playerStatus, loading: false });
              onActionNext();
            }}
            onError={(error) => {
              // onActionNext();
              // setPlayerStatus({ ...playerStatus, loading: false });
              if (error === "hlsError") {
                // myToast.error(`HLS Error: We can't play this video ${videoUrl}`);
              }
              if (error.name === "NotAllowedError") {
                // myToast.error(`Not Allowed Error: We can't play this video ${videoUrl}`);
              }
            }}
          ></VideoPlayer>
        </Box>
      )}
      <Box
        className="asset-player-actions"
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent="space-between"
        sx={{
          position: "absolute",
          right: 8,
          zIndex: 30,
          bottom: 16,
        }}
      >
        <IconButton
          size="large"
          onClick={() => {
            if (volume === 0) setVolume(50);
            else setVolume(0);
          }}
        >
          {volume === 0 && <IconVolumeMute fontSize="inherit"></IconVolumeMute>}
          {volume > 0 && volume < 50 && <IconVolumeDown fontSize="inherit"></IconVolumeDown>}
          {volume >= 50 && <IconVolumeUp fontSize="inherit"></IconVolumeUp>}
        </IconButton>{" "}
        {channels?.length > 1 && (
          <IconButton
            size="large"
            onClick={() => {
              onPrev();
            }}
          >
            <IconSkipPrev fontSize="inherit"></IconSkipPrev>
          </IconButton>
        )}
        {channels?.length > 1 && (
          <IconButton
            size="large"
            onClick={() => {
              onNext();
            }}
          >
            <IconSkipNext fontSize="inherit"></IconSkipNext>
          </IconButton>
        )}
        {/* <IconButton
          sx={{ color: "#fff" }}
          size="large"
          onClick={() => {
            if (!statusOfChannels[currentChannel?.id]?.liked) {
              apiChannelAnalyticsLikeChannel(currentChannel?.id).then(() => {
                actionLikeAnChannel(currentChannel?.id);
              });
            } else {
              actionLikeAnChannel(currentChannel?.id, false);
            }
          }}
        >
          {statusOfChannels[currentChannel?.id]?.liked ? (
            <IconThumbUp fontSize="inherit"></IconThumbUp>
          ) : (
            <IconThumbUpOffAlt fontSize="inherit"></IconThumbUpOffAlt>
          )}
        </IconButton>
        <IconButton
          size="large"
          onClick={() => {
            if (!statusOfChannels[currentChannel?.id]?.disliked) {
              apiChannelAnalyticsDislikeChannel(currentChannel?.id).then(() => {
                actionDislikeAnChannel(currentChannel?.id);
              });
            } else {
              actionDislikeAnChannel(currentChannel?.id, false);
            }
          }}
        >
          {statusOfChannels[currentChannel?.id]?.disliked ? (
            <IconThumbDown fontSize="inherit"></IconThumbDown>
          ) : (
            <IconThumbDownOffAlt fontSize="inherit"></IconThumbDownOffAlt>
          )}
        </IconButton> */}
        <IconButton
          sx={{ color: "#fff", p: 1 }}
          size="large"
          onClick={() => {
            if (currentChannel?.desc?.title || currentChannel?.desc?.short) {
              setOpenChannelDrawer(true);
            }
          }}
        >
          <Avatar src={getAvatarPath(currentChannel?.avatar)}></Avatar>
        </IconButton>
      </Box>
      <ChannelDrawer
        open={openChannelDrawer}
        onClose={() => {
          setOpenChannelDrawer(false);
        }}
        channel={currentChannel}
      ></ChannelDrawer>
      {/*
      <UserDrawer
        open={openUserDrawer}
        onClose={() => {
          setOpenUserDrawer(false);
        }}
        user={currentChannel}
      ></UserDrawer> */}
    </div>
  );
}

ChannelPlayer.defaultProps = {
  onActionNext: () => {},
  onActionPrev: () => {},
};

export default ChannelPlayer;

import React from "react";
import { Box } from "@mui/material";
import ReactInputVerificationCode from "react-input-verification-code";

import "./VerificationCodeInput.scss";

function VerificationCodeInput({ value, setValue }) {
  return (
    <Box className="location-auth-code">
      <ReactInputVerificationCode
        value={value}
        placeholder={null}
        length={6}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      />
    </Box>
  );
}

export default VerificationCodeInput;

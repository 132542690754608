import React from "react";
import { Box, Avatar } from "@mui/material";
import { getAvatarPath } from "../../utils";
import { CellTower as IconCellTower } from "@mui/icons-material";

import { GRADIENT_CARD_BACKGROUND } from "../../utils/constants";

function ChannelInfo({ index, channel, onClick, size }) {
  return (
    <Box
      sx={{
        cursor: "pointer",
        width: size * 0.9,
        height: size * 0.9,
      }}
    >
      <Avatar
        variant="rounded"
        sx={{
          width: size * 0.9,
          height: size * 0.9,
          background: GRADIENT_CARD_BACKGROUND[index % 5],
        }}
        src={getAvatarPath(channel.avatar)}
        onClick={() => {
          onClick();
        }}
        imgProps={{
          style: {
            objectFit: "contain",
          },
        }}
      >
        <IconCellTower
          sx={{
            fontSize: Math.floor(size / 2),
            color: "#fff",
          }}
        ></IconCellTower>
      </Avatar>
      {/* {channel?.style?.icon?.name !== "image" && (
        <Avatar
          sx={{
            bgcolor: channel?.style?.folder?.bg,
            height: iconSize,
            width: iconSize,
          }}
          variant="rounded"
        >
          <FontAwesomeIcon
            icon={getFolderIcon(channel?.style?.icon?.name)}
            style={{
              fontSize: iconSize / 2,
              color: "#fff",
            }}
          />
        </Avatar>
      )}
      {channel?.style?.icon?.name === "image" && (
        <Avatar
          variant="rounded"
          sx={{
            height: iconSize,
            width: iconSize,
          }}
          src={getAvatarPath(channel.avatar)}
        ></Avatar>
      )} */}
      {/* <Typography variant="body2" color="common.white" sx={{ mt: 1 }}>
        {channel?.name}
      </Typography> */}
    </Box>
  );
}

ChannelInfo.defaultProps = {
  index: 0,
  iconSize: 48,
  onClick: () => {},
};

export default ChannelInfo;

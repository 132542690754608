import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowBackIosNew as IconArrowBackIosNew } from "@mui/icons-material";

const BottomActionButton = styled(Button)(({ theme }) => ({
  color: "#333333",
}));

export default function BackButton(props) {
  return (
    <BottomActionButton {...props} sx={{ px: 0.5 }}>
      <IconArrowBackIosNew></IconArrowBackIosNew>
      Back
    </BottomActionButton>
  );
}

import React, { useState } from "react";
import {
  Box,
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  Slide,
  TextField,
  ThemeProvider,
  DialogActions,
} from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Clear as IconDelete } from "@mui/icons-material";
import { getHLSUrl, getPlayerTime, isVideoAsset } from "../../utils";
import _ from "lodash";
import { lightTheme } from "../../libs/theme";
import ButtonSave from "../buttons/ButtonSave";
import AssetThumbnail from "../avatars/AssetThumbnail";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function NoteEditor({ open, onClose, content, setContent }) {
  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog
        open={open}
        onClose={() => {
          onClose(false);
        }}
        maxWidth={"sm"}
        fullWidth
        TransitionComponent={Transition}
        PaperProps={{
          sx: { backgroundColor: "#e2e3e6" },
        }}
      >
        <DialogContent sx={{ px: 1, pt: 2, pb: 0 }}>
          <Box sx={{ width: "100%" }}>
            <TextField
              multiline
              rows={4}
              value={content?.note?.text}
              onChange={(e) => {
                setContent({
                  ...content,
                  note: {
                    text: e.target.value,
                  },
                });
              }}
              variant="outlined"
              fullWidth
              placeholder="Edit Note"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonSave
            onClick={() => {
              onClose(true);
            }}
          ></ButtonSave>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

function NoteCreateModal({ open, onClose, noteItems, onChange, onSave, onDelete, currentAsset }) {
  const [openNoteEditor, setOpenNoteEditor] = useState(false);
  const [noteContent, setNoteContent] = useState({
    note: {
      text: "",
    },
  });

  return (
    <>
      <NoteEditor
        open={openNoteEditor}
        onClose={(saved) => {
          if (saved) {
            onChange(
              noteItems.map((item) => {
                if (item.uId === noteContent?.uId) {
                  return noteContent;
                } else return item;
              })
            );
          }
          setOpenNoteEditor(false);
        }}
        content={noteContent}
        setContent={setNoteContent}
      ></NoteEditor>
      <ThemeProvider theme={lightTheme}>
        <Dialog
          open={open}
          onClose={() => {
            onSave();
            onClose();
          }}
          maxWidth={"sm"}
          fullWidth
          TransitionComponent={Transition}
          PaperProps={{
            sx: { backgroundColor: "transparent", boxShadow: "none" },
          }}
        >
          <DialogContent sx={{ p: 2 }}>
            <Box sx={{ width: "100%" }}>
              <TransitionGroup>
                {noteItems?.map((noteItem, index) => (
                  <CSSTransition key={index} timeout={500} classNames="note-item">
                    <Box
                      sx={{
                        mb: 1,
                        borderRadius: 2,
                        py: 1.5,
                        bgcolor: "#333333cc",
                        position: "relative",
                        height: "72px",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          left: 20,
                          top: 0,
                          width: "28px",
                          height: "72px",
                          background: "#cccccccc",
                          zIndex: 10,
                          borderRadius: "20%",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          border: "8px solid #cccccccc",
                          borderRadius: "50%",
                          position: "absolute",
                          top: 6,
                          left: 4,
                          width: "60px",
                          height: "60px",
                          zIndex: 12,
                          background: "#ffffffcc",
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="body1" sx={{ color: "#000" }} textAlign="center">
                          {getPlayerTime(noteItem?.start)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          background: "#ffffffcc",
                          height: "100%",
                          pr: isVideoAsset(currentAsset) ? "90px" : "4px",
                          pl: "72px",
                          py: 0.25,
                        }}
                        onClick={() => {
                          setNoteContent(noteItem);
                          setOpenNoteEditor(true);
                        }}
                      >
                        <Typography variant="body2">{noteItem?.note?.text}</Typography>
                      </Box>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: -5,
                          right: -5,
                          zIndex: 10,
                        }}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(noteItem?.uId);
                        }}
                      >
                        <IconDelete sx={{ color: "#fff", fontSize: "0.875rem" }} />
                      </IconButton>
                      {isVideoAsset(currentAsset) && (
                        <AssetThumbnail
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 12,
                            zIndex: 10,
                            width: 72,
                            height: 72,
                          }}
                          src={getHLSUrl(
                            `/${currentAsset?.domain}/_Thumbnails/${currentAsset?.id}/${_.padStart(
                              noteItem?.start || 1,
                              4,
                              "0"
                            )}.png`
                          )}
                          variant="rounded"
                        ></AssetThumbnail>
                      )}
                    </Box>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
}

export default NoteCreateModal;

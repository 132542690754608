import { gql } from "@apollo/client";

const GET_ASSET = gql`
  query mediaAsset($id: ID!) {
    mediaAsset(id: $id) {
      id
      domain
      locationId
      name
      owner
      type
      desc {
        title
        short
        body
        website
        caption
      }
      categories
      status
      parentNodeId
      stockAudioId
      keywords
      file {
        name
        # signedUrl
        contentType
        directory
        outputHls
        outputDash
        displayName
        outputCC
      }
      timestamp
      rank
      highlight
      updatedAt
      videoWidgets {
        id
        assetId
        name
        type
        desc {
          title
          short
          body
          website
          caption
          style
          rows
        }
        boxInfo {
          x
          y
          width
          height
          videoWidth
          videoHeight
          foreground
        }
        videoPos {
          startAt
          duration
        }
        data
        keyword
        videoWidgetType
      }
      avatar {
        name
        dir
        base
        mimetype
        status
        faIcon
        emoji
        thumbnail
        style
      }
    }
  }
`;

const GET_ASSETS = gql`
  query mediaAssets($input: MediaAssetsInput!) {
    mediaAssets(input: $input) {
      total
      mediaAssets {
        id
        domain
        locationId
        name
        owner
        type
        desc {
          title
          short
          body
          website
          caption
        }
        categories
        status
        parentNodeId
        stockAudioId
        keywords
        file {
          name
          # signedUrl
          contentType
          directory
          outputHls
          outputDash
          displayName
          outputCC
        }
        timestamp
        rank
        highlight
        updatedAt
        videoWidgets {
          id
          assetId
          name
          type
          desc {
            title
            short
            body
            website
            caption
            style
            rows
          }
          boxInfo {
            x
            y
            width
            height
            videoWidth
            videoHeight
            foreground
          }
          videoPos {
            startAt
            duration
          }
          data
          keyword
          videoWidgetType
        }
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
        }
      }
    }
  }
`;

export { GET_ASSET, GET_ASSETS };

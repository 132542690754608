import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Avatar,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Slider,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { PhotoCamera as IconPhotoCamera, Image as ImageIcon, Close as IconClose } from "@mui/icons-material";
import Cropper from "react-easy-crop";
import { ButtonSave } from "../buttons";
import getCroppedImg from "./cropImage";
import { getFileExt } from "../../utils";
import { v4 as uuid_v4 } from "uuid";
import { useRef } from "react";
import { IMAGE_RATIO } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangle, faRectangleVertical, faRectangleWide, faSquare } from "@fortawesome/pro-regular-svg-icons";
import {
  faRectangleVertical as faRectangleVerticalSelected,
  faRectangleWide as faRectangleWideSelected,
  faSquare as faSquareSelected,
  faRectangle as faRectangleSelected,
} from "@fortawesome/pro-solid-svg-icons";
import { faCamera, faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import { useUploadContext } from "../../contexts/UploadContext";
import { apiUpdateUser } from "../../graphQL/user";
import { updateCurrentUser } from "../../store/auth.slice";
import { actionUpdateUser } from "../../store/User.slice";
import { useDispatch, useSelector } from "react-redux";
import { myToast } from "../../libs";
import config from "../../config";
import { setOtherProfile } from "../../store/OtherProfile";

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const ThumbnailUploadWithEditor = ({
  url,
  onDelete = () => {},
  variant = "sqaure",
  width = 240,
  height = 120,
  Icon,
  PrivateInfo = {},
  isAvatar = true,
  isSame = false,
  disabled = false,
}) => {
  const fileRef = useRef();
  const profile = useSelector((store) => store.auth.PrivateInfo);
  const OtherProfile = useSelector((store) => store.OtherProfile.Profile);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [ratio, setRatio] = useState(1);
  const [ratioIndex, setRatioIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [, { uploadAvatar }] = useUploadContext();
  const dispatch = useDispatch();

  const actionSave = (variables) => {
    return apiUpdateUser(variables)
      .then((res) => {
        setLoading(false);
        if (profile?.id === PrivateInfo?.id) {
          dispatch(updateCurrentUser(res));
        } else if (OtherProfile?.id === PrivateInfo?.id) {
          dispatch(setOtherProfile(res));
        }
        dispatch(actionUpdateUser(res));
        setOpenDialog(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setOpenDialog(false);
      });
  };

  const handleOnChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setAvatarSrc(URL.createObjectURL(e.target.files[0]));
      setUploadFileName(`${uuid_v4()}.${getFileExt(e.target.files[0].name)}`);

      fileRef.current.value = "";
      // setAvatarSrc(URL.createObjectURL(e.target.files[0]));
      setOpenDialog(true);
      // return onChange(e.target.files[0]);
    }
    return onChange(null);
  };

  useEffect(() => {
    setAvatarSrc(url);
  }, [url]);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(avatarSrc, croppedAreaPixels, rotation);
      onChange(new File([croppedImage], uploadFileName));
      setAvatarSrc(URL.createObjectURL(croppedImage));
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const handleRadioChange = (value) => {
    setRatio(IMAGE_RATIO[value].value);
    setRatioIndex(value);
  };

  const onChange = (file) => {
    if (!file) return;
    const directory = PrivateInfo?.avatar?.dir
      ? PrivateInfo?.avatar?.dir
      : `${PrivateInfo?.domain || "admin"}/${PrivateInfo?.id}`;
    setLoading(true);
    uploadAvatar(directory, file, (err, result, fileName) => {
      if (err) {
        myToast.error("Upload thumbnail was failed");
        return;
      }
      const avatar = {
        ...(PrivateInfo.avatar || {}),
        dir: directory,
        base: config.ENV.REACT_APP_HLS_BASE_URL,
        status: "uploaded",
        mimetype: file?.type,
      };
      if (isAvatar) {
        avatar.name = fileName;
      } else {
        avatar.thumbnail = fileName;
      }
      actionSave({ id: PrivateInfo?.id, avatar });
    });
  };

  const isCircle = variant === "circle";

  return (
    <Box
      sx={{ textAlign: "center", position: "relative" }}
      display="flex"
      alignItems="flex-end"
      justifyContent="center"
    >
      <Dialog
        open={openDialog}
        onClose={() => {
          setAvatarSrc(url);
          setOpenDialog(false);
        }}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogContent>
          <Box sx={{ height: 300, position: "relative", mb: 2 }}>
            <Cropper
              image={avatarSrc}
              rotation={rotation}
              crop={crop}
              zoom={zoom}
              aspect={ratio}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={() => {}}
              zoomWithScroll={true}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" sx={{ width: 120 }} textAlign="center">
              Zoom
            </Typography>
            <PrettoSlider
              size="small"
              aria-label="Small"
              valueLabelDisplay="auto"
              min={0.5}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e) => {
                setZoom(e.target.value);
              }}
            />
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="body1" sx={{ width: 120 }} textAlign="center">
              Rotation
            </Typography>
            <PrettoSlider
              size="small"
              aria-label="Small"
              valueLabelDisplay="auto"
              min={0}
              max={360}
              step={1}
              value={rotation}
              onChange={(e) => {
                setRotation(e.target.value);
              }}
            />
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="body1" sx={{ width: 120 }} textAlign="center">
              Ratio
            </Typography>
            <IconButton
              onClick={() => {
                handleRadioChange(0);
              }}
            >
              <FontAwesomeIcon icon={ratioIndex === 0 ? faSquareSelected : faSquare} size="lg" />
            </IconButton>
            <IconButton
              onClick={() => {
                handleRadioChange(1);
              }}
            >
              <FontAwesomeIcon icon={ratioIndex === 1 ? faRectangleSelected : faRectangle} size="lg" rotation={90} />
            </IconButton>
            <IconButton
              onClick={() => {
                handleRadioChange(2);
              }}
            >
              <FontAwesomeIcon icon={ratioIndex === 2 ? faRectangleSelected : faRectangle} size="lg" />
            </IconButton>
            <IconButton
              onClick={() => {
                handleRadioChange(3);
              }}
            >
              <FontAwesomeIcon
                icon={ratioIndex === 3 ? faRectangleWideSelected : faRectangleWide}
                size="lg"
                rotation={90}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                handleRadioChange(4);
              }}
            >
              <FontAwesomeIcon icon={ratioIndex === 4 ? faRectangleWideSelected : faRectangleWide} size="lg" />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => {
              setAvatarSrc(url);
              setOpenDialog(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Box>
            <Button
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faTrash} size="sm" />}
              sx={{ mr: 1 }}
              onClick={() => {
                const avatar = {
                  ...(PrivateInfo.avatar || {}),
                  base: config.ENV.REACT_APP_HLS_BASE_URL,

                  mimetype: null,
                  thumbnail: null,
                };
                isAvatar ? (avatar.name = null) : (avatar.thumbnail = null);
                isAvatar && (avatar.status = null);
                actionSave({ id: PrivateInfo?.id, avatar });
              }}
            >
              Delete
            </Button>
            <ButtonSave
              loading={isLoading}
              onClick={() => {
                showCroppedImage();
              }}
            />
          </Box>
        </DialogActions>
      </Dialog>
      <Box sx={{ position: "relative" }}>
        <Avatar
          alt="Avatar"
          variant={variant}
          src={avatarSrc}
          imgProps={{
            style: {
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
            },
          }}
          sx={{
            width: width,
            height: height,
          }}
        >
          <Icon sx={{ fontSize: 60 }}></Icon>
        </Avatar>
        {/* {avatarSrc && (
          <IconButton
            onClick={() => {
              onDelete();
            }}
            color="error"
            variant="contained"
            aria-label="delete pciture"
            component="label"
            sx={{
              position: "absolute",
              top: 0,
              right: isCircle ? "-8px" : "0px",
            }}
          >
            <IconClose />
          </IconButton>
        )} */}
        <Box sx={{ position: "absolute", left: -5, bottom: -5 }}>
          <IconButton
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              fileRef.current.click();
            }}
            sx={{ color: "#D3D3D3" }}
          >
            <FontAwesomeIcon icon={faCamera} size="xs"></FontAwesomeIcon>
          </IconButton>
          <input hidden accept="image/*" type="file" onChange={handleOnChange} ref={fileRef} />
        </Box>
        <Box sx={{ position: "absolute", right: -5, top: -5 }}>
          <IconButton
            sx={{ color: "#D3D3D3" }}
            onClick={() => {
              avatarSrc && setOpenDialog(true);
            }}
            disabled={disabled || !avatarSrc || (isAvatar === false && isSame === true)}
          >
            <FontAwesomeIcon icon={faPencil} size="xs" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

ThumbnailUploadWithEditor.defaultProps = {
  Icon: ImageIcon,
};

export default ThumbnailUploadWithEditor;

import React from "react";
import { Box, Typography } from "@mui/material";
import { Logout as IconLogout, ArrowBackIosNew as IconArrowBackIosNew } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faArrowRightFromBracket } from "@fortawesome/pro-solid-svg-icons";
import "./style.scss";

const LogoutGuardView = (props) => {
  const { handleClickBack = () => {} } = props;

  return (
    <Box className="guard-div-root">
      <Box className="guard-div-label">
        <Typography variant="h6">Are you sure you want to logout?</Typography>
      </Box>
      <Box className="guard-div-actions">
        <Box className="btn-back" onClick={() => handleClickBack("cancel")} display="flex" alignItems="center">
          <IconArrowBackIosNew></IconArrowBackIosNew>
          <Typography variant="h6" sx={{ pl: 1 }}>
            Back
          </Typography>
        </Box>
        <Box className="btn-back" onClick={() => handleClickBack("logout")} display="flex" alignItems="center">
          <IconLogout sx={{ transform: "rotate(180deg)" }}></IconLogout>
          <Typography variant="h6" sx={{ pl: 1 }}>
            Logout
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default LogoutGuardView;

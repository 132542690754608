import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  Add as IconAdd,
  YouTube as IconYoutube,
  Movie as IconMovie,
  Star as IconStar,
  Visibility as IconVisibility,
  VisibilityOff as IconVisibilityOff,
  PermMedia as IconPermMedia,
} from "@mui/icons-material";
import moment from "moment";

import SubPageWrapper from "../../layouts/wrappers/SubPageWrapper";
import BottomActionBar from "../../components/nav/BottomActionBar";
import MyAssetCard from "../../components/CustomCards/MyAssetCard/MyAssetCard";
import UploadingCard from "../../components/CustomCards/UploadingCard/UploadingCard";

import { ACCEPT_ASSET_TYPES } from "../../utils/constants";
import { actionUpdateAsset, actionUpdateMyAsset, getAllMyAssets, getAllOthersAssets, reloadConvertingAssets } from "../../store/Asset.slice";
import { isPossibleTypeForAsset, getAssetDisplayName, isImageType, isAudioType, getFileExt } from "../../utils";
import { myToast } from "../../libs";
import { v4 as uuid_v4 } from "uuid";
import { useUploadContext } from "../../contexts/UploadContext";

import { getAllMyAssetFolders, getAllOthersAssetFolders } from "../../store/AssetFolder.slice";
import { apiCreateAsset, apiUpdateAsset } from "../../graphQL/asset";
import AssetEditDrawer from "../../components/CustomDrawers/AssetEditDrawer/AssetEditDrawer";
import AssetFolderAvatar from "../../components/avatars/AssetFolderAvatar";
import { useAppContext } from "../../contexts/AppContext";
import DialogAssetAnalytics from "../../components/dialogs/DialogAssetAnalytics";
import { useConfirm } from "material-ui-confirm";
import { MediaPermissionsError, MediaPermissionsErrorType, requestMediaPermissions } from "mic-check";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePlus } from "@fortawesome/pro-light-svg-icons";
import DescriptionModal from "../../components/modals/DescriptionModal";
import CommentDrawer from "../../components/CustomDrawers/CommentDrawer/CommentDrawer";
import { GUEST } from "../../config";
import { useNavigate } from "react-router-dom";
import { getAllStockAudios } from "../../store/StockAudio.slice";

function PageMyVideos() {
  const dispatch = useDispatch();
  const uploadAssetRef = useRef();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [{ domain }] = useAppContext();
  const [{ queue }, { upload }] = useUploadContext();

  const userId = useSelector((store) => store.auth.userId);
  const currentUser = useSelector((store) => store.auth.PrivateInfo);
  const myAssets = useSelector((store) => store.Asset.myAssets);
  const myAssetFolders = useSelector((store) => store.AssetFolder.myAssetFolders);
  const otherProfile = useSelector((store) => store.OtherProfile.Profile);

  const [editAsset, setEditAsset] = useState(null);
  const [filter, setFilter] = useState("all");
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [openedAssetAnalytics, setOpenedAssetAnalytics] = useState(null);
  const [file, setFile] = useState();
  const [openDescBodyEditor, setOpenDescBodyEditor] = useState(false);
  const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
  const [assetForComment, setAssetForComment] = useState(null);
  const [isPrivate, setPrivate] = useState(false);

  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);

  if(PrivateInfo?.name === GUEST.email) {
    navigate("/");
  }

  const onUpload = (file, data) => {
    uploadAssetRef.current.value = "";
    const ext = getFileExt(file.name);
    if (!isPossibleTypeForAsset(file.type)) {
      myToast.error("Unknown file type. Please upload video, audio or image file!");
      return false;
    }
    // const id = uuid_v4();
    const displayName = getAssetDisplayName(file.type);
    const fileName = `${uuid_v4()}.${ext}`;
    setEditAsset(null);
    upload(file, "asset", data.id, `${domain}/${data.id}/${fileName}`, (err, result, callbackDone) => {
      if (err) {
        myToast.error("Upload was failed.");
        return;
      }
      const uploadedVideo = {
        name: fileName,
        directory: `${domain}/${data.id}`,
        contentType: file.type,
        displayName,
      };
      let variables = {
        ...data,
        name: uploadedVideo?.displayName,
        owner: userId,
        domain,
        locationId: currentUser.locationId || editAsset.locationId,
        rank: moment().utc().valueOf(),
        status: isImageType(uploadedVideo.contentType) ? "Converted" : "Converting",
        parentNodeId:
          filter && !["all", "highlight", "me", "published", "unpublished"].includes(filter)
            ? filter
            : currentUser?.defaultMediaFolderId || myAssetFolders[0]?.id,
        file: uploadedVideo,
        autoPublish: true,
      };
      apiCreateAsset(variables)
        .then((res) => {
          dispatch(actionUpdateMyAsset(res));
          dispatch(actionUpdateAsset(res));
        })
        .catch(() => {})
        .then(() => {
          callbackDone && callbackDone();
        });
    });
  };

  const getPermission = () => {
    try {
      const isPermissionAllowed = window.localStorage.getItem("camera");
      if (isPermissionAllowed === "allowed") {
        return;
      }
      requestMediaPermissions({
        video: true,
        audio: true,
        preferCurrentTab: true,
      })
        .then(() => {
          // can successfully access camera and microphone streams
          // DO SOMETHING HERE
          window.localStorage.setItem("camera", "allowed");
        })
        .catch((err) => {
          const { type, name, message } = err;
          if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
            // browser does not have permission to access camera or microphone
            confirmPermissionDialog(
              "Browser doesn't have camera or microphone permission. Please allow camera and microphone from settings for capture video."
            );
          } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
            // user didn't allow app to access camera or microphone
            confirmPermissionDialog(
              "Website doesn't have camera or microphone permission. Please allow camera and microphone from settings for capture video."
            );
          } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
            // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
            // (mostly Windows specific problem)
            confirmPermissionDialog("Camera or microphone used by another app.");
          } else {
            // not all error types are handled by this library
          }
          console.log(type, name, message);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const confirmPermissionDialog = (message) => {
    confirm({ title: "Permission required!", description: message, hideCancelButton: true })
      .then(() => {
        // close input here.
      })
      .catch(() => {});
  };

  const handleDescriptionSave = (state, title, desc) => {
    let variables = {
      id: uuid_v4(),
      name: title,
      owner: userId,
      domain,
      rank: moment().utc().valueOf(),
      status: "Created",
      parentNodeId:
        filter && !["all", "highlight", "me", "published", "unpublished"].includes(filter)
          ? filter
          : currentUser?.defaultMediaFolderId || myAssetFolders[0]?.id,
      autoPublish: true,
      desc: {
        title,
        body: state.value,
        short: desc,
      },
    };
    apiCreateAsset(variables)
      .then((res) => {
        dispatch(actionUpdateMyAsset(res));
        dispatch(actionUpdateAsset(res));
      })
      .catch(() => {});
    setOpenDescBodyEditor(false);
  };

  useEffect(() => {
    if (myAssets?.filter((item) => item.status === "Converting").map((item) => item.id)?.length > 0) {
      const timer = setInterval(() => {
        dispatch(
          reloadConvertingAssets({
            owner: userId,
            ids: myAssets?.filter((item) => item.status === "Converting").map((item) => item.id) || [],
            ordering: {
              field: "timestamp",
              order: "Desc",
            },
          })
        );
      }, 10 * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [dispatch, myAssets, userId]);

  useEffect(() => {
    if (otherProfile) {
      dispatch(getAllOthersAssets({owner: otherProfile.id}));
      dispatch(getAllOthersAssetFolders({owner: otherProfile.id}));
    } else {
      dispatch(getAllMyAssets());
      if (userId) dispatch(getAllMyAssetFolders(userId));
    }
    dispatch(getAllStockAudios({
      domain: PrivateInfo?.domain,
    }));
  }, [dispatch, userId, otherProfile]);

  useEffect(() => {
    switch (filter) {
      case "all":
        setFilteredAssets(myAssets);
        break;
      case "highlight":
        setFilteredAssets(myAssets?.filter((t) => t.highlight));
        break;
      case "published":
        setFilteredAssets(myAssets?.filter((t) => t.status === "Ready"));
        break;
      case "unpublished":
        setFilteredAssets(myAssets?.filter((t) => t.status !== "Ready"));
        break;
      case "me":
        break;
      default:
        setFilteredAssets(myAssets?.filter((t) => t.parentNodeId === filter));
    }
  }, [filter, myAssets]);

  return (
    <SubPageWrapper>
      <Box display="flex" alignItems="center" sx={{ px: 2, pt: 2 }}>
        <FormControl sx={{ minWidth: 200 }} size="small">
          <Select
            size="small"
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            value={filter}
            sx={{
              ".MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                py: 0.5,
                ".MuiListItemIcon-root": {
                  minWidth: 32,
                },
              },
            }}
          >
            <MenuItem dense value="all">
              <ListItemIcon>
                <IconMovie></IconMovie>
              </ListItemIcon>
              <ListItemText> All Media </ListItemText>
            </MenuItem>
            <MenuItem dense value="highlight">
              <ListItemIcon>
                <IconStar></IconStar>
              </ListItemIcon>
              <ListItemText> Highlight </ListItemText>
            </MenuItem>
            <MenuItem dense value="published">
              <ListItemIcon>
                <IconVisibility />
              </ListItemIcon>
              <ListItemText> Published </ListItemText>
            </MenuItem>
            <MenuItem dense value="unpublished">
              <ListItemIcon>
                <IconVisibilityOff />
              </ListItemIcon>
              <ListItemText> Unpublished </ListItemText>
            </MenuItem>
            <MenuItem dense value="me">
              <ListItemIcon>
                <IconPermMedia />
              </ListItemIcon>
              <ListItemText> My Collections </ListItemText>
            </MenuItem>
            <Divider></Divider>
            {myAssetFolders?.map((folder) => (
              <MenuItem dense key={folder?.id} value={folder?.id}>
                <ListItemIcon>
                  <AssetFolderAvatar assetFolder={folder}></AssetFolderAvatar>
                </ListItemIcon>
                <ListItemText>{folder?.name}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box flexGrow={1} sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            overflowY: "auto",
            px: 2,
            py: 1,
          }}
        >
          <Grid container spacing={2}>
            {queue.map((assetUploading) => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={assetUploading?.id}>
                <UploadingCard assetUploading={assetUploading}></UploadingCard>
              </Grid>
            ))}
            {filteredAssets?.map((asset) => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={asset?.id}>
                <MyAssetCard
                  asset={asset}
                  onEdit={() => {
                    setEditAsset(asset);
                  }}
                  onAnalytics={() => {
                    setOpenedAssetAnalytics(asset);
                  }}
                  onChangeFolder={(folder) => {
                    apiUpdateAsset({ id: asset?.id, parentNodeId: folder?.id }).then((res) => {
                      dispatch(actionUpdateMyAsset(res));
                      dispatch(actionUpdateAsset(res));
                    });
                  }}
                  onMenuSelect={(type) => {
                    if (type === "Comments") {
                      setOpenCommentDrawer(true);
                      setPrivate(false);
                      setAssetForComment(asset);
                    } else if (type === "PrivateComments") {
                      setOpenCommentDrawer(true);
                      setPrivate(true);
                      setAssetForComment(asset);
                    }
                  }}
                ></MyAssetCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <BottomActionBar
        center={<Typography variant="h6"></Typography>}
        right={
          <Box sx={{ width: "96px" }} display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton onClick={() => {}} size="small" sx={{ mr: 1 }}>
              <IconYoutube fontSize="large"></IconYoutube>
            </IconButton>
            <IconButton
              onClick={() => {
                getPermission();
              }}
              size="small"
              component="label"
              aria-label="Upload Asset"
            >
              <IconAdd fontSize="large"></IconAdd>
              <input
                disabled={otherProfile}
                ref={uploadAssetRef}
                hidden
                accept={ACCEPT_ASSET_TYPES}
                type="file"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const id = uuid_v4();
                    setEditAsset({
                      id,
                      isCreate: true,
                    });
                    setFile(e.target.files[0]);
                    // return onUpload(e.target.files[0]);
                  }
                }}
              />
            </IconButton>
            <IconButton
              disabled={otherProfile}
              onClick={() => {
                setOpenDescBodyEditor(true);
              }}
            >
              <FontAwesomeIcon icon={faFilePlus} />
            </IconButton>
          </Box>
        }
      ></BottomActionBar>
      <AssetEditDrawer
        editAsset={editAsset}
        setEditAsset={setEditAsset}
        onSave={(variables) => {
          return onUpload(file, variables);
        }}
      ></AssetEditDrawer>
      <DialogAssetAnalytics
        asset={openedAssetAnalytics}
        onClose={() => {
          setOpenedAssetAnalytics(null);
        }}
      ></DialogAssetAnalytics>
      <DescriptionModal
        open={openDescBodyEditor}
        onClose={() => {
          setOpenDescBodyEditor(false);
        }}
        onSave={handleDescriptionSave}
        showTitle={true}
      />
      <CommentDrawer
        open={openCommentDrawer}
        setOpen={setOpenCommentDrawer}
        onClose={() => {
          setAssetForComment(null);
          setPrivate(false);
          setOpenCommentDrawer(false);
        }}
        asset={assetForComment}
        isDirect={isPrivate}
      />
    </SubPageWrapper>
  );
}

export default PageMyVideos;

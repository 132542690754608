import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAllLocations } from "../graphQL/locations";

export const getAllLocations = createAsyncThunk("location/read_locations", async (filter) => {
  filter = {
    ...filter,
    ordering: {
      field: "updatedAt",
      order: "Desc",
    },
    pagination: {
      page: 1,
      limit: 32768,
    },
  };
  const data = await apiGetAllLocations(filter);
  return data;
});

const locatonSlice = createSlice({
  name: "Location",
  initialState: {
    total: 0,
    locations: null,
  },
  reducers: {
    setAllLocations: (state, action) => {
      state.locations = action.payload?.locations;
      state.total = state.locations?.length;
    },
    actionUpdateLocation: (state, action) => {
      if (state.locations) {
        if (state.locations.find((item) => item.id === action.payload.id)) {
          state.locations = state.locations.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.locations = [...state.locations, action.payload];
          state.total = state.locations.length;
        }
      } else {
        state.locations = [action.payload];
        state.total = 1;
      }
    },
    actionDeleteLocation: (state, action) => {
      state.locations = state.locations?.filter((item) => item?.id !== action.payload);
      state.total = state.locations.length;
    },
    actionDeleteAllLocations: (state, action) => {
      state.locations = state.locations?.filter((t) => t.domain !== action.payload.domain);
      state.total = state.locations.length;
    },
  },
  extraReducers: {
    [getAllLocations.fulfilled]: (state, action) => {
      state.total = action.payload.total;
      state.locations = action.payload.locations;
    },
    [getAllLocations.rejected]: (state, action) => {
      state.total = 0;
      state.locations = null;
    },
  },
});

export const { actionUpdateLocation, actionDeleteLocation, actionDeleteAllLocations, setAllLocations } =
  locatonSlice.actions;
export default locatonSlice.reducer;

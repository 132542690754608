import React from "react";
import { useDispatch } from "react-redux";
import { Authenticator } from "@z_packages/react-mui-aws-auth-amplify";
import { Routes, Route } from "react-router-dom";
import { AUTH_USER_ROLES, AUTH_USER_TOKEN_KEY } from "../utils/constants";
import { signIn, signOut } from "../store/auth.slice";

import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

function MainRoutes() {
  const dispatch = useDispatch();

  return (
    <Routes>
      <Route path="v/*" element={<PublicRoutes></PublicRoutes>}></Route>
      <Route
        path="*"
        element={
          <Authenticator
            services={{
              onSignIn: (user) => {
                dispatch(signIn(user));
              },
              onSignOut: () => {
                dispatch(signOut());
              },
            }}
            authUserTokenKey={AUTH_USER_TOKEN_KEY}
            roles={AUTH_USER_ROLES}
          >
            <PrivateRoutes></PrivateRoutes>
          </Authenticator>
        }
      />
    </Routes>
  );
}

export default MainRoutes;

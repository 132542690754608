import React, { useEffect, useState } from "react";
import { Drawer, Box, ThemeProvider, TextField, Button, Typography, IconButton, Grid } from "@mui/material";
import { lightTheme } from "../../../libs/theme";
import { CancelRounded, Send } from "@mui/icons-material";
import ReservationItem from "./ReservationItem";
import { apiCreateReservation, apiDeleteReservation, apiGetAllReservations, apiUpdateReservation } from "../../../graphQL/reservation";
import { useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import DialogReservationReply from "../../dialogs/DialogReservationReply";
import { useOnCreateReservation, useOnDeleteReservation, useOnUpdateReservation } from "../../../graphQL/reservation/subscription";

function ReservationDrawer({
  asset = {},
  onClose = () => { },
  open = false,
  setOpen = () => { },
}) {
  const confirm = useConfirm();
  const [editReservation, setEditReservation] = useState("");
  const [loading, setLoading] = useState(false);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const OtherProfile = useSelector((store) => store.OtherProfile.Profile);
  const [reservations, setReservations] = useState();
  const [count, setCount] = useState(1);
  const [reply, setReplay] = useState();
  const [openReply, setOpenReply] = useState(false);
  const [reservation, setReservation] = useState(null);
  const [profile, setProfile] = useState();

  const { data: createReservation, loading: loadingCreateReservation, error: errorCreateReservation } = useOnCreateReservation();
  const { data: updateReservation, loading: loadingUpdateReservation, error: errorUpdateReservation } = useOnUpdateReservation();
  const { data: deleteReservation, loading: loadingDeleteReservation, error: errorDeleteReservation } = useOnDeleteReservation();

  useEffect(() => {
    if (!errorCreateReservation && !loadingCreateReservation && createReservation) {
      console.log(createReservation?.onCreateTextReservation);
      fetchReservations();
    } 
    if (errorCreateReservation) {
      console.log(errorCreateReservation)
    }
  }, [
    createReservation, loadingCreateReservation, errorCreateReservation
  ])

  useEffect(() => {
    if (!errorUpdateReservation && !loadingUpdateReservation && updateReservation) {
      console.log(updateReservation);
      fetchReservations();
    }
    if (errorUpdateReservation) {
      console.log(errorUpdateReservation)
    }
  }, [
    updateReservation, loadingUpdateReservation, errorUpdateReservation
  ])
  
  useEffect(() => {
    if (deleteReservation) {
      console.log(deleteReservation)
      fetchReservations();
    }
    if (errorDeleteReservation) {
      console.log(errorDeleteReservation)
    }

  }, [
    deleteReservation, loadingDeleteReservation, errorDeleteReservation
  ])


  useEffect(() => {
    if (asset?.id && open) {
      setReservations([]);
      fetchReservations();
    }
  }, [asset, open]);

  useEffect(() => {
    if (OtherProfile) {
      setProfile(OtherProfile);
    } else {
      setProfile(PrivateInfo);
    }
  }, [PrivateInfo, OtherProfile])

  const fetchReservations = (userId) => {
    setLoading(true);
    let variables = {};
    if (userId) {
      variables.creatorId = userId;
    } else {
      variables.assetId = asset?.id;
    }
    apiGetAllReservations(variables).then((data) => {
      setReservations(
        data?.reservations?.filter(
          (item) => item.creatorId && (item.creatorId === profile?.id || item.authorId === profile?.id)
        )
      );
      setLoading(false);
    });
  };

  const addReservation = () => {
    if (editReservation !== "") {
      apiCreateReservation({
        assetId: asset?.id,
        creatorId: profile?.id,
        authorId: asset?.owner,
        comment: editReservation,
        count: parseInt(count),
        status: "Reserved" //Completed
      }).then((data) => {
        console.log(data);
        setEditReservation("");
        fetchReservations();
      });
      setCount(1);
    }
  };

  const sendReply = (reply, status, comment) => {
    let variables = {
      id: reservation?.id || comment?.id,
    };
    if (reply) variables.reply = reply;
    if (status) variables.status = status;

    setOpenReply(false);
    apiUpdateReservation(variables).then((data) => {
      console.log(data);
      setReservations(reservations.map(item => {
        if (item.id === data.id) {
          return data;
        }
        return item;
      }))
    });
  }

  const handleReservationAction = (type, comment, status) => {
    if (type === 'delete') {
      confirm({ title: "Delete Reservation", description: "Are you sure to remove this reservation?" })
      .then(() => {
        apiDeleteReservation(comment?.id).then((id) => {
          setReservations(reservations.filter(item => item.id !== id));
        });
      })
      .catch(() => {});
    } else if (type === 'reply') {
      setOpenReply(true);
      setReservation(comment);
    } else if (type === 'status') {
      if (status !== comment.status) {
        sendReply(null, status, comment);
      }
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      PaperProps={{
        sx: {
          background: "white",
          pt: 1,
          height: "70%",
          width: "100%",
          maxWidth: "1200px",
          left: "50%",
          right: "unset",
          transform: "translateX(-50%) !important",
          borderRadius: "10px 10px 0px 0px",
        },
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <Box
          sx={{
            height: 40,
            width: "100%",
          }}
        >
          <Typography color="black" textAlign={"center"}>
            Reservation
          </Typography>
          <Box sx={{ position: "absolute", top: 10, right: 10 }}>
            <IconButton
              onClick={() => {
                onClose();
              }}
              sx={{ padding: 0, margin: 0, color: "#232323" }}
            >
              <CancelRounded />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            maxHeight: "85%",
            overflow: "auto",
            paddingBottom: 5,
          }}
        >
          {reservations?.length &&
            reservations?.map((item, index) => {
              return (
                <ReservationItem
                  key={index}
                  comment={item}
                  onClick={(type, status) => {
                    handleReservationAction(type, item, status);
                  }}
                  PrivateInfo={profile}
                />
              );
            })}
        </Box>
        <Box sx={{
          position: "absolute",
          bottom: 3,
          left: 3,
          right: 3,
          background: "white",
          px: 1,
          pt: 0,
        }}>
          <Grid container>
            <Grid item xs={2}>
            <Box
                sx={{
                  border: "2px solid #333333",
                  borderRadius: 4,
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  mr: 1
                }}
              >
                <TextField
                  type="number"
                  rows={1}
                  value={count} 
                  fullWidth
                  variant="standard"
                  sx={{
                    height: 35,
                    alignItems: "center",
                    pl: "5px",
                  }}
                  InputProps={{ disableUnderline: true }}
                  onChange={(e) => {
                    if (parseInt(e.target.value || 0) < 0) {
                      setCount(1);
                      return;
                    }
                    setCount(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  border: "2px solid #333333",
                  borderRadius: 8,
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <TextField
                  multiline
                  rows={1}
                  value={editReservation}
                  sx={{ mt: "1px", pl: 1 }}
                  onChange={(e) => {
                    setEditReservation(e.target.value);
                  }}
                  variant="standard"
                  fullWidth
                  placeholder="Add comment..."
                  InputProps={{ disableUnderline: true }}
                  onKeyDown={(event) => {
                    if (event.code === "Enter") {
                      addReservation();
                    }
                  }}
                />
                <Button onClick={addReservation} disabled={false}>
                  <Send />
                </Button>
              </Box>
            </Grid>
          </Grid>
          <DialogReservationReply
            isOpen={openReply}
            setOpen={setOpenReply}
            reply={reply}
            setReply={setReplay}
            onReply={() => {sendReply(reply)}}
          />
        </Box>
      </ThemeProvider>
    </Drawer>
  );
}

export default ReservationDrawer;

import { gql } from "@apollo/client";

const CREATE_TEXT_COMMENT = gql`
  mutation createTextComment($input: CreateTextCommentInput!) {
    createTextComment(input: $input) {
      textComment {
        id
        authorId
        creatorId
        assetId
        title
        content
        liked
        disliked
        createdAt
        updatedAt
        timestamp
      }
    }
  }
`;

const UPDATE_TEXT_COMMENT = gql`
  mutation updateTextComment($input: UpdateTextCommentInput!) {
    updateTextComment(input: $input) {
      textComment {
        id
        authorId
        creatorId
        assetId
        title
        content
        liked
        disliked
        createdAt
        updatedAt
        timestamp
      }
    }
  }
`;

const DELETE_TEXT_COMMENT = gql`
  mutation deleteTextComment($id: ID!) {
    deleteTextComment(id: $id) {
      id
    }
  }
`;

export { CREATE_TEXT_COMMENT, UPDATE_TEXT_COMMENT, DELETE_TEXT_COMMENT };

import { gql } from "@apollo/client";

const GET_ALERT_ASSET = gql`
  query alertAsset($id: ID!) {
    alertAsset(id: $id) {
      id
      domain
      locationId
      parentNodeId
      name
      avatar {
        name
        dir
        base
        mimetype
        status
        thumbnail
        style
      }
      file {
        name
        signedUrl
        contentType
        directory
        outputHls
        outputDash
        displayName
      }
      categories
      status
      rank
      availability {
        from
        to
      }
      duration
      track
      desc {
        title
        short
        body
        website
      }
      autoPublish
      data
    }
  }
`;

const GET_ALERT_ASSETS = gql`
  query alertAssets($input: AlertAssetsInput!) {
    alertAssets(input: $input) {
      total
      alertAssets {
        id
        domain
        locationId
        parentNodeId
        name
        avatar {
          name
          dir
          base
          mimetype
          status
          thumbnail
          style
        }
        file {
          name
          signedUrl
          contentType
          directory
          outputHls
          outputDash
          displayName
        }
        categories
        status
        rank
        availability {
          from
          to
        }
        duration
        track
        desc {
          title
          short
          body
          website
        }
        autoPublish
        data
      }
    }
  }
`;

export { GET_ALERT_ASSET, GET_ALERT_ASSETS };

import client from "../index.js";
import { GET_DOMAINS } from "./query.js";
import { CREATE_DOMAIN, UPDATE_DOMAIN, DELETE_DOMAIN } from "./mutation";

export const apiGetAllDomains = (filter = {}) => {
  return client
    .query({
      query: GET_DOMAINS,
      variables: {
        input: filter,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.domains;
    });
};

export const apiCreateDomain = (data) => {
  return client
    .mutate({
      mutation: CREATE_DOMAIN,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.createDomain.domain;
    });
};

export const apiUpdateDomain = (data) => {
  return client
    .mutate({
      mutation: UPDATE_DOMAIN,
      variables: {
        input: data,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.updateDomain.domain;
    });
};

export const apiDeleteDomain = (id) => {
  return client
    .mutate({
      mutation: DELETE_DOMAIN,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => {
      return res.data.deleteDomain.id;
    });
};

import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemButton,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";
import { ButtonSave } from "../../components/buttons";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { apiUpdateUser } from "../../graphQL/user";
import { updateCurrentUser } from "../../store/auth.slice";
import { actionUpdateUser } from "../../store/User.slice";
import { setOtherProfile } from "../../store/OtherProfile";

function DialogUserCategory({ open, handleClose, userId, userCategories }) {
  const categories = useSelector((store) => store.Category.categories);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const PrivateInfo = useSelector((store) => store.auth.PrivateInfo);
  const OtherProfile = useSelector((store) => store.OtherProfile.Profile);

  const handleToggle = (value) => () => {
    const currentIndex = selected?.indexOf(value);
    const newChecked = [...selected];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelected(newChecked);
  };

  useEffect(() => {
    setSelected(userCategories || []);
  }, [open, userCategories]);

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogContent>
        <List dense sx={{ width: "100%" }}>
          {categories?.filter((category) => category.domain === PrivateInfo?.domain)?.map((category) => (
            <ListItem
              key={category?.id}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={handleToggle(category.name)}
                  checked={selected?.includes(category.name)}
                />
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemText primary={<Typography variant="body1">{category.name || ""}</Typography>} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <ButtonSave
          loading={isLoading}
          onClick={() => {
            setLoading(true);
            return apiUpdateUser({
              id: userId,
              categories: selected,
            })
              .then((res) => {
                setLoading(false);
                if (userId === PrivateInfo?.id) {
                  dispatch(updateCurrentUser(res));
                } else if (userId === OtherProfile?.id) {
                  dispatch(setOtherProfile(res));
                }
                dispatch(actionUpdateUser(res));
              })
              .catch((err) => console.log(err))
              .then(() => {
                handleClose();
              });
          }}
        ></ButtonSave>
      </DialogActions>
    </Dialog>
  );
}

export default DialogUserCategory;

import { gql } from "@apollo/client";

const CREATE_ALERT_ASSET = gql`
  mutation createAlertAsset($input: CreateAlertAssetInput!) {
    createAlertAsset(input: $input) {
      alertAsset {
        id
        domain
        locationId
        parentNodeId
        name
        avatar {
          name
          dir
          base
          mimetype
          status
          thumbnail
          style
        }
        file {
          name
          signedUrl
          contentType
          directory
          outputHls
          outputDash
          displayName
        }
        categories
        status
        rank
        availability {
          from
          to
        }
        duration
        track
        desc {
          title
          short
          body
          website
        }
        autoPublish
        data
      }
    }
  }
`;

const UPDATE_ALERT_ASSET = gql`
  mutation updateAlertAsset($input: UpdateAlertAssetInput!) {
    updateAlertAsset(input: $input) {
      alertAsset {
        id
        domain
        locationId
        parentNodeId
        name
        avatar {
          name
          dir
          base
          mimetype
          status
          thumbnail
          style
        }
        file {
          name
          signedUrl
          contentType
          directory
          outputHls
          outputDash
          displayName
        }
        categories
        status
        rank
        availability {
          from
          to
        }
        duration
        track
        desc {
          title
          short
          body
          website
        }
        autoPublish
        data
      }
    }
  }
`;

const DELETE_ALERT_ASSET = gql`
  mutation deleteAlertAsset($id: ID!) {
    deleteAlertAsset(id: $id) {
      id
    }
  }
`;

const DELETE_ALL_ALERT_ASSETS = gql`
  mutation deleteAllAlertAssets($input: DeleteAllInput!) {
    deleteAllAlertAssets(input: $input) {
      total
    }
  }
`;

export { CREATE_ALERT_ASSET, UPDATE_ALERT_ASSET, DELETE_ALERT_ASSET, DELETE_ALL_ALERT_ASSETS };

import { gql } from "@apollo/client";

const CREATE_MENU = gql`
  mutation createMenu($input: CreateMenuInput!) {
    createMenu(input: $input) {
      menu {
        id
        name
        desc {
          title
          short
          body
          website
          style
          rows
        }
        type
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
          type
          desc
        }
        action
        params
        rank
        style
        domain
        locationId
        component
        childNodeIds
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const UPDATE_MENU = gql`
  mutation updateMenu($input: UpdateMenuInput!) {
    updateMenu(input: $input) {
      menu {
        id
        name
        desc {
          title
          short
          body
          website
          style
          rows
        }
        type
        avatar {
          name
          dir
          base
          mimetype
          status
          faIcon
          emoji
          thumbnail
          style
          type
          desc
        }
        action
        params
        rank
        style
        domain
        locationId
        component
        childNodeIds
        data
        timestamp
        updatedAt
      }
    }
  }
`;

const DELETE_MENU = gql`
  mutation deleteMenu($id: ID!) {
    deleteMenu(id: $id) {
      id
    }
  }
`;

const DELETE_ALL_MENUS = gql`
  mutation deleteAllMenus($input: DeleteAllInput!) {
    deleteAllMenus(input: $input) {
      total
    }
  }
`;

export { CREATE_MENU, UPDATE_MENU, DELETE_MENU, DELETE_ALL_MENUS };

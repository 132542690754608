import { gql, useSubscription } from "@apollo/client";

export const ON_CREATE_TEXT_COMMENT = gql`
  subscription onCreateTextComment{
    onCreateTextComment{
      textComment{
        id
        authorId
        creatorId
        assetId
        title
        content
        liked
        disliked
        createdAt
        updatedAt
        timestamp
      }
    }
  }
`;

export const ON_UPDATE_TEXT_COMMENT = gql`
  subscription onUpdateTextComment{
    onUpdateTextComment{
      textComment{
        id
        authorId
        creatorId
        assetId
        title
        content
        liked
        disliked
        createdAt
        updatedAt
        timestamp
      }
    }
  }
`;

export const ON_DELETE_TEXT_COMMENT = gql`
  subscription onDeleteTextComment{
    onDeleteTextComment
  }
`;

const useOnCreateTextComment= () => useSubscription(ON_CREATE_TEXT_COMMENT);
const useOnUpdateTextComment= () => useSubscription(ON_UPDATE_TEXT_COMMENT);
const useOnDeleteTextComment= () => useSubscription(ON_DELETE_TEXT_COMMENT);

export { useOnCreateTextComment, useOnUpdateTextComment, useOnDeleteTextComment};
